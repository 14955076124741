import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';

import Checkbox from 'components/Checkbox';

import {
  Modal,
  Row,
  Col,
} from 'antd';

import './style.less';

const OnboardingSelectorOne = ({
  onboardingMode = false,
  intl,
  title,
  initialValue = null,
  values = [],
  visible,
  onClose,
  onSave,
  loading,
  isRequired,
}) => {
  const t = intl.messages;

  const [ selectedValue, setSelectedValue ] = useState(initialValue);
  const [ saving, setSaving ] = useState(false);

  const handleChange = useCallback((value) => {
    setSelectedValue(value);
  }, []);

  const handleSave = useCallback(() => {
    if(
      initialValue === selectedValue
    ) {
      // Bail out
      return onClose();
    }

    setSaving(true);
    onSave(selectedValue);
  }, [
    initialValue,
    selectedValue,
    onClose,
    onSave,
  ]);

  useEffect(() => {
    if(saving && !loading) {
      setSaving(false);
      onClose();
    }
  }, [
    saving,
    loading,
    onClose,
  ]);

  return (
    <Modal
      className="Onboarding__modal"
      onOk={handleSave}
      onCancel={onClose}
      okText={t.save}
      cancelText={onboardingMode ? t.continue_without_this : t.cancel}
      visible={visible}
      title={t[title]}
      cancelButtonProps={{ style: { display: isRequired ? 'none' : 'initial' } }}
      maskClosable={!isRequired}
      keyboard={!isRequired}
      closable={!isRequired}
    >
      <div className="Onboarding__modal-inner">
        {values.map(({ name, slug }) => (
          <Row align="middle" key={name}>
            <Col span={24}>
              <Checkbox
                checked={slug === selectedValue}
                onClick={() => handleChange(slug)}
                title={name}
              />
            </Col>
          </Row>
        ))}
      </div>
    </Modal>
  );
};

export default injectIntl(OnboardingSelectorOne);
