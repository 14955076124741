import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import './style.less';

//import { isAfterNow } from 'utils/date';

import {
  requestMyInitiatives,
} from 'actions/api';

const LoadingSignedIn = ({
  organization,
  history,
  my_initiatives,
  requestMyInitiatives,
  location,
}) => {
  const replace = history.replace;
  const [ started, setStarted ] = useState(false);

  useEffect(() => {
    requestMyInitiatives(organization.slug);
    setTimeout(() => setStarted(true));
  }, [organization.slug, requestMyInitiatives]);

  const {
    items: allInitiatives,
    loading,
    error,
  } = my_initiatives;

  useEffect(() => {
    if(started && !loading) {
      if(error) {
        return replace('/signin');
      }
      const referrer = location.state && location.state.referrer;
      //const hasActiveInitiatives = !!allInitiatives.find(({ end_time }) => isAfterNow(end_time));
      //const target_url = referrer && referrer.pathname !== '/loading' ? referrer : ( hasActiveInitiatives ? '/initiative' : '/wall');
      const target_url = referrer && referrer.pathname !== '/loading' ? referrer : '/wall';
      replace(target_url);
    }
  }, [allInitiatives, loading, error, replace, started, location.state]);

  return (
    <div className="LoadingSignedIn-back">
      <img src="/images/aplanet_a.png" alt="Aplanet" />
    </div>
  )
}

const mapStateToProps = ({
  organization,
  my_initiatives = {},
}) => ({
  organization,
  my_initiatives,
});

export default injectIntl(connect(mapStateToProps, { requestMyInitiatives })(withRouter(LoadingSignedIn)));
