import { loop, Cmd } from 'redux-loop';
import { parseResponse } from 'utils';
import config from 'config';

// We need this in the backend for the link to come back here
const app = 'mobile';

const requestResend = async (email) => {
  const response = await fetch(`${config.AUTH_URL}/confirm_email`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      email,
      app,
    }),
  })

  const result = await parseResponse(response);
  return result;
};

const resendSuccess = () => ({
  type: 'RESEND_CONFIRMATION_EMAIL_COMPLETE',
});

const resendFail = ({ code, text }) => ({
  type: 'RESEND_CONFIRMATION_EMAIL_FAILED',
  code,
  text,
});

const requestConfirm = async (token, password) => {
  const response = await fetch(`${config.AUTH_URL}/confirm_email/${token}`, {
    method: 'PUT',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      password,
    }),
  })

  const result = await parseResponse(response);
  return result;
};

const confirmSuccess = (response) => ({
  ...response,
  type: 'CONFIRM_EMAIL_COMPLETE',
});

const confirmFail = ({ code, text }) => ({
  type: 'CONFIRM_EMAIL_FAILED',
  code,
  text,
});

const initialState = {
  requested: false,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'RESEND_CONFIRMATION_EMAIL_REQUEST':
      return loop({ requested: false, loading: true, error: null, },
                Cmd.run(requestResend, {
                  successActionCreator: resendSuccess,
                  failActionCreator: resendFail,
                  args: [ action.email ],
                }));
    case 'RESEND_CONFIRMATION_EMAIL_COMPLETE':
      return { requested: true, loading: false, error: null };
    case 'CONFIRM_EMAIL_REQUEST':
      return loop({ requested: false, loading: true, error: null, },
                Cmd.run(requestConfirm, {
                  successActionCreator: confirmSuccess,
                  failActionCreator: confirmFail,
                  args: [ action.token, action.password ],
                }));
    case 'RESEND_CONFIRMATION_EMAIL_FAILED':
    case 'CONFIRM_EMAIL_FAILED':
      return { requested: false, loading: false, error: action.code };
    case 'RESET_AUTH_ERROR':
      return {
        ...state,
        error: null,
      };
    case 'CONFIRM_EMAIL_COMPLETE':
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState; // The 'auth' reducer will do the rest
    default:
      return state;
  }
};

export {
  reducer as email_confirmation,
};
