import { loop, Cmd } from 'redux-loop';
import { parseResponse } from 'utils';
import { defaultSlug } from './organization';
import config from 'config';

const requestOrganization = async (signup_token, initiative_slug) => {
  const url = signup_token
    ? `${config.API_URL}/organization/${signup_token}`
    : `${config.API_URL}/organization/by_initiative_slug/${initiative_slug}`;

  const response = await fetch(url);

  const result = await parseResponse(response);
  return {
    ...result,
    signup_token,
  };
};

const requestSuccess = (organization) => ({
  type: 'ORGANIZATION_COMPLETE',
  organization,
});

const requestFail = ({ code, text }) => ({
  type: 'ORGANIZATION_FAILED',
  code,
  text,
});

const initialState = {
  done: false,
  corporate: false,
  loading: false,
  token: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'ORGANIZATION_REQUEST':
      return loop({ done: false, corporate: false, loading: true, token: null, error: null, },
                Cmd.run(requestOrganization, {
                  successActionCreator: requestSuccess,
                  failActionCreator: requestFail,
                  args: [ action.signup_token, action.initiative_slug ],
                }));
    case 'ORGANIZATION_COMPLETE':
      const slug = (action.organization || {}).slug;
      const token = slug === defaultSlug ? null : (action.organization || {}).uuid;
      const corporate = !!token;
      return { done: true, corporate, loading: false, token, error: null };
    case 'ORGANIZATION_FAILED':
      return { done: true, corporate: false, loading: false, token: null, error: action.code };
    case 'RESET_AUTH_ERROR':
      return {
        ...state,
        error: null,
      };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState; // The 'auth' reducer will do the rest
    default:
      return state;
  }
};

export {
  reducer as corporate_signup,
};
