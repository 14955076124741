import React from 'react';
import {
  useSelector
} from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router';
import NotFound from 'containers/NotFound';
import SignUp from 'containers/SignUp';
import SignIn from 'containers/SignIn';
import PreSignIn from 'containers/PreSignIn';
import SsoInitiate from 'containers/SsoInitiate';
import SsoSuccess from 'containers/SsoSuccess';
import SsoError from 'containers/SsoError';
import ConfirmEmail from 'containers/ConfirmEmail';
import RecoverPassword from 'containers/RecoverPassword';
import SetInitialPassword from 'containers/SetInitialPassword';
import ResponsibleProfile from 'containers/ResponsibleProfile';
import MyInitiatives from 'containers/MyInitiatives';
import Wall from 'containers/Wall';
import Rankings from 'containers/Rankings';
import InitiativeDetail from 'containers/InitiativeDetail';
import ProposeInitiative from 'containers/ProposeInitiative';
import Proposals from 'containers/Proposals';
import LoadingSignedIn from 'containers/LoadingSignedIn';
import Logout from 'containers/Logout';
import Settings from 'containers/Settings';
import AboutOurProgram from 'containers/AboutOurProgram';
import { useFeatureList } from 'components/FeatureSwitch';

const EXTRACT_INITIATIVE_SLUG_REGEX = new RegExp('^/initiative/([a-z0-9_-]+)/([a-z0-9_-]+)/?.*$');

const replaceWithInitiativeSignupLink = (location) => {
  const results = EXTRACT_INITIATIVE_SLUG_REGEX.exec(location.pathname)
  if(!results || !results[1]) {
    return {
      pathname: '/signup',
      state: { referrer: location },
    }
  }

  const slug = results[1];
  const signupToken = results[2];
  return {
    pathname: `/signup/i/${slug}/${signupToken || ''}`,
    state: { referrer: location },
  }
};

const ProtectedRoute = ({ isAllowed, customRedirect, ...props }) => {
  if(isAllowed) {
    return (<Route {...props} />);
  }
  
  const redirection = (customRedirect && customRedirect(props.location)) || {
    pathname: '/signin',
    state: { referrer: props.location },
  };

  return (<Redirect to={redirection} />);
};

const Routes = ({
  location,
}) => {
  const auth = useSelector(state => state.auth) || {};
  const organization = useSelector(state => state.organization);

  const defaultRoute = auth.logged_in ? '/loading' : '/welcome';
  const featureList = useFeatureList();
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to={{
          pathname: defaultRoute,
          state: { referrer: location.state && location.state.referrer },
        }}
      />
      <Route path="/signup" component={SignUp} />
      <Route path="/signin" component={SignIn} />
      <Route path="/welcome" component={PreSignIn} />
      <Route path="/confirm_email/:token" component={ConfirmEmail} />
      <Route path="/invite/:token" component={SetInitialPassword} />
      <Route path="/recover_password/:token" component={RecoverPassword} />
      <Route path="/recover_password" component={RecoverPassword} />
      <Route path="/logout" component={Logout} />
      <Route path="/sso/initiate/:provider/:redirectPath*" component={SsoInitiate} />
      <Route path="/sso/success" component={SsoSuccess} />
      <Route path="/sso/error/:code" component={SsoError} />
      <ProtectedRoute isAllowed={auth.logged_in} path="/loading" component={LoadingSignedIn} />
      <ProtectedRoute
        isAllowed={auth.logged_in}
        customRedirect={replaceWithInitiativeSignupLink}
        path="/:base(initiative|wall)/:slug/:action?"
        component={InitiativeDetail}
      />
      <ProtectedRoute isAllowed={auth.logged_in} path="/wall" component={Wall} />
      <ProtectedRoute isAllowed={auth.logged_in} path="/initiative" component={MyInitiatives} />
      { !featureList.has('proposals') ? null :
        <ProtectedRoute isAllowed={auth.logged_in} path="/proposal/new/:step" component={ProposeInitiative} />
      }
      { !featureList.has('proposals') ? null :
        <ProtectedRoute isAllowed={auth.logged_in} path="/proposal" component={Proposals} />
      }
      { !featureList.has('rankings') || organization.config.hide_ranking_in_app ? null :
        <ProtectedRoute isAllowed={auth.logged_in} path="/rankings" component={Rankings} />
      }
      { !featureList.has('responsible_profile') ? null :
        <ProtectedRoute isAllowed={auth.logged_in} path="/responsible_profile" component={ResponsibleProfile} />
      }
      { !featureList.has('about_our_program') ? null :
        <ProtectedRoute isAllowed={auth.logged_in} path="/about-our-program" component={AboutOurProgram} />
      }
      <ProtectedRoute isAllowed={auth.logged_in} path="/settings" component={Settings} />
      <Route component={NotFound} />
    </Switch>
    )
}

export default withRouter(Routes);
