import { loop, Cmd } from 'redux-loop';
import { history } from 'store';

import { parseResponse } from 'utils';
import { tryRefreshFollowUrl } from 'utils/sso';
import { changeLocale } from '../intl'; // NOTICE: 'intl' is a node package
import config from 'config';

const DEFAULT_LOCALE = navigator.language || navigator.userLanguage;
const DEFAULT_PASSWORD_POLICY = {
  minLength: 6,
  maxLength: 80,
  forbidEmail: true,
};

const requestPush = async (url, state) => {
  history.push(url, { referrer: state });
  return Promise.resolve();
};

const requestSignup = async (name, email, password, agreeTC, agreeComms, language, signup_token, public_organization_slug) => {
  const response = await fetch(`${config.API_URL}/signup`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      name,
      email,
      password,
      agreeTC,
      agreeComms,
      language,
      signup_token,
      public_organization_slug,
    }),
  })

  const result = await parseResponse(response);
  return { ...result, defaults: { name, email, agreeTC, agreeComms } };
};

const signupSuccess = ({
  corporate,
  user,
  auth,
  missing,
  password_policy,
  public_organizations,
  defaults,
  url,
}) => ({
  type: 'SIGNUP_COMPLETE',
  corporate,
  user,
  auth,
  missing,
  password_policy,
  url,
  public_organizations,
  defaults,
});

const signupFail = ({ code, text }) => ({
  type: 'SIGNUP_FAILED',
  code,
  text,
});

// LOGOUT ACTIONS
const requestLogout = async (redirectToSignIn) => {
  try {
    const response = await fetch(`${config.AUTH_URL}/identity`, {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include',
    });
    await parseResponse(response);
    return redirectToSignIn;
  } catch (err) {
    throw redirectToSignIn;
  }
};

const logoutSuccess = (redirectToSignIn) => ({
  type: 'LOGOUT_COMPLETE',
  redirectToSignIn,
});

const logoutFail = (redirectToSignIn) => ({
  type: 'LOGOUT_FAILED',
  redirectToSignIn,
});

// IDENTITY ACTIONS
const requestIdentity = async () => {
  const response = await fetch(`${config.AUTH_URL}/identity`, {
    method: 'PUT',
    mode: 'cors',
    credentials: 'include',
  }).then(tryRefreshFollowUrl);

  const result = await parseResponse(response);
  return result;
};

const identitySuccess = ({ access_token, user }) => ({
  type: 'IDENTITY_COMPLETE',
  access_token,
  user,
});

const identityFail = ({ code, text }) => ({
  type: 'IDENTITY_FAILED',
  code,
  text,
});

// REFRESH ACTIONS
const requestRefreshToken = async () => {
  const response = await fetch(`${config.AUTH_URL}/refresh`, {
    method: 'PUT',
    mode: 'cors',
    credentials: 'include',
  }).then(tryRefreshFollowUrl);

  const result = await parseResponse(response);
  return result;
};

const refreshTokenSuccess = ({ access_token }) => ({
  type: 'REFRESH_TOKEN_COMPLETE',
  access_token,
});

const refreshTokenFail = ({ code, text }) => ({
  type: 'REFRESH_TOKEN_FAILED',
  code,
  text,
});

const initialState = {
  refreshing_token: false,
  logged_in: false,
  missing: null,
  password_policy: DEFAULT_PASSWORD_POLICY,
  sso_signup_url: null,
  public_organizations: [],
  defaults: {},
  error: null,
  redirectState: null,
  refreshProfile: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SIGNUP_REQUEST':
      return loop({
        ...state,
        sso_signup_url: null,
        redirectState: action.state,
      }, Cmd.run(requestSignup, {
        successActionCreator: signupSuccess,
        failActionCreator: signupFail,
        args: [
          action.name,
          action.email,
          action.password,
          action.agreeTC,
          action.agreeComms,
          action.language,
          action.signup_token,
          action.public_organization_slug,
        ],
      })
    );
    case 'LOOKUP_REQUEST':
    case 'LOGIN_REQUEST':
      return {
        ...state,
        sso_signup_url: null,
        redirectState: action.state,
      };
    case 'IDENTITY_REQUEST':
      if(state.refreshing_token) {
        // Already refreshing, move along...
        return state;
      }
      return loop({
        ...state,
        refreshing_token: true,
      }, Cmd.run(requestIdentity, {
        successActionCreator: identitySuccess,
        failActionCreator: identityFail,
        args: [],
      }));
    case 'REFRESH_TOKEN_REQUEST':
      if(state.refreshing_token) {
        // Already refreshing, move along...
        return state;
      }
      return loop({
        ...state,
        refreshing_token: true,
      }, Cmd.run(requestRefreshToken, {
        successActionCreator: refreshTokenSuccess,
        failActionCreator: refreshTokenFail,
        args: [],
      }));
    case 'SIGNUP_COMPLETE':
      if(action.auth) {
        return loop({
          ...initialState,
          sso_signup_url: null,
          logged_in: true,
        }, Cmd.run(requestPush, { args: ['/', state.redirectState] }));
      }
      return {
        ...state,
        missing: action.missing,
        password_policy: action.password_policy || state.password_policy,
        sso_signup_url: action.url || null,
        public_organizations: action.public_organizations || state.public_organizations,
        defaults: action.defaults,
      };
    case 'LOGIN_COMPLETE':
    case 'SUBMIT_RECOVERED_PASSWORD_COMPLETE':
    case 'CONFIRM_EMAIL_COMPLETE':
      return loop({
        ...initialState,
        sso_signup_url: null,
        logged_in: true,
        refreshProfile: true,
      }, Cmd.list([
        Cmd.run(requestPush, { args: ['/', state.redirectState] }),
        Cmd.action(changeLocale((action.user || {}).language || DEFAULT_LOCALE)),
      ]));
    case 'UNSET_AUTH_REFRESH_PROFILE':
      if (state.refreshProfile) {
        return {
          ...state,
          refreshProfile: false,
        };
      }
      return state;
    case 'REFRESH_TOKEN_COMPLETE':
    case 'IDENTITY_COMPLETE':
      return {
        ...initialState,
        refreshing_token: false,
        logged_in: true,
      };
    case 'LOGOUT_REQUEST':
      return loop(state, Cmd.run(requestLogout, {
        args: [action.redirectToSignIn],
        successActionCreator: logoutSuccess,
        failActionCreator: logoutFail,
      }));
    case 'LOGOUT_COMPLETE':
    case 'LOGOUT_FAILED':
      if (action.redirectToSignIn) {
        return loop(initialState, Cmd.list([
          Cmd.run(requestPush, { args: ['/signin', null] }),
          Cmd.action(changeLocale(DEFAULT_LOCALE)),
        ]));
      }
      return initialState;
    case 'SIGNUP_FAILED':
    case 'LOGIN_FAILED':
      return {
        ...initialState,
        sso_signup_url: null,
        error: action.code,
      };
    case 'RESET_AUTH_ERROR':
      return {
        ...state,
        error: null,
      };
    case 'REFRESH_TOKEN_FAILED':
    case 'IDENTITY_FAILED':
      return initialState;
    case 'RESET_AUTH':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as auth,
};
