import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import {
  List,
  notification,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Straight outta https://stackoverflow.com/a/48020189
const copyContentToClipboard = (el) => {
  return new Promise((resolve, reject) => {
    if(window.getSelection) {
      let range = document.createRange();
      window.getSelection().removeAllRanges();
      range.selectNode(el);
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      return resolve(el.innerText);
    }
    reject();
  });
};

const CONFIRMATION_STRING_LENGTH = 4;

const randomConfirmationString = digits => {
  digits = digits || 0;
  const crockfordBase32 = '0123456789ABCDEFGHJKMNPQRSTVWXYZ'.split('');
  let result = '';
  while (result.length < digits) {
    result += crockfordBase32[Math.random() * 31 >> 0];
  }
  return result;
}

const Transfer = ({
  intl,
  source = 'transfer',
  params = {},
  single = false,
  title,
  sourceParams = {},
  setSourceParams,
  organization,
}) => {
  const t = intl.messages;

  /*
  const {
    transfer_bank,
    transfer_iban,
    transfer_recipient,
    transfer_swift,
  } = params;
  */

  const refs = {
    transfer_bank: useRef(null),
    transfer_iban: useRef(null),
    transfer_recipient: useRef(null),
    transfer_swift: useRef(null),
    br_transfer_agency: useRef(null),
    br_transfer_bank: useRef(null),
    br_transfer_cpf: useRef(null),
    br_transfer_cnpj: useRef(null),
    concept: useRef(null),
  };

  const copy = useCallback((ref) => () => {
    if(ref && ref.current) {
      copyContentToClipboard(ref.current)
        .then((text) => notification.open({message: t.copied_success, duration: 1}));
    } else {
      notification.open({message: t.copied_failed, duration: 1});
    }
  }, [ t ]);

  const [ transferCode, setTransferCode ] = useState('....');

  useEffect(() => {
    const confirmationString = randomConfirmationString( CONFIRMATION_STRING_LENGTH );
    setTransferCode( confirmationString );
    setSourceParams({
      confirmationString,
    });
  }, [ setSourceParams ]);

  const transferKeys = useMemo(() => {
    return Object.keys(params).filter(
      key => {
        switch (organization.region) {
          case 'BR':
            return key.startsWith('br_transfer')
          default:
            return key.startsWith('transfer_')
        }
      }
    )
  },
  [organization, params]
  );

  // const concept = `#${transferCode} - ${title} - ${ member.name }`;
  const concept = `#${transferCode} - ${title}`;

  return (
    <>
      <div className="ContributionScreen__desc">{ t.donation_transfer_desc }</div>
      <List className="ContributionScreen__list">
        {transferKeys.map(key => (
          <List.Item
            key={key}
            extra={<FontAwesomeIcon icon="copy" />}
            onClick={copy(refs[key])}
          >
            <List.Item.Meta
              title={t[`donation_${key}`]}
              description={params[key]}
            />
            <span ref={refs[key] || null}>{params[key]}</span>
          </List.Item>
        ))}
        <List.Item
          key="concept"
          extra={<FontAwesomeIcon icon="copy" />}
          onClick={copy(refs.concept)}
        >
          <List.Item
            onClick={copy(refs.concept)}
          >
            <List.Item.Meta
              title={t.donation_transfer_concept}
              description={concept}
            />
            <span ref={refs.concept}>{concept}</span>
          </List.Item>
        </List.Item>
      </List>
    </>
  );
};

export default injectIntl(Transfer);

