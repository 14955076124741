import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

import {
  updateProfile,
} from 'actions/api';

import {
  onboardingComplete,
} from 'actions/onboarding';

import './style.less';
import config from 'config';

import CohortSelector from 'components/CohortSelector';
import SocialNetworkInput from 'components/SocialNetworkInput';
import OnboardingSelector from 'components/OnboardingSelector';
import OnboardingSelectorOne from 'components/OnboardingSelectorOne';
import OnboardingAddress from 'components/OnboardingAddress';
import OnboardingExperienceEducationSkills from 'components/OnboardingExperienceEducationSkills';
import OnboardingAlias from 'components/OnboardingAlias';
import OnboardingMachineNumber from 'components/OnboardingMachineNumber';
import OnboardingSpecificTraining from 'components/OnboardingSpecificTraining';
import OnboardingAvailability from 'components/OnboardingAvailability';
import OnboardingVolunteeringMotivation from 'components/OnboardingVolunteeringMotivation';
import OnboardingPhone from 'components/OnboardingPhone';
import OnboardingDateOfBirth from 'components/OnboardingDateOfBirth';
import OnboardingTextArea from 'components/OnboardingTextArea';

// NOTICE: The order is important here
const STEPS = {
  'public_groups': 1,
  'social_networks': 2,
  'categories': 3,
  'target_audience': 4,
  'address': 5,
  'experience': 6,
  'experience_education_and_skills': 7,
  'alias': 8,
  'specific_training': 9,
  'availability': 10,
  'availability_and_mobility': 11,
  'volunteering_motivation': 12,
  'motivations_and_expectations': 13,
  'machine_number': 14,
  'relation_with_org': 15,
  'tshirt_size': 16,
  'phone': 17,
  'date_of_birth': 18
};

// This is repeated from the Settings/PreferenceSettings component, but really who cares...
const RELATIONS_WITH_ORG = [
  'employee', // Colaborador no ativo
  'retired', // Reformado do BPI
  'group_employee', // Colaborador do Grupo BPI
  'friends_family', // Amigo ou Familiar
  'customer', // Cliente BPI
];

// This is repeated from the Settings/PreferenceSettings component, but really who cares...
const TSHIRT_SIZES = [
  { slug: 'XS', name: 'XS', },
  { slug: 'S', name: 'S', },
  { slug: 'M', name: 'M', },
  { slug: 'L', name: 'L', },
  { slug: 'XL', name: 'XL', },
  { slug: 'XXL', name: 'XXL', },
];

const Onboarding = ({
  intl,
  organization,
  onboarding,
  my_profile,
  updateProfile,
  onboardingComplete,
}) => {
  const t = intl.messages;

  const {
    completed,
  } = onboarding;

  const {
    data: profile,
    loading,
  } = my_profile;

  // NOTICE: luckily 'completed' comes from local storage so it is hydrated before we even render this
  const [done, setDone] = useState(completed >= config.ONBOARDING_VERSION);
  const [currentStep, setCurrentStep] = useState(0);

  const organizationSteps = useMemo(() => organization?.config?.onboarding_steps || [], [organization])
  const organizationRequiredSteps = useMemo(() => organization?.config?.required_onboarding_steps || [], [organization])

  const [ stepsNeeded, stepParams ] = useMemo(() => {
    let steps = [];
    let params = {};

    // STEP 1: register in public user groups
    if(
      organizationSteps.includes('public_groups') &&
      profile &&
      profile.public_user_groups &&
      profile.public_user_groups.length > 0 &&
      profile.public_user_groups.filter(({ member }) => member).length === 0
    ) { steps.push( STEPS['public_groups'] ) };

    // STEP 2: record social network profiles
    if(organizationSteps.includes('social_networks') && profile) {
      const needed = organization.config.onboarding_social_network_profiles || [];
      const present = new Set(Object.keys(profile.social || {}).filter(network => !((profile.social || {})[network])));
      const missing = needed.filter(network => !present.has(network));

      if(missing.length > 0) {
        steps.push( STEPS['social_networks'] );
        params.socialNetworks = missing;
      };
    };

    if (
      organizationSteps.includes('categories') &&
      profile &&
      profile.categories &&
      profile.categories.length > 0 &&
      profile.categories.filter(({ member }) => member).length === 0
    ) { steps.push( STEPS['categories'] ) };

    if (
      organizationSteps.includes('target_audience') &&
      profile &&
      profile.target_audience &&
      profile.target_audience.length > 0 &&
      profile.target_audience.filter(({ member }) => member).length === 0
    ) { steps.push( STEPS['target_audience'] ) };

    if (
      organizationSteps.includes('address') &&
      profile &&
      profile.details &&
      !profile.details.address
    ) { steps.push( STEPS['address'] ) };

    if (
      organizationSteps.includes('experience') &&
      profile &&
      profile.details &&
      !profile.details.professional_experience &&
      !profile.details.volunteering_experience &&
      !profile.details.social_work_motivations &&
      !profile.details.talents_and_skills
    ) { steps.push( STEPS['experience'] ) };

    if (
      organizationSteps.includes('experience_education_and_skills') &&
      profile &&
      profile.details &&
      !profile.details.education &&
      !profile.details.professional_experience &&
      !profile.details.technical_skills &&
      !profile.details.leadership_skills
    ) { steps.push( STEPS['experience_education_and_skills'] ) };

    if (
      organizationSteps.includes('alias') &&
      profile &&
      profile.details &&
      !profile.details.alias
    ) { steps.push( STEPS['alias'] ) };

    if (
      organizationSteps.includes('specific_training') &&
      profile &&
      profile.details &&
      !profile.details.specific_training_cap_ccp &&
      !profile.details.specific_training_first_aid &&
      !profile.details.specific_training_received_volunteering_training &&
      !profile.details.specific_training_received_volunteering_training_where
    ) { steps.push( STEPS['specific_training'] ) };

    if (
      organizationSteps.includes('availability') &&
      profile &&
      profile.details &&
      (
        !profile.details.availability_preferred_location ||
        profile.details.availability_preferred_location.length === 0
      ) &&
      (
        !profile.details.availability_days ||
        profile.details.availability_days.length === 0
      ) &&
      (
        !profile.details.availability_hours ||
        profile.details.availability_hours.length === 0
      )
    ) { steps.push( STEPS['availability'] ) };

    if (
      organizationSteps.includes('availability_and_mobility') &&
      profile?.details &&
      !profile.details.availability_and_mobility
    ) { steps.push( STEPS['availability_and_mobility'] ) };

    if (
      organizationSteps.includes('volunteering_motivation') &&
      profile &&
      profile.details &&
      (
        !profile.details.volunteering_motivation_reason ||
        profile.details.volunteering_motivation_reason.length === 0
      )
    ) { steps.push( STEPS['volunteering_motivation'] ) };

    if (
      organizationSteps.includes('motivations_and_expectations') &&
      profile?.details &&
      !profile.details.motivations_and_expectations
    ) { steps.push( STEPS['motivations_and_expectations'] ) };

    if (
      organizationSteps.includes('machine_number') &&
      profile &&
      profile.details &&
      !profile.details.machine_number
    ) { steps.push( STEPS['machine_number'] ) };

    if (
      organizationSteps.includes('relation_with_org') &&
      profile &&
      profile.details &&
      !profile.details.relation_with_org
    ) { steps.push( STEPS['relation_with_org'] ) };

    if (
      organizationSteps.includes('tshirt_size') &&
      profile &&
      profile.details &&
      !profile.details.tshirt_size
    ) { steps.push( STEPS['tshirt_size'] ) };

    if (
      organizationSteps.includes('phone') &&
      !profile?.phone
    ) { steps.push( STEPS['phone'] ) };

    if (
      organizationSteps.includes('date_of_birth') &&
      profile?.details &&
      !profile.details.date_of_birth
    ) { steps.push( STEPS['date_of_birth'] ) };

    return [steps, params];
  }, [ profile, organization, organizationSteps ]);

  const advanceStep = useCallback(() => {
    if(!profile) {
      return; // We are not ready yet
    }

    const isRequired = organizationRequiredSteps.map(
      step => STEPS[step]
    ).includes(currentStep);

    if (isRequired && stepsNeeded.includes(currentStep)) {
      // Current step cannot advance since it's required
      return;
    }

    const missingSteps = stepsNeeded.filter(step => step > currentStep);
    if(missingSteps.length > 0) {
      setCurrentStep(missingSteps[0]);
    } else {
      setDone(true);
      setCurrentStep(0);
      onboardingComplete(config.ONBOARDING_VERSION);
    }
  }, [ organizationRequiredSteps, profile, currentStep, stepsNeeded, onboardingComplete ]);

  const updatePhone = useCallback((phone = '') => {
    updateProfile(organization.slug, { phone });
  }, [
    updateProfile,
    organization.slug,
  ]);

  const updatePublicUserGroups = useCallback((public_user_groups = []) => {
    updateProfile(organization.slug, { public_user_groups });
  }, [
    updateProfile,
    organization.slug,
  ]);

  const updateTargetAudienceCategory = useCallback((data = {}) => {
    updateProfile(organization.slug, data);
  }, [
    updateProfile,
    organization.slug,
  ]);

  const updateDetails = useCallback((changes = {}) => {
    const oldDetails = profile.details || {};
    updateProfile(
      organization.slug,
      {
        details: {
          ...oldDetails,
          ...changes,
        }
      }
    );
  }, [
    profile,
    updateProfile,
    organization.slug,
  ]);

  const updateSocialNetwork = useCallback((network, value = null) => {
    updateProfile(
      organization.slug,
      {
        social: {
          ...profile.social,
          [network]: value,
        }
      });
  }, [
    updateProfile,
    organization.slug,
    profile,
  ]);

  useEffect(() => {
    if(stepsNeeded.length > 0 && !done && currentStep === 0) {
      // Start the onboarding
      advanceStep();
    }
  }, [
    profile,
    stepsNeeded,
    currentStep,
    done,
    advanceStep,
  ]);

  const translatedCategories = useMemo(() => {
    return ((profile && profile.categories) || []).map(({ slug, name }) => {
      return {
        slug,
        name: t[`category_${slug}`] || name,
      };
    });
  }, [
    t,
    profile,
  ]);

  const translatedTargetAudience = useMemo(() => {
    return ((profile && profile.target_audience) || []).map(({ slug, name }) => {
      return {
        slug,
        name: t[`target_audience_${slug}`] || name,
      };
    });
  }, [
    t,
    profile,
  ]);

  const translatedRelationWithOrgValues = useMemo(() => {
    return RELATIONS_WITH_ORG.map((slug) => {
      return {
        slug,
        name: t[`relation_with_org_${slug}`],
      };
    });
  }, [
    t,
  ]);

  // Onboarding shouldn't start unless the user has accepted the terms.
  if (
    (profile?.last_terms_and_conditions_version > profile?.last_terms_accepted) ||
    (profile?.organization?.org_last_terms_version > profile?.org_last_terms_accepted)
  ) {
    return null;
  }

  return (
    done || !profile || currentStep === 0
    ? null
    : currentStep === STEPS.public_groups
    ? <CohortSelector
        visible
        onboardingMode
        values={profile.public_user_groups || []}
        onClose={advanceStep}
        loading={loading}
        onSave={updatePublicUserGroups}
        isRequired={organizationRequiredSteps.includes('public_groups')}
      />
    : currentStep === STEPS.social_networks
    ? <SocialNetworkInput
        visible
        onboardingMode
        network={stepParams.socialNetworks[0]}
        value={profile.social[stepParams.socialNetworks[0]]}
        onClose={advanceStep}
        loading={loading}
        onSave={updateSocialNetwork}
        isRequired={organizationRequiredSteps.includes('social_networks')}
      />
    : currentStep === STEPS.categories
    ? <OnboardingSelector
        key='categories'
        visible
        onboardingMode
        title='category_selector_title'
        subTitle='category_selector_subtitle'
        values={translatedCategories}
        onClose={advanceStep}
        loading={loading}
        onSave={(categories) => updateTargetAudienceCategory({categories})}
        isRequired={organizationRequiredSteps.includes('categories')}
      />
    : currentStep === STEPS.target_audience
    ? <OnboardingSelector
        key='target_audience'
        visible
        onboardingMode
        title='target_audience_selector_title'
        subTitle='target_audience_selector_subtitle'
        values={translatedTargetAudience}
        onClose={advanceStep}
        loading={loading}
        onSave={(target_audience) => updateTargetAudienceCategory({target_audience})}
        isRequired={organizationRequiredSteps.includes('target_audience')}
      />
    : currentStep === STEPS.address
    ? <OnboardingAddress
        visible
        onboardingMode
        value={profile.details ? profile.details.address : ''}
        onClose={advanceStep}
        loading={loading}
        onSave={(address) => updateDetails({address})}
        isRequired={organizationRequiredSteps.includes('address')}
      />
    : (currentStep === STEPS.experience || currentStep === STEPS.experience_education_and_skills)
    ? <OnboardingExperienceEducationSkills
        visible
        onboardingMode
        values={profile.details}
        onClose={advanceStep}
        loading={loading}
        onboardingStep={currentStep === STEPS.experience ? 'experience' : 'experience_education_and_skills'}
        onSave={(data) => updateDetails(data)}
        isRequired={organizationRequiredSteps.includes(currentStep === STEPS.experience ? 'experience' : 'experience_education_and_skills')}
      />
    : currentStep === STEPS.alias
    ? <OnboardingAlias
        visible
        onboardingMode
        value={profile.details ? profile.details.alias : ''}
        onClose={advanceStep}
        loading={loading}
        onSave={(alias) => updateDetails({alias})}
        isRequired={organizationRequiredSteps.includes('alias')}
      />
    : currentStep === STEPS.specific_training
    ? <OnboardingSpecificTraining
        visible
        onboardingMode
        details={profile.details}
        onClose={advanceStep}
        loading={loading}
        onSave={(data) => updateDetails(data)}
        isRequired={organizationRequiredSteps.includes('specific_training')}
      />
    : currentStep === STEPS.availability
    ? <OnboardingAvailability
        visible
        onboardingMode
        details={profile.details}
        onClose={advanceStep}
        loading={loading}
        onSave={(data) => updateDetails(data)}
        isRequired={organizationRequiredSteps.includes('availability')}
      />
    : currentStep === STEPS.volunteering_motivation
    ? <OnboardingVolunteeringMotivation
        visible
        onboardingMode
        details={profile.details}
        onClose={advanceStep}
        loading={loading}
        onSave={(data) => updateDetails(data)}
        isRequired={organizationRequiredSteps.includes('volunteering_motivation')}
      />
    : (currentStep === STEPS.motivations_and_expectations || currentStep === STEPS.availability_and_mobility)
    ? <OnboardingTextArea
        visible
        onboardingMode
        value={profile.details ? profile.details[currentStep === STEPS.motivations_and_expectations ? 'motivations_and_expectations' : 'availability_and_mobility'] : ''}
        onClose={advanceStep}
        loading={loading}
        onboardingStep={currentStep === STEPS.motivations_and_expectations ? 'motivations_and_expectations' : 'availability_and_mobility'}
        onSave={(textValue) => updateDetails({ [currentStep === STEPS.motivations_and_expectations ? 'motivations_and_expectations' : 'availability_and_mobility']: textValue })}
        isRequired={organizationRequiredSteps.includes(currentStep === STEPS.motivations_and_expectations ? 'motivations_and_expectations' : 'availability_and_mobility')}
      />
    : currentStep === STEPS.machine_number
    ? <OnboardingMachineNumber
        visible
        onboardingMode
        value={profile.details ? profile.details.machine_number : ''}
        onClose={advanceStep}
        loading={loading}
        onSave={(machine_number) => updateDetails({ machine_number })}
        isRequired={organizationRequiredSteps.includes('machine_number')}
      />
    : currentStep === STEPS.relation_with_org
    ? <OnboardingSelectorOne
        key='relation_with_org'
        visible
        onboardingMode
        title='relation_with_org_title'
        values={translatedRelationWithOrgValues}
        onClose={advanceStep}
        loading={loading}
        onSave={(relation_with_org) => updateDetails({ relation_with_org })}
        isRequired={organizationRequiredSteps.includes('relation_with_org')}
      />
    : currentStep === STEPS.tshirt_size
    ? <OnboardingSelectorOne
        key='tshirt_size'
        visible
        onboardingMode
        title='tshirt_size_title'
        values={TSHIRT_SIZES}
        onClose={advanceStep}
        loading={loading}
        onSave={(tshirt_size) => updateDetails({ tshirt_size })}
        isRequired={organizationRequiredSteps.includes('tshirt_size')}
      />
    : currentStep === STEPS.phone
    ? <OnboardingPhone
        visible
        onboardingMode
        value={profile?.phone || ''}
        onClose={advanceStep}
        loading={loading}
        onSave={updatePhone}
        isRequired={organizationRequiredSteps.includes('phone')}
      />
      : currentStep === STEPS.date_of_birth
      ? <OnboardingDateOfBirth
          visible
          onboardingMode
          value={profile.details ? profile.details.date_of_birth : ''}
          onClose={advanceStep}
          loading={loading}
          onboardingStep={'date_of_birth'}
          onSave={(date_of_birth) => updateDetails({ date_of_birth })}
          isRequired={organizationRequiredSteps.includes('date_of_birth')}
        />
    : null
  );
}

const mapStateToProps = ({
  organization,
  onboarding,
  my_profile,
}) => ({
  organization,
  onboarding,
  my_profile,
});

export default connect(
  mapStateToProps,
  {
    updateProfile,
    onboardingComplete,
  }
)(
  injectIntl(
    Onboarding
  )
);
