import React from 'react';
import { injectIntl } from 'react-intl';

const Competences = ({ intl, competences = [] }) => {
  return (
    <>
      <span className="title">{ intl.formatMessage({ id: `competences` }) }</span>
      { competences.map(competence =>
        <div className="items" key={competence}>{ intl.formatMessage({ id: `competence_${competence}` }) }</div>
        )
      }
    </>
  );
};

export default injectIntl(Competences);

