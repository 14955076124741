import React, { useMemo, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import {
  Layout,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Button
} from 'antd';

import Nav from 'containers/Nav';
import AppHeader from 'containers/AppHeader';
import NavBottom from 'containers/NavBottom';
import NewProposalHeader from 'components/NewProposalHeader/NewProposalHeader';

import {
  getTomorrow,
  isSameDay,
} from 'utils/date';

const When = ({
  intl,
  organization,
  values,
  handleChange,
  showError,
  next,
}) => {
  const t = intl.messages;

  const minimumDate = useCallback((current) => {
    const canOverrideDates =
      ((organization.config || {}).can_override_date_limits || []).includes('user');

    return !canOverrideDates ? current && current < moment().endOf('day') : undefined;
  }, [organization.config]);

  const defaultStartTime = useMemo(() => {
    const tomorrow = getTomorrow();
    return new moment(tomorrow.setHours(10, 0, 0, 0));
  }, []);

  const defaultEndTime = useMemo(() => {
    const tomorrow = getTomorrow();
    return new moment(tomorrow.setHours(18, 0, 0, 0));
  }, []);

  return (
    <>
      <AppHeader />
      <Layout>
        <Nav />
        <Layout.Content>
          <NewProposalHeader
            stepCount={3}
          />
          <Row className="ProposalInitiative-when">
            <Col span={24} className="ProposalInitiative-heading">
              { t.proposal_when }
            </Col>
            <Col span={24} className="ProposalInitiative-subheading">
              { t.starts }
            </Col>
            <Col span={12} className="ProposalInitiative-date">
              <DatePicker
                name='start_date'
                value={values.start_date}
                placeholder={t.select_date}
                onChange={(date) => {
                  handleChange('start_date')(date);
                  handleChange('start_time')(defaultStartTime);
                  handleChange('end_date')(date);
                  handleChange('end_time')(defaultEndTime);
                }}
                disabledDate={minimumDate}
              />
            </Col>
            <Col span={8} className="ProposalInitiative-time">
              <TimePicker
                disabled={!values.start_date}
                value={values.start_time}
                placeholder={t.select_time}
                onChange={(time) => {
                  handleChange('start_time')(time);
                }}
              />
            </Col>
            <Col span={24}>
              { showError('start_date') }
            </Col>
          </Row>
          <Row className="ProposalInitiative-when">
            <Col span={24} className="ProposalInitiative-subheading">
              { t.ends }
            </Col>
            <Col span={12} className="ProposalInitiative-date">
              <DatePicker
                disabled={!values.start_date}
                value={values.end_date}
                placeholder={t.select_date}
                onChange={(date) => {
                  handleChange('end_date')(date);
                  if(isSameDay(values.start_date, date)) {
                    handleChange('end_time')(null);
                  } else {
                    handleChange('end_time')(defaultEndTime);
                  }
                }}
                disabledDate={(current) => current < values.start_date}
              />
            </Col>
            <Col span={8} className="ProposalInitiative-time">
              <TimePicker
                disabled={!values.end_date || !values.start_time}
                value={values.end_time}
                onChange={handleChange('end_time')}
                placeholder={t.select_time}
              />
            </Col>
            <Col span={24}>
              { showError('end_date') }
            </Col>
          </Row>
          <Row className="ProposalInitiative-button-next">
            <Col span={24}>
              <Button
                disabled={!values.start_date
                && !values.start_time
                && !values.end_date
                && !values.end_time}
                type="primary"
                onClick={next}
              >
                { t.continue }
              </Button>
            </Col>
            <Col span={24} className="ProposalInitiative-button-skip">
              <span onClick={next}>
                { t.continue_without_this }
              </span>
            </Col>
          </Row>
          <NavBottom />
        </Layout.Content>
      </Layout>
    </>
  );
};

export default injectIntl(When);
