import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Switch, Route, withRouter } from 'react-router'

import Type from './screens/Type';
import Title from './screens/Title';
import Description from './screens/Description';
import When from './screens/When';
import Where from './screens/Where';
import Sdgs from './screens/Sdgs';
import Skills from './screens/Competences';
import Send from './screens/Send';
import Finish from './screens/Finish';

import {
  proposeInitiative,
} from 'actions/api';

import { Alert } from 'antd';

import useForm from 'utils/useForm';

import { initiativeFromFormToDb } from './converters';

import validation from './validation';
import './style.less';

const defaultNextSteps = {
  type: 'title',
  title: 'description',
  description: 'when',
  when: 'where',
  where: 'sdgs',
  sdgs: 'skills',
  skills: 'send',
  send: 'finish',
  finish: null,
};

const SUBMISSION_STEP = 'send';
const FINAL_STEP = 'finish';

const ProposeInitiative = ({
  intl,
  organization,
  proposeInitiative,
  proposals,
  history,
}) => {
  const t = intl.messages;
  const push = history.push;

  const [localErrors, setLocalErrors] = useState({});

  const {
    loading,
    error,
  } = proposals;

  const submitForm = () => {
    const payload = initiativeFromFormToDb(values);
    proposeInitiative(organization.slug, payload);
  };

  const validateForm = useMemo(() => validation(t), [ t ]);

  const {
    handleSubmit,
    values,
    errors,
    ...formFields
  } = useForm({
    callback: submitForm,
    validate: data => validateForm.send(data, organization.config),
    setNames: ['sdgs', 'competences'],
  });

  const nextSteps = useMemo(() => {
    switch(values.type) {
      case 'informative':
      case 'collection':
        return {
          ...defaultNextSteps,
          sdgs: 'send',
        };
      case 'donation':
        return {
          ...defaultNextSteps,
          when: 'sdgs',
          sdgs: 'send',
        };
      default:
        return defaultNextSteps;
    }
  }, [
    values,
  ]);

  const next = useCallback((step) => () => {
    if(validateForm[step]) {
      // Where is a local validator, run it...
      const errors = validateForm[step](values, organization.config);
      setLocalErrors(errors);
      if(Object.keys(errors).length > 0) {
        return;
      }
    }

    switch(step) {
      case SUBMISSION_STEP:
        handleSubmit();
        break;
      case FINAL_STEP:
        push('/proposal');
        break;
      default:
        push(`/proposal/new/${nextSteps[step]}`);
    };
  }, [ nextSteps, values, handleSubmit, push, validateForm, organization ]);

  useEffect(() => {
    if(localErrors._firstError || errors._firstError) {
      push(`/proposal/new/${localErrors._firstError || errors._firstError}`);
    }
  }, [ localErrors._firstError, errors._firstError, push ]);

  const showError = useCallback((section) => {
    if(errors[section] || localErrors[section]) {
      return (
        <Alert type="error" message={ errors[section] || localErrors[section]} />
      );
    } else {
      return (<div style={{ height: '36px' }}></div>);
    }
  }, [ errors, localErrors ]);

  const props = {
    ...formFields,
    organization,
    values,
    errors: { ...errors, ...localErrors },
    showError,
    loading,
    error,
  };

  return (
    <Switch>
      <Route
        path="/proposal/new/type"
        render={() => <Type {...props} next={next('type')} />}
      />
      <Route
        path="/proposal/new/title"
        render={() => <Title {...props} next={next('title')} />}
      />
      <Route
        path="/proposal/new/description"
        render={() => <Description {...props} next={next('description')} />}
      />
      <Route
        path="/proposal/new/when"
        render={() => <When {...props} next={next('when')} />}
      />
      <Route
        path="/proposal/new/where"
        render={() => <Where {...props} next={next('where')} />}
      />
      <Route
        path="/proposal/new/sdgs"
        render={() => <Sdgs {...props} next={next('sdgs')} />}
      />
      <Route
        path="/proposal/new/skills"
        render={() => <Skills {...props} next={next('skills')} />}
      />
      <Route
        path="/proposal/new/send"
        render={() => <Send {...props} next={next('send')} />}
      />
      <Route
        path="/proposal/new/finish"
        render={() => <Finish {...props} next={next('finish')} />}
      />
    </Switch>
  )
}

const mapStateToProps = ({
  organization,
  proposals,
}) => ({
  organization,
  proposals,
});

export default injectIntl(connect(mapStateToProps, { proposeInitiative })(withRouter(ProposeInitiative)));
