const config = {
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || '',
  SENTRY_SAMPLE_RATE: +process.env.REACT_APP_SENTRY_SAMPLE_RATE || 1.0,
  API_URL: process.env.REACT_APP_API_URL || 'https://localhost:7500/mobile',
  ASSETS_URL: 'https://aplanet-static.ams3.digitaloceanspaces.com/assets',
  AUTH_URL: process.env.REACT_APP_AUTH_URL || 'https://localhost:7501',
  QR_URL: process.env.REACT_APP_QR_URL || 'https://localhost:7500/qr',
  FILE_URL: process.env.REACT_APP_FILE_URL || 'https://localhost:7506',
  FONTS_URL: 'https://aplanet-static.ams3.digitaloceanspaces.com/fonts',
  GOOGLE_TAG_MANAGER_KEY: process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY || '',
  SEGMENT_KEY: process.env.REACT_APP_SEGMENT_KEY || '',
  INSPECTLET_KEY: process.env.REACT_APP_INSPECTLET_KEY || '',
  GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
  QUICKBLOX_APP_ID: process.env.REACT_APP_QUICKBLOX_APP_ID || '',
  QUICKBLOX_AUTH_KEY: process.env.REACT_APP_QUICKBLOX_AUTH_KEY || '',
  QUICKBLOX_AUTH_SECRET: process.env.REACT_APP_QUICKBLOX_AUTH_SECRET || '',
  QUICKBLOX_ACCOUNT_KEY: process.env.REACT_APP_QUICKBLOX_ACCOUNT_KEY || '',
  DEFAULT_FEATURES: ['rankings', 'proposals', 'collaborations', 'responsible_profile'],
  DEFAULT_CONFIG: {
    features: {
      rankings: true,
      proposals: true,
      collaborations: true,
      responsible_profile: true,
    },
    can_override_dates: [],
    social_network_profiles: ['linkedin'],
    onboarding_social_network_profiles: [],
    consider_working_hours: true,
    require_manager_approval: true,
    proposal_types: ['volunteering', 'donation', 'collection', 'informative'],
  },
  ONBOARDING_VERSION: 5,
}

export default config;
