import qs from 'query-string';

export const getEmailFromQueryString = (search) => {
  const parsed = qs.parse(search);

  return parsed.email ? decodeURIComponent(parsed.email) : null;
}

export const getSsoPayloadFromQueryString = (search) => {
  const parsed = qs.parse(search);

  return Object.keys(parsed)
    .map(key => ([ key, decodeURIComponent(parsed[key]) ]))
    .reduce((obj, [key, val]) => { obj[key] = val; return obj }, {});
}
