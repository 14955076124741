import React, { useMemo, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Layout,
  Button,
  Input,
  Radio,
  notification,
} from 'antd';

import Nav from 'containers/Nav';
import AppHeader from 'containers/AppHeader';
import NavBottom from 'containers/NavBottom';
import NewProposalHeader from 'components/NewProposalHeader/NewProposalHeader';

const Send = ({
  intl,
  values,
  handleChangeEvent,
  handleChange,
  next,
  loading,
  error,
}) => {
  const t = intl.messages;

  useEffect(
    () => {
      if (error) {
        notification.error({
          message: intl.formatMessage({
            id: error === 429
              ? 'proposal_error_too_many_requests'
              : 'proposal_error'
          }),
          description: intl.formatMessage({
            id: error === 429
              ? 'proposal_error_too_many_requests_desc'
              : 'proposal_error_desc'
          }),
        });
      }
    },
    [error, intl]
  );

  const showMaxParticipants = useMemo(() => {
    return !['informative', 'donation'].includes(values.type);
  }, [
    values.type,
  ]);

  return (
    <>
      <AppHeader />
      <Layout>
        <Nav />
        <Layout.Content>
          <NewProposalHeader
            stepCount={7}
          />
          <Row className="ProposalInitiative-send">
            <Col span={24} className="ProposalInitiative-heading">
              { t.proposal_collaborate }
            </Col>
            <Col span={10}>
              <Radio.Group
                name="help_organize"
                size="large"
                value={!!values.help_organize}
                onChange={(e) => handleChange('help_organize')(e.target.value)}
              >
                <Radio.Button value={true}>
                  { t.yes }
                </Radio.Button>
                <Radio.Button value={false}>
                  { t.no }
                </Radio.Button>
              </Radio.Group>
            </Col>
            {showMaxParticipants &&
            <>
              <Col span={24} className="ProposalInitiative-heading">
                { t.proposal_max_participants }
              </Col>
              <Col span={10}>
                <Input
                  type="number"
                  name="max_participants"
                  placeholder={t.unlimited}
                  value={values.max_participants}
                  onChange={handleChangeEvent}
                />
              </Col>
            </>
            }
          </Row>
          <Row className="ProposalInitiative-button-next relative">
            <Col span={24}>
              <Button
                type="primary"
                loading={loading}
                onClick={next}
              >
                { t.proposal_send_long }
              </Button>
            </Col>
          </Row>
          <NavBottom />
        </Layout.Content>
      </Layout>
    </>
  );
};

export default injectIntl(Send);
