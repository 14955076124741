import React from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';

import { Layout } from 'antd';

import './style.less';

const Loading = ({
  intl,
}) => {
  const t = intl.messages;

  const organization = useSelector(state => state.organization);

  return (
    <Layout className="Loading-back">
      <Layout.Content className="Content-noheader">
        <div className="Loading-back">
          <img src={organization.logo} alt={t.loading} />
        </div>
      </Layout.Content>
    </Layout>
  )
}

const mapStateToProps = () => ({});

export default injectIntl(connect(mapStateToProps)(Loading));
