import React, {
  useEffect,
  useMemo,
} from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import {
  Layout,
  Spin,
} from 'antd';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  resetAuth,
  initiateSso,
} from 'actions/auth';

import {
  getSsoPayloadFromQueryString,
} from 'utils/clientUrl';

import './style.less';

const SsoError = ({
  intl,
  match,
  location,
  ...props
}) => {
  const t = intl.messages;

  const {
    provider,
    redirectPath,
  } = match.params;
  const payload = useMemo(() => {
    return getSsoPayloadFromQueryString(location?.search);
  }, [
    location,
  ]);
  const dispatch = useDispatch();

  const {
    done,
    loading,
    error,
  } = useSelector(state => state.sso);

  useEffect(() => {
    // Make sure we do not collide...
    dispatch(
      resetAuth()
    );

    if(provider && payload) {
      dispatch(
        initiateSso(provider, payload)
      );
    }
  }, [
    provider,
    payload,
    dispatch,
  ]);

  useEffect(() => {
    // Make sure we do not collide...
    if(loading) {
      return;
    }
    if(done) {
      return window.location.replace(`/${redirectPath || ''}`);
    }
  }, [
    done,
    loading,
    redirectPath,
  ]);

  return (
    <div className="LoadingSignedIn-back">
      {
        !error
        ? (
          <Layout className="Loading-back">
            <Layout.Content className="Content-noheader">
              <div className="SsoInitiate">
                <Spin size="large" />
              </div>
            </Layout.Content>
          </Layout>
        ) : (
          <Layout className="Loading-back">
            <Layout.Content className="Content-noheader">
              <div className="SsoInitiate">
                <h1 className="SsoInitiate-title">{ t.sso_initiate_error_title }</h1>
                <h2 className="SsoInitiate-subtitle">{ t.sso_initiate_error_subtitle }</h2>
              </div>
            </Layout.Content>
          </Layout>
        )
      }
    </div>
  )
}

export default injectIntl(
  withRouter(
    SsoError
  )
);
