import React, { useState, useMemo, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DateRange from 'components/DateRange';

import './style.less';

import useSetState from 'utils/useSetState';
import useScreenSize from 'utils/useScreenSize';
import useNow from 'utils/useNow';
import InitiativeTypeString from 'components/InitiativeTypeString';

import {
  rangeOverlap,
  formatDb,
  formatDbDate,
} from 'utils/date';

import {
  Button,
  Checkbox,
  Drawer,
  Input,
} from 'antd';

// NOTICE: Just here, we need the separator to be a single _ because of the translations
const SUBTYPESEP = '_';

// TODO: fetch from backend
const sdgMap = new Map([
  ['no-poverty',                             { id: 1, color: '' }],
  ['zero-hunger',                            { id: 2, color: '' }],
  ['good-health-and-wellbeing',              { id: 3, color: '' }],
  ['quality-education',                      { id: 4, color: '' }],
  ['gender-equality',                        { id: 5, color: '' }],
  ['clean-water-and-sanitation',             { id: 6, color: '' }],
  ['affordable-and-clean-energy',            { id: 7, color: '' }],
  ['decent-work-and-economic-growth',        { id: 8, color: '' }],
  ['industry-innovation-and-infrastructure', { id: 9, color: '' }],
  ['reduced-inequalities',                   { id: 10, color: '' }],
  ['sustainable-cities-and-communities',     { id: 11, color: '' }],
  ['responsible-consumption-and-production', { id: 12, color: '' }],
  ['climate-action',                         { id: 13, color: '' }],
  ['life-below-water',                       { id: 14, color: '' }],
  ['life-on-land',                           { id: 15, color: '' }],
  ['peace-justice-and-strong-institutions',  { id: 16, color: '' }],
  ['partnership-for-the-goals',              { id: 17, color: '' }],
]);

const getSorterByTranslation = (t, prefix = '') => (slug_a, slug_b) =>
   (t[`${prefix}${slug_a}`] || '').localeCompare(t[`${prefix}${slug_b}`] || '');

const getSorterByNumericalProp = (entityMap, propName = 'id') => (slug_a, slug_b) =>
  ( entityMap.get(slug_a)[propName] - entityMap.get(slug_b)[propName] );

const InitiativeFilter = ({
  intl,
  data = [],
  profile = {},
  className = '',
  renderChildren = () => null,
  isWallFilter = false,
  organization = {},
  isPastInitiative,
  organizationHasPoints,
  organizationHasProgram,
}) => {
  const t = intl.messages;
  const now = useNow();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);
  const [preferences, setPreferences] = useState(null);
  const drawerPlacement = ['xs', 'sm', 'md'].includes(useScreenSize()) ? 'bottom' : 'right';

  const {
    set: filteredCategories,
    has: isCategoryFilterSet,
    toggle: toggleCategoryFilter,
    replaceAll: setFilteredCategories,
  } = useSetState([]);
  const categoryFilterSet = useMemo(() => filteredCategories.length > 0, [ filteredCategories ]);

  const {
    set: filteredCompetences,
    has: isCompetenceFilterSet,
    toggle: toggleCompetenceFilter,
    replaceAll: setFilteredCompetences,
  } = useSetState([]);
  const competenceFilterSet = useMemo(() => filteredCompetences.length > 0, [ filteredCompetences ]);

  const {
    set: filteredSdgs,
    has: isSdgFilterSet,
    toggle: toggleSdgFilter,
    replaceAll: setFilteredSdgs,
  } = useSetState([]);
  const sdgFilterSet = useMemo(() => filteredSdgs.length > 0, [ filteredSdgs ]);

  const {
    set: filteredTargetAudience,
    has: isTargetAudienceFilterSet,
    toggle: toggleTargetAudienceFilter,
    replaceAll: setFilteredTargetAudience,
  } = useSetState([]);
  const targetAudienceFilterSet = useMemo(
    () => filteredTargetAudience.length > 0,
    [ filteredTargetAudience ]
  );

  const {
    set: filteredInitiativeTypes,
    has: isInitiativeTypeFilterSet,
    toggle: toggleInitiativeTypeFilter,
    replaceAll: setFilteredInitiativeTypes,
  } = useSetState([]);
  const initiativeTypeFilterSet = useMemo(
    () => filteredInitiativeTypes.length > 0,
    [ filteredInitiativeTypes ]
  );

  const {
    set: filteredInitiativePrograms,
    has: isInitiativeProgramsFilterSet,
    toggle: toggleInitiativeProgramsFilter,
    replaceAll: setFilteredInitiativePrograms,
  } = useSetState([]);
  const initiativeProgramsFilterSet = useMemo(
    () => filteredInitiativePrograms.length > 0,
    [ filteredInitiativePrograms ]
  );

  const {
    set: filteredInitiativeTags,
    has: isInitiativeTagsFilterSet,
    toggle: toggleInitiativeTagsFilter,
    replaceAll: setFilteredInitiativeTags,
  } = useSetState([]);
  const initiativeTagsFilterSet = useMemo(
    () => filteredInitiativeTags.length > 0,
    [ filteredInitiativeTags ]
  );

  const {
    set: filteredInitiativePoints,
    has: isInitiativePointsFilterSet,
    toggle: toggleInitiativePointsFilter,
    replaceAll: setFilteredInitiativePoints,
  } = useSetState([]);
  const initiativePointsFilterSet = useMemo(
    () => filteredInitiativePoints.length > 0,
    [ filteredInitiativePoints ]
  );

  const {
    set: filteredLocation,
    has: isLocationFilterSet,
    toggle: toggleLocationFilter,
    replaceAll: setFilteredLocation,
  } = useSetState([]);
  const locationFilterSet = useMemo(
    () => filteredLocation.length > 0,
    [ filteredLocation ]
  );

  const {
    set: filteredPinnedInitiatives,
    has: isPinnedInitiativesFilterSet,
    toggle: togglePinnedInitiativesFilter,
    replaceAll: setFilteredPinnedInitiatives,
  } = useSetState([]);
  const pinnedInitiativesFilterSet = useMemo(() => filteredPinnedInitiatives.length > 0, [ filteredPinnedInitiatives ]);


  const resetAllFilters = useCallback(() => {
    setSearchText('');
    setStartDateValue(null);
    setEndDateValue(null);
    setFilteredCategories([]);
    setFilteredCompetences([]);
    setFilteredSdgs([]);
    setFilteredTargetAudience([]);
    setFilteredInitiativeTypes([]);
    setPreferences(null);
    setFilteredInitiativePrograms([]);
    setFilteredInitiativeTags([]);
    setFilteredInitiativePoints([]);
    setFilteredLocation([]);
    setFilteredPinnedInitiatives([]);
  }, [
    setSearchText,
    setStartDateValue,
    setEndDateValue,
    setFilteredCategories,
    setFilteredCompetences,
    setFilteredSdgs,
    setFilteredTargetAudience,
    setFilteredInitiativeTypes,
    setPreferences,
    setFilteredInitiativePrograms,
    setFilteredInitiativeTags,
    setFilteredInitiativePoints,
    setFilteredLocation,
    setFilteredPinnedInitiatives
  ]);

  const taxonomies = useMemo(() => {
    let categories = new Set();
    let competences = new Set();
    let sdgs = new Set();
    let targetAudience = new Set();
    let initiativeType = new Set();
    let initiativePrograms = new Set();
    let initiativeTags = new Set();
    let location = new Set();
    let pinnedInitiatives = [];
    let initiativePoints = [];

    for(let item of data) {
      (item.categories || []).forEach(categories.add, categories);
      (item.competences || []).forEach(competences.add, competences);
      (item.sdgs || []).forEach(sdgs.add, sdgs);
      (item.target_audience || []).forEach(targetAudience.add, targetAudience);
      initiativeType.add(item.subtype ? `${item.type}${SUBTYPESEP}${item.subtype}` : item.type);
      (item.tags || []).forEach(initiativeTags.add, initiativeTags);

      if (item.is_in_person) {
        location.add('in_person');
      }
      if (item.is_remote) {
        location.add('remote');
      }
      if (organizationHasProgram && item.program) {
        initiativePrograms.add(item.program);
      }
      if (organizationHasPoints) {
        initiativePoints = ['has_points', 'has_not_points'];
      }
    }

    if (isWallFilter) {
      pinnedInitiatives = ['is_pinned', 'is_not_pinned'];
    }

    return {
      categories: Array.from(categories).sort(getSorterByTranslation(t, 'category_')),
      competences: Array.from(competences).sort(getSorterByTranslation(t, 'competence_')),
      sdgs: Array.from(sdgs).sort(getSorterByNumericalProp(sdgMap, 'id')),
      targetAudience: Array.from(targetAudience).sort(getSorterByTranslation(t, 'target_audience_')),
      initiativeType: Array.from(initiativeType).sort(getSorterByTranslation(t, 'initiative_type_')),
      initiativePrograms: Array.from(initiativePrograms).sort(),
      initiativeTags: Array.from(initiativeTags).sort(),
      location: Array.from(location).sort(getSorterByTranslation(t, 'initiative_')),
      pinnedInitiatives,
      initiativePoints
    }
  }, [ data, t, isWallFilter, organizationHasProgram, organizationHasPoints ]);

  const taxonomyDateOrPreferencesFiltersSet = useMemo(() => (
    categoryFilterSet ||
    competenceFilterSet || sdgFilterSet || targetAudienceFilterSet ||
    preferences || initiativeTypeFilterSet || initiativeProgramsFilterSet ||
    initiativeTagsFilterSet || locationFilterSet || pinnedInitiativesFilterSet ||
    initiativePointsFilterSet
  ), [
    categoryFilterSet,
    competenceFilterSet,
    sdgFilterSet,
    targetAudienceFilterSet,
    preferences,
    initiativeTypeFilterSet,
    initiativeProgramsFilterSet,
    initiativeTagsFilterSet,
    locationFilterSet,
    pinnedInitiativesFilterSet,
    initiativePointsFilterSet
  ]);

  const membersCategories = useMemo(
    () => {
      if (!profile || !profile.categories) {
        return [];
      }
      return profile.categories.filter(({member}) => member).map(({slug}) => slug);
    },
    [profile]
  );
  const membersTargetAudience = useMemo(
    () => {
      if (!profile || !profile.target_audience) {
        return [];
      }
      return profile.target_audience.filter(({member}) => member).map(({slug}) => slug);
    },
    [profile]
  );

  const isForMember = useCallback(
    ({ categories, target_audience }) => {
      const hasCommonCategories = membersCategories.some(
        item => categories.includes(item)
      );
      const hasCommonTargetAudience = membersTargetAudience.some(
        item => target_audience.includes(item)
      );
      return hasCommonCategories || hasCommonTargetAudience;
    },
    [membersCategories, membersTargetAudience]
  );

  const filteredData = useMemo(() => {
    // Bail out early if no filters are set
    if(!searchText &&
       !startDateValue &&
       !endDateValue &&
       !categoryFilterSet &&
       !competenceFilterSet &&
       !sdgFilterSet &&
       !targetAudienceFilterSet &&
       !preferences &&
       !initiativeTypeFilterSet &&
       !initiativeProgramsFilterSet &&
       !initiativeTagsFilterSet &&
       !locationFilterSet &&
       !pinnedInitiativesFilterSet &&
       !initiativePointsFilterSet
      ) return data;

    return data.filter(({
      type: initiativeType,
      subtype,
      title,
      description,
      address,
      organization_name,
      collaborators = [],
      start_time,
      end_time,
      categories = [],
      competences = [],
      sdgs = [],
      target_audience = [],
      tags = [],
      is_in_person,
      is_remote,
      pinned_initiative,
      registration_end_time,
      points,
      program,
    }) => {
      const lowerSearchText = (searchText || '').toLowerCase();
      const searchMatch = (
        !searchText ||
        (title || '').toLowerCase().includes(lowerSearchText) ||
        (description || '').toLowerCase().includes(lowerSearchText) ||
        (address || '').toLowerCase().includes(lowerSearchText) ||
        (organization_name || '').toLowerCase().includes(lowerSearchText) ||
        collaborators.find(({ name }) => (name || '').toLowerCase().includes(lowerSearchText))
      );

      const dateMatch = (
        !startDateValue ||
        !endDateValue ||
        rangeOverlap(
          [
            `${formatDbDate(startDateValue)}T00:00:00.000Z`,
            `${formatDbDate(endDateValue)}T23:59:59.999Z`,
          ],
          [
            formatDb(start_time), // NOTICE: this should solve timezones...
            formatDb(end_time),
          ]
        )
      );

      const skipTaxonomyFilters = !categoryFilterSet &&
        !competenceFilterSet &&
        !sdgFilterSet &&
        !targetAudienceFilterSet &&
        !initiativeTypeFilterSet &&
        !initiativeProgramsFilterSet &&
        !initiativeTagsFilterSet &&
        !locationFilterSet &&
        !pinnedInitiativesFilterSet &&
        !initiativePointsFilterSet;

      const categoryMatch = (
        !categoryFilterSet ||
        categories.find(isCategoryFilterSet)
      );

      const competenceMatch = (
        !competenceFilterSet ||
        competences.find(isCompetenceFilterSet)
      );

      const sdgMatch = (
        !sdgFilterSet ||
        sdgs.find(isSdgFilterSet)
      );

      const targetAudienceMatch = (
        !targetAudienceFilterSet ||
        target_audience.find(isTargetAudienceFilterSet)
      );

      const initiativeTypeMatch = (
        !initiativeTypeFilterSet ||
        isInitiativeTypeFilterSet(  subtype ? `${initiativeType}${SUBTYPESEP}${subtype}` : initiativeType )
      );

      const preferencesMatch = preferences === 'for_you' ?
        isForMember({categories, target_audience}) :
        true;

      const initiativeProgramsMatch = (
        !initiativeProgramsFilterSet ||
        isInitiativeProgramsFilterSet(program)
      );

      const initiativeTagsMatch = (
        !initiativeTagsFilterSet ||
        tags.find(isInitiativeTagsFilterSet)
      );

      const locationMatch = (
        !locationFilterSet ||
        (isLocationFilterSet('remote') && is_remote) ||
        (isLocationFilterSet('in_person') && is_in_person)
      );

      const initiativePinnedMatch = (
        !pinnedInitiativesFilterSet || (
          isWallFilter && organization.config?.can_pin_initiatives && !isPastInitiative({ registration_end_time, end_time }) &&
          ((isPinnedInitiativesFilterSet('is_not_pinned') && !pinned_initiative) ||
          (isPinnedInitiativesFilterSet('is_pinned') && pinned_initiative))
        )
      );

      const initiativePointsMatch = (
        !initiativePointsFilterSet || (
          organizationHasPoints &&
          ((isInitiativePointsFilterSet('has_not_points') && (!points || points < 0)) ||
          (isInitiativePointsFilterSet('has_points') && points > 0))
        )
      );

      return searchMatch &&
        dateMatch &&
        (
          skipTaxonomyFilters ||
          (categoryFilterSet && categoryMatch) ||
          (competenceFilterSet && competenceMatch) ||
          (sdgFilterSet && sdgMatch) ||
          (targetAudienceFilterSet && targetAudienceMatch) ||
          (initiativeTypeFilterSet && initiativeTypeMatch) ||
          (initiativeProgramsFilterSet && initiativeProgramsMatch) ||
          (initiativeTagsFilterSet && initiativeTagsMatch) ||
          (initiativePointsFilterSet && initiativePointsMatch) ||
          (locationFilterSet && locationMatch) || (
            isWallFilter && organization.config?.can_pin_initiatives
            && !isPastInitiative({ registration_end_time, end_time })
            && pinnedInitiativesFilterSet && initiativePinnedMatch
          )
        ) &&
        preferencesMatch;
    })
  }, [
    data,
    searchText,
    startDateValue,
    endDateValue,
    categoryFilterSet,
    competenceFilterSet,
    sdgFilterSet,
    targetAudienceFilterSet,
    initiativeTypeFilterSet,
    initiativeProgramsFilterSet,
    initiativeTagsFilterSet,
    isCategoryFilterSet,
    isCompetenceFilterSet,
    isSdgFilterSet,
    isTargetAudienceFilterSet,
    isInitiativeTypeFilterSet,
    isInitiativeProgramsFilterSet,
    isInitiativeTagsFilterSet,
    preferences,
    isForMember,
    locationFilterSet,
    isLocationFilterSet,
    isWallFilter,
    isPastInitiative,
    pinnedInitiativesFilterSet,
    isPinnedInitiativesFilterSet,
    organization,
    initiativePointsFilterSet,
    organizationHasPoints,
    isInitiativePointsFilterSet
  ]);

  const onDateConfirm = useCallback((start_time, end_time) => {
    if(endDateValue && start_time > endDateValue) {
      setEndDateValue(null);
    }
    start_time && setStartDateValue(start_time);
    end_time && setEndDateValue(end_time);
    setIsDateFilterOpen(false);
  }, [endDateValue]);

  return (
    <div className={className}>
      <Drawer
        className="drawer"
        title={t.initiative_filter_title}
        placement={drawerPlacement}
        closable={true}
        onClose={() => setIsFilterOpen(false)}
        visible={isFilterOpen}
      >
      <div>
        <div className="Initiative-taxonomy-filter">
          <h4>{ t.preferences }</h4>
          <div>
            <Checkbox
              checked={!preferences}
              onChange={() => setPreferences(null)}
              style={{ display: 'block' }}
            >
              { t.all }
            </Checkbox>
            <Checkbox
              checked={preferences === 'for_you'}
              onChange={() => setPreferences('for_you')}
              style={{ display: 'block' }}
            >
              { t.for_you }
            </Checkbox>
          </div>
        </div>
        { taxonomies.categories.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
              <h4>{ t.categories }</h4>
            <div>
              <Checkbox
                checked={!categoryFilterSet}
                onChange={() => setFilteredCategories([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.categories.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isCategoryFilterSet(slug)}
                  onChange={() => toggleCategoryFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`category_${slug}`] }
                </Checkbox>
              ))}
            </div>
          </div>
        }
        { taxonomies.competences.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
          <h4>{ t.competences }</h4>
            <div>
              <Checkbox
                checked={!competenceFilterSet}
                onChange={() => setFilteredCompetences([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.competences.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isCompetenceFilterSet(slug)}
                  onChange={() => toggleCompetenceFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`competence_${slug}`] }
                </Checkbox>
              ))}
            </div>
          </div>
        }
        { !taxonomies.initiativeType.length ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.initiative_type }</h4>
            <div>
              <Checkbox
                checked={!initiativeTypeFilterSet}
                onChange={() => setFilteredInitiativeTypes([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              {
                taxonomies.initiativeType.map(slug => {
                  const [
                    type,
                    subtype = null,
                  ] = slug.split(SUBTYPESEP);
                  return (
                    <Checkbox
                      key={slug}
                      checked={isInitiativeTypeFilterSet(slug)}
                      onChange={() => toggleInitiativeTypeFilter(slug)}
                      style={{ display: 'block' }}
                    >
                      <InitiativeTypeString
                        type={type}
                        subtype={subtype}
                      />
                    </Checkbox>
                  );
                }
              )}
            </div>
          </div>
        }
        { isWallFilter && organization.config?.can_pin_initiatives && taxonomies.pinnedInitiatives.length > 0 && (
          <div className="Initiative-taxonomy-filter">
            <h4>{ intl.formatMessage({ id: `filter_pinned_initiatives` }) }</h4>
            <div>
              <Checkbox
                checked={!pinnedInitiativesFilterSet}
                onChange={() => setFilteredPinnedInitiatives([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.pinnedInitiatives.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isPinnedInitiativesFilterSet(slug)}
                  onChange={() => togglePinnedInitiativesFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { intl.formatMessage({ id: `filter_initiative_${slug}` }) }
                </Checkbox>
              )) }
            </div>
          </div>
        )}
        { taxonomies.location.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.location }</h4>
            <div>
              <Checkbox
                checked={!locationFilterSet}
                onChange={() => setFilteredLocation([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.location.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isLocationFilterSet(slug)}
                  onChange={() => toggleLocationFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`initiative_${slug}`] }
                </Checkbox>
              )) }
            </div>
          </div>
        }
        { taxonomies.sdgs.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.sdg }</h4>
            <div>
              <Checkbox
                checked={!sdgFilterSet}
                onChange={() => setFilteredSdgs([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.sdgs.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isSdgFilterSet(slug)}
                  onChange={() => toggleSdgFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`sdg_${slug}`] }
                </Checkbox>
              ))}
            </div>
          </div>
        }
        { taxonomies.targetAudience.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.target_audience }</h4>
            <div>
              <Checkbox
                checked={!targetAudienceFilterSet}
                onChange={() => setFilteredTargetAudience([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.targetAudience.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isTargetAudienceFilterSet(slug)}
                  onChange={() => toggleTargetAudienceFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { t[`target_audience_${slug}`] }
                </Checkbox>
              ))}
            </div>
          </div>
        }
        { taxonomies.initiativePrograms.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.program_title }</h4>
            <div>
              <Checkbox
                checked={!initiativeProgramsFilterSet}
                onChange={() => setFilteredInitiativePrograms([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.initiativePrograms.map(name => (
                <Checkbox
                  key={name}
                  checked={isInitiativeProgramsFilterSet(name)}
                  onChange={() => toggleInitiativeProgramsFilter(name)}
                  style={{ display: 'block' }}
                  >
                  { name }
                </Checkbox>
              ))}
            </div>
          </div>
        }
        { taxonomies.initiativeTags.length === 0 ? null :
          <div className="Initiative-taxonomy-filter">
            <h4>{ t.tags }</h4>
            <div>
              <Checkbox
                checked={!initiativeTagsFilterSet}
                onChange={() => setFilteredInitiativeTags([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.initiativeTags.map(name => (
                <Checkbox
                  key={name}
                  checked={isInitiativeTagsFilterSet(name)}
                  onChange={() => toggleInitiativeTagsFilter(name)}
                  style={{ display: 'block' }}
                  >
                  { name }
                </Checkbox>
              ))}
            </div>
          </div>
        }
        { organizationHasPoints && taxonomies.initiativePoints.length > 0 && (
          <div className="Initiative-taxonomy-filter">
            <h4>{ intl.formatMessage({ id: `initiative_filter_points` }) }</h4>
            <div>
              <Checkbox
                checked={!initiativePointsFilterSet}
                onChange={() => setFilteredInitiativePoints([])}
                style={{ display: 'block' }}
              >
                { t.all }
              </Checkbox>
              { taxonomies.initiativePoints.map(slug => (
                <Checkbox
                  key={slug}
                  checked={isInitiativePointsFilterSet(slug)}
                  onChange={() => toggleInitiativePointsFilter(slug)}
                  style={{ display: 'block' }}
                >
                  { intl.formatMessage({ id: `initiative_filter_${slug}` }) }
                </Checkbox>
              )) }
            </div>
          </div>
        )}
      </div>
      </Drawer>
        <Input.Search
          allowClear
          type="text"
          className="InitiativeFilter-search"
          placeholder={t.initiative_search_placeholder}
          onChange={(term) => setSearchText(term.target.value)}
          suffix={( <FontAwesomeIcon icon={"search"}/> )}
        />
        <Button
          className={{filterButton: true, filterActive: taxonomyDateOrPreferencesFiltersSet}}
          size="large"
          onClick={() => setIsFilterOpen(true) }
        >
          { t.filters }
        </Button>
        <Button
          className={{filterButton: true, filterActive: startDateValue || endDateValue}}
          size="large"
          onClick={() => setIsDateFilterOpen(!isDateFilterOpen)}
          extra={
            startDateValue && endDateValue
            ? formatDbDate(startDateValue) === formatDbDate(endDateValue)
            ? formatDbDate(startDateValue)
            : `${formatDbDate(startDateValue)} - ${formatDbDate(endDateValue)}`
            : ''
          }
        >{ t.date }</Button>

        <DateRange
          visible={isDateFilterOpen}
          onConfirm={onDateConfirm}
          startDateValue={startDateValue}
          endDateValue={endDateValue}
          defaultDate={new Date(now)}
          minDate={new Date(+now - 5184000000)}
          maxDate={new Date(+now + 31536000000)}
          title={t.select_date}
          extra={t.select_date}
          placeholder={[t.initiative_filter_start_date, [t.initiative_filter_end_date]]}
        />

        {(taxonomyDateOrPreferencesFiltersSet || startDateValue || endDateValue) &&
          <Button
            className="filterButton"
            size="large"
            onClick={resetAllFilters}
          >
            { t.reset }
          </Button>
        }

      { renderChildren(filteredData, searchText) }
    </div>
  );
};

export default injectIntl(InitiativeFilter);
