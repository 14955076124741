// To store local app state

const initialState = {
  isSidebarCollapsed: undefined,
  isInitiativeBannerVisible: true,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'INITIATIVE_BANNER_VISIBILITY':
      return {
        ...state,
        isInitiativeBannerVisible: state.data,
      }
    case 'TOGGLE_SIDEBAR':
      return {
        ...state,
        isSidebarCollapsed: !state.isSidebarCollapsed,
      }
    default:
      return state;
  }
};

export {
  reducer as app,
};
