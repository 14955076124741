import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import './style.less';

import { Progress } from 'antd';

const MARGIN_OVER_TOP = 0;

// For visual reasons
const MAX_PROGRESS = 100;
const MIN_PROGRESS = 0;

const DonationProgress = ({
  total: totalStr,
  limit: limitStr,
  currency,
  active = true,
  showValue = true,
  intl
}) => {
  const t = intl.messages;

  const total = useMemo(() => parseInt(totalStr, 10), [ totalStr ]);
  const limit = useMemo(() => {
    const limit = limitStr ? parseInt(limitStr, 10) : 0;
    if(limit >= total || !active) {
      return limit;
    }
    return total * (1 + MARGIN_OVER_TOP);
  }, [ limitStr, total, active ]);

  const progress = useMemo(() => {
    return Math.max(MIN_PROGRESS, Math.min(MAX_PROGRESS, Math.ceil( 100 * total / limit )));
  }, [ total, limit ]);

  const status = useMemo(() => {
    if(active) {
      return 'active';
    }
    return (limit > total) ? 'exception' : 'success';
  }, [ active, limit, total ]);

  return (
    <div className="progressbar">
      <Progress
        percent={progress}
        showInfo={false}
        status={status}
      />
      {
        showValue && <p>{`${progress}% - ${currency}${total} ${t.raised}`}</p>
      }
    </div>
  );
}

export default injectIntl(DonationProgress);
