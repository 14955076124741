import React from 'react';
import { Layout, Row, Col, Button } from 'antd';
import { injectIntl } from 'react-intl';

import Nav from 'containers/Nav';
import NavBottom from 'containers/NavBottom';
import AppHeader from 'containers/AppHeader';

const Finish = ({
  intl,
  next
}) => {
  const t = intl.messages;

  return (
    <>
      <AppHeader />
      <Layout>
        <Nav />
        <Layout.Content className="ProposalInitiative-finish">
          <Row className="ProposalInitiative-message">
            <Col span={24}>
              <img alt="congratulations" src="/images/congratulations.png" />
            </Col>
            <Col span={24} className="ProposalInitiative-message-heading">
              { t.congrats }
            </Col>
            <Col span={24} className="ProposalInitiative-message-description">
              { t.proposal_final_message }
            </Col>
          </Row>
          <Row className="ProposalInitiative-button-next">
            <Col span={24}>
              <Button
                type="primary"
                onClick={next}
              >
                { t.finish }
              </Button>
            </Col>
          </Row>
          <NavBottom />
        </Layout.Content>
      </Layout>
    </>
  );
}

export default injectIntl(Finish);
