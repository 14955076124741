import { toDb } from 'utils/date';

export const dateAndTimeToUTC = (date, time) => {
  const localDate = new Date(
    date.year(),
    date.month(),
    date.date(),
    time.hours(),
    time.minutes(),
    0,
    0
  );

  // NOTICE: This is a kludge, the final time IS NOT in UTC,
  // but since toDB converts using "Z" independently of the TZ of the Date object...
  const offset = new Date().getTimezoneOffset();

  return new Date(localDate.getTime() + offset * 60000);
}

export const initiativeFromFormToDb = (form) => {
  const {
    start_date,
    start_time,
    end_date,
    end_time,
    ...rest
  } = form;

  return {
    ...rest,
    start_time: start_date && start_time ? toDb(dateAndTimeToUTC(start_date, start_time)) : undefined,
    end_time: end_date && end_time ? toDb(dateAndTimeToUTC(end_date, end_time)) : undefined,
  };
};

