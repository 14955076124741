import React, {
	useMemo,
	useCallback,
} from "react";
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {formatDateOrTime} from 'utils/date'

// @ts-ignore
import {
  ConversationList,
  Conversation
} from "@chatscope/chat-ui-kit-react";
import {
  Avatar,
} from 'aplanet-ui-kit';

const MESSAGE_DIRECTION_OUTGOING = 'outgoing'
const CHAT_CONVERSATION_KEY = 'chatConversation'

const ChatsList = ({
	intl,
	useChat,
  setExpandChatWindow,
  expandChatWindow,
  setShowUsersList,
}) => {

		const {
			conversations,
			activeConversation,
			getUser,
			setActiveConversation,
			getLastMessage,
      setCurrentMessage,
      messages,
      setMessagesAsRead,
		} = useChat

		const sortedConversations = useMemo(
      () => conversations.sort(({last_message_timestamp : a}, {last_message_timestamp : b}) => (a===null)-(b===null) || -(a>b)||+(a<b)),
      [conversations]
    )

		const getConversationProfileData = useCallback(conversation => {
			const participant = conversation.participants.length > 0 ? conversation.participants[0] : undefined;
			if (participant) {
				const user = getUser(participant.id);
				if (user) {
					return [<Avatar src={user.avatar} name={user.username}/>, user.username]
				}
			}
			return [undefined, undefined]
		}, [getUser])

    const onOpenConversation = useCallback((conversation) => {
      setCurrentMessage('')
      setShowUsersList(false)
      expandChatWindow?.length === 0 && setExpandChatWindow([CHAT_CONVERSATION_KEY])
      setActiveConversation(conversation.id)
      if (messages[conversation.id] && conversation.unreadCounter) {
        setMessagesAsRead(conversation?.id, messages[conversation?.id], conversation.unreadCounter)
      }
		}, [
      setCurrentMessage,
      setShowUsersList,
      expandChatWindow,
      setExpandChatWindow,
      setActiveConversation,
      messages,
      setMessagesAsRead
    ])

    return (
      <ConversationList className="GlobalChat__chatsList">
        {sortedConversations.map(c => {
          const [avatar, name] = getConversationProfileData(c)
          const lastMessage = getLastMessage(c.id)
          return (
            <Conversation
              className="GlobalChat__conversation"
              key={c.id}
              onClick={() => onOpenConversation(c)}	
              active={activeConversation?.id === c.id}
            >
              <Conversation.Content className="GlobalChat__conversationContent">
                {avatar}	
                <div className="GlobalChat__conversationWrapper">
                  <div className="GlobalChat__conversationName">{name}</div>
                  {lastMessage?.content ? (
                    <div className="GlobalChat__conversationInfo">
                      <div className="GlobalChat__conversationLastSender">
                        {lastMessage?.direction === MESSAGE_DIRECTION_OUTGOING ? intl.formatMessage({ id: `chats_list_last_sender_you` }) : name}
                      </div>:
                      <div className="GlobalChat__conversationLastMessage" dangerouslySetInnerHTML={{ __html: lastMessage.content }}></div>
                    </div>
                  ) : (
                    <div className="GlobalChat__conversationNoMessage">{intl.formatMessage({ id: `chats_list_empty_messages` }, {username: name})}</div>
                  )}
                </div>
                <div className="GlobalChat__conversationLastTimeWrapper">
                  <div className="GlobalChat__conversationLastTime">{c.last_message ? formatDateOrTime(c.last_message_timestamp, intl) : ""}</div>
                  {c.unreadCounter ? (<div className="GlobalChat__conversationUnread">{c.unreadCounter}</div>) : null}
                </div>
              </Conversation.Content>
            </Conversation>
          )
        })}
      </ConversationList>
    )
}

ChatsList.propTypes = {
  expandChatWindow: PropTypes.arrayOf(PropTypes.string),
	setExpandChatWindow: PropTypes.func,
	setShowUsersList: PropTypes.func.isRequired,
	useChat: PropTypes.object.isRequired,
};

export default injectIntl(ChatsList)