import React, { useMemo } from 'react';
import { Router } from 'react-router';
import { IntlProvider } from 'react-intl-redux'
import { Route, Switch } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';

import { Capacitor } from '@capacitor/core';

import configureStore, { history } from './store';
import './App.less';

import Root from './containers/Root';
import ScrollTop from './components/ScrollTop';

// Setup icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faSadCry,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAddressCard,
  faArrowLeft,
  faArrowsAltH,
  faArrowsAltV,
  faAward,
  faBell,
  faBoxOpen,
  faBuilding,
  faCalculator,
  faCalendarAlt,
  faCaretDown,
  faChalkboardTeacher,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronCircleLeft,
  faChevronLeft,
  faChevronRight,
  faClock,
  faCoffee,
  faCoins,
  faCog,
  faCopy,
  faCreditCard,
  faCubes,
  faDollarSign,
  faEnvelope,
  faEuroSign,
  faExclamationCircle,
  faExternalLinkAlt,
  faFileDownload,
  faFilter,
  faGlobe,
  faGlobeAmericas,
  faHandHoldingUsd,
  faHandsHelping,
  faHeart,
  faInfoCircle,
  faLink,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMapSigns,
  faMedal,
  faMoneyBillAlt,
  faMoneyCheckAlt,
  faPencilAlt,
  faPhone,
  faPlusCircle,
  faPlusSquare,
  faQrcode,
  faQuestionCircle,
  faSearch,
  faSpinner,
  faStar,
  faTimes,
  faTint,
  faTrophy,
  faUnlockAlt,
  faUserAlt,
  faUserCircle,
  faWifi,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faAddressCard,
  faArrowLeft,
  faArrowsAltH,
  faArrowsAltV,
  faAward,
  faBell,
  faBoxOpen,
  faBuilding,
  faCalculator,
  faCalendarAlt,
  faCaretDown,
  faChalkboardTeacher,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronCircleLeft,
  faChevronLeft,
  faChevronRight,
  faClock,
  faCoffee,
  faCoins,
  faCog,
  faCopy,
  faCreditCard,
  faCubes,
  faDollarSign,
  faEnvelope,
  faEuroSign,
  faExclamationCircle,
  faExternalLinkAlt,
  faFileDownload,
  faFilter,
  faGlobe,
  faGlobeAmericas,
  faHandHoldingUsd,
  faHandsHelping,
  faHeart,
  faInfoCircle,
  faLink,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMapSigns,
  faMedal,
  faMoneyBillAlt,
  faMoneyCheckAlt,
  faPencilAlt,
  faPhone,
  faPlusCircle,
  faPlusSquare,
  faQrcode,
  faQuestionCircle,
  faSadCry,
  faSearch,
  faSpinner,
  faStar,
  faTimes,
  faTint,
  faTrophy,
  faUnlockAlt,
  faUserAlt,
  faUserCircle,
  fab,
  faWifi,
);

export const store = configureStore();


/*
  App content is extracted out in a new AppContent component so we can
  start making use of the store in here.
*/
const AppContent = () => {
  const organization = useSelector(state => state.organization);

  const embedabble_community_app = useMemo(
    () => organization?.config.embedabble_community_app, [organization]
  );

  return (
    <div className={`App ${Capacitor.platform} ${embedabble_community_app ? 'embedded' : ''}`}>
      <Switch>
        <Route path="/" component={Root}/>
      </Switch>
    </div>
  );
}

const App = () => 
  <Provider store={store}>
    <IntlProvider>
        <Router history={history}>
          <ScrollTop>
            <AppContent />
          </ScrollTop>
        </Router>
    </IntlProvider>
  </Provider>;

export default App;
