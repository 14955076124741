import React, { useState, useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  Row,
  Col,
  List,
  Layout,
  Input,
  Button,
  Alert,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useForm from 'utils/useForm';
import Nav from 'containers/Nav';
import SettingsHeader from 'components/Settings/SettingsHeader';
import SocialNetworkInput from 'components/SocialNetworkInput';
import PhoneInput from 'components/PhoneInput';
import validation from 'components/PhoneInput/validation';
import AcceptTermsModal from 'components/AcceptTermsModal';
import {updateUsername} from 'utils/chat'

import './style.less';

const ICON_PER_PROFILE = {
  linkedin: ["fab", "linkedin"],
  twitter: ["fab", "twitter-square"],
  github: ["fab", "github"],
  gitlab: ["fab", "gitlab"],
  facebook: ["fab", "facebook"],
  default: "user-circle",
};

const AccountSettings = ({
  intl,
  profile,
  loading,
  organization,
  updateProfile,
  chatUserId,
}) => {
  const t = intl.messages;
  const [socialNetworkModal, setSocialNetworkModal] = useState(null);
  const [showVolunteeringTermsModal, setShowVolunteeringTermsModal] = useState(false);
  const [showAplanetTermsModal, setShowAplanetTermsModal] = useState(false);
  const socialProfiles = ((organization.config || {}).social_network_profiles || []);
  const validateForm = useMemo(() => validation(t), [ t ]);

  const handleUpdateProfile = () => {
    updateProfile(
      organization.slug,
      {phone: values.phone, name: values.name}
    );
    updateUsername(values.name, chatUserId)
  };

  const defaultValues = useMemo(() => {
    return profile && {
      phone: profile.phone,
      name: profile.name,
    };
  }, [
    profile
  ]);

  const {
    values,
    handleChange,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: handleUpdateProfile,
    validate: validateForm,
    defaultValues,
  });

  const updateSocialNetwork = useCallback((network, value = null) => {
    updateProfile(
      organization.slug,
      {
        social: {
          ...profile.social,
          [network]: value,
        }
      });
  }, [
    updateProfile,
    organization.slug,
    profile,
  ]);

  return (
    <>
      <Layout>
        <Nav />
        <Layout.Content className="Settings-main">
          <SettingsHeader
            heading={t.account}
            to="/settings"
          />
          <Row gutter={[0, 20]} className="Settings-account">
            <Col span={24}>
              <span>{ t.name }</span>
              <Input
                size="large"
                placeholder={t.your_name}
                value={values.name}
                onChange={(e) => handleChange('name')(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <span>{ t.email }</span>
              <Input
                size="large"
                placeholder={t.your_email}
                value={profile.email}
                disabled
              />
            </Col>
            <Col span={24}>
              <span>{ t.phone }</span>
              { errors.phone &&
                <Alert message={errors.phone} type="error" showIcon />
              }
              <PhoneInput
                size="large"
                placeholder={t.we_need_your_phone}
                value={values.phone}
                onChange={handleChange('phone')}
              />
            </Col>
            <Col span={24}>
              <List>
                <List.Item
                  onClick={() => setShowAplanetTermsModal(true)}
                  extra={<FontAwesomeIcon icon="chevron-right"/>}
                >
                  {intl.formatMessage({ id: `account_settings_view_aplanet_terms`})}
                </List.Item>
                {profile?.organization?.terms &&
                <List.Item
                  onClick={() => setShowVolunteeringTermsModal(true)}
                  extra={<FontAwesomeIcon icon="chevron-right"/>}
                >
                  {intl.formatMessage({ id: `account_settings_view_volunteering_terms`})}
                </List.Item>
                }
              </List>
            </Col>
            <Col span={24}>
              <Row justify="center">
              {(isDirty('name') || isDirty('phone')) && (
                <Col xs={24} md={8}>
                  <Button onClick={handleSubmit}>
                    { t.save }
                  </Button>
                </Col>
              )}
              </Row>
            </Col>
            <Col span={24}>
              { socialProfiles.length === 0 ? null :
              <List header={t.social_networks} >
                { socialProfiles.map(network => (
                  <List.Item
                    key={network}
                    onClick={() => setSocialNetworkModal(network)}
                    className="Settings-account-list"
                  >
                    <FontAwesomeIcon
                      className="Profile-network-icon"
                      icon={ICON_PER_PROFILE[network] || ICON_PER_PROFILE.default}
                    />
                    { ' ' }
                    { t[`social_network_${network}`]}
                    <span>
                      {profile.social[network] || t.social_unset}
                    </span>
                  </List.Item>
                ))}
              </List>
              }
            </Col>
          </Row>
          { !socialNetworkModal ? null :
            <SocialNetworkInput
              visible={true}
              network={socialNetworkModal}
              value={profile.social[socialNetworkModal]}
              onClose={() => setSocialNetworkModal(null)}
              loading={loading}
              onSave={updateSocialNetwork}
            />
          }
          <AcceptTermsModal
            title={t.terms_and_conditions_title}
            body={t.terms_and_conditions_body}
            lastTermsVersion={profile && profile.last_terms_and_conditions_version}
            lastTermsAccepted={profile && profile.last_terms_accepted}
            footer={null}
            visible={showAplanetTermsModal}
            onCancel={() => setShowAplanetTermsModal(false)}
          />
          <AcceptTermsModal
            title={t.volunteering_policy}
            body={profile?.organization?.terms}
            lastTermsVersion={profile?.organization?.org_last_terms_version}
            lastTermsAccepted={profile && profile.org_last_terms_accepted}
            privacyEmail={organization?.general_config?.contact_email}
            footer={null}
            visible={showVolunteeringTermsModal}
            onCancel={() => setShowVolunteeringTermsModal(false)}
          />
        </Layout.Content>
      </Layout>
    </>
  );
}

export default injectIntl(withRouter(AccountSettings));
