import React from 'react';
import { injectIntl } from 'react-intl';
import Avatar from 'components/Avatar';
import { getUserName } from 'utils/user';

const Submitter = ({
  intl,
  submitter,
}) => {
  // TODO: Change Avatar here for the proper Avatar in aplanet-ui-kit
  return (
    <>
      <div className="title">{ intl.formatMessage({ id: `submitted_by` }) }</div>
      <Avatar
        size="span"
        src={ submitter.avatar }
      >
        {getUserName(submitter, intl)}
      </Avatar>
      <span className="subtitle">{getUserName(submitter, intl)}</span>
    </>
  );
};

export default injectIntl(Submitter);

