import { cloneDeep, set, without } from 'lodash';
import {nanoid} from "nanoid";
import MessageGroup from 'utils/MessageGroup';

const initialState = {
  loggedIn: false,
  activeUser: {},
  users: [],
  activeConversation: null,
  conversations: {},
  messages: {},
}

const reducer = (state = initialState, action) => {
  const {conversation, conversationId, newMessages, message, count, userId, user, status, allUsers} = action
  const newState = cloneDeep(state)
  const {conversations, activeConversation, users} = newState

  switch(action.type) {
    case 'CHAT_CHANGE_LOGIN_STATUS':
      set(newState, ['loggedIn'], status)
      return newState
    case 'CHAT_ADD_ACTIVE_USER':
      set(newState, ['activeUser'], user)
      return newState
    case 'CHAT_ADD_USER':
      set(newState, ['users'], [...users, user])
      return newState
    case 'CHAT_ADD_USERS':
      set(newState, ['users'], allUsers)
      return newState
    case 'CHAT_ADD_ACTIVE_CONVERSATION':
      set(newState, ['activeConversation'], conversation)
      if(conversation) set(newState, ['conversations', conversation.id, 'unreadCounter'], 0)
      return newState
    case 'CHAT_ADD_CONVERSATION':
      set(newState, ['conversations', conversation.id], conversation)
      return newState
    case 'CHAT_ADD_MESSAGE':
      newMessages?.forEach(message => {
        const {messages} = newState
        if (conversationId in messages) {
          const groups = messages[conversationId];
          const lastGroup = groups[groups.length - 1];
          if (lastGroup.senderId === message.senderId) {
            lastGroup.addMessage(message)
            return
          }
        }
        const newGroup = new MessageGroup({
          id: nanoid(),
          senderId: message.senderId,
          direction: message.direction,
        })
        newGroup.addMessage(message)
        set(newState, ['messages', conversationId], [...(messages[conversationId] || []), newGroup])
      })
      return newState
    case 'CHAT_SET_CURRENT_MESSAGE':
      set(newState, ['currentMessage'], message)
      return newState
    case 'CHAT_SET_LAST_MESSAGE': 
      set(newState, ['conversations', conversationId, 'last_message'], message)
      const newDate = new Date()
      set(newState, ['conversations', conversationId, 'last_message_timestamp'], newDate.toISOString())
      set(newState, ['conversations', conversationId, 'last_message_user_id'], userId)
      return newState
    case 'CHAT_SET_UNREAD_COUNT_CONVERSATION':
      set(newState, ['conversations', conversationId, 'unreadCounter'], count)
      return newState
    case 'CHAT_SET_UNREAD_COUNT_ACTIVE_CONVERSATION':
      set(newState, ['activeConversation', 'unreadCounter'], count)
      return newState
    case 'CHAT_ADD_TYPING_USER': 
      set(newState, ['conversations', conversationId, 'typingUsers'], [...conversations[conversationId].typingUsers, userId])
      if(state.activeConversation?.id === conversationId) set(newState, ['activeConversation', 'typingUsers'], [...activeConversation.typingUsers, userId])
      return newState
    case 'CHAT_REMOVE_TYPING_USER': 
      set(newState, ['conversations', conversationId, 'typingUsers'], without(conversations[conversationId].typingUsers, userId))
      if(state.activeConversation?.id === conversationId) set(newState, ['activeConversation', 'typingUsers'], without(activeConversation.typingUsers, userId))
      return newState
    default:
      return state;
  }
};

export {
  reducer as chat,
};