import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryFallback } from 'aplanet-ui-kit';
import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  requestRankings,
} from 'actions/api';

import './style.less';

import NavBottom from 'containers/NavBottom';
import RankingCard from 'components/RankingCard';

import AppHeader from 'containers/AppHeader';
import Nav from 'containers/Nav';

import {
  Layout,
  Row,
  Col,
} from 'antd';

const Rankings = ({
  intl
}) => {
  const dispatch = useDispatch();
  const organization = useSelector(state => state.organization);

  // TODO: Pull to refresh
  useEffect(() => {
    dispatch(requestRankings(organization.slug));
  }, [organization.slug, dispatch]);

  const {
    data
  } = useSelector(state => state.rankings);

  const t = intl.messages;

  return (
    <Layout>
      <AppHeader/>
      <Layout>
        <Nav/>
          <Layout.Content>
            <Sentry.ErrorBoundary
              fallback={
              <ErrorBoundaryFallback
                titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
                buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
                descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_ranking_message' })}
                customErrorImage="/images/error_image.png"
              />
            }>
              { data &&
                <>
                  <Row className="Rankings-header">
                    <Col span={24}>
                      <h1>{ t.hall_of_fame }</h1>
                    </Col>
                  </Row>
                  <Row justify="center" className="Rankings-headertext">
                    <Col span={24}>
                      <h2>{ t.ranking_organization }</h2>
                    </Col>
                    <Col span={24}>
                      <RankingCard
                        region={organization?.region}
                        participants={data.organization_ranking}
                      />
                    </Col>
                  </Row>
                </>
              }
            </Sentry.ErrorBoundary>
          </Layout.Content>
      </Layout>
      <NavBottom />
    </ Layout>
  )
}

export default injectIntl(Rankings);
