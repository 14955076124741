import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import {
  Button,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Contact from './Contact';

const Buttons = ({
  intl,
  contact_person,
  contact_phone,
  contact_email,
  interested,
  loading,
  own_interested,
  slug,
  setShowRatingDrawer,
}) => {
  const t = intl.messages;
  const [ contactsShown, setContactsShown ] = useState(false);

  const showRate = own_interested;

  return (
    <>
      <Button
        className="secondary-button"
        onClick={() => setContactsShown(true)}
      >{ t.contact_organizer }</Button>

      { showRate
      ? <Button
        className="brand-button-primary"
        icon={<FontAwesomeIcon icon="star" />}
        onClick={() => setShowRatingDrawer(true)}
        >
        { t.rate_button }
      </Button>
      : <Button
          className="brand-button-primary"
          icon={<FontAwesomeIcon icon="heart" />}
          disabled={loading}
          onClick={interested}
        >
          { t.interested_button }
        </Button>
      }

      { contactsShown &&
        <Contact
          t={t}
          contact_person={contact_person}
          contact_phone={contact_phone}
          contact_email={contact_email}
          onClose={() => setContactsShown(false)}
        />
      }
    </>
  );
};

export default injectIntl(Buttons);

