import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import './style.less';

//import { isAfterNow } from 'utils/date';

import {
  logout,
} from 'actions/auth';

const Logout = ({
  history,
  auth,
  location,
  logout,
}) => {
  const replace = history.replace;
  const [ started, setStarted ] = useState(false);

  useEffect(() => {
    logout();
    setTimeout(() => setStarted(true));
  }, [ logout ]);

  const {
    logged_in,
  } = auth;

  useEffect(() => {
    if(started && !logged_in) {
      return replace('/signin');
    }
  }, [logged_in, replace, started, location.state]);

  return (
    <div className="Logout-back">
      <img src="/images/aplanet_a.png" alt="Aplanet" />
    </div>
  )
}

const mapStateToProps = ({
  auth,
}) => ({
  auth,
});

export default injectIntl(connect(mapStateToProps, { logout })(withRouter(Logout)));
