import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Contact from './Contact';

import {
  Button,
} from 'antd';

const Buttons = ({
  intl,
  profile,
  status,
  manager_approval_status,
  participation_method,
  participant_status,
  max_participants,
  participant_count,
  contact_person,
  contact_phone,
  contact_email,
  join,
  participate,
}) => {
  const [ contactsShown, setContactsShown ] = useState(false);

  const isCanceled = status === 'suspended';
  const showRejected = participant_status === 'rejected';
  const showParticipate = participant_status === 'accepted'; // TODO: Date limit? Init limit?
  const showComplete = !isCanceled && !showRejected && !showParticipate && max_participants >= 0 && (participant_count >= max_participants);
  const showJoin = !showComplete && !showRejected && !showParticipate;
  const disableJoin = isCanceled;

  const ParticipationIcon = <FontAwesomeIcon icon={ participation_method === 'qr' ? 'qrcode' : 'clock'} />;

  return (
    <>
      <Button
        className="secondary-button"
        onClick={() => setContactsShown(true)}
      >
        { intl.formatMessage({ id: `contact_organizer` }) }
      </Button>

      { showComplete &&
        <Button
          className="secondary-button"
          disabled
        >
          { intl.formatMessage({ id: `complete` }) }
        </Button>
      }

      { showRejected &&
        <Button
          className="secondary-button"
          type="warning"
          disabled
        >
          {
            manager_approval_status === 'rejected'
            ? intl.formatMessage({ id: `not_approved` })
            : intl.formatMessage({ id: `rejected` })
          }
        </Button>
      }

      { showParticipate &&
        <Button
          className="brand-button-primary"
          icon={ParticipationIcon}
          onClick={participate}
        >
          { intl.formatMessage({ id: `participation` }) }
        </Button>
      }

      { !!showJoin &&
        <Button
          className="brand-button-primary"
          disabled={disableJoin}
          onClick={join}
        >
          { intl.formatMessage({ id: `join` }) }
        </Button>
      }

      { contactsShown &&
        <Contact
          contact_person={contact_person}
          contact_phone={contact_phone}
          contact_email={contact_email}
          onClose={() => setContactsShown(false)}
        />
      }
    </>
  );
};

export default injectIntl(Buttons);
