import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from 'colors';

import './style.less';

const SATURATION = 30;
const LIGHTNESS = 80;
const MAX_WORDS = 2;

// TODO: When we add ProptTypes, check we have either 'onClick' or 'to'
const Avatar = ({
  onClick,
  region,
  src,
  name,
  icon,
}) => {
  const color = useMemo(() => {
    if(src || !name) {
      return null;
    }
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    var hue = hash % 360;
    // See https://medium.com/@pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
    return `hsl(${ hue }, ${ SATURATION }%, ${ LIGHTNESS }%)`;
  }, [ src, name ]);

  const initials = useMemo(() => {
    if(src || !name) {
      return null;
    }

    const words = name.split(' ').filter(str => str.length > 0);

    switch(region) {
      case 'PT':
      case 'BR':
        // For reasons passing human understanding we support different initials when the
        // names correspond to Portugal or Brazil. See, in those countries we need to use
        // the FIRST and LAST words instead of the first two...
        if(words.length === 1) {
          return words[0][0].toUpperCase();
        }
        // This assumes MAX_WORDS = 2, because what should we do otherwise?
        return [
          words[0],
          words.slice(-1)[0],
        ].map(word => word[0].toUpperCase()).join('');
      default:
        return words.slice(0, MAX_WORDS).map(word => word[0].toUpperCase()).join('');
    }
  }, [ src, name, region ]);

  return (
    <div onClick={onClick} className="Avatar">
      { src
      ? <img alt="" src={src} />
      : name
      ? <div style={{ backgroundColor: color }} className="Avatar-initials">{ initials }</div>
      : <FontAwesomeIcon icon={icon} color={colors['@color-icon-faded']} />
      }
    </div>
  );
}

export default Avatar;
