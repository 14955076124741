import { loop, Cmd } from 'redux-loop';
import { parseResponse } from 'utils';
import config from 'config';

// We need this in the backend for the link to come back here
const app = 'mobile';

const requestRecover = async (email) => {
  const response = await fetch(`${config.AUTH_URL}/recover_password`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      email,
      app,
    }),
  })

  const result = await parseResponse(response);
  return result;
};

const requestSuccess = (response) => ({
  type: 'RECOVER_PASSWORD_COMPLETE',
});

const requestFail = ({ code, text }) => ({
  type: 'RECOVER_PASSWORD_FAILED',
  code,
  text,
});

const requestSubmit = async (token, password, agreements) => {
  const response = await fetch(`${config.AUTH_URL}/recover_password/${token}`, {
    method: 'PUT',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      password,
      agreements,
    }),
  })

  const result = await parseResponse(response);
  return result;
};

const submitSuccess = (response) => ({
  ...response,
  type: 'SUBMIT_RECOVERED_PASSWORD_COMPLETE',
});

const submitFail = ({ code, text }) => ({
  type: 'SUBMIT_RECOVERED_PASSWORD_FAILED',
  code,
  text,
});

const initialState = {
  requested: false,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'RECOVER_PASSWORD_REQUEST':
      return loop({ requested: false, loading: true, error: null, },
                Cmd.run(requestRecover, {
                  successActionCreator: requestSuccess,
                  failActionCreator: requestFail,
                  args: [ action.email ],
                }));
    case 'RECOVER_PASSWORD_COMPLETE':
      return { requested: true, loading: false, error: null };
    case 'SUBMIT_RECOVERED_PASSWORD_REQUEST':
      return loop({ requested: false, loading: true, error: null, },
                Cmd.run(requestSubmit, {
                  successActionCreator: submitSuccess,
                  failActionCreator: submitFail,
                  args: [ action.token, action.password, action.agreements ],
                }));
    case 'RECOVER_PASSWORD_FAILED':
    case 'SUBMIT_RECOVERED_PASSWORD_FAILED':
      return { requested: false, loading: false, error: action.code };
    case 'RESET_AUTH_ERROR':
      return {
        ...state,
        error: null,
      };
    case 'SUBMIT_RECOVERED_PASSWORD_COMPLETE':
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState; // The 'auth' reducer will do the rest
    default:
      return state;
  }
};

export {
  reducer as password_recovery,
};
