const validations = (t) => ({
  type: ({ type }) => {
    let errors = {};
    if(!type) {
      errors.type = t.form_error_required;
      errors._firstError = 'type';
    }
    return errors;
  },

  title: ({ title }) => {
    let errors = {};
    if(!title) {
      errors.title = t.form_error_required;
      errors._firstError = 'title';
    } else if(title.length > 75) {
      errors.title = t.form_error_max_title_length;
      errors._firstError = 'title';
    }
    return errors;
  },

  description: ({ description }) => {
    let errors = {};
    if(!description) {
      errors.description = t.form_error_required;
      errors._firstError = 'description';
    }
    return errors;
  },

  when: ({ start_date, start_time, end_date, end_time }) => {
    let errors = {};
    if(!start_date && !start_time && !end_date && !end_time) {
      // This field is not mandatory, bailing out
      return errors;
    }

    if(!start_date || !start_time) {
      errors.start_date = t.form_error_required;
      errors._firstError = 'when';
    }

    if(!end_date || !end_time) {
      errors.end_date = t.form_error_required;
      errors._firstError = 'when';
    }
    return errors;
  },

  where: ({
    is_in_person,
    address,
    latitude,
    longitude
  }) => {
    let errors = {};

    if(is_in_person && !address) {
      errors.address = t.form_error_required;
      errors._firstError = 'where';
      return errors;
    }

    if(typeof latitude === 'undefined' && typeof longitude === 'undefined') {
      return errors;
    }

    if(typeof latitude !== 'number' || typeof longitude !== 'number') {
      errors.address = t.form_error_wrong_coords;
      errors._firstError = 'where';
    }
    return errors;
  },

  sdgs: ({ sdgs }, orgConfig) => {
    let errors = {};
    if(sdgs.length > (orgConfig?.max_number_of_sdgs || 3)) {
      errors.sdgs = t.form_error_max_sdgs;
      errors._firstError = 'sdgs';
    }
    return errors;
  },

  skills: ({ competences }) => {
    let errors = {};
    return errors;
  },
});

const fullValidation = (t) => {
  const v = validations(t);

  return {
    ...v,
    send: (body, orgConfig) => {
      // NOTICE: The order is important here so errors._firstError is overwritten
      let errors = {
        ...v.skills(body),
        ...v.sdgs(body, orgConfig),
        ...v.where(body),
        ...v.when(body),
        ...v.description(body),
        ...v.title(body),
      };

      return errors;
    },
  }
};

export default fullValidation;