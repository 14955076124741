import React, {useMemo, useCallback} from "react";
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Spin } from 'antd';
import { isEmpty } from "lodash";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import ChatHeader from "./ChatHeader";
import ChatsList from "./ChatsList";
import ChatConversation from "./ChatConversation";
import ChatUsersList from "./ChatUsersList";

const ChatFullScreen = ({
  onClose,
  useChat,
  handleChange,
  handleSend,
  onClickNewChat,
  showUsersList,
  setShowChatUsersList,
  searchUser,
  setSearchUser,
}) => {
    const {
      activeConversation,
      conversations,
      activeUser,
      getUser,
      setActiveConversation,
    } = useChat

    const currentChatUserHeader = useMemo(() => {

      if (activeConversation) {
        const participant = activeConversation.participants?.length > 0 ? activeConversation.participants[0] : undefined;
        if (participant) {
          const user = getUser(participant.id);
          if (user) {
            return {
              avatar: user.avatar,
              username: user.username,
              userId: user.id,
            }
          }
        }
      }
  
      return {
        avatar: activeUser.avatar,
        username: activeUser.username,
        id: activeUser.id,
      }
  
    }, [
      activeConversation,
      activeUser.avatar,
      activeUser.id,
      activeUser.username,
      getUser,
    ])

    const onClickGoBack = useCallback((event) => {
      event.stopPropagation()
      setActiveConversation("")
      setShowChatUsersList(false)
    }, [
      setActiveConversation,
      setShowChatUsersList
    ])

    const isMyChat = useMemo(
      () => activeUser.id === currentChatUserHeader.id && !showUsersList && !activeConversation,
      [activeConversation, activeUser.id, currentChatUserHeader.id, showUsersList]
    );

    return (
      <div className="GlobalChat__chatFullScreen" >
        <div className="GlobalChat__chatFullScreenHeaderWrapper">
          <ChatHeader
            avatar={currentChatUserHeader.avatar}
            username={currentChatUserHeader.username}
            onClose={onClose}
            onGoBack={(event) => onClickGoBack(event)}
            onClickNewChat={onClickNewChat}
            isMyChat={isMyChat}
            isNewChat={showUsersList}
            hasClose={isMyChat}
            hasNewChat={isMyChat}
            hasGoBack={showUsersList || !isEmpty(activeConversation)}
          />
        </div>
        {!conversations.length && !activeConversation && (<Spin/>)}
        {conversations.length && !activeConversation && !showUsersList && (
            <ChatsList useChat={useChat} setShowUsersList={setShowChatUsersList} />
        )}
        {activeConversation ? (
          <ChatConversation
            handleChange={handleChange}
            handleSend={handleSend}
            useChat={useChat}
            isFullScreen
          />
        ) : null}
        {showUsersList ? (
          <ChatUsersList
            useChat={useChat}
            setShowChatUsersList={setShowChatUsersList}
            searchUser={searchUser}
						setSearchUser={setSearchUser}
          />
        ) : null}
      </div>
    )
}

ChatFullScreen.propTypes = {
	handleChange: PropTypes.func.isRequired,
	handleSend: PropTypes.func.isRequired,
	useChat: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	onClickNewChat: PropTypes.func.isRequired,
	setShowChatUsersList: PropTypes.func.isRequired,
	setSearchUser: PropTypes.func.isRequired,
  searchUser: PropTypes.string,
	showUsersList: PropTypes.bool.isRequired,
};

export default injectIntl(ChatFullScreen)