import React, {
  useEffect,
  useMemo,
} from 'react';

import {
  Row,
  Col,
  Button,
  Input,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PasswordPolicyInfo } from 'aplanet-ui-kit';

import A from 'components/A';
import QuoteWall from 'components/QuoteWall';

const DEFAULT_COVER_URL = '/images/cover.jpg';

const PasswordSignUp = ({
  t,
  intl,
  organization,
  values,
  handleFieldChange,
  handleSubmit,
  showError,
  passwordError,
  reset,
  resetAuthError,
  error,
  resendConfirmationEmail,
  history,
  locationState,
  password_policy,
}) => {
  const cover_url = useMemo(() => {
    return organization?.config?.cover_url || DEFAULT_COVER_URL
  }, [
    organization,
  ]);

  useEffect(() => {
    if(!values.name || !values.email || !values.agreeTC) reset();
  }, [values, reset]);

  return (
    <Row className="SignUp-password">
      <Col xs={24} sm={12}>
        <Row>
          <Col
            span={24}
            className="header"
            style={{
              background: `linear-gradient( to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url('${cover_url}')`,
              backgroundSize: "cover"
            }}
          >
            <FontAwesomeIcon size="2x" icon="arrow-left" onClick={reset} />
            <h1>{t.password}</h1>
            <h4>{values.name}</h4>
            <h4>{values.email}</h4>
          </Col>
          <Col span={24}>
            { showError }
            <div className="SignUp-input">
              <Input
                type="password"
                placeholder={ t.choose_your_password }
                suffix={<PasswordPolicyInfo intl={intl} policy={password_policy} />}
                value={values.password}
                onChange={e => handleFieldChange.password(e.target.value)}
              />
            </div>
          </Col>
          <div className="SignUp-bottomlink">
            <A onClick={() => history.push('/signin', locationState)}>{ t.already_have_account }</A>
          </div>
        </Row>
        <Button
          className="brand-button-primary cta"
          type="primary"
          disabled={!values.name || !values.email || !values.password || !values.agreeTC || passwordError}
          onClick={handleSubmit}
        >{ t.register }</Button>
      </Col>
      <Col xs={0} sm={12} className="cover">
        <QuoteWall organization={organization} />
      </Col>
    </Row>
  );
}

export default PasswordSignUp;
