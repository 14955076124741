import React from 'react';

import './style.less';

const A = ({ href, onClick, children }) => {
  // NOTE: provide either 'href' or 'onClick' here, but not both
  if(href) {
    const newWindow = href.startsWith('http');
    // eslint-disable-next-line react/jsx-no-target-blank
    return (<a
        className="A-a"
        href={href}
        target={newWindow ? '_blank' : undefined}
        rel={newWindow ? 'nofollow noopener noreferrer' : 'nofollow'}
      >{ children }</a>
    );
  }

  return (
    <button
      type="button"
      className="A-button"
      onClick={onClick}
    >{ children }</button>
  );
}

export default A;
