import React from 'react';

import './style.css';

const OrganizationLogo = ({ name, logo, logo_small, className = '' }) => {
  return (
    <div className={`OrganizationLogo ${className}`}>
      <img src={logo} alt={name} />
    </div>
  );
}

export default OrganizationLogo;
