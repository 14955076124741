import React from 'react';
import { Link } from 'react-router-dom';

import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SettingsHeader = ({
  heading,
  to,
}) => {

  return (
    <Row className="Settings-header">
      <Link to={to}>
        <FontAwesomeIcon
          size="2x"
          icon="arrow-left"
        />
      </Link>
      <Col span={24} className="Settings-heading">
        { heading }
      </Col>
    </Row>
  );
}

export default SettingsHeader;
