import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import Loading from 'containers/Loading';

const CorporateSignUpRedirect = ({
  match,
  history,
  corporate_signup,
  requestOrganizationByToken,
  requestOrganizationByInitiative,
  locationState,
  reset,
}) => {
  const push = history.push;
  const {
    organization_token,
    initiative_slug,
  } = match.params;

  const [requested, setRequested] = useState(false);

  useEffect(() => {
    if(requested) {
      return;
    }
    setRequested(true);
    if(initiative_slug && !organization_token) {
      requestOrganizationByInitiative(initiative_slug);
    } else if(!organization_token || organization_token === 'c') {
      // This means we no longer haver a corporate_signup.token
      // So we do NOT know how to do a corporate signup
      reset();
    } else {
      requestOrganizationByToken(organization_token);
    }
    // TODO: Cleanup requests on unmount
  }, [
    requested,
    organization_token,
    initiative_slug,
    requestOrganizationByToken,
    requestOrganizationByInitiative,
    reset,
  ]);

  useEffect(() => {
    if(!corporate_signup.done) return;

    if(corporate_signup.corporate) {
      push(`/signup/c/${corporate_signup.token}`, locationState);
    } else {
      push(`/signup`, locationState);
    }
  }, [
    corporate_signup,
    push,
    locationState,
  ]);

  return (<Loading />);
};

export default withRouter(CorporateSignUpRedirect);
