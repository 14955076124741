import React, {
  useEffect,
  useRef,
} from 'react';
import { withRouter } from 'react-router';
import {
  Row,
  Col,
} from 'antd';
import NavTopBack from 'components/NavTopBack';
import OrganizationLogo from 'components/OrganizationLogo';
import QuoteWall from 'components/QuoteWall';
import A from 'components/A';

const LOGGED_IN_REDIRECT_URL = '/wall';
const POPUP_NAME = 'popup_window';
const POPUP_SPECS = 'resizable=no';

const SsoConfirmationSignUp = ({
  t,
  history,
  organization,
  values,
  reset,
  ssoSignupUrl,
  loggedIn,
  reset: resetAuth,
}) => {
  const push = history.push;
  const popupRef = useRef(null);

  useEffect(() => {
    if(loggedIn) {
      push(LOGGED_IN_REDIRECT_URL);
    }
  }, [
    loggedIn,
    push,
  ]);

  useEffect(() => {
    if(ssoSignupUrl) {
      popupRef.current = window.open(ssoSignupUrl, POPUP_NAME, POPUP_SPECS);
    }
  }, [ ssoSignupUrl ]);


  return (
    <Row className="SignUp SignUp-SsoConfirmationSignUp">
      <NavTopBack onClick={reset} />
      <Col xs={24} sm={12}>
        <div className="SignUp-flex"></div>
        <img className="SignUp-aplanetlogo" src="/images/aplanet_a.png" alt="Aplanet" />
        <div>{ t.member_of }</div>
        <OrganizationLogo className="SignUp-corporatelogo" {...organization} />
        <div>{ t.redirecting_to_sso }</div>
        <div><strong>{ values.email }</strong></div>
        <div className="SignUp-flex2"></div>
        <div className="SignUp-bottomlink">
          <A onClick={reset}>{ t.dont_want_this_community }</A>
        </div>
      </Col>
      <Col xs={0} sm={12} className="cover">
        <QuoteWall organization={organization} />
      </Col>
    </Row>
  );
}

export default withRouter(SsoConfirmationSignUp);
