import React, {
  useState,
  useCallback,
} from 'react';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from 'colors';

import {
  Input,
  Button,
} from 'antd';

const iconsForUnits = {
  'kilogram': 'cubes',
  'kilometre': 'map-signs',
  'litre': 'tint',
  'number': 'calculator',
  'metre': 'arrows-alt-h',
  'square-metre': 'arrows-alt-v',
  'euro': 'euro-sign',
  'brl': 'dollar-sign',
};

const ReportIndicator = ({ intl, indicator, reportIndicator }) => {
  const [value, setValue] = useState('');

  const report = useCallback(
    (indicatorId, value) => {
      reportIndicator(indicatorId, value)
      setValue('');
    }, [reportIndicator]
  );

  return (
    <div className="ParticipationScreen-report">
      <div className="header">
        <span> { intl.formatMessage({ id: `donation_need_to_be_validated` }) }</span>
      </div>
      <Input
        type="number"
        min={1}
        placeholder={ `${indicator.name} (${intl.formatMessage({ id: `unit_${indicator.unit}` })})` }
        value={value}
        onChange={(e) => setValue(e.target.value)}
        prefix={<FontAwesomeIcon icon={iconsForUnits[indicator.unit]} color={colors['@color-icon-faded']} />}
        suffix={
          <Button
            type="primary"
            size="small"
            disabled={!value || parseInt(value) <= 0}
            onClick={() => report(indicator.id, value)}
          >
            { intl.formatMessage({ id: `report` }) }
          </Button>
        }
      >
      </Input>
    </div>
  );
};

export default injectIntl(ReportIndicator);
