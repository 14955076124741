import { combineReducers } from 'redux-loop';
import { intlReducer } from 'react-intl-redux';
import { auth } from './auth';
import { signin } from './signin';
import { api_requests } from './api_requests';
import { organization } from './organization';
import { wall } from './wall';
import { my_initiatives } from './my_initiatives';
import { initiative_detail } from './initiative_detail';
import { password_recovery } from './password_recovery';
import { password_policy } from './password_policy';
import { email_confirmation } from './email_confirmation';
import { corporate_signup } from './corporate_signup';
import { my_profile } from './my_profile';
import { responsible_profile } from './responsible_profile';
import { rankings } from './rankings';
import { initiative_join_done } from './initiative_join_done';
import { initiative_not_interested_done } from './initiative_not_interested_done';
import { submit_score } from './submit_score';
import { proposals } from './proposals';
import { active_wait } from './active_wait';
import { onboarding } from './onboarding';
import { profile_request_failed } from './profile_request_failed';
import { initiative_documents } from './initiative_documents';
import { initiative_images } from './initiative_images';
import { app } from './app';
import { sso } from './sso';
import { chat } from './chat';

const reducers = (history) => combineReducers({
  intl: intlReducer,
  app,
  auth,
  sso,
  signin,
  api_requests,
  organization,
  wall,
  my_initiatives,
  initiative_detail,
  password_recovery,
  password_policy,
  email_confirmation,
  corporate_signup,
  my_profile,
  responsible_profile,
  rankings,
  initiative_join_done,
  initiative_not_interested_done,
  submit_score,
  proposals,
  active_wait,
  onboarding,
  profile_request_failed,
  initiative_documents,
  initiative_images,
  chat,
});

export default reducers;