import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import {
  isAfterNow,
  differenceInDays,
  fromDb,
} from 'utils/date';
import DonationProgress from 'components/DonationProgress';

const DonationBlock = ({
  intl,
  organization,
  own_contribution_total = 0,
  contribution_total = 0,
  donation_params = {},
  contributor_count = 0,
  contributions,
  start_time,
  end_time,
}) => {
  const currency = donation_params?.currency ? donation_params.currency : organization.config.currency;
  const target_amount = donation_params.target_amount;

  const statusText = isAfterNow(start_time)
    ? intl.formatMessage({ id: `donation_upcoming` })
    : (
      isAfterNow(end_time)
      ? intl.formatMessage({ id: `donation_ongoing` })
      : intl.formatMessage({ id: `donation_finished` })
    );

  const ownTotal = useMemo(() => parseFloat(own_contribution_total, 10), [ own_contribution_total ]);
  const total = useMemo(() => parseFloat(contribution_total, 10), [ contribution_total ]);
  const limit = useMemo(() => {
    const limit = target_amount ? parseInt(target_amount, 10) : 0;
    if(limit >= total) {
      return limit;
    }
    return 0;
  }, [ target_amount, total ]);

  const showTargetAmount = donation_params?.show_target_amount && target_amount;

  const daysLeftStr = useMemo(() => (end_time && differenceInDays(fromDb(end_time), new Date()) > 0 && differenceInDays(fromDb(end_time), new Date())) || 0, [ end_time ]);
  return (
    <div>
      <div className="title">{ intl.formatMessage({ id: `fundraising_info` }) }</div>
      <div className="status">{statusText}</div>
      {
        showTargetAmount && (
          <div className="progress-bar">
            <DonationProgress
              showValue={false}
              total={contribution_total}
              limit={target_amount}
              currency={currency}
            />
          </div>
        )
      }
      <div className="donation-details">
        <div className="details">
          <div className="type">
            <div className="main">
              { `${total} ${ intl.formatMessage({ id: currency }) }` }
            </div>
          </div>

          {
            showTargetAmount && (
              <div className="value">
                  { intl.formatMessage({ id: `target_amount` }) }
                  {
                    !!limit && <span> { limit }</span>
                  }
              </div>
            )
          }
        </div>
        { ownTotal === 0 ? null :
          <div className="details">
            <div className="type">{ intl.formatMessage({ id: `donation_own_contribution` }) }: </div>
            <div className="value">{ `${ownTotal} ${intl.formatMessage({ id: currency })}` }</div>
          </div>
        }
        <div className="details">
          <div className="type">{contributor_count}</div>
          <div className="value">{intl.formatMessage({ id: `contributors` })}</div>
        </div>
        <div className="details">
          <div className="type">{ daysLeftStr }</div>
          <div className="value">{intl.formatMessage({ id: `days_left` })}</div>
        </div>
      </div>
      { !!donation_params.tax_deductible &&
        <div className="tax-info">
          <img src='/images/checkmark.svg' alt={intl.formatMessage({ id: `donation_tax_deductible` })}/>
          <p>{ intl.formatMessage({ id: `donation_tax_info_default` }) }</p>
        </div>
      }
    </div>
  );
};

export default injectIntl(DonationBlock);
