import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';

import {
  Layout,
  List,
  Switch,
} from 'antd';

import Nav from 'containers/Nav';
import SettingsHeader from 'components/Settings/SettingsHeader';

const NotificationSettings = ({
  intl,
  profile,
  organization,
  updateProfile,
}) => {
  const t = intl.messages;

  const updateCommercialComms = useCallback(() => {
    const commercial_comms_accepted = !profile.commercial_comms_accepted;
    updateProfile(organization.slug, { commercial_comms_accepted });
  }, [
    updateProfile,
    organization.slug,
    profile,
  ]);

  if (!profile) {
    return null;
  }

  return (
    <>
      <Layout>
        <Nav />
        <Layout.Content className="Settings-main">
          <SettingsHeader heading={t.notifications} to="/settings" />
          <List className="Settings-notification">
            <List.Item
              extra={
                <Switch
                  checkedChildren={t.yes}
                  unCheckedChildren={t.no}
                  onChange={updateCommercialComms}
                  defaultChecked={profile.commercial_comms_accepted}
                />
              }
            >
              <div>
                { t.receive_commercial_comms }
              </div>
            </List.Item>
          </List>
        </Layout.Content>
      </Layout>
    </>
  );
}

export default injectIntl(NotificationSettings);
