import React, {useCallback, useRef, useEffect} from "react";
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Spin } from 'antd';

// @ts-ignore
import {
	ChatContainer,
	MessageGroup,
	Message,
	MessageList,
	MessageInput,
	TypingIndicator,
	MessageSeparator,
} from "@chatscope/chat-ui-kit-react";

const ChatConversation = ({
	intl,
	handleChange,
	handleSend,
	useChat,
	isFullScreen = false,
}) => {

	const {
		activeConversation,
		currentMessages,
		currentMessage,
		getUser
	} = useChat;

	const inputRef = useRef();
	useEffect(() => {
		if (inputRef.current && isFullScreen) {
			inputRef.current.focus()
		}
	});

	const getTypingIndicator = useCallback(
		() => {
			if (activeConversation) {
				const typingUsers = activeConversation.typingUsers;	
				if (typingUsers.length > 0) {
					const typingUserId = typingUsers[0];
					// Check if typing user participates in the conversation
					if (activeConversation.participants.some(part => part.id === typingUserId)) {
							const typingUser = getUser(typingUserId);
							if (typingUser) {
									return <TypingIndicator content={intl.formatMessage({ id: `chat_conversation_user_typing` }, { user: typingUser.username }) } />
							}
					}
				}
			}
			return undefined;
		}, [
			activeConversation,
			getUser,
			intl,
		],
	);	

	return (
    <div className="GlobalChat__chatConversation">
      <ChatContainer className="GlobalChat__chatContainer">
        <MessageList typingIndicator={getTypingIndicator()} className="GlobalChat__messageList">
        {currentMessages ? (currentMessages.map((g, groupIndex) =>
						<MessageGroup key={g.id} direction={g.direction}>
							<MessageGroup.Messages>
									{g.messages.map((m, messageIndex) =>
										<React.Fragment key={m.id}>
											{groupIndex === currentMessages.length - 1 && messageIndex === g.messages.length - activeConversation.unreadCounter ?
												(<MessageSeparator className="GlobalChat__messageSeparator" content={intl.formatMessage({ id: `chat_conversation_new_message` })} />) : null
											}
											<Message
												model={{
														type: "html",
														payload: m.content
												}}
											/>
										</React.Fragment>
									)}
							</MessageGroup.Messages>
						</MessageGroup>
          )) : (activeConversation.last_message && <Spin/>)}
        </MessageList>
        <MessageInput
					ref={inputRef}
					value={currentMessage}
					onChange={handleChange}
					onSend={handleSend}
					disabled={!activeConversation}
					attachButton={false}
					placeholder={intl.formatMessage({ id: `chat_conversation_input_placeholder` })}
					autoFocus
				/>
      </ChatContainer>
    </div>
	)
}

ChatConversation.propTypes = {
	handleChange: PropTypes.func.isRequired,
	handleSend: PropTypes.func.isRequired,
	useChat: PropTypes.object.isRequired,
	isFullScreen: PropTypes.bool,
};

export default injectIntl(ChatConversation)