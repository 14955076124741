import React from 'react';
import { injectIntl } from 'react-intl';

const Categories = ({ intl, categories = [] }) => {
  return (
    <>
      <div className="title"> { intl.formatMessage({ id: `categories` }) } </div>
      <div className="items">
        { categories.map(category =>
            <div className="item" key={category}>{ intl.formatMessage({ id: `category_${category}` }) }</div>
          )
        }
      </div>
    </>
  );
};

export default injectIntl(Categories);
