import React from 'react';

import { Typography } from 'antd';

import T from 'components/T';

const Paypal = ({
  source = 'paypal',
  params = {},
  single = false,
  amount,
  currency,
}) => {
  return (
    <Typography.Paragraph>
      <T donation_paypal_desc={{ amount, currency }} />
    </Typography.Paragraph>
  );
};

export default Paypal;

