import React, {
  useCallback,
  useState,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Nav from 'containers/Nav';
import CohortSelector from 'components/CohortSelector';
import OnboardingAddress from 'components/OnboardingAddress';
import OnboardingExperienceEducationSkills from 'components/OnboardingExperienceEducationSkills';
import OnboardingSelector from 'components/OnboardingSelector';
import OnboardingSelectorOne from 'components/OnboardingSelectorOne';
import OnboardingAlias from 'components/OnboardingAlias';
import OnboardingMachineNumber from 'components/OnboardingMachineNumber';
import OnboardingSpecificTraining from 'components/OnboardingSpecificTraining';
import OnboardingAvailability from 'components/OnboardingAvailability';
import OnboardingVolunteeringMotivation from 'components/OnboardingVolunteeringMotivation';
import OnboardingTextArea from 'components/OnboardingTextArea';
import OnboardingDateOfBirth from 'components/OnboardingDateOfBirth';
import SettingsHeader from 'components/Settings/SettingsHeader';

import {
  Layout,
  Row,
  Col,
  Card,
  List,
  Button,
  Empty
} from 'antd';

// This is repeated from the Onboarding container, but really who cares...
const RELATIONS_WITH_ORG = [
  'employee', // Colaborador no ativo
  'retired', // Reformado do BPI
  'group_employee', // Colaborador do Grupo BPI
  'friends_family', // Amigo ou Familiar
  'customer', // Cliente BPI
];

// This is repeated from the Onboarding container, but really who cares...
const TSHIRT_SIZES = [
  { slug: 'XS', name: 'XS', },
  { slug: 'S', name: 'S', },
  { slug: 'M', name: 'M', },
  { slug: 'L', name: 'L', },
  { slug: 'XL', name: 'XL', },
  { slug: 'XXL', name: 'XXL', },
];

const PreferenceSettings = ({
  intl,
  profile,
  updateProfile,
  organization,
}) => {
  const t = intl.messages;
  const [currentModal, setCurrentModal] = useState();

  const updateDetails = useCallback((changes = {}) => {
    if (profile) {
      const oldDetails = profile.details || {};
      updateProfile(
        organization.slug,
        {
          details: {
            ...oldDetails,
            ...changes,
          }
        }
      );
    }
  }, [
    profile,
    updateProfile,
    organization.slug,
  ]);

  const onProfileSave = useCallback((data = {}) => {
    updateProfile(organization.slug, data);
  }, [
    updateProfile,
    organization.slug,
  ]);

  const targetAudiences = useMemo(() => {
    return ((profile && profile.target_audience) || []).map(({ slug, ...rest }) => {
      return {
        ...rest,
        slug,
        name: t[`target_audience_${slug}`],
      };
    });
  }, [
    profile,
    t,
  ]);

  const categories = useMemo(() => {
    return ((profile && profile.categories) || []).map(({ slug, ...rest }) => {
      return {
        ...rest,
        slug,
        name: t[`category_${slug}`],
      };
    });
  }, [
    profile,
    t,
  ]);

  const translatedRelationWithOrgValues = useMemo(() => {
    return RELATIONS_WITH_ORG.map((slug) => {
      return {
        slug,
        name: t[`relation_with_org_${slug}`],
      };
    });
  }, [
    t,
  ]);

  const getValueForBoolean = useCallback(
    (value) => {
      if (value === true) {
        return t.yes;
      } else if (value === false) {
        return t.no;
      } else {
        return t.no_data;
      }
    },
    [t]
  );

  const onboardingSteps = useMemo(() => {
    return new Set(
      organization.config
        ? organization.config.onboarding_steps || []
        : []
    );
  }, [
    organization,
  ]);

  if (!profile) {
    return null;
  }

  return (
    <>
      <Layout>
        <Nav />
        <Layout.Content className="Settings-main">
          <SettingsHeader heading={t.preferences} to="/settings" />
          {!onboardingSteps?.length &&
            <Empty description={t.no_data} />
          }
          <Row align="middle" className="Settings-preference-row">
            { !!profile.public_user_groups?.length && onboardingSteps.has('public_groups') &&
            <Col span={24}>
              <Card title={t.groups} className="ResponsibleProfile-statcard">
                <List>
                  {profile.public_user_groups.filter(({ member }) => member).map(({ name, member }) => (
                    <List.Item key={name}>
                      { name }
                    </List.Item>
                  ))}
                  <List.Item>
                    <Button
                      className="Button-secondary"
                      onClick={() => setCurrentModal('cohorts')}
                    >
                      {t.select_group}
                    </Button>
                  </List.Item>
                </List>
              </Card>
            </Col>
            }
            { onboardingSteps.has('target_audience') &&
              <Col span={24}>
                <Card title={t.target_audience} className="ResponsibleProfile-statcard">
                  <List>
                    {targetAudiences.filter(({ member }) => member).map(({ slug, name }) => (
                      <List.Item key={slug}>
                        { name }
                      </List.Item>
                    ))}
                    <List.Item>
                      <Button
                        className="Button-secondary"
                        onClick={() => setCurrentModal('target_audience')}
                      >
                        {t.target_audience_selector_title}
                      </Button>
                    </List.Item>
                  </List>
                </Card>
              </Col>
            }
            { onboardingSteps.has('categories') &&
              <Col span={24}>
                <Card title={t.categories} className="ResponsibleProfile-statcard">
                  <List>
                    {categories.filter(({ member }) => member).map(({ slug, name }) => (
                      <List.Item key={slug}>
                        { name }
                      </List.Item>
                    ))}
                    <List.Item>
                      <Button
                        className="Button-secondary"
                        onClick={() => setCurrentModal('categories')}
                      >
                        {t.category_selector_title}
                      </Button>
                    </List.Item>
                  </List>
                </Card>
              </Col>
            }
            { onboardingSteps.has('address') &&
              <Col span={24}>
                <Card
                  title={t.place_of_residence}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('address')} />}
                  className="ResponsibleProfile-statcard"
                >
                  {profile.details.address
                    ?
                    <List>
                      <List.Item>
                        {profile.details.address}
                      </List.Item>
                    </List>
                    :
                    <React.Fragment>
                      <div className="ResponsibleProfile-emptytext">{t.no_data}</div>
                    </React.Fragment>
                  }
                </Card>
              </Col>
            }
            { onboardingSteps.has('experience') &&
              <Col span={24}>
                <Card
                  title={t.professional_experience}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('details')} />}
                  className="ResponsibleProfile-statcard"
                >
                  {profile.details.professional_experience
                    ?
                    <List>
                      <List.Item>
                        {profile.details.professional_experience}
                      </List.Item>
                    </List>
                    :
                    <React.Fragment>
                      <div className="ResponsibleProfile-emptytext">{t.no_data}</div>
                    </React.Fragment>
                  }
                </Card>
              </Col>
            }
            { onboardingSteps.has('experience') &&
              <Col span={24}>
                <Card
                  title={t.volunteering_experience}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('details')} />}
                  className="ResponsibleProfile-statcard"
                >
                  {profile.details.volunteering_experience
                    ?
                    <List>
                      <List.Item>
                        {profile.details.volunteering_experience}
                      </List.Item>
                    </List>
                    :
                    <React.Fragment>
                      <div className="ResponsibleProfile-emptytext">{t.no_data}</div>
                    </React.Fragment>
                  }
                </Card>
              </Col>
            }
            { onboardingSteps.has('experience') &&
              <Col span={24}>
                <Card
                  title={t.motivations_for_social_work}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('details')} />}
                  className="ResponsibleProfile-statcard"
                >
                  {profile.details.social_work_motivations
                    ?
                    <List>
                      <List.Item>
                        {profile.details.social_work_motivations}
                      </List.Item>
                    </List>
                    :
                    <React.Fragment>
                      <div className="ResponsibleProfile-emptytext">{t.no_data}</div>
                    </React.Fragment>
                  }
                </Card>
              </Col>
            }
            { onboardingSteps.has('experience') &&
              <Col span={24}>
                <Card
                  title={t.talents_and_skills}
                  extra={
                    <FontAwesomeIcon
                      icon={"pencil-alt"}
                      onClick={() => setCurrentModal(onboardingSteps.has('experience') ? 'details' : 'experience_education_and_skills')}
                    />
                  }
                  className="ResponsibleProfile-statcard"
                >
                  {profile.details.talents_and_skills
                    ?
                    <List>
                      <List.Item>
                        {profile.details.talents_and_skills}
                      </List.Item>
                    </List>
                    :
                    <React.Fragment>
                      <div className="ResponsibleProfile-emptytext">{t.no_data}</div>
                    </React.Fragment>
                  }
                </Card>
              </Col>
            }
            { onboardingSteps.has('experience_education_and_skills') &&
              <Col span={24}>
                <Card
                  title={intl.formatMessage({ id: `onboarding_education_title` })}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('experience_education_and_skills')} />}
                  className="ResponsibleProfile-statcard"
                >
                  {profile.details.education
                    ?
                    <List>
                      <List.Item>
                        {profile.details.education}
                      </List.Item>
                    </List>
                    :
                    <React.Fragment>
                      <div className="ResponsibleProfile-emptytext">{t.no_data}</div>
                    </React.Fragment>
                  }
                </Card>
              </Col>
            }
            { onboardingSteps.has('experience_education_and_skills') &&
              <Col span={24}>
                <Card
                  title={intl.formatMessage({ id: `onboarding_technical_skills_title` })}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('experience_education_and_skills')} />}
                  className="ResponsibleProfile-statcard"
                >
                  {profile.details.technical_skills
                    ?
                    <List>
                      <List.Item>
                        {profile.details.technical_skills}
                      </List.Item>
                    </List>
                    :
                    <React.Fragment>
                      <div className="ResponsibleProfile-emptytext">{t.no_data}</div>
                    </React.Fragment>
                  }
                </Card>
              </Col>
            }
            { onboardingSteps.has('experience_education_and_skills') &&
              <Col span={24}>
                <Card
                  title={intl.formatMessage({ id: `onboarding_leadership_skills_title` })}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('experience_education_and_skills')} />}
                  className="ResponsibleProfile-statcard"
                >
                  {profile.details.leadership_skills
                    ?
                    <List>
                      <List.Item>
                        {profile.details.leadership_skills}
                      </List.Item>
                    </List>
                    :
                    <React.Fragment>
                      <div className="ResponsibleProfile-emptytext">{t.no_data}</div>
                    </React.Fragment>
                  }
                </Card>
              </Col>
            }
            { onboardingSteps.has('alias') &&
              <Col span={24}>
                <Card
                  title={t.onboarding_alias_title}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('alias')} />}
                  className="ResponsibleProfile-statcard"
                >
                  {profile.details.alias
                    ?
                    <List>
                      <List.Item>
                        {profile.details.alias}
                      </List.Item>
                    </List>
                    :
                    <React.Fragment>
                      <div className="ResponsibleProfile-emptytext">{t.no_data}</div>
                    </React.Fragment>
                  }
                </Card>
              </Col>
            }
            { onboardingSteps.has('machine_number') &&
              <Col span={24}>
                <Card
                  title={t.onboarding_machine_number_title}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('machine_number')} />}
                  className="ResponsibleProfile-statcard"
                >
                  {profile.details.machine_number
                    ?
                    <List>
                      <List.Item>
                        {profile.details.machine_number}
                      </List.Item>
                    </List>
                    :
                    <React.Fragment>
                      <div className="ResponsibleProfile-emptytext">{t.no_data}</div>
                    </React.Fragment>
                  }
                </Card>
              </Col>
            }
            { onboardingSteps.has('relation_with_org') &&
              <Col span={24}>
                <Card
                  title={t.relation_with_org_title}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('relation_with_org')} />}
                  className="ResponsibleProfile-statcard"
                >
                  {profile.details.relation_with_org
                    ?
                    <List>
                      <List.Item>
                        { t[`relation_with_org_${profile.details.relation_with_org}`] }
                      </List.Item>
                    </List>
                    :
                    <React.Fragment>
                      <div className="ResponsibleProfile-emptytext">{t.no_data}</div>
                    </React.Fragment>
                  }
                </Card>
              </Col>
            }
            { onboardingSteps.has('tshirt_size') &&
              <Col span={24}>
                <Card
                  title={t.tshirt_size_title}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('tshirt_size')} />}
                  className="ResponsibleProfile-statcard"
                >
                  {profile.details.tshirt_size
                    ?
                    <List>
                      <List.Item>
                        { profile.details.tshirt_size }
                      </List.Item>
                    </List>
                    :
                    <React.Fragment>
                      <div className="ResponsibleProfile-emptytext">{t.no_data}</div>
                    </React.Fragment>
                  }
                </Card>
              </Col>
            }
            { onboardingSteps.has('specific_training') &&
              <Col span={24}>
                <Card
                  title={t.onboarding_specific_training_title}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('specific_training')} />}
                  className="ResponsibleProfile-statcard"
                >
                  <p>
                    {t.onboarding_specific_training_cap_ccp_title}: {getValueForBoolean(profile.details.specific_training_cap_ccp)}
                  </p>
                  <p>
                  {t.onboarding_specific_training_first_aid_title}: {getValueForBoolean(profile.details.specific_training_first_aid)}
                  </p>
                  <p>
                  {t.onboarding_specific_training_received_volunteering_training_title}: {getValueForBoolean(profile.details.specific_training_received_volunteering_training)}
                  </p>
                  {profile.details.specific_training_received_volunteering_training &&
                    <p>
                      {t.onboarding_specific_training_received_volunteering_training_where_title}: {profile.details.specific_training_received_volunteering_training_where || t.no_data}
                    </p>
                  }
                </Card>
              </Col>
            }
            { onboardingSteps.has('availability') &&
              <Col span={24}>
                <Card
                  title={t.onboarding_availability_title}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('availability')} />}
                  className="ResponsibleProfile-statcard"
                >
                  <p>
                    {t.onboarding_availability_preferred_location_title}: {
                      profile.details.availability_preferred_location && profile.details.availability_preferred_location.length > 0
                      ? profile.details.availability_preferred_location
                          .map(slug => t[`onboarding_availability_preferred_location_option_${slug}`])
                          .join(', ')
                      : t.no_data
                    }
                  </p>
                  <p>
                    {t.onboarding_availability_days_title}: {
                      profile.details.availability_days && profile.details.availability_days.length > 0
                      ? profile.details.availability_days
                          .map(slug => t[`onboarding_availability_days_option_${slug}`])
                          .join(', ')
                      : t.no_data
                    }
                  </p>
                  <p>
                    {t.onboarding_availability_hours_title}: {
                      profile.details.availability_hours
                      && profile.details.availability_hours.length === 2
                      && profile.details.availability_hours[0]
                      && profile.details.availability_hours[1]
                      ? `${profile.details.availability_hours[0]} - ${profile.details.availability_hours[1]}`
                      : t.no_data
                    }
                  </p>
                </Card>
              </Col>
            }
            { onboardingSteps.has('availability_and_mobility') &&
              <Col span={24}>
                <Card
                  title={intl.formatMessage({ id: `onboarding_availability_and_mobility_title` })}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('availability_and_mobility')} />}
                  className="ResponsibleProfile-statcard"
                >
                  {profile.details.availability_and_mobility
                    ?
                    <List>
                      <List.Item>
                        {profile.details.availability_and_mobility}
                      </List.Item>
                    </List>
                    :
                    <React.Fragment>
                      <div className="ResponsibleProfile-emptytext">{t.no_data}</div>
                    </React.Fragment>
                  }
                </Card>
              </Col>
            }
            { onboardingSteps.has('volunteering_motivation') &&
              <Col span={24}>
                <Card
                  title={t.onboarding_volunteering_motivation_title}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('volunteering_motivation')} />}
                  className="ResponsibleProfile-statcard"
                >
                  <p>
                    {t.onboarding_volunteering_motivation_reason_title}: {
                      profile.details.volunteering_motivation_reason && profile.details.volunteering_motivation_reason.length > 0
                      ? profile.details.volunteering_motivation_reason
                          .map(slug => t[`onboarding_volunteering_motivation_option_${slug}`])
                          .join(', ')
                      : t.no_data
                    }
                  </p>
                </Card>
              </Col>
            }
            { onboardingSteps.has('motivations_and_expectations') &&
              <Col span={24}>
                <Card
                  title={intl.formatMessage({ id: `onboarding_motivations_and_expectations_title` })}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('motivations_and_expectations')} />}
                  className="ResponsibleProfile-statcard"
                >
                  {profile.details.motivations_and_expectations
                    ?
                    <List>
                      <List.Item>
                        {profile.details.motivations_and_expectations}
                      </List.Item>
                    </List>
                    :
                    <React.Fragment>
                      <div className="ResponsibleProfile-emptytext">{t.no_data}</div>
                    </React.Fragment>
                  }
                </Card>
              </Col>
            }
            { onboardingSteps.has('date_of_birth') &&
              <Col span={24}>
                <Card
                  title={intl.formatMessage({ id: `onboarding_date_of_birth_title` })}
                  extra={<FontAwesomeIcon icon={"pencil-alt"} onClick={() => setCurrentModal('date_of_birth')} />}
                  className="ResponsibleProfile-statcard"
                >
                  {profile.details.date_of_birth
                    ?
                    <List>
                      <List.Item>
                        {profile.details.date_of_birth}
                      </List.Item>
                    </List>
                    :
                    <React.Fragment>
                      <div className="ResponsibleProfile-emptytext">{t.no_data}</div>
                    </React.Fragment>
                  }
                </Card>
              </Col>
            }
          </Row>
          <CohortSelector
            visible={currentModal === 'cohorts'}
            values={profile.public_user_groups}
            onClose={() => setCurrentModal(null)}
            onSave={(public_user_groups) => onProfileSave({ public_user_groups })}
          />
          <OnboardingExperienceEducationSkills
            visible={currentModal === 'details' || currentModal === 'experience_education_and_skills'}
            onboardingMode={false}
            details={profile.details}
            onboardingStep={currentModal}
            onClose={() => setCurrentModal(null)}
            onSave={(data) => updateDetails(data)}
          />
          <OnboardingAddress
            visible={currentModal === 'address'}
            onboardingMode={false}
            value={profile.details ? profile.details.address : ''}
            onClose={() => setCurrentModal(null)}
            onSave={(address) => updateDetails({ address })}
          />
          <OnboardingSelector
            key='target_audience'
            visible={currentModal === 'target_audience'}
            onboardingMode={false}
            title='target_audience_selector_title'
            values={targetAudiences}
            onClose={() => setCurrentModal(null)}
            onSave={(target_audience) => onProfileSave({ target_audience })}
          />
          <OnboardingSelector
            key='categories'
            visible={currentModal === 'categories'}
            onboardingMode={false}
            title='category_selector_title'
            values={categories}
            onClose={() => setCurrentModal(null)}
            onSave={(categories) => onProfileSave({ categories })}
          />
          <OnboardingAlias
            visible={currentModal === 'alias'}
            onboardingMode={false}
            value={profile.details ? profile.details.alias : ''}
            onClose={() => setCurrentModal(null)}
            onSave={(alias) => updateDetails({alias})}
          />
          <OnboardingMachineNumber
            visible={currentModal === 'machine_number'}
            onboardingMode={false}
            value={profile.details ? profile.details.machine_number: ''}
            onClose={() => setCurrentModal(null)}
            onSave={(machine_number) => updateDetails({machine_number})}
          />
          <OnboardingSelectorOne
            visible={currentModal === 'relation_with_org'}
            title='relation_with_org_title'
            values={translatedRelationWithOrgValues}
            onboardingMode={false}
            value={profile.details ? profile.details.relation_with_org: ''}
            onClose={() => setCurrentModal(null)}
            onSave={(relation_with_org) => updateDetails({ relation_with_org })}
          />
          <OnboardingSelectorOne
            visible={currentModal === 'tshirt_size'}
            title='tshirt_size_title'
            values={TSHIRT_SIZES}
            onboardingMode={false}
            value={profile.details ? profile.details.tshirt_size: ''}
            onClose={() => setCurrentModal(null)}
            onSave={(tshirt_size) => updateDetails({tshirt_size})}
          />
          <OnboardingSpecificTraining
            visible={currentModal === 'specific_training'}
            onboardingMode={false}
            details={profile.details}
            onClose={() => setCurrentModal(null)}
            onSave={(data) => updateDetails(data)}
          />
          <OnboardingAvailability
            visible={currentModal === 'availability'}
            onboardingMode={false}
            details={profile.details}
            onClose={() => setCurrentModal(null)}
            onSave={(data) => updateDetails(data)}
          />
          <OnboardingVolunteeringMotivation
            visible={currentModal === 'volunteering_motivation'}
            onboardingMode={false}
            details={profile.details}
            onClose={() => setCurrentModal(null)}
            onSave={(data) => updateDetails(data)}
          />
          {(currentModal === 'motivations_and_expectations' || currentModal === 'availability_and_mobility') && (
            <OnboardingTextArea
              visible={currentModal === 'motivations_and_expectations' || currentModal === 'availability_and_mobility'}
              onboardingMode={false}
              onboardingStep={currentModal}
              value={profile.details ? profile.details[currentModal] : ''}
              onClose={() => setCurrentModal(null)}
              onSave={(value) => updateDetails({[currentModal]: value})}
            />
          )}
          <OnboardingDateOfBirth
            visible={currentModal === 'date_of_birth'}
            onboardingMode={false}
            onboardingStep={currentModal}
            value={profile.details ? profile.details.date_of_birth: ''}
            onClose={() => setCurrentModal(null)}
            onSave={(date_of_birth) => updateDetails({date_of_birth})}
          />
        </Layout.Content>
      </Layout>
    </>
  );
}

export default injectIntl(PreferenceSettings);
