import React from 'react';
import { injectIntl } from 'react-intl';

const Points = ({
  intl,
  points,
}) => {
  return (
    <>
      <div className="title">{ intl.formatMessage({ id: `points_title` }) }</div>
      <div className="info">
        { points }
      </div>
    </>
  );
};

export default injectIntl(Points);

