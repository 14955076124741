import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Layout,
  Button,
} from 'antd';

import Nav from 'containers/Nav';
import AppHeader from 'containers/AppHeader';
import NavBottom from 'containers/NavBottom';
import NewProposalHeader from 'components/NewProposalHeader/NewProposalHeader';
import SdgLogo from 'components/SdgLogo';
import T from 'components/T';

// TODO: fetch from backend
const sdgs = [
  "no-poverty",
  "zero-hunger",
  "good-health-and-wellbeing",
  "quality-education",
  "gender-equality",
  "clean-water-and-sanitation",
  "affordable-and-clean-energy",
  "decent-work-and-economic-growth",
  "industry-innovation-and-infrastructure",
  "reduced-inequalities",
  "sustainable-cities-and-communities",
  "responsible-consumption-and-production",
  "climate-action",
  "life-below-water",
  "life-on-land",
  "peace-justice-and-strong-institutions",
  "partnership-for-the-goals",
];

const DEFAULT_MAX_SDG = 3;

const Sdgs = ({
  intl,
  values,
  handleChange,
  showError,
  next,
  organization,
}) => {
  const t = intl.messages;

  const maxSDGs = useMemo(
    () => organization.config.max_number_of_sdgs || DEFAULT_MAX_SDG,
    [ organization ]
  );

  return (
    <>
      <AppHeader />
      <Layout>
        <Nav />
        <Layout.Content>
          <NewProposalHeader
            stepCount={5}
          />
          <Row className="ProposalInitiative-sdgs">
            <Col span={24} className="ProposalInitiative-heading">
              <T proposal_sdgs_title={{num: maxSDGs}} />
            </Col>
            <Col span={24} className="ProposeInitiative-sdglogo-container">
              {sdgs.map(slug => (
                <div className="ProposeInitiative-sdglogo" key={slug}>
                  <SdgLogo
                    sdg={slug}
                    selected={values.sdgs.includes(slug)}
                    onClick={() => {
                      if(values.sdgs.length < maxSDGs || values.sdgs.includes(slug)) {
                        handleChange('sdgs')(slug);
                      } else {
                        // TODO: Shake element?
                      }
                    }}
                  />
                </div>
              ))}
            </Col>
            <Col span={24}>
              { showError('sdgs') }
            </Col>
          </Row>
          <Row className="ProposalInitiative-button-next relative">
            <Col span={24}>
              <Button
                type="primary"
                onClick={next}
                disabled={values.sdgs.length === 0}
              >
                { t.continue }
              </Button>
            </Col>
            <Col span={24} className="ProposalInitiative-button-skip">
              <span onClick={next}>
                { t.continue_without_this }
              </span>
            </Col>
          </Row>
          <NavBottom />
        </Layout.Content>
      </Layout>
    </>
  );
};

export default injectIntl(Sdgs);
