import React from 'react';
import { injectIntl } from 'react-intl';

import './style.less';
import {
  Result,
  Button,
} from 'antd';

import forbiddenImage from './images/403.png'
import notFoundImage from './images/404.png'
import internalErrorImage from './images/500.png'

const IMAGES = {
  404: (<img src={notFoundImage} className="Error__img" alt="" />),
  403: (<img src={forbiddenImage} className="Error__img" alt="" />),
  default: (<img src={internalErrorImage} className="Error__img" alt="" />),
};

const Error = ({
  intl,
  error,
  title,
  message,
  buttonText,
  buttonType,
  onButtonClick,
}) => {
  const t = intl.messages;
  return (
    <Result
      icon={IMAGES[error] || IMAGES.default}
      title={ title || t[`error_title_${error}`] || t.error_title_default }
      subTitle={ message || t[`error_message_${error}`] || t.error_message_default }
      extra={
        <Button type={buttonType} onClick={onButtonClick}>
          {buttonText}
        </Button>
      }
    />
  );
}

export default injectIntl(Error);
