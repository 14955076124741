import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';

import {
  Modal,
  Row,
  Col,
} from 'antd';

import Checkbox from 'components/Checkbox';

import './style.less';

import useSetState from 'utils/useSetState';

const OnboardingSelector = ({
  onboardingMode = false,
  intl,
  title,
  values = [],
  visible,
  onClose,
  onSave,
  loading,
  isRequired,
}) => {
  const t = intl.messages;
  const initialItems = useMemo(() =>
    values.filter(({ member }) => member).map(({ slug }) => slug)
  , [ values ]);

  const {
    has: isSelected,
    toggle: toggleSelected,
    set: selectedItems,
  } = useSetState( initialItems );

  const [ saving, setSaving ] = useState(false);

  const handleSave = useCallback(() => {
    const initialSet = new Set(initialItems);
    if(
      initialItems.length === selectedItems.length &&
      selectedItems.every(slug => initialSet.has(slug))
    ) {
      // Bail out
      return onClose();
    }

    setSaving(true);
    onSave(selectedItems);
  }, [
    initialItems,
    selectedItems,
    onClose,
    onSave,
  ]);

  useEffect(() => {
    if(saving && !loading) {
      setSaving(false);
      onClose();
    }
  }, [
    saving,
    loading,
    onClose,
  ]);

  return (
    <Modal
      className="Onboarding__modal"
      onOk={handleSave}
      onCancel={onClose}
      okText={t.save}
      cancelText={onboardingMode ? t.continue_without_this : t.cancel}
      visible={visible}
      title={t[title]}
      cancelButtonProps={{ style: { display: isRequired ? 'none' : 'initial' } }}
      maskClosable={!isRequired}
      keyboard={!isRequired}
      closable={!isRequired}
    >
      <div className="Onboarding__modal-inner">
        {values.map(({ name, slug }) => (
          <Row align="middle" key={name}>
            <Col span={24}>
              <Checkbox
                checked={isSelected(slug)}
                onClick={() => toggleSelected(slug)}
                title={name}
              />
            </Col>
          </Row>
        ))}
      </div>
    </Modal>
  );
};

export default injectIntl(OnboardingSelector);
