import React from 'react';
import { injectIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from 'colors';
import './style.less';

const srcs = {
  'affordable-and-clean-energy': '07-affordable-and-clean-energy.png',
  'clean-water-and-sanitation': '06-clean-water-and-sanitation.png',
  'climate-action': '13-climate-action.png',
  'decent-work-and-economic-growth': '08-decent-work-and-economic-growth.png',
  'gender-equality': '05-gender-equality.png',
  'good-health-and-wellbeing': '03-good-health-and-wellbeing.png',
  'industry-innovation-and-infrastructure': '09-industry-innovation-and-infrastructure.png',
  'life-below-water': '14-life-below-water.png',
  'life-on-land': '15-life-on-land.png',
  'no-poverty': '01-no-poverty.png',
  'partnership-for-the-goals': '17-partnership-for-the-goals.png',
  'peace-justice-and-strong-institutions': '16-peace-justice-and-strong-institutions.png',
  'quality-education': '04-quality-education.png',
  'reduced-inequalities': '10-reduced-inequalities.png',
  'responsible-consumption-and-production': '12-responsible-consumption-and-production.png',
  'sustainable-cities-and-communities': '11-sustainable-cities-and-communities.png',
  'zero-hunger': '02-zero-hunger.png',
};

const AVAILABLE_LANGUAGES = ['en', 'es', 'pt', 'fr'];

const getImage = (locale, sdg) => {
  const language = (locale || '').slice(0, 2);
  if(AVAILABLE_LANGUAGES.includes(language)) {
    return `/images/sdg/${language}/${srcs[sdg]}`;
  }
  return `/images/sdg/${AVAILABLE_LANGUAGES[0]}/${srcs[sdg]}`;
}

const SdgLogo = ({ intl, sdg, selected, onClick }) => {
  const t = intl.messages;
  const locale = intl.locale;
  return (
    <div
      onClick={onClick}
      className="relative logo"
    >
      {selected
      ? (
        <div className="overlay">
          <FontAwesomeIcon
            size="2x"
            icon="check"
            color={colors['@color-icon-faded']}
          />
        </div>
      )
      : null}
      <img alt={t[`sdg_${sdg}`]} src={getImage(locale, sdg)} />
    </div>
  );
}

export default injectIntl(SdgLogo);
