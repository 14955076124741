import { loop, Cmd } from 'redux-loop';
import { changeLocale } from '../intl'; // NOTICE: 'intl' is a node package

const TARGET = 'my_profile';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const unsetAuthRefreshProfile = () => ({
  type: 'UNSET_AUTH_REFRESH_PROFILE',
});

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(action.target !== TARGET) return state;
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || action.response.target !== TARGET) return state;
      const result = action.response.result;
      const appIntl = result.organization.product_config.community.appIntl;
      if(result && result.language !== (state.data || {}).language) {
        return loop({
          data: result,
          loading: false,
          error: null,
        }, Cmd.list([
          Cmd.action(changeLocale(result.language, appIntl)),
          Cmd.action(unsetAuthRefreshProfile()),
        ]));
      }
      return {
        data: result,
        loading: false,
        error: null,
      };
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        loading: false,
        error: {
          code: action.code,
          body: action.request.body,
        },
      };
    case 'RESET_PROFILE_ERROR':
    case 'RESET_AUTH_ERROR':
      return {
        ...state,
        error: null,
      };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as my_profile,
};
