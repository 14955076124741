import { useEffect, useState, useMemo } from 'react';
import useSetState from './useSetState';

const mergeValueKeys = (values, defaults = {}) => {
  const keys = Array.from(new Set( [ ...Object.keys(values), ...Object.keys(defaults)  ] ));
  let key, result = {};

  for(key of keys) {
    result[key] = typeof values[key] === 'undefined' ? defaults[key] : values[key];
  }
  return result;
};

const useForm = ({
  callback,
  validate,
  validationDefaults = {},
  setNames = [],
  defaultValues = {},
  defaultSetValues = {}
}) => {

  const [flatValues, setFlatValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const {
    has: isDirty,
    set: dirtyFields,
    add: setDirty,
    replaceAll: clearDirty,
  } = useSetState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let sets = {}
  setNames.forEach(name => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    sets[name] = useSetState(defaultSetValues[name] || []);
  });

  const values = useMemo(() => ({
    ...flatValues,
    ...setNames.map(name => [name, sets[name].set]).reduce((obj, [name, set]) => {
      obj[name] = set; return obj;
    }, {})
  }), [flatValues, setNames, sets]);

  const runValidation = () => {
    clearDirty([]);
    setErrors(validate(mergeValueKeys(values, validationDefaults)));
  }

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);
    runValidation();
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (name) => (value) => {
    setDirty(name);
    if(setNames.includes(name)) {
      // This is a set
      if(Array.isArray(value)) {
        sets[name].replaceAll(value);
      } else {
        sets[name].toggle(value);
      }
    } else {
      // This is a flat
      setFlatValues(flatValues => ({ ...flatValues, [name]: value }));
    }
  };

  const handleChangeEvent = (event) => {
    event.persist();
    handleChange(event.target.name)(event.target.value);
  };

  return {
    handleChange,
    handleChangeEvent,
    runValidation,
    handleSubmit,
    values,
    isDirty,
    dirtyFields,
    errors,
  }
};

export default useForm;
