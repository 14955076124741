const initialState = {
  completed: 0,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'ONBOARDING_COMPLETE':
      if(action.completed > state.completed) {
        return {
          ...state,
          completed: action.completed,
        };
      }
      return state;
    case 'LOGOUT_REQUEST':
    case 'RESET_AUTH':
    case 'LOGIN_FAILED':
    case 'REFRESH_TOKEN_FAILED':
      return initialState;
    case 'HYDRATE_STORE':
      return action.state ? (action.state.onboarding || initialState) : state;
    default:
      return state;
  }
};

export {
  reducer as onboarding,
};
