import React, {
  useCallback,
} from 'react';
import { injectIntl } from 'react-intl';

import {
  Button,
  Modal,
} from 'antd';

import useOpenInNewWindow from 'utils/useOpenInNewWindow';
import useDonationExternalUrl from 'utils/useDonationExternalUrl';

const ExternalLinkButton = ({
  submit,
  intl,
  source,
  disabled = false,
  amount,
  currency,
  params = {},
}) => {
  const t = intl.messages;

  const {
    external_url,
  } = params;

  const redirect_url = useDonationExternalUrl(
    external_url,
    {
      currency,
      amount,
    },
  );

  const openInNewWindow = useOpenInNewWindow();

  const handleClick = useCallback(() => {
    Modal.confirm({
      title: t.donation_external_modal_title,
      content: t.donation_external_modal_body,
      okText: t.accept,
      cancelText: t.cancel,
      onOk: () => {
        submit({ external_url });
        openInNewWindow(redirect_url);
      },
    });
  }, [ t, submit, external_url, redirect_url, openInNewWindow ]);

  return (
    <Button
      className="Button-secondary"
      disabled={disabled}
      onClick={handleClick}
    >
      { t.donation_external_go }
    </Button>
  );
};

export default injectIntl(ExternalLinkButton);

