import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  Row,
  Col,
  Layout,
  Drawer,
} from 'antd';
import {
  MenuOutlined,
} from '@ant-design/icons';

import A from 'components/A';
import Avatar from 'components/Avatar';
import OrganizationLogo from 'components/OrganizationLogo';
import useScreenSize from 'utils/useScreenSize';
import { Nav } from 'containers/Nav';
import { useFeatureList } from 'components/FeatureSwitch';

import './style.less';

const AppHeader = ({
  organization,
  my_profile,
  showOnSmallScreen,
  location,
  intl,
}) => {
  const [ isMenuOpen, setIsMenuOpen ] = useState(false);

  const profile = useMemo(
    () => my_profile.data
    , [my_profile]
  );

  const featureList = useFeatureList();
  const isSmallScreen = ['xs', 'sm', 'md', 'lg'].includes(useScreenSize());

  return (
    <>
      <Drawer
        className="hamburger-drawer"
        placement="left"
        closable={false}
        visible={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <Nav intl={intl} location={location} />
      </Drawer>

      { isSmallScreen && !showOnSmallScreen ? (
        <Row>
          <Col className="header-logo">
            <MenuOutlined className="hamburger-menu" onClick={() => setIsMenuOpen(true)} />
          </Col>
        </Row>
      ) :
      <Layout.Header>
        <Row type="flex" className="header" justify="space-between" align="middle">
          <Col className="header-logo">
            <MenuOutlined className="hamburger-menu" onClick={() => setIsMenuOpen(true)} />
            <A href="/">
              <OrganizationLogo className="organization-logo" {...organization} />
            </A>
          </Col>
          <Col>
            <div className="Wall-avatar">
              <Link
                to={
                  featureList.has('responsible_profile')
                  ? '/responsible_profile'
                  : '/settings'
                }
              >
                <Avatar
                  icon="user-circle"
                  region={organization?.region}
                  name={profile && profile.name}
                  src={profile && profile.avatar}
                />
              </Link>
            </div>
          </Col>
        </Row>
      </Layout.Header>
      }
    </>
  );
}

const mapStateToProps = ({
  organization,
  my_profile = {},
}) => ({
  organization,
  my_profile,
});

export default injectIntl(connect(mapStateToProps)(withRouter(AppHeader)));
