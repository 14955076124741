import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Checkbox } from 'antd';

const Anonymous = ({
  intl,
  value,
  onChange,
}) => {
  const t = intl.messages;

  const handleChange = useCallback(() => {
    onChange(!value);
  }, [ onChange, value ]);

  return (
    <Checkbox
      className="ContributionScreen__checkbox"
      checked={value}
      onChange={handleChange}
    >
      <b>{ t.donation_anonymous }</b>
      <p>{ t.donation_anonymous_desc }</p>
    </Checkbox>
  );
};

export default injectIntl(Anonymous);
