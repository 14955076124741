import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router'
import * as Sentry from '@sentry/react';
import { ErrorBoundaryFallback } from 'aplanet-ui-kit';

import {
  requestMyProposals,
} from 'actions/api';

import {
  Layout,
  Row,
  Col,
  Empty,
  Divider,
  Button,
} from 'antd';

import Nav from 'containers/Nav';
import AppHeader from 'containers/AppHeader';
import NavBottom from 'containers/NavBottom';

import ProposalCard from 'components/ProposalCard';

import { formatDateLong } from 'utils/date';

import './style.less';

const Proposals = ({
  intl,
  organization,
  requestMyProposals,
  proposals,
  history,
}) => {
  const t = intl.messages;
  const push = history.push;

  useEffect(() => {
    requestMyProposals(organization.slug);
  }, [organization, requestMyProposals]);

  const { items } = proposals;

  const proposed = useMemo(
    () => items.filter(({ status }) => status === 'proposed'),
    [items]
  );

  const reviewed = useMemo(
    () => items.filter(({ status }) => status !== 'proposed'),
    [items]
  );

  return (
    <>
      <AppHeader />
      <Layout>
        <Layout>
          <Nav />
          <Layout.Content>
            <Sentry.ErrorBoundary
              fallback={
              <ErrorBoundaryFallback
                titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
                buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button'})}
                descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_proposals_message' })}
                customErrorImage="/images/error_image.png"
              />
            }>
              <Row className="Proposals-header" align="middle" justify="space-between">
                <Col span={24}>
                  <h1>
                    {t.proposals_nav}
                  </h1>
                </Col>
              </Row>
              <Row className="Proposals-buttonrow">
                <Col span={24}>
                  <Button
                    type="primary"
                    onClick={() => push(`/proposal/new/type`)}
                  >
                    {t.proposal_initiative}
                  </Button>
                </Col>
              </Row>
              {!items || items.length === 0
                ? <Row className="Proposals-empty-row">
                  <Col span={24}>
                    <Empty
                      description={
                        <>
                          <span className="Proposals-empty-title">
                            {t.proposal_empty_title}
                          </span>
                          <span className="Proposals-empty-description">
                            {t.proposal_empty_description}
                          </span>
                        </>
                      }
                    />
                  </Col>
                </Row>
                : <>
                  {proposed.map(({ id, title, created_at }) => (
                    <ProposalCard
                      key={id}
                      header={t.proposal_proposed_on}
                      title={title}
                      created_at={created_at}
                      formatDateLong={formatDateLong}
                    />
                  ))}
                  <div className="Proposals-reviewed-divider">
                    <Divider>
                      {t.reviewed} <span className="reviewed-count">{reviewed.length}</span>
                    </Divider>
                  </div>
                  {reviewed.map(({ id, title, status, created_at, reject_reason }) => (
                    <ProposalCard
                      key={id}
                      header={`${status === 'open' ? t.proposal_approved : t.proposal_rejected}`}
                      title={title}
                      status={status}
                      created_at={created_at}
                      reject_reason={reject_reason}
                      formatDateLong={formatDateLong}
                    />
                  ))}
                </>
              }
            </Sentry.ErrorBoundary>
            <NavBottom />
          </Layout.Content>
        </Layout>
      </Layout>
    </>
  );
}

const mapStateToProps = ({
  organization,
  proposals,
}) => ({
  organization,
  proposals,
});

export default injectIntl(connect(mapStateToProps, { requestMyProposals })(withRouter(Proposals)));
