import React, { useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Route, Switch } from 'react-router';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Spin,
  Row,
  notification,
} from 'antd';

import SettingsComponent from 'components/Settings';
import AccountSettings from 'components/Settings/AccountSettings';
import NotificationSettings from 'components/Settings/NotificationSettings';
import LanguageSettings from 'components/Settings/LanguageSettings';
import PreferenceSettings from 'components/Settings/PreferenceSettings';
import AppHeader from 'containers/AppHeader';

import {
  requestProfile,
  updateProfile,
} from 'actions/api';

import {
  formatDateLong,
} from 'utils/date';

import './style.less';

const Settings = ({
  match,
  intl,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const {
    data: profile,
    error: profileError,
    loading,
  } = useSelector(state => state.my_profile)
	const organization = useSelector(state => state.organization);
	const chat = useSelector(state => state.chat);

  useEffect(() => {
    dispatch(requestProfile(organization.slug));
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    requestProfile,
  ]);

  useEffect(() => {
    if (profileError?.code === 409) {
      notification.error({
        message: t.profile_update_error_title,
        description: t.profile_update_error_desc,
      });
      dispatch({type: 'RESET_PROFILE_ERROR'});
    }
  }, [
    t,
    dispatch,
    profileError,
  ]);

  const onUpdateProfile = useCallback((organizationSlug, newData) => {
    dispatch(updateProfile(organizationSlug, newData));
  }, [
    dispatch
  ]);

  if(loading) {
    return (
      <>
        <AppHeader />
        <Row justify="center">
          <Spin/>
        </Row>
      </>
    );
  }

  return (
    <>
      <AppHeader />
      <Switch>
        <Route path={`${match.path}/account`}>
          <AccountSettings
            profile={profile}
            organization={organization}
            loading={loading}
            updateProfile={onUpdateProfile}
            chatUserId={chat.activeUser?.id}
          />
        </Route>
        <Route path={`${match.path}/language`}>
          <LanguageSettings
            profile={profile}
            organization={organization}
            updateProfile={onUpdateProfile}
          />
        </Route>
        <Route path={`${match.path}/notification`}>
          <NotificationSettings
            profile={profile}
            organization={organization}
            updateProfile={onUpdateProfile}
          />
        </Route>
        <Route path={`${match.path}/preferences`}>
          <PreferenceSettings
            profile={profile}
            updateProfile={onUpdateProfile}
            formatDateLong={formatDateLong}
            organization={organization}
          />
        </Route>
        <Route path={`${match.path}/`}>
          <SettingsComponent />
        </Route>
      </Switch>
    </>
  );
}

export default injectIntl(Settings);
