import moment from 'moment';

import React, { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';

import {
  Button,
  DatePicker
} from 'antd';
import { Calendar } from 'antd-mobile';

import useScreenSize from 'utils/useScreenSize';

import './style.less';

const DEFAULT_PLACEHOLDER = ['Start date', 'End date'];

const DateRange = ({
  startDateValue,
  endDateValue,
  defaultDate,
  placeholder = DEFAULT_PLACEHOLDER,
  onConfirm,
  visible,
  minDate,
  maxDate,
  title,
  extra,
  intl,
}) => {
  const t = intl.messages;
  const { RangePicker } = DatePicker;
  const isSmallScreen = ['xs', 'sm', 'md'].includes(useScreenSize());
  const [rangePickerStart, setRangePickerStart] = useState(null);
  const [rangePickerEnd, setRangePickerEnd] = useState(null);

  // TODO: move this to i18n and translate 'h', 'm', 'am' and 'pm'...
  const locale = useMemo(() => ({
    okText: t.ok,
    dismissText: t.cancel,
    CalendarLocale: {
      title: t.select_date,
      today: t.calendar_today,
      month: t.calendar_month,
      year: t.calendar_year,
      am: 'AM',
      pm: 'PM',
      dateTimeFormat: 'yyyy-MM-dd hh:mm',
      dateFormat: 'yyyy-MM-dd',
      noChoose: t.calendar_no_choose,
      week: [
        t.calendar_dayofweek_sunday,
        t.calendar_dayofweek_monday,
        t.calendar_dayofweek_tuesday,
        t.calendar_dayofweek_wednesday,
        t.calendar_dayofweek_thursday,
        t.calendar_dayofweek_friday,
        t.calendar_dayofweek_saturday
      ],
      clear: t.calendar_clear,
      selectTime: '',
      selectStartTime: '',
      selectEndTime: '',
      start: t.calendar_start,
      end: t.calendar_end,
      begin: t.calendar_begin,
      over: t.calendar_over,
      begin_over: t.calendar_begin_over_abbr,
      confirm: t.calendar_confirm,
      monthTitle: 'yyyy-MM',
      loadPrevMonth: t.calendar_load_prev_month,
      yesterday: t.calendar_yesterday,
      lastWeek: t.calendar_last_week,
      lastMonth: t.calendar_last_month,
    },
  }), [ t ]);

  const defaultRangePickerValues = useMemo(() => [
    startDateValue && moment(startDateValue),
    endDateValue && moment(endDateValue)
  ], [endDateValue, startDateValue]);

  return (
    <div className="DatePicker">
      {isSmallScreen
      ? (
        <Calendar
          visible={visible}
          onCancel={onConfirm}
          onConfirm={onConfirm}
          enterDirection="horizontal"
          defaultDate={defaultDate}
          defaultValue={[startDateValue, endDateValue]}
          minDate={minDate}
          maxDate={maxDate}
          title={title}
          extra={extra}
          locale={locale.CalendarLocale}
        />
      )
      : (visible &&
        <RangePicker
          open={visible}
          locale={locale.CalendarLocale}
          defaultValue={defaultRangePickerValues}
          defaultPickerValue={defaultRangePickerValues}
          placeholder={placeholder}
          disabledDate={(current) => current && (
            current < moment(minDate)
            || current > moment(maxDate)
          )}
          onCalendarChange={([start_time, end_time]) => {
            start_time && setRangePickerStart(start_time.toDate());
            end_time && setRangePickerEnd(end_time.toDate());
          }}
          renderExtraFooter={() => (
            <Button
              disabled={!rangePickerStart || !rangePickerEnd}
              onClick={() => onConfirm(rangePickerStart, rangePickerEnd)}
            >
              {t.calendar_confirm}
            </Button>
          )}
        />
      )}
    </div>
  );
}

export default injectIntl(DateRange);
