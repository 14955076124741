import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  List,
  Button,
  Input,
  Alert,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import QuoteWall from 'components/QuoteWall';

import {
  lookup,
  login,
  resetAuth,
  resetAuthError,
} from 'actions/auth';
import { recoverPassword } from 'actions/auth';
import A from 'components/A';

import validation from './validation';
import './style.less';

import {
  getEmailFromQueryString,
} from 'utils/clientUrl';

import useForm from 'utils/useForm';

const LOGGED_IN_REDIRECT_URL = '/wall';
const POPUP_NAME = 'popup_window';
const POPUP_SPECS = 'resizable=no';
const DEFAULT_COVER_URL = '/images/cover.jpg';

const SignIn = ({
  intl,
  auth,
  signin,
  organization,
  lookup,
  login,
  history,
  resetAuth,
  resetAuthError,
  recoverPassword,
  password_recovery,
  location,
}) => {
  const push = history.push;
  const t = intl.messages;
  const popupRef = useRef(null);

  const {
    error,
    errorBody,
    loading,
    stage,
    email: confirmedEmail,
    url: redirectUrl,
  } = signin;

  // NOTICE: This is only executed once
  useEffect(() => {
    if(auth.logged_in) {
      push(LOGGED_IN_REDIRECT_URL);
    } else {
      resetAuth();
    }
  }, [
    auth.logged_in,
    push,
    resetAuth,
  ]);

  const submitForm = () => {
    resetAuthError();
    const redirectState = (location && location.state && location.state.referrer) || null;
    if(stage === 'email') {
      lookup(values.email, redirectState);
    } else {
      login(values.email, values.password, redirectState);
    }
  };

  const validateForm = useMemo(() => validation(t), [ t ]);

  const defaultValues = useMemo(() => {
    return {
      email: getEmailFromQueryString(location.search),
    };
  }, [
    location.search,
  ]);

  const {
    values,
    handleChange,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues,
  });

  const {
    requested: recoverPasswordRequested,
    error: recoverPasswordError,
  } = password_recovery;

  const emailError = error === 404 || (!isDirty('email') && errors.email);
  const passwordError = error === 401 || (!isDirty('password') && errors.password);

  const showError = useMemo(() => {
    // 404 = Email does not exist
    // 401 = Invalid password
    const showErrorTip = error === 404 || error === 401;
    const errorAction = showErrorTip && (() => emailError ? push('/signup') : recoverPassword(values.email));

    let errorText = error
      ? (
        t[`signin_error_${error}_${errorBody?.error || ''}`] ||
        t[`signin_error_${error}`] ||
        t.signin_error_default
      )
      : (emailError || passwordError || recoverPasswordError);

    if (showErrorTip) {
      errorText += ' - ' + t[`signin_error_${error}_tip`];
    }

    if(error || emailError || passwordError) {
      return (
        <Alert
          message={errorText}
          type="error"
          onClick={errorAction}
          showIcon
        />
      );
    }
  }, [
    t,
    push,
    error,
    errorBody,
    values,
    emailError,
    passwordError,
    recoverPassword,
    recoverPasswordError,
  ]);

  const handleEmailChange = useCallback((e) => {
    const email = e.target.value;
    if(emailError) resetAuthError();
    handleChange('email')((email || '').toLowerCase().trim());
  }, [handleChange, emailError, resetAuthError]);

  const handlePasswordChange = useCallback((e) => {
    const password = e.target.value;
    if(passwordError) resetAuthError();
    handleChange('password')(password);
  }, [handleChange, passwordError, resetAuthError]);

  useEffect(() => {
    if(
      stage === 'sso_popup' &&
      redirectUrl &&
      !popupRef.current
    ) {
      popupRef.current = window.open(redirectUrl, POPUP_NAME, POPUP_SPECS);
    }
  }, [
    stage,
    redirectUrl,
  ]);

  const cover_url = useMemo(() => {
    return organization?.config?.cover_url || DEFAULT_COVER_URL
  }, [
    organization,
  ]);

  return (
    <Row className="SignIn">
      <Col xs={24} sm={12}>
        <Row gutter={[0, 40]}>
          <Col
            span={24}
            className="header"
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url('${cover_url}')`,
            }}
          >
            <Link to='/welcome'>
              <FontAwesomeIcon size="2x" icon="arrow-left" />
            </Link>
            <h1>{stage === 'email' ? t.signin : t.welcome_back}</h1>
            <h4>{confirmedEmail || ''}</h4>
          </Col>
          <Col span={24}>
            { showError }
            <div className="SignIn-input">
              { stage !== 'email' ? null : (
                <>
                  <label>{t.email_or_phone}</label>
                  <Input
                    placeholder={ t.email }
                    value={values.email}
                    onPressEnter={handleSubmit}
                    onChange={handleEmailChange}
                  />
                </>
              )}
              { stage !== 'password' ? null : (
                <>
                  <label>{t.password}</label>
                  <Input
                    type="password"
                    placeholder={ t.password }
                    value={values.password}
                    onPressEnter={handleSubmit}
                    onChange={handlePasswordChange}
                  />
                </>
              )}
              { stage !== 'sso_popup' ? null : (
                <List.Item>
                  { t.waiting_for_signin }
                </List.Item>
              )}
            </div>
            <div className="forgot-password">
              {stage === 'password' && (
                <A onClick={() => recoverPassword(confirmedEmail)}>{ t.forgot_password }</A>
              )}
              {recoverPasswordRequested &&
                <div className="success">
                  <FontAwesomeIcon icon="check" /> {t.recover_link_sent}
                </div>
              }
            </div>
          </Col>
        </Row>
        <Button
          className="brand-button-primary cta"
          onClick={handleSubmit}
          loading={loading}
        >
            { t.login }
        </Button>
      </Col>
      <Col xs={0} sm={12} className="cover">
        <QuoteWall organization={organization} />
      </Col>
    </Row>
    )
}

const mapStateToProps = ({
  auth,
  organization,
  signin,
  password_recovery,
}) => ({
  auth,
  organization,
  signin,
  password_recovery,
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      login,
      lookup,
      resetAuth,
      resetAuthError,
      recoverPassword,
    }
  )(withRouter(SignIn))
);
