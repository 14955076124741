import React, {
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { isBeforeNow } from 'utils/date';

import {
  requestInitiativeDetail,
  registerInInitiative,
  notGoingToInitiative,
  interestedInInitiative,
  notInterestedInInitiative,
  reportContribution,
  reportHours,
  submitScores,
  reportIndicator,
} from 'actions/api';

import Error from 'components/Error';
import ParticipationScreen from 'components/ParticipationScreen';
import Donation from './types/Donation';
import Collection from './types/Collection';
import Volunteering from './types/Volunteering';
import Informative from './types/Informative';

import './style.less';

import {
  Row,
  Col,
  Spin,
} from 'antd';

const InitiativeDetail = ({
  intl,
  match,
  history,
  organization,
  initiative_detail,
  my_profile,
  requestInitiativeDetail,
  registerInInitiative,
  notGoingToInitiative,
  interestedInInitiative,
  notInterestedInInitiative,
  reportContribution: doReportContribution,
  reportHours: doReportHours,
  submitScores: doSubmitScores,
  reportIndicator: doReportIndicator,
}) => {
  const push = history.push;
  const { slug, action } = match.params;
  useEffect(() => {
    requestInitiativeDetail(organization.slug, slug);
  }, [organization.slug, slug, requestInitiativeDetail]); // TODO: Pull to refresh

  const {
    data: initiative,
    loading,
    error,
  } = initiative_detail;

  const {
    data: profile,
  } = my_profile;

  const isPastInitiative = useMemo(() => {
    const {
      participant_status,
      registration_end_time,
      end_time
    } = initiative || {};

    if(participant_status === 'accepted') {
      return false;
    }
    if (registration_end_time) {
      return isBeforeNow(registration_end_time);
    }
    if (end_time) {
      return isBeforeNow(end_time);
    }
    return false;
  }, [
    initiative,
  ]);

  const urlBase = useMemo(() => (match.params || {}).base || 'initiative', [
    match.params,
  ]);

  const goBackTo = `/${urlBase}`;

  const participate = useCallback(() => {
    if(initiative.slug) {
      push(`/${urlBase}/${initiative.slug}/participate`);
    }
  }, [
    urlBase,
    initiative,
    push,
  ]);

  const join = useCallback(() => {
    if(initiative.slug) {
      registerInInitiative(organization.slug, initiative.slug);
    }
  }, [ initiative, organization.slug, registerInInitiative ]);

  const notGoing = useCallback(() => {
    if(initiative.slug) {
      notGoingToInitiative(organization.slug, initiative.slug);
    }
  }, [ initiative, organization.slug, notGoingToInitiative ]);

  const reportHours = useCallback((hours) => {
    if(initiative.slug) {
      doReportHours(organization.slug, initiative.slug, hours);
    }
  }, [ initiative, organization.slug, doReportHours ]);

  const reportContribution = useCallback((value, source, params, anonymous, comment) => {
    if(initiative.slug) {
      doReportContribution(organization.slug, initiative.slug, value, source, params, anonymous, comment);
    }
  }, [ initiative, organization.slug, doReportContribution ]);

  const submitScores = useCallback((initiative_score, organization_score) => {
    if(initiative.slug) {
      doSubmitScores(organization.slug, initiative.slug, initiative_score, organization_score);
    }
  }, [ initiative, organization.slug, doSubmitScores ]);

  const interested = useCallback(() => {
    if(initiative.slug) {
      interestedInInitiative(organization.slug, initiative.slug);
    }
  }, [ initiative, organization.slug, interestedInInitiative ]);

  const notInterested = useCallback(() => {
    if(initiative.slug) {
      notInterestedInInitiative(organization.slug, initiative.slug);
    }
  }, [ initiative, organization.slug, notInterestedInInitiative ]);

  const reportIndicator = useCallback((indicator_id, value) => {
    if (initiative.slug) {
      doReportIndicator(organization.slug, initiative.slug, indicator_id, value);
    }
  }, [ initiative, organization.slug, doReportIndicator ]);

  const params = {
    organization,
    loading,
    error,
    participate,
    join,
    notGoing,
    reportHours,
    reportIndicator,
    reportContribution,
    submitScores,
    profile,
    interested,
    notInterested,
    isPastInitiative,
    hideRating: (organization?.config || {}).hide_rating,
    evaluationConfig: (organization?.config || {}).evaluation_configuration,
    ...initiative,
  };

  if(!initiative || error) {
    // TODO: Add loading state
    return (
      <Row justify="center">
        <Col>
          { !error
            ? <Spin/>
            : <Error
                error={error}
                buttonType="primary"
                buttonText={intl.formatMessage({ id: `check_wall` })}
                onButtonClick={() => push('/')}
              />
          }
        </Col>
      </Row>
    );
  };

  return (
    action === 'participate'
    ? <ParticipationScreen
        goBackTo={`/${urlBase}/${initiative.slug}`}
        {...params}
      />
    : initiative.type === 'volunteering' || initiative.type === 'training'
    ? <Volunteering
        goBackTo={goBackTo}
        {...params}
      />
    : initiative.type === 'donation'
    ? <Donation
        goBackTo={goBackTo}
        {...params}
      />
    : initiative.type === 'collection'
    ? <Collection
        goBackTo={goBackTo}
        {...params}
      />
    : initiative.type === 'informative'
    ? <Informative
        goBackTo={goBackTo}
        {...params}
      />
    : null
  );
}

const mapStateToProps = ({
  organization,
  initiative_detail = {},
  my_profile = {},
}) => ({
  organization,
  initiative_detail,
  my_profile,
});

export default injectIntl(connect(
    mapStateToProps,
    {
      requestInitiativeDetail,
      registerInInitiative,
      notGoingToInitiative,
      interestedInInitiative,
      notInterestedInInitiative,
      reportContribution,
      reportHours,
      submitScores,
      reportIndicator,
    }
  )(
    withRouter(
      InitiativeDetail
    )
));
