const requestWall = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'wall',
  method: 'GET',
  endpoint: `${organization_slug}/wall`,
  body: null,
});

const requestMyInitiatives = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'my_initiatives',
  method: 'GET',
  endpoint: `${organization_slug}/initiative`,
  body: null,
});

const requestInitiativeDetail = (organization_slug, slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'GET',
  endpoint: `${organization_slug}/initiative/${slug}`,
  body: null,
});

const registerInInitiative = (organization_slug, slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${slug}`,
  body: null,
});

const notGoingToInitiative = (organization_slug, slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'DELETE',
  endpoint: `${organization_slug}/initiative/${slug}`,
  body: null,
});

const requestProfile = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'my_profile',
  method: 'GET',
  endpoint: `${organization_slug}/profile`,
  body: null,
});

const updateProfile = (organization_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'my_profile',
  method: 'PUT',
  endpoint: `${organization_slug}/profile`,
  body,
});

const requestResponsibleProfile = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'responsible_profile',
  method: 'GET',
  endpoint: `${organization_slug}/responsible_profile`,
  body: null,
});

const requestRankings = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'rankings',
  method: 'GET',
  endpoint: `${organization_slug}/ranking`,
  body: null,
});

const reportHours = (organization_slug, slug, hours) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'POST',
  endpoint: `${organization_slug}/initiative/${slug}/participation`,
  body: { hours },
});

const reportIndicator = (organization_slug, slug, indicator_id, value) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'POST',
  endpoint: `${organization_slug}/initiative/${slug}/indicator/${indicator_id}/participation`,
  body: { value },
});

const reportContribution = (organization_slug, slug, value, source, params = {}, anonymous = false, comment = '') => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'POST',
  endpoint: `${organization_slug}/initiative/${slug}/contribution`,
  body: {
    value,
    source,
    params,
    anonymous,
    comment,
  },
});

const submitScores = (organization_slug, slug, initiative_score, organization_score) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${slug}/score`,
  body: {
    initiative_score,
    organization_score,
  },
});

const requestMyProposals = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'proposals',
  method: 'GET',
  endpoint: `${organization_slug}/proposal`,
});

const proposeInitiative = (organization_slug, body) => ({
  type: 'API_CALL_REQUEST',
  target: 'proposals',
  method: 'POST',
  endpoint: `${organization_slug}/proposal`,
  body,
});

const requestGenerateResponsibleProfilePDF = (organization_slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'responsible_profile_pdf',
  method: 'PUT',
  endpoint: `${organization_slug}/responsible_profile/pdf`,
  body: {
    // NOTICE: This is a workaround, we need to implement proper
    //         TZ support in initiatives
    timezone_offset: (new Date()).getTimezoneOffset(),
  },
});

const interestedInInitiative = (organization_slug, slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'PUT',
  endpoint: `${organization_slug}/initiative/${slug}/interested`,
  body: null,
});

const notInterestedInInitiative = (organization_slug, slug) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_detail',
  method: 'DELETE',
  endpoint: `${organization_slug}/initiative/${slug}/interested`,
  body: null,
});

const requestInitiativeDocuments = (organization_slug, initiativeSlug) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_documents',
  method: 'GET',
  endpoint: `${organization_slug}/initiative/${initiativeSlug}/attachment/document`,
});

const deleteInitiativeDocument = (organization_slug, initiativeSlug, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_documents',
  method: 'DELETE',
  endpoint: `${organization_slug}/initiative/${initiativeSlug}/attachment/document/${id}`,
});

const requestInitiativeImages = (organization_slug, initiativeSlug) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_images',
  method: 'GET',
  endpoint: `${organization_slug}/initiative/${initiativeSlug}/attachment/image`,
});

const deleteInitiativeImage = (organization_slug, initiativeSlug, id) => ({
  type: 'API_CALL_REQUEST',
  target: 'initiative_images',
  method: 'DELETE',
  endpoint: `${organization_slug}/initiative/${initiativeSlug}/attachment/image/${id}`,
});

export {
  requestWall,
  requestMyInitiatives,
  requestInitiativeDetail,
  registerInInitiative,
  notGoingToInitiative,
  requestProfile,
  updateProfile,
  requestResponsibleProfile,
  requestRankings,
  reportHours,
  reportContribution,
  submitScores,
  requestMyProposals,
  proposeInitiative,
  requestGenerateResponsibleProfilePDF,
  interestedInInitiative,
  notInterestedInInitiative,
  reportIndicator,
  requestInitiativeDocuments,
  requestInitiativeImages,
  deleteInitiativeDocument,
  deleteInitiativeImage,
}
