const lookup = (email, state = null) => ({
  type: 'LOOKUP_REQUEST',
  email,
  state,
});

const login = (email, password, state = null) => ({
  type: 'LOGIN_REQUEST',
  email,
  password,
  state,
});

const logout = (redirectToSignIn = true) => ({
  type: 'LOGOUT_REQUEST',
  redirectToSignIn,
});

const signup = (
  name,
  email,
  password,
  agreeTC,
  agreeComms,
  language,
  signup_token = null,
  public_organization_slug = null,
  state = null
) => ({
  type: 'SIGNUP_REQUEST',
  name,
  email,
  password,
  agreeTC,
  agreeComms,
  language,
  signup_token,
  public_organization_slug,
  state,
});

const recoverPassword = (email) => ({
  type: 'RECOVER_PASSWORD_REQUEST',
  email,
});

const fetchPasswordPolicy = (token, token_type) => ({
  type: 'PASSWORD_POLICY_REQUEST',
  token,
  token_type,
});

const submitRecoveredPassword = (token, password, agreements) => ({
  type: 'SUBMIT_RECOVERED_PASSWORD_REQUEST',
  token,
  password,
  agreements,
});

const confirmEmail = (token, password) => ({
  type: 'CONFIRM_EMAIL_REQUEST',
  token,
  password,
});

const resendConfirmationEmail = (email) => ({
  type: 'RESEND_CONFIRMATION_EMAIL_REQUEST',
  email,
});

const resetAuth = () => ({
  type: 'RESET_AUTH',
});

const resetAuthError = () => ({
  type: 'RESET_AUTH_ERROR',
});

const requestOrganizationByToken = (signup_token) => ({
  type: 'ORGANIZATION_REQUEST',
  signup_token,
  initiative_slug: null,
});

const requestOrganizationByInitiative = (initiative_slug) => ({
  type: 'ORGANIZATION_REQUEST',
  signup_token: null,
  initiative_slug,
});

const refreshAccessToken = () => ({
  type: 'REFRESH_TOKEN_REQUEST',
});

const requestIdentity = () => ({
  type: 'IDENTITY_REQUEST',
});

const requestOrganizationProfile = slug => ({
  type: 'ORGANIZATION_PROFILE_REQUEST',
  slug,
});

const initiateSso = (provider, payload) => ({
  type: 'INITIATE_SSO_REQUEST',
  provider,
  payload,
});

export {
  lookup,
  login,
  logout,
  signup,
  recoverPassword,
  fetchPasswordPolicy,
  submitRecoveredPassword,
  confirmEmail,
  resendConfirmationEmail,
  resetAuth,
  resetAuthError,
  requestOrganizationByToken,
  requestOrganizationByInitiative,
  refreshAccessToken,
  requestIdentity,
  requestOrganizationProfile,
  initiateSso,
}
