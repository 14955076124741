import {
  useMemo,
} from 'react';

const AMOUNT_TOKEN = '<AMOUNT>';
const CURRENCY_TOKEN = '<CURRENCY>';

const useDonationExternalUrl = (
  external_url,
  params = {},
) => {
  const patched_url = useMemo(() => {
    return (external_url || '')
      .replace(AMOUNT_TOKEN, params?.amount)
      .replace(CURRENCY_TOKEN, params?.currency)
  }, [
    external_url,
    params,
  ]);

  return patched_url;
};

export default useDonationExternalUrl;
