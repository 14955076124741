import { loop, Cmd } from 'redux-loop';
import { parseResponse } from 'utils';
import config from 'config';

const requestProfile = async (slug) => {
  const response = await fetch(`${config.API_URL}/organization/profile/${slug}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-type': 'application/json'
    },
  })

  const result = await parseResponse(response);
  return result;
};

const profileSuccess = (organization) => ({
  type: 'ORGANIZATION_PROFILE_COMPLETE',
  organization,
});

const profileFail = ({ code, text }) => ({
  type: 'ORGANIZATION_PROFILE_FAILED',
  code,
  text,
});

const defaultSlug = 'aplanet';

const initialState = {
  name: 'Aplanet',
  logo: '/images/aplanet.png',
  slug: defaultSlug,
  region: null,
  is_default_org: true,
  is_mini_profile: true,
  signup_token: null,
  email_domains: [],
  features: config.DEFAULT_FEATURES,
  config: config.DEFAULT_CONFIG,
  fetching: false,
};

const getOrganizationFromResponse = (response) => {
  const {
    organization_name,
    organization_slug,
    organization_logo,
    name: provided_name,
    slug: provided_slug,
    logo: provided_logo,
    region,
    email_domains = [],
    product_config,
    uuid,
  } = response;

  // NOTICE: if we are processing a member we prefer organization_*
  const name = organization_name || provided_name;
  const slug = organization_slug || provided_slug;
  const logo = organization_logo || provided_logo;

  if(slug === initialState.slug) {
    // return initialState;
  }

  const features = product_config && product_config.community && product_config.community.features
    ? Object.keys(product_config.community.features).filter(feature => product_config.community.features[feature])
    : config.DEFAULT_FEATURES;

  return {
    name,
    slug,
    logo,
    region,
    email_domains,
    features,
    config: (product_config || {}).community || product_config,
    general_config: product_config?.general || product_config,
    signup_token: uuid || null,
  };
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SIGNUP_COMPLETE':
    case 'LOGIN_COMPLETE':
    case 'SUBMIT_RECOVERED_PASSWORD_COMPLETE':
    case 'CONFIRM_EMAIL_COMPLETE':
    case 'IDENTITY_COMPLETE':
      if(
        !action.user ||
        !action.user.organizations ||
        action.user.organizations.length === 0
      ) {
        return state;
      }
      return getOrganizationFromResponse(action.user.organizations[0]);
    case 'ORGANIZATION_COMPLETE':
      return getOrganizationFromResponse(action.organization); // This includes 'signup_token'

    case 'ORGANIZATION_PROFILE_REQUEST':
      return loop({
        ...state,
        fetching: true,
      }, Cmd.run(requestProfile, {
        successActionCreator: profileSuccess,
        failActionCreator: profileFail,
        args: [action.slug],
      })
    );
    case 'ORGANIZATION_PROFILE_COMPLETE':
      return {
        ...(getOrganizationFromResponse(action.organization)),
        is_mini_profile: true,
        fetching: false,
      };
    case 'ORGANIZATION_PROFILE_FAILED':
      return {
        ...state,
        fetching: false,
      };
    case 'RESET_AUTH':
      // Weird thing here: we want to keep the profile if its a "default one",
      // but not if it's a result of loging in or a partially completed signup process
      if(state.is_mini_profile) {
        return state;
      }
      return initialState;
    case 'LOGOUT_REQUEST':
    case 'LOGIN_FAILED':
    case 'REFRESH_TOKEN_FAILED':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as organization,
  defaultSlug,
};
