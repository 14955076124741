import React, { useMemo } from 'react';
import RcRate from 'rc-rate';

import './style.css';
import { parseScore } from 'utils/score';

const Rating = ({ value }) => {
  const roundedScore = useMemo(() => parseScore(value), [ value ]);
  return (
    <RcRate
      className="Rating-stars"
      value={roundedScore}
      disabled
      allowHalf
    />
  );
}

export default Rating;
