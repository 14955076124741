import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  updateProfile,
} from 'actions/api';

import {
  resetAuthError,
} from 'actions/auth';

import './style.less';

import {
  Drawer,
  Button,
  Alert,
  Affix,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useScreenSize from 'utils/useScreenSize';
import validation from 'components/PhoneInput/validation';
import useForm from 'utils/useForm';
import PhoneInput from 'components/PhoneInput';

const PhoneModal = ({
  intl,
  organization,
  my_profile,
  updateProfile,
  resetAuthError,
  onSuccess,
  onCancel,
}) => {
  const t = intl.messages;
  const validateForm = useMemo(() => validation(t), [ t ]);
  const [submitting, setSubmitting] = useState(false);

  const {
    loading,
    error,
  } = my_profile;


  useEffect(() => {
    if(submitting && !loading && !error) {
      onSuccess();
    } else if(submitting && !loading && error) {
      setSubmitting(false);
    }
  }, [submitting, loading, error, onSuccess]);

  const submitForm = () => {
    resetAuthError();
    updateProfile(organization.slug, { phone: values.phone });
    setSubmitting(true);
  };


  const {
    values,
    handleChange,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
  });

  const handlePhoneChange = useCallback((phone) => {
    if(error) resetAuthError();
    handleChange('phone')(phone);
  }, [
    error,
    handleChange,
    resetAuthError,
  ]);

  const drawerPlacement = ['xs', 'sm', 'md'].includes(useScreenSize()) ? 'bottom' : 'right';

  const phoneError = !isDirty('phone') && errors.phone;

  const errorText = error ? (t[`profile_error_${error}`] || t.profile_error_default) : phoneError;

  return (
    <Drawer
      className="phone-drawer"
      title={t.join_the_initiative}
      placement={drawerPlacement}
      visible
      onClose={onCancel}
    >
      <div className="check-icon-wrapper">
        <FontAwesomeIcon color="red" className={"check-icon"} icon="check-circle" />
      </div>
      <h3>
        { t.we_need_your_phone }
      </h3>
      <p>
        { t.we_need_your_phone_why }
      </p>
      { (error || phoneError)
        ? <Alert message={errorText} type="error" showIcon />
        : <div style={{ height: '36px' }}></div>
      }
      <PhoneInput
        disable={loading}
        placeholder={ t.phone }
        value={values.phone}
        onChange={handlePhoneChange}
        country={organization.region}
        error={phoneError || false}
      />
      <Affix className="affix-button">
        <Button
          className="finish-button"
          type="primary"
          size="large"
          disabled={loading || !values.phone}
          onClick={handleSubmit}
        >{ t.done }</Button>
      </Affix>
    </Drawer>
  )
}

const mapStateToProps = ({
  organization,
  my_profile,
}) => ({
  organization,
  my_profile,
});

export default injectIntl(connect(mapStateToProps, { updateProfile, resetAuthError })(PhoneModal));
