import React, {
  useMemo,
  useState,
  useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  Layout,
  List,
  Empty,
  Button,
  Affix,
  Divider,
  Row,
  Col,
} from 'antd';

import './style.less';

import { formatDateLong, fromDb } from 'utils/date';
import Qr from './cards/Qr';
import ReportHours from './cards/ReportHours';
import ReportIndicator from './cards/ReportIndicator';
import AppHeader from 'containers/AppHeader';
import Nav from 'containers/Nav';
import RatingScreen from 'components/RatingScreen';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TYPES_WITH_HOURS = [
  'volunteering',
  'training',
];

const ParticipationScreen = ({
  intl,
  history,
  title,
  organization,
  slug,
  type,
  reportHours,
  reportIndicator,
  participant_uuid,
  participation_method,
  hours_per_day = -1,
  participation = [],
  indicators = [],
  indicator_participation,
  feedback_url,
  submitScores,
  participant_initiative_score = 0,
  participant_organization_score = 0,
  evaluationConfig,
}) => {
  const { push } = history;
  const [ showRatingDrawer, setShowRatingDrawer ] = useState(false);

  const sendToRateScreen = useMemo(() => {
    return Boolean(
      !participant_initiative_score &&
      (
        participation_method === 'report_hours' ||
        participation.find(({ status }) => ['validated', 'granted'].includes(status))
      )
    );
  }, [
    participant_initiative_score,
    participation_method,
    participation,
  ]);

  const [reportDone, setReportDone] = useState(false);

  const handleReportHours = useCallback((...params) => {
    setReportDone(true);
    return reportHours(...params);
  }, [
    reportHours,
  ]);

  const handleReportIndicator = useCallback((...params) => {
    setReportDone(true);
    return reportIndicator(...params);
  }, [
    reportIndicator,
  ]);

  const hasHours = useMemo(() => {
    return TYPES_WITH_HOURS.includes(type);
  }, [
    type,
  ]);

  const dataHistory = useMemo(
    () => {
      let data = [
        ...participation,
        ...indicator_participation,
      ];
      return data.sort((a, b) => fromDb(b.created_at) - fromDb(a.created_at));
    },
    [participation, indicator_participation]
  );

  const indicatorNamesById = useMemo(
    () => {
      let names = {};
      indicators.map(indicator => names[indicator.id] = indicator.name);
      return names;
    },
    [indicators]
  )

  const showRatingScreen = useMemo(() => {
    const {
      hide_evaluate_initiative,
      hide_evaluate_organizer
    } = evaluationConfig;
    return showRatingDrawer && (!hide_evaluate_initiative || !hide_evaluate_organizer || feedback_url);
  }, [
    showRatingDrawer,
    evaluationConfig,
    feedback_url
  ]);

  return (
    <Layout className="ParticipationScreen">
      <AppHeader />
      <Layout>
        <Nav />
        <Layout.Content>
          <Affix>
            <Layout.Header className="ParticipationScreen-header">
              <Row justify="start">
                <Col>
                  <Link to={`/initiative/${slug}`}>
                    <FontAwesomeIcon size="2x" icon="chevron-left" />
                    <h2>{ title }</h2>
                  </Link>
                </Col>
              </Row>
            </Layout.Header>
          </Affix>
          { participation_method === 'qr' ?
            <div className="ParticipationScreen-qr">
              <Qr uuid={participant_uuid} />
            </div>
          : null }
          { participation_method === 'report_hours' ?
            <>
              {
                !hasHours ? null : (
                  <div className="ParticipationScreen-reporthours">
                    <ReportHours
                      uuid={participant_uuid}
                      reportHours={handleReportHours}
                      max={hours_per_day}
                    />
                  </div>
                )
              }
              <div className="ParticipationScreen-reporthours">
                {indicators.map(
                  indicator => (
                    <ReportIndicator
                      key={indicator.id}
                      indicator={indicator}
                      reportIndicator={handleReportIndicator} />
                  )
                )}
              </div>
            </>
          : null }

          <Divider />

          <List
            locale={{
              emptyText: <Empty description={intl.formatMessage({ id: `no_history_yet_${type}` }) || intl.formatMessage({ id: `no_history_yet` })} />
            }}
            className="ParticipationScreen-list"
            dataSource={dataHistory}
            renderItem={entry => (
              <List.Item >
                <List.Item.Meta
                  title={
                    entry.type === 'qr' ?
                      <div>{ intl.formatMessage({ id: `qr_scanned` }) }</div>
                    : (
                      <div>
                        {entry.type === 'report_hours'
                          ? `${entry.hours} ${intl.formatMessage({ id: `hours` })}`
                          : `${entry.value} ${intl.formatMessage({ id: `unit_${entry.unit}_symbol` })}`}
                      </div>
                    )
                  }
                  description={
                    <>
                      {!entry.type && <div>{indicatorNamesById[entry.indicator_id]}</div>}
                      <div>{ intl.formatMessage({ id: `reported` }) } { formatDateLong(entry.created_at, intl) }</div>
                      <div>
                        { entry.type !== 'qr' &&
                          <span className={`ParticipationScreen-status-${entry.status}`}>
                            { intl.formatMessage({ id: `participation_status_${entry.status}` }) || entry.status }
                          </span>
                        }
                      </div>
                    </>
                  }
                />
              </List.Item>
            )}
          />
          <Affix offsetBottom={0} className="affix-button">
            {reportDone &&
            <Button
              className="brand-button-primary"
              disabled={!reportDone}
              onClick={() =>
                sendToRateScreen
                ? setShowRatingDrawer(true)
                : push(`/initiative/${slug}`)}
            >
              { intl.formatMessage({ id: `done` }) }
            </Button>
            }
          </Affix>
        </Layout.Content>
      </Layout>
      <RatingScreen
        visible={showRatingScreen}
        onClose={() => setShowRatingDrawer(false)}
        feedback_url={feedback_url}
        submitScores={submitScores}
        hideRatingInitiative={evaluationConfig.hide_evaluate_initiative}
        hideRatingOrganizer={evaluationConfig.hide_evaluate_organizer}
        participant_initiative_score={participant_initiative_score}
        participant_organization_score={participant_organization_score}
      />
    </Layout>
    )
}

export default injectIntl(withRouter(ParticipationScreen));
