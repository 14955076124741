import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  requestInitiativeDocuments,
  deleteInitiativeDocument,
} from 'actions/api';

import {
  addInitiativeDocument,
} from 'actions/initiative';

import {
  Row,
  Col,
  List,
  Button,
  Empty,
  Modal,
  Alert,
} from 'antd';

import FileItem from 'components/FileItem';
import UploadButton from 'containers/UploadButton';
import config from 'config';

const SHORT_LIST_SIZE = 4;

const AttachmentDocuments = ({
  intl,
  ...initiative
}) => {
  const t = intl.messages;

  const [expandList, setExpandList] = useState();
  const [showMyAttachments, setShowMyAttachments] = useState();
  const [isUploaded, setIsUploaded] = useState();
  const [isUploading, setIsUploading] = useState();

  const dispatch = useDispatch();
  const { data } = useSelector(state => state.initiative_documents);
  const { data: profile } = useSelector(state => state.my_profile);

  useEffect(() => {
    dispatch(requestInitiativeDocuments(
      initiative?.organization?.slug || initiative.organization_slug,
      initiative.slug,
    ));
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    dispatch,
    initiative.slug,
    initiative.organization,
    initiative.organization_slug,
  ]);

  const uploadUrl = useMemo(() => {
    const { slug, organization } = initiative;
    const { API_URL } = config;
    return `${API_URL}/${organization.slug}/initiative/${slug}/attachment/document`;
  }, [
    initiative,
  ]);

  const officialAttachments = useMemo(() => {
    const attachments = data?.filter(attachment => attachment.status === 'published') || [];
    if(attachments.length > SHORT_LIST_SIZE && !expandList) {
      return attachments.slice(0, SHORT_LIST_SIZE);
    }
    return attachments;
  }, [
    expandList,
    data,
  ]);

  const myAttachments = useMemo(() => (
    data?.filter(attachment => attachment.member_id === profile?.id)
  ), [
    profile,
    data,
  ]);

  const onUploadSuccess = useCallback((attachment) => {
    dispatch(
      addInitiativeDocument(JSON.parse(attachment))
    );
    setIsUploaded(true);
  }, [
    dispatch,
  ]);

  const onDelete = useCallback(attachment => {
    Modal.confirm({
      title: `${t.attachment_documents_delete} ${attachment.filename}`,
      content: t.attachment_documents_confirm_delete,
      okText: t.attachment_documents_delete,
      onOk() {
        dispatch(deleteInitiativeDocument(
          initiative.organization_slug,
          initiative.slug,
          attachment.id,
        ));
      },
    })
  }, [
    dispatch,
    initiative,
    t,
  ]);

  const onUploadStatusChange = useCallback(file => {
    switch(file?.status) {
      case 'error':
      case 'done':
        setIsUploading(false);
        break;
      case 'uploading':
        setIsUploading(true);
        break;
      default:
    }
  }, [ ]);

  return (
    <div className="AttachmentDocuments">
      <div className="title">{ t.attachment_documents }</div>

      <List
        size="small"
        dataSource={officialAttachments || []}
        locale={{
          emptyText: <Empty description={t.no_data} />,
        }}
        renderItem={attachment => (
          <List.Item>
            <FileItem key={attachment.url} {...attachment} />
          </List.Item>
        )}
      />

      <Row type="flex" justify="space-between">
        <Col>
          <Button
            onClick={() => setShowMyAttachments(true)}
            type="primary"
          >
            { t.attachment_documents_send_docs }
          </Button>
        </Col>
        <Col>
          {officialAttachments?.length >= SHORT_LIST_SIZE && (
            expandList
            ? <Button onClick={() => setExpandList(false)}>{t.attachment_documents_show_less}</Button>
            : <Button onClick={() => setExpandList(true) }>{t.attachment_documents_show_more}</Button>
          )}
        </Col>
      </Row>

      <Modal
        className="InitiativeDetail-upload-attachments"
        title={t.attachment_documents_upload_docs}
        visible={showMyAttachments}
        footer={null}
        onCancel={() => setShowMyAttachments(false)}
      >
        <List
          size="small"
          dataSource={myAttachments || []}
          locale={{
            emptyText: <Empty description={t.no_data} />,
          }}
          renderItem={attachment => (
            <List.Item
              actions={[<a onClick={() => onDelete(attachment)}>{t.attachment_documents_delete}</a>]}
            >
              <FileItem key={attachment.url} {...attachment} />
            </List.Item>
          )}
        />

        <Row type="flex" justify="center" gutter={[0, 20]}>
          <Col span={24}>
            {isUploaded &&
            <Alert
              message={t.attachment_documents_upload_success}
              type="success"
              closable
            />
            }
          </Col>
          <Col>
            <UploadButton
              actionUrl={uploadUrl}
              onSuccess={onUploadSuccess}
              onChange={onUploadStatusChange}
              component={
                <Button
                  type="primary"
                  loading={isUploading}
                >
                  {t.attachment_documents_upload}
                </Button>
              }
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default injectIntl(AttachmentDocuments);
