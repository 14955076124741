import React, { useState, useCallback } from 'react';
import { injectIntl } from 'react-intl';

import './style.less';

import { toDateArray, isAfterNow } from 'utils/date';
import DonationProgress from 'components/DonationProgress';
import SdgMiniLogo from 'components/SdgMiniLogo';

import Highlighter from 'react-highlight-words';
import colors from 'colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InitiativeTypeIcon from 'components/InitiativeTypeIcon';
import InitiativeTypeString from 'components/InitiativeTypeString';
import { PushpinFilled } from "@ant-design/icons";

import {
  Button,
  Card,
  Modal,
  Popover,
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';


const DEFAULT_IMAGE = 'https://aplanet-static.ams3.digitaloceanspaces.com/assets/default_initiative_image.jpg';
const CANCELED_STATUSES = new Set([ 'suspended', 'canceled' ]);

const InitiativeCard = ({
  intl,
  profile,
  considerParticipantCount = true,
  initiative,
  loading,
  viewDetail,
  join,
  leave,
  participate,
  contribute,
  className,
  searchText,
  interested,
  notInterested,
  isPinned = false,
  hasPoints,
  hasProgram,
}) => {
  const t = intl.messages;
  const [showNotGoing, setShowNotGoing] = useState(false);
  const isCanceled = CANCELED_STATUSES.has(initiative.status);
  const showLeave = !isCanceled &&
    (initiative.type === 'volunteering' || initiative.type === 'training') &&
    initiative.participant_status === 'accepted' &&
    isAfterNow(initiative.end_time);

  const showNotInterested = !showLeave && initiative.own_interested;

  const showOwnContribution = initiative.type === 'donation' &&
    initiative.own_contribution_total &&
    parseInt(initiative.own_contribution_total, 10) > 0;

  const showAddress = initiative.type !== 'donation';
  const showContribution = initiative.type === 'donation' && initiative?.donation_params?.show_target_amount && initiative?.donation_params?.target_amount;

  const maybeHighlight = useCallback((text) => (
    !searchText ? text : (
      <Highlighter
        highlightStyle={{ backgroundColor: colors['@brand-success'], padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text || ''}
      />
    )
  ), [ searchText ]);

  const dateArray = toDateArray(initiative.start_time, initiative.end_time, intl);

  const { donation_params = {} } = initiative;
  const currency = donation_params.currency;
  const target_amount = donation_params.target_amount;

  return (
    <Card className={`initiative-card ${className}`}>
      <div onClick={viewDetail}>
        <div className="card-image">
          { hasProgram && (
            <div className="programWrapper" title={initiative.program}>
              <span>{ initiative.program }</span>
            </div>
          )}
          { isPinned && (
            <div className="pinWrapper" title={ intl.formatMessage({ id: 'initiative_card_pinned' }) }>
              <PushpinFilled />
            </div>
          )}

          <img alt="" src={initiative.image || DEFAULT_IMAGE} />


          {
            isCanceled
            ? <div className="card-date canceled">{ t.canceled }</div>
            : dateArray.length === 2
              ? (
                <div className="card-date">
                  <div className="start-date">
                    <span className="day">{dateArray[0].day}</span>
                    <span className="month">{dateArray[0].month}</span>
                  </div>
                  <figure className="divider"/>
                  <div className="end-date">
                    <span className="day">{dateArray[1].day}</span>
                    <span className="month">{dateArray[1].month}</span>
                  </div>
                </div>
              ) : (
                <div className="card-date single">
                  <div className="start-date">
                    <span className="day">{dateArray[0].day}</span>
                    <span className="month">{dateArray[0].month}</span>
                  </div>
                </div>
              )
          }
        
          { showLeave &&
            <Button
              className="card-button"
              size="small"
              disabled={loading}
              onClick={(e) => {
                e.stopPropagation();
                setShowNotGoing(true);
            }}>
              { t.not_going }
            </Button>
          }

          { showNotInterested &&
            <Button
              className="card-button"
              size="small"
              disabled={loading}
              onClick={(e) => {
                e.stopPropagation();
                notInterested();
              }}>
              { t.not_interested_button }
            </Button>
          }

        { hasPoints && (
            <div className={`card-points ${isPinned ? 'isPinned' : ''}`}>
              {intl.formatMessage({ id: 'initiative_card_points' }, { value: initiative.points })}
            </div>
        )}
        </div>

        <div className="card-body">
          <p className="card-title">
            { maybeHighlight(initiative.title) }
          </p>
          { showAddress && (
            <p className="card-address"> { maybeHighlight(initiative.address) } </p>
          )}

          { showContribution &&
            <DonationProgress
              total={initiative.contribution_total}
              limit={target_amount}
              currency={t[`${currency}_SYMBOL`]}
            />
          }

          { showOwnContribution &&
            <div className="contribution">
              { t.donation_own_contribution }
              <span> { initiative.own_contribution_total + ( currency ? ` ${t[currency]}` : '') }</span>
            </div>
          }

          <div className="initiative-type">
            { initiative.is_remote
              ? <FontAwesomeIcon icon="wifi" />
              : null
            }
            { initiative.is_in_person
              ? <FontAwesomeIcon icon="map-marked-alt" />
              : null
            }
            <InitiativeTypeIcon
              type={initiative.type}
              subtype={initiative.subtype}
            />
            <p>
              <InitiativeTypeString
                type={initiative.type}
                subtype={initiative.subtype}
              /> {initiative.is_remote && `${t.initiative_remote}`}
            </p>
          </div>

          <div className="sdgs">
            {initiative.sdgs.slice(0, 3).map(sdg =>
              <SdgMiniLogo key={sdg} sdg={sdg}/>
            )}
            {initiative.sdgs.length > 3 &&
              <Popover
                placement="bottom"
                className="InitiativeCard-sdgs-popover"
                trigger="hover"
                content={
                  <div className="InitiativeCard-sdgs-popover-content">
                    {initiative.sdgs.slice(3).map(sdg =>
                      <SdgMiniLogo key={sdg} sdg={sdg}/>
                    )}
                  </div>
                }
              >
                <MoreOutlined onClick={e => e.stopPropagation()} />
              </Popover>
            }
          </div>
        </div>
      </div>

      <Modal
        className="not-going-modal"
        title={t.not_going}
        visible={showNotGoing}
        onOk={leave}
        onCancel={() => setShowNotGoing(false)}
        okText={t.not_going_confirm}
        cancelText={t.not_going_cancel}
      >
        <div>
          <div>{ t.not_going_are_you_sure }</div>
          <div>{ t.not_going_are_you_sure_2 }</div>
        </div>
      </Modal>
    </Card>
  );
}

export default injectIntl(InitiativeCard);
