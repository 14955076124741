import React, { useMemo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  useSelector,
} from 'react-redux';

import {
  Layout,
  Row,
  Col,
  Button,
} from 'antd';

import Nav from 'containers/Nav';
import AppHeader from 'containers/AppHeader';
import NavBottom from 'containers/NavBottom';
import NewProposalHeader from 'components/NewProposalHeader/NewProposalHeader';
import InitiativeTypeIcon from 'components/InitiativeTypeIcon';
import InitiativeTypeString from 'components/InitiativeTypeString';

const Type = ({
  intl,
  values,
  handleChange,
  showError,
  next,
}) => {
  const t = intl.messages;

  const organization = useSelector(state => state.organization);

  const proposalTypes = useMemo(() => {
    const orgConfig = organization.config;
    return orgConfig.proposal_types || orgConfig.initiative_types || [];
  }, [
    organization
  ]);

  useEffect(() => {
    if(proposalTypes.length === 1) {
      handleChange('type')(proposalTypes[0]);
      next();
    }
  }, [
    proposalTypes,
    handleChange,
    next,
  ]);

  return (
    <>
      <AppHeader />
      <Layout>
        <Nav />
        <Layout.Content className="ProposalInitiative-types">
          <NewProposalHeader
            stepCount={0}
          />
          <Row gutter={[20, 20]}>
            {
              proposalTypes.map(type => {
                // TODO: implement this
                const subtype = null;
                return (
                  <Col xs={12} md={8} xxl={4} key={type}>
                    <Row
                      type="flex"
                      align="middle"
                      justify="center"
                      className={`ProposalInitiative-types-card ${values.type === type ? 'selected' : ''}`}
                      onClick={() => handleChange('type')(type)}
                    >
                      <div className="icon">
                        <InitiativeTypeIcon
                          type={type}
                          subtype={subtype}
                        />
                      </div>
                      <div className="name">
                        <InitiativeTypeString
                          type={type}
                          subtype={subtype}
                        />
                      </div>
                    </Row>
                  </Col>
                )
              })
            }
          </Row>
          <Row>
            { showError('type') }
          </Row>
          <Row className="ProposalInitiative-button-next">
            <Col span={24}>
              <Button
                type="primary"
                onClick={next}
              >
                { t.next }
              </Button>
            </Col>
          </Row>
          <NavBottom />
        </Layout.Content>
      </Layout>
    </>
    );
};

export default injectIntl(Type);

