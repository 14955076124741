import React from 'react';
import { injectIntl } from 'react-intl';
import Rating from 'components/Rating';

const InitiativeScore = ({
  intl,
  initiative_score,
  initiative_score_count,
}) => {
  return (
    <>
      {!!initiative_score_count && (
        <div className="rate">
          <div className="title">
          {intl.formatMessage({ id: `score_initiative` })}
          </div>
          <div className="rating">
            <Rating value={initiative_score} />
            <span>({ initiative_score_count })</span>
          </div>
        </div>
      )}
    </>
  );
};

export default injectIntl(InitiativeScore);
