import React, { useEffect } from 'react';
import { withRouter } from 'react-router';

import {
  Row,
  Col,
  Input,
  Checkbox,
  Button,
} from 'antd';

import OrganizationLogo from 'components/OrganizationLogo';
import QuoteWall from 'components/QuoteWall';
import A from 'components/A';

const DEFAULT_ORGANIZATION_SLUG='aplanet';

const CorporateSignUp = ({
  t,
  organization,
  history,
  match,
  values,
  handleFieldChange,
  handleSubmit,
  showError,
  nameError,
  emailError,
  reset,
  resetAuthError,
  error,
  resendConfirmationEmail,
  locationState,
}) => {
  const push = history.push;
  const {
    organization_token,
  } = match.params;

  useEffect(() => {
    if(!organization || organization.slug === DEFAULT_ORGANIZATION_SLUG) {
      push(
        organization_token ? `/signup/${organization_token}` : `/signup`,
        locationState
      );
    }
  }, [organization, push, locationState, organization_token]);

  return (
    <Row className="SignUp CorporateSignUp">
      <Col xs={24} sm={12} className="CorporateSignUp-content">
        <center>{ t.member_of }</center>
        <OrganizationLogo className="SignUp-corporatelogo" {...organization} />
        <center>{ t.member_of2 }</center>
        { showError }
        <Row className="SignUp-input">
          <Col span={24}>
            <label>{t.email_or_phone}</label>
          </Col>
          <Col span={24}>
            <Input
              placeholder={ t.your_email }
              value={values.email}
              onChange={e => handleFieldChange.email(e.target.value)}
            />
          </Col>
          <Col span={24}>
            <label>{t.full_name}</label>
          </Col>
          <Col span={24}>
            <Input
              placeholder={ t.your_name }
              value={values.name}
              onChange={e => handleFieldChange.name(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="SignUp-checkboxes">
          <Col span={24}>
            <Checkbox
              checked={values.agreeTC}
              onChange={() => handleFieldChange.agreeTC(!values.agreeTC)}
            >{ t.terms_and_conditions_accept } <A href={t.terms_and_conditions_url}>{ t.terms_and_conditions }</A></Checkbox>
          </Col>
          <Col>
            <Checkbox
              checked={values.agreeComms}
              onChange={() => handleFieldChange.agreeComms(!values.agreeComms)}
            >{ t.receive_commercial_comms }</Checkbox>
          </Col>
        </Row>
        <div className="SignUp-flex"></div>
        <div className="SignUp-bottomlink">
          <A onClick={() => push('/signin', locationState)}>{ t.already_have_account }</A>
        </div>
        <div className="SignUp-calltoaction">
          <Button
            className="brand-button-primary cta"
            type="primary"
            disabled={!values.name || !values.email || !values.agreeTC}
            onClick={handleSubmit}
          >{ t.next }</Button>
        </div>
      </Col>
      <Col xs={0} sm={12} className="cover">
        <QuoteWall organization={organization} />
      </Col>
    </Row>
  );
};

export default withRouter(CorporateSignUp);
