import {
  useMemo,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';

const useOpenInNewWindow = () => {
  // TODO: Better way to detect if embedded
  const organization = useSelector(state => state.organization);
  const embedded = useMemo(() => organization?.config.embedabble_community_app, [organization]);

  const callback = useCallback( (url, focus = true) => {
    if(embedded) {
      window.location = url;
      return null;
    } else {
      const win = window.open(url, '_blank');
      if(focus && win) {
        win.focus();
      }
      return win;
    }
  }, [
    embedded,
  ]);

  return callback;
};

export default useOpenInNewWindow;

