import React from 'react';
import { injectIntl } from 'react-intl';

import A from 'components/A';
import useDonationExternalUrl from 'utils/useDonationExternalUrl';

const External = ({
  intl,
  source = 'external',
  params = {},
  single = false,
  amount,
  currency,
}) => {
  const t = intl.messages;

  const {
    external_url,
  } = params;

  const url = useDonationExternalUrl(
    external_url,
    {
      currency,
      amount,
    },
  );

  return (
    <>
      <div className="ContributionScreen__desc">
        { t.donation_external_desc }
      </div>
      <div className="ContributionScreen__disable-selection">
        <A href={url}>{ url }</A>
      </div>
    </>
  );
};

export default injectIntl(External);

