import React, { useCallback } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';

const AplanetPaypalButton = ({
  goBack,
  amount,
  submit,
  disabled = false,
  params = {},
}) => {
  const {
    paypal_clientid: clientId,
    currency,
  } = params;

  const handleSuccess = useCallback((details, data) => {
    submit({ details, data });
  }, [ submit ]);

  return (
    <PayPalButton
      amount={amount}
      onSuccess={handleSuccess}
      style={{
        layout: 'horizontal',
        fundingicons: false,
        color: 'white',
        shape: 'rect',
        label: 'paypal',
        tagline: false,
      }}
      options={{
        clientId,
        currency,
        disableFunding: 'credit,bancontact,eps,giropay,ideal,sofort',
        disableCard: 'amex,discover,jcb,elo,hiper',
        commit: true,
      }}
    />
  );
};

export default AplanetPaypalButton;

