import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Row,
  Input,
  Button,
  Checkbox,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import A from 'components/A';
import QuoteWall from 'components/QuoteWall';

const DEFAULT_COVER_URL = '/images/cover.jpg';

const BasicSignUp = ({
  t,
  history,
  organization,
  values,
  handleFieldChange,
  handleSubmit,
  showError,
  nameError,
  emailError,
  reset,
  resetAuthError,
  error,
  resendConfirmationEmail,
  locationState,
}) => {
  const push = history.push;
  const [redirected, setRedirected] = useState(false);
  useEffect(() => {
    if(
      !redirected &&
      organization &&
      !organization.is_default_org &&
      !organization.is_mini_profile
    ) {
      setRedirected(true);
      push(`/signup/c`, locationState);
    }
  }, [
    redirected,
    organization,
    push,
    locationState,
  ]);

  const cover_url = useMemo(() => {
    return organization?.config?.cover_url || DEFAULT_COVER_URL
  }, [
    organization,
  ]);

  return (
    <Row className="SignUp">
      <Col xs={24} sm={12}>
        <Row>
          <Col
            span={24}
            className="header"
            style={{
              background: `linear-gradient( to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url('${cover_url}')`,
              backgroundSize: "cover"
            }}
          >
            <Link to='/welcome'>
              <FontAwesomeIcon size="2x" icon="arrow-left" />
            </Link>
            <h1>{t.signup}</h1>
          </Col>
          <Col span={24}>
            { showError }
            <Row className="SignUp-input">
              <Col span={24}>
                <label>{t.email_or_phone}</label>
              </Col>
              <Col span={24}>
                <Input
                  placeholder={ t.email }
                  value={values.email}
                  onPressEnter={handleSubmit}
                  onChange={e => handleFieldChange.email(e.target.value)}
                />
              </Col>
              <Col span={24}>
                <label>{t.full_name}</label>
              </Col>
              <Col span={24}>
                <Input
                  placeholder={ t.your_name }
                  value={values.name}
                  onPressEnter={handleSubmit}
                  onChange={e => handleFieldChange.name(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="SignUp-checkboxes">
              <Col span={24}>
                <Checkbox
                  checked={values.agreeTC}
                  onChange={() => handleFieldChange.agreeTC(!values.agreeTC)}
                >{ t.terms_and_conditions_accept } <A href={t.terms_and_conditions_url}>{ t.terms_and_conditions }</A></Checkbox>
              </Col>
              <Col>
                <Checkbox
                  checked={values.agreeComms}
                  onChange={() => handleFieldChange.agreeComms(!values.agreeComms)}
                >{ t.receive_commercial_comms }</Checkbox>
              </Col>
            </Row>
            <div className="SignUp-bottomlink">
              <A onClick={() => push('/signin', locationState)}>{ t.already_have_account }</A>
            </div>
          </Col>
        </Row>
        <Button
          className="brand-button-primary cta"
          type="primary"
          disabled={!values.name || !values.email || !values.agreeTC}
          onClick={handleSubmit}
        >{ t.next }</Button>
      </Col>
      <Col xs={0} sm={12} className="cover">
        <QuoteWall organization={organization} />
      </Col>
    </Row>
  );
};

export default BasicSignUp;
