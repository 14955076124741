import React, { useLayoutEffect, useCallback, useMemo } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import Linkify from 'react-linkify';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryFallback } from 'aplanet-ui-kit';

import {
  isBeforeNow,
} from 'utils/date';

import AppHeader from 'containers/AppHeader';

import {
  requestWall,
} from 'actions/api';
import { updateInitiativeBannerVisibility } from 'actions/app';

import NavBottom from 'containers/NavBottom';
import InitiativeFilter from 'components/InitiativeFilter';
import InitiativeCard from 'components/InitiativeCard';
import T from 'components/T';
import Nav from 'containers/Nav';
import { useFeatureList } from 'components/FeatureSwitch';
import { ReactComponent as ProposalsIcon } from 'assets/icons/icon-proposals.svg';

import './style.less';

import {
  Row,
  Col,
  Spin,
  Empty,
  Alert,
  Layout,
  Divider,
} from 'antd'

const LoadingInitiatives = () => (
  <Row type="flex" align="middle" justify="center">
    <Col>
      <Spin/>
    </Col>
  </Row>
);

const NoInitiativeFound = ({
  t,
  showSuggest,
}) => (
  <div className="no-initiative">
    <Empty
      className="empty"
      image={'images/empty-card.svg'}
      description={t.no_initiatives}
    />
    { showSuggest &&
      <T no_initiatives_suggest= {{icon: <ProposalsIcon />}} />
    }
  </div>
);

const Wall = ({
  intl,
  history
}) => {
  const push = history.push;
  const dispatch = useDispatch();

  const {
    items: initiatives = [],
    loading,
  } = useSelector(state => state.wall);

  const {
    data: profile
  } = useSelector(state => state.my_profile);

  const organization = useSelector(state => state.organization);

  const {
    isInitiativeBannerVisible,
  } = useSelector(state => state.app);

  useLayoutEffect(() => {
    dispatch(requestWall(organization.slug));
  }, [organization.slug, dispatch]); // TODO: Pull to refresh

  const featureList = useFeatureList();

  const isPastInitiative = useCallback(({ registration_end_time, end_time }) => {
    if (registration_end_time) {
      return isBeforeNow(registration_end_time);
    }
    if (end_time) {
      return isBeforeNow(end_time);
    }
    return false;
  }, [ ]);

  const onInitiativeBannerClose = useCallback(() => {
    dispatch(updateInitiativeBannerVisibility(false));
  }, [
    dispatch,
  ]);

  const activeInitiatives = useMemo(() => {
    return initiatives.filter((initiative) =>
      initiative.max_participants < 0 ||
      parseInt(initiative.participant_count) < initiative.max_participants
    )
  }, [initiatives]);

  const initiativeBanner = useMemo(() => organization.config.initiative_banner || {},
  [organization],
  );

  const isPinnedInitiative = useCallback((initiative) => (
    initiative.pinned_initiative && organization?.config?.can_pin_initiatives
  ), [ organization ]);
  
  const hasPoints = useMemo(() => {
    const orgFeatures = organization?.features;
    if(!orgFeatures) {
      return false;
    }
    return orgFeatures.includes('points');
  }, [
    organization
  ]);

  const hasProgram = useMemo(() => {
    const orgFeatures = organization?.features;
    if(!orgFeatures) {
      return false;
    }
    return orgFeatures.includes('program');
  }, [
    organization
  ]);

  const t = intl.messages;
  return (
    <>
      <AppHeader showOnSmallScreen />
      <Layout>
        <Nav/>
        <Layout.Content className="padding-md-x" >
          <Sentry.ErrorBoundary
            fallback={
            <ErrorBoundaryFallback
              titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
              buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
              descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_wall_message' })}
              customErrorImage="/images/error_image.png"
            />
          }>
            <p className="Wall-title">{ t.wall_title }</p>
            { loading
            ? <LoadingInitiatives />
            : ( !activeInitiatives.length
                ? <NoInitiativeFound t={t} showSuggest={featureList.has('proposals')} />
                : <InitiativeFilter
                    data={activeInitiatives}
                    profile={profile}
                    className='Wall-filters'
                    organization={organization}
                    isWallFilter
                    isPastInitiative={isPastInitiative}
                    organizationHasPoints={hasPoints}
                    organizationHasProgram={hasProgram}
                    renderChildren={
                      (initiatives, searchText) => (
                      <>
                        { initiativeBanner.visible && isInitiativeBannerVisible &&
                        <Alert
                          showIcon
                          closable
                          className="initiativeBanner"
                          type="warning"
                          onClose={onInitiativeBannerClose}
                          message={
                            <Linkify properties={{target: '_blank'}}>
                              {initiativeBanner.content}
                            </Linkify>
                          }
                        />
                        }
                        {!initiatives.filter(initiative => !isPastInitiative(initiative)).length &&
                        <NoInitiativeFound t={t} showSuggest={featureList.has('proposals')} />
                        }
                        <section className='initiativeContainer'>
                          {
                            initiatives
                              .filter(initiative => !isPastInitiative(initiative))
                              .sort((a, b) =>(isPinnedInitiative(b))-(isPinnedInitiative(a)))
                              .map((initiative, index) => (
                                <InitiativeCard
                                  key={`${initiative.slug}_${index}`}
                                  initiative={initiative}
                                  loading={loading}
                                  profile={profile}
                                  considerParticipantCount={true}
                                  viewDetail={() => push(`/wall/${initiative.slug}`)}
                                  searchText={searchText}
                                  isPinned={isPinnedInitiative(initiative)}
                                  hasPoints={hasPoints && initiative.points > 0}
                                  hasProgram={hasProgram && initiative.program}
                                />
                              ))
                          }
                        </section>
                        {!!initiatives.filter(isPastInitiative).length &&
                        <Divider className="initiativeDivider">
                          { t.wall_past_initiatives }
                        </Divider>
                        }
                        <section className='initiativeContainer pastInitiatives'>
                          {initiatives
                            .filter(isPastInitiative)
                            .map((initiative, index) => (
                              <InitiativeCard
                                key={`${initiative.slug}_${index}`}
                                initiative={initiative}
                                loading={loading}
                                profile={profile}
                                considerParticipantCount={true}
                                viewDetail={() => push(`/wall/${initiative.slug}`)}
                                searchText={searchText}
                                isPinned={isPinnedInitiative(initiative)}
                                hasPoints={hasPoints && initiative.points > 0}
                                hasProgram={hasProgram && initiative.program}
                              />
                            ))
                          }
                        </section>
                      </>
                    )
                    }
                  />
              )
            }
          </Sentry.ErrorBoundary>
          <NavBottom />
        </Layout.Content>
      </Layout>
    </>
    )
}

export default injectIntl((withRouter(Wall)));
