import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'antd';

const InitiativeRating = ({
  rating,
}) => (
  <div className="rating">
    <FontAwesomeIcon icon="star"/>
    <Badge count={Number(rating).toFixed(1)}/>
  </div>
);

export default InitiativeRating;
