import React from 'react';
import { injectIntl } from 'react-intl';

const TargetAudience = ({ intl, targetAudience = [] }) => {
  return (
    <>
      <div className="title">
        { intl.formatMessage({ id: `target_audience` }) }
      </div>
      <div className="items">
        { targetAudience.length
          ? targetAudience.map(item =>
              <div className="item" key={item}>{ intl.formatMessage({ id: `target_audience_${item}` }) }</div>
            )
          : intl.formatMessage({ id: `none` })
        }
      </div>
    </>
  );
};

export default injectIntl(TargetAudience);
