import React from 'react';
import { injectIntl } from 'react-intl';

import Rating from 'components/Rating';

import {
  List,
  Avatar,
} from 'antd';
import {
  GlobalOutlined,
} from '@ant-design/icons';


const Organization = ({
  intl,
  collaborators = [],
  hideRating = false
}) => {
  return (
    <List className="InitiativeDetail-collaborators" renderHeader={() => intl.formatMessage({ id: `collaborators` })}>
      <div className="title">{intl.formatMessage({ id: `collaborators` })}</div>
      { collaborators.map(({ name, slug, logo_small, score, score_count }) => (
        <List.Item key={slug}>
          <List.Item.Meta
            avatar={<Avatar icon={<GlobalOutlined />} src={logo_small} />}
            title={ name }
            description={ hideRating || !score_count ? null :
              <>
                <Rating value={score} />
                <span>({ score_count })</span>
              </>
            }
          />
        </List.Item>
      ))}
    </List>
  );
};

export default injectIntl(Organization);

