import React, {
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Switch, Route, withRouter } from 'react-router'
import {
  Alert,
  notification,
} from 'antd';

import validatePasswordPolicy from 'utils/validatePasswordPolicy';

import {
  signup,
  resetAuth,
  resetAuthError,
  resendConfirmationEmail,
  requestOrganizationByToken,
  requestOrganizationByInitiative,
} from 'actions/auth';
import CorporateSignUp from './screens/CorporateSignUp';
import CorporateSignUpRedirect from './screens/CorporateSignUpRedirect';
import BasicSignUp from './screens/BasicSignUp';
import PasswordSignUp from './screens/PasswordSignUp';
import PublicOrganizationSignUp from './screens/PublicOrganizationSignUp';
import EmailConfirmationSignUp from './screens/EmailConfirmationSignUp';
import SsoConfirmationSignUp from './screens/SsoConfirmationSignUp';

import validation from './validation';
import './style.less';

import useForm from 'utils/useForm';

const SignUp = ({
  intl,
  auth,
  organization,
  signup,
  history,
  location,
  email_confirmation,
  corporate_signup,
  resetAuth,
  resetAuthError,
  resendConfirmationEmail,
  requestOrganizationByToken,
  requestOrganizationByInitiative,
}) => {
  const push = history.push;
  const t = intl.messages;

  useEffect(() => {
    resetAuthError();
    if(auth.missing) {
      push(`/signup/${auth.missing}`, location.state);
    }
  }, [
    auth.missing,
    push,
    resetAuthError,
    location.state,
  ]);

  const {
    public_organizations = [],
    loading,
    error,
  } = auth;

  const submitForm = () => {
    resetAuthError();
    const redirectState = (location && location.state && location.state.referrer) || null;
    signup(
      values.name.trim(),
      values.email,
      values.password || null,
      values.agreeTC,
      values.agreeComms,
      intl.locale,
      corporate_signup.token || null,
      values.public_organization_slug || null,
      redirectState,
    );
  };

  const validateForm = useMemo(() => validation(t), [ t ]);

  const {
    values,
    handleChange,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
  });

  const nameError = !isDirty('name') && errors.name;
  const emailError = error === 409 || error === 412 || (!isDirty('email') && errors.email);

  const passwordError = useMemo(() => {
    if(!values.password || !auth || !auth.password_policy) {
      return null;
    }
    return validatePasswordPolicy(
      intl,
      auth.password_policy,
      values.email,
    )(values.password)
  }, [
    intl,
    auth,
    values,
  ]);

  const showError = useMemo(() => {
    // 409 = User already exists
    // 412 = User is pending email confirmation
    const showErrorTip = error === 409 || error === 412;
    const errorAction = () => {
      if(!showErrorTip) return;

      if(error === 409) {
        push('/signin', location.state);
      } else {
        notification.open({message: t.re_send_email_done, duration: 1});
        resendConfirmationEmail(values.email);
        resetAuthError();
      };
    };

    let errorText = error ? (t[`signup_error_${error}`] || t.signup_error_default) : (nameError || emailError || passwordError);

    if (showErrorTip) {
      errorText += ' - ' + t[`signup_error_${auth.error}_tip`];
    }

    if(auth.error || nameError || emailError || passwordError) {
      return (
        <Alert
          message={errorText}
          type="error"
          onClick={errorAction}
          showIcon
        />
      );
    }
    return <div style={{ height: '36px' }}></div>;
  }, [
    t,
    error,
    values.email,
    auth.error,
    nameError,
    emailError,
    passwordError,
    resendConfirmationEmail,
    resetAuthError,
    push,
    location.state,
  ]);

  const handleFieldChange = {
    name: useCallback((name) => {
      if(error) resetAuthError();
      handleChange('name')(name);
    }, [
      error,
      handleChange,
      resetAuthError,
    ]),

    email: useCallback((email) => {
      if(error) resetAuthError();
      handleChange('email')((email || '').toLowerCase().trim());
    }, [
      error,
      handleChange,
      resetAuthError,
    ]),

    public_organization_slug: useCallback((public_organization_slug) => {
      if(error) resetAuthError();
      handleChange('public_organization_slug')(public_organization_slug);
      handleSubmit();
    }, [
      error,
      handleChange,
      handleSubmit,
      resetAuthError,
    ]),

    password: useCallback((password) => {
      if(error) resetAuthError();
      handleChange('password')(password);
    }, [
      error,
      handleChange,
      resetAuthError,
    ]),

    agreeTC: handleChange('agreeTC'),

    agreeComms: handleChange('agreeComms'),
  };

  const reset = () => {
    handleChange('name')('');
    handleChange('email')('');
    handleChange('public_organization_slug')('');
    handleChange('password')('');
    handleChange('agreeTC')(false);
    handleChange('agreeComms')(false);
    resetAuth();
    push('/signup');
  };

  const props = {
    t,
    intl,
    history,
    organization,
    values,
    handleFieldChange,
    handleSubmit,
    showError,
    nameError,
    emailError,
    passwordError,
    reset,
    resetAuthError,
    resendConfirmationEmail,
    requestOrganizationByToken,
    requestOrganizationByInitiative,
    error,
    loading,
    email_confirmation,
    corporate_signup,
    public_organizations,
    locationState: location.state,
    ssoSignupUrl: auth.sso_signup_url,
    loggedIn: auth.logged_in,
    password_policy: auth.password_policy,
  };

  return (
    <Switch>
      <Route path="/signup/password" render={() => <PasswordSignUp {...props} />} />
      <Route path="/signup/organization" render={() => <PublicOrganizationSignUp {...props} />} />
      <Route path="/signup/email_confirmation" render={() => <EmailConfirmationSignUp {...props} />} />
      <Route path="/signup/sso_confirmation" render={() => <SsoConfirmationSignUp {...props} />} />
      <Route path="/signup/c/:organization_token" render={() => <CorporateSignUp {...props} />} />
      <Route path="/signup/i/:initiative_slug/:organization_token?" render={() => <CorporateSignUpRedirect {...props} />} />
      <Route path="/signup/:organization_token" render={() => <CorporateSignUpRedirect {...props} />} />
      <Route render={() => <BasicSignUp {...props} />} />
    </Switch>
    )
}

const mapStateToProps = ({
  auth = { defaults: {} },
  organization = {},
  email_confirmation,
  corporate_signup,
}) => ({
  auth,
  organization,
  email_confirmation,
  corporate_signup,
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      signup,
      resetAuth,
      resetAuthError,
      resendConfirmationEmail,
      requestOrganizationByToken,
      requestOrganizationByInitiative,
    }
  )(withRouter(SignUp))
);
