import React, { useEffect } from 'react';
import {
  Row,
  Col,
  notification,
} from 'antd';

import QuoteWall from 'components/QuoteWall';
import NavTopBack from 'components/NavTopBack';
import OrganizationLogo from 'components/OrganizationLogo';
import A from 'components/A';
import T from 'components/T';

const EmailConfirmationSignUp = ({
  t,
  organization,
  values,
  reset,
  email_confirmation,
  resendConfirmationEmail,
}) => {
  const {
    requested,
    loading,
  } = email_confirmation;

  useEffect(() => {
    if(!values.email) reset();
  }, [values.email, reset]);

  useEffect(() => {
    if(requested) {
      notification.open({message: t.re_send_email_done , duration: 1});
    }
  }, [requested, t]);

  return (
    <Row className="SignUp SignUp-emailConfirmation">
      <NavTopBack onClick={reset} />
      <Col xs={24} sm={12}>
        <Row justify="center">
          <Row justify="center" align="middle">
            <h4>
              <T
                signup_emailconfirmation_welcome_to_org={{
                  organization: organization.name
                }}
              />
            </h4>
            <OrganizationLogo className="RecoverPassword-corporatelogo" {...organization} />
          </Row>
          <Col span={24}>
            <Col>
              <h4>
                <T
                  signup_emailconfirmation_we_have_sent_email={{
                    email: values.email,
                  }}
                />
              </h4>
            </Col>
            <Col>
              <div>{ t.signup_emailconfirmation_thank_you }</div>
            </Col>
          </Col>
          <Row gutter={[0, 50]}>
            <Col span={24}>
              <A onClick={() => (!loading && !requested && resendConfirmationEmail(values.email)) }>{ t.re_send_email }</A>
            </Col>
            <Col span={24}>
              <A onClick={reset}>{ t.dont_want_this_community }</A>
            </Col>
          </Row>
        </Row>
      </Col>
      <Col xs={0} sm={12} className="cover">
        <QuoteWall organization={organization} />
      </Col>
    </Row>
  );
}

export default EmailConfirmationSignUp;
