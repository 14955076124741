import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { InputNumber } from 'antd';


const DECIMAL_SEPARATOR = {
  'es': ',',
  'es-ES': ',',
  'pt-PT': ',',
  'pt': ',',
  'pt-BR': ',',
  'fr-FR': ',',
  'ca-ES': ',',
  'eu-ES': ',',
  default: '.',
};
const TRUE_DECIMAL_SEPARATOR = '.';
const NOT_NUMBER_OR_DECIMAL = new RegExp('[^\\d.]', 'g');

const removeAdditionalDots = (str) => {
  let n = 0;
  return (str || '').replace(
    /\./g,
    () => n++ > 0 ? '' : '.'
  );
};


const Amount = ({
  intl,
  contributionAmount,
  setContributionAmount,
  fixedAmounts = [],
  currency,
}) => {
  const decimalSeparator = DECIMAL_SEPARATOR[intl.locale] || DECIMAL_SEPARATOR.default;

  const handleAmountChange = useCallback((event) => {
    const amount = parseInt(event.target.value || 0, 10);
    setContributionAmount(amount);
  }, [ setContributionAmount ]);

  const inputFontSize = useMemo(() => {
    const DEFAULT_SIZE = 70;
    // Adapting font size so that the entire amount is always visible.
    if(contributionAmount) {
      return Math.max(30, DEFAULT_SIZE - contributionAmount.toString().length * 10);
    }
    return DEFAULT_SIZE;
  }, [ contributionAmount ])

  return (
    <>
      <div className="ContributionScreen__input">
        <InputNumber
          style={{fontSize: inputFontSize}}
          placeholder="0"
          value={contributionAmount || ''}
          decimalSeparator={decimalSeparator}
          onChange={value => setContributionAmount(value)}
          formatter={
            value => value?.replace(/^0+/, '').replace(TRUE_DECIMAL_SEPARATOR, decimalSeparator)
          }
          parser={value => removeAdditionalDots(
            value.replace(/[^0-9|.|,]/g, '').replace(decimalSeparator, TRUE_DECIMAL_SEPARATOR).replace(NOT_NUMBER_OR_DECIMAL, '')
          )}
        />
        <span>{ currency }</span>
      </div>
      <div className="ContributionScreen__radiocontainer-h">
        { !fixedAmounts || fixedAmounts.length === 0
          ? null
          : fixedAmounts.map((amount, index) => (
            <div className="ContributionScreen__radio" key={index}>
              <input
                type="radio"
                key={amount}
                id={`radio-${amount}`}
                name="amount"
                value={amount}
                checked={contributionAmount === amount}
                onChange={handleAmountChange}
              />
              <label htmlFor={`radio-${amount}`}>
                { amount }&nbsp;{ currency }
              </label>
            </div>
          ))
        }
      </div>
    </>
  );
};

export default injectIntl(Amount);

