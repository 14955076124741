import React from 'react';
import { injectIntl } from 'react-intl';

import Sdgs from '../cards/Sdgs';
import Organization from '../cards/Organization';

import InitiativeRating from './InitiativeRating';

import {
  Row,
  Col,
} from 'antd';

const DEFAULT_IMAGE = 'https://aplanet-static.ams3.digitaloceanspaces.com/assets/default_initiative_image.jpg';

const Header = ({
  image,
  title,
  sdgs,
  organization_logo_small,
  organization_score,
  initiative_score,
  hideRating = false,
  organization_score_count,
  initiative_score_count
}) => {

  return (
    <Row className="header" type="flex">
      <Col className="image-section" xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
        {!hideRating && !!initiative_score_count && (
          <div className="initiative-rating">
            <InitiativeRating rating={initiative_score}/>
          </div>
        )}
        <img alt="" src={image || DEFAULT_IMAGE} />
      </Col>

      <Col className="title-section" xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
        <div className="title">
          { title }
        </div>

        <div className="bottom-section">
          <div className="sdgs">
            <Sdgs sdgs={sdgs}/>
          </div>

          {!hideRating && !!organization_score_count && (
            <div className="organization-rating">
              <Organization
                organization_logo_small={organization_logo_small}
                organization_score={organization_score}
              />
            </div>
          )}
          
        </div>
      </Col>
    </Row>
  );
};

export default injectIntl(Header);
