import React from 'react';
import { injectIntl } from 'react-intl';

import { Row, Col, Card } from 'antd';

const ProposalCard = ({
  title,
  header,
  created_at,
  status,
  intl,
  reject_reason,
  formatDateLong
}) => {
  return (
    <Row className="Proposals-proposalcard-row">
      <Col span={24}>
        <Card className="Proposals-proposalcard">
          <div className={`Proposals-proposalcard-header ${status}`}>
            <div className="header-title">
              {header}: {reject_reason}
            </div>
            <div className="header-date">
              {formatDateLong(created_at, intl)}
            </div>
          </div>
          <div className="Proposals-proposalcard-body">
            {title}
          </div>
        </Card>
      </Col>
    </Row>
  );
}

export default injectIntl(ProposalCard);
