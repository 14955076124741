import React, { useState, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import {
  Button,
} from 'antd';
import {CustomTextArea} from "aplanet-ui-kit";

const MAX_DESCRIPTION_CHARACTERS = 300;

const Description = ({
  intl,
  description,
}) => {
  const [forceShowDescription, setForceShowDescription] = useState(false)

  const isDescriptionLong = useMemo(
    () => description.length > MAX_DESCRIPTION_CHARACTERS,
    [description]
  );

  return (
    <div className="action-description">
      <span className="title">{ intl.formatMessage({ id: `description` }) }</span>
      <span className={forceShowDescription ? 'description' : 'description-showMore'}>
        <CustomTextArea
          intl={intl}
          readOnly
          value={description}
        />
      </span>
      <div className="showMore">
        { isDescriptionLong &&
          <>
          {!forceShowDescription &&
            <figure className="shadow"></figure>
          }
          <Button
            className="secondary-button"
            onClick={() => setForceShowDescription(!forceShowDescription)}>
            <span>{forceShowDescription ? intl.formatMessage({ id: `showLess` }) : intl.formatMessage({ id: `showMore` })}</span>
          </Button>
         </>
        }
        </div>
    </div>
  );
};

export default injectIntl(Description);
