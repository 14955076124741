import React from 'react';
import { injectIntl } from 'react-intl';

import {
  Layout,
  Row,
  Col,
  Button,
  Input,
} from 'antd';

import Nav from 'containers/Nav';
import AppHeader from 'containers/AppHeader';
import NavBottom from 'containers/NavBottom';
import NewProposalHeader from 'components/NewProposalHeader/NewProposalHeader';

const Title = ({
  intl,
  values,
  handleChangeEvent,
  showError,
  next,
}) => {
  const t = intl.messages;

  return (
    <>
      <AppHeader />
      <Layout>
        <Nav />
        <Layout.Content>
          <NewProposalHeader
            stepCount={1}
          />
          <Row className="ProposalInitiative-title">
            <Col span={24} className="ProposalInitiative-input">
              <Input
                value={values.title}
                name='title'
                onChange={handleChangeEvent}
                placeholder={ t.proposal_title_placeholder }
                required
              />
            </Col>
            <Col offset={14} span={10} className="ProposalInitiative-limit">
              <span>75 characters max.</span>
            </Col>
            <Col span={24}>
              { showError('title') }
            </Col>
          </Row>
          <Row className="ProposalInitiative-button-next">
            <Col span={24}>
              <Button
                type="primary"
                onClick={next}
              >
                { t.next }
              </Button>
            </Col>
          </Row>
          <NavBottom />
        </Layout.Content>
      </Layout>
    </>
    );
};

export default injectIntl(Title);

