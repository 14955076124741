import React from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
} from 'antd';

const Success = ({
  intl,
  source = 'transfer',
  title,
  amount,
  currency,
}) => {
  const t = intl.messages;
  return (
    <Row justify="center">
      <Col className="ContributionScreen__big-title">
        { t.donation_success_title }
      </Col>
      <Col className="ContributionScreen__desc">
        { t.donation_success_desc }
      </Col>
    </Row>
  );
};

export default injectIntl(Success);

