import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import * as Sentry from '@sentry/react';
import {
  BrowserBanner,
  ErrorBoundaryFallback,
} from 'aplanet-ui-kit';
import { ConfigProvider } from 'antd';

import { saveState, loadState } from 'store/localForage';
import Loading from 'containers/Loading';
import Routes from 'containers/Routes';
import Onboarding from 'containers/Onboarding';
import { hydrateStore } from 'actions/store';
import {
  requestIdentity,
  requestOrganizationProfile,
} from 'actions/auth';
import { requestProfile } from 'actions/api';
import { FeatureProvider } from 'components/FeatureSwitch';
import AcceptTermsModal from 'components/AcceptTermsModal';
import GlobalChat from 'components/GlobalChat'
import config from 'config';
import {
  updateProfile,
} from 'actions/api';

import updateTheme from '../../utils/updateTheme';

const onResize = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const HOST_TO_ORG_MAPPING = {
  'fundacaoageas.voluntariadoempresa.pt': 'fundacao-ageas',
  'roletoplay-getinvolved.novasbe.pt': 'nova-sbe',
  'voluntariado.aesbrasil.com.br': 'plataforma-de-voluntariado-aes-brasil',
  'imaginchangers-app.aplanet.org': 'imagin',
  //'localhost:3000': 'nova-sbe',
  //'localhost:3000': 'aplanet',
};

const Root = ({
  intl,
  auth,
  organization,
  onboarding,
  hydrateStore,
  requestIdentity,
  requestProfile,
  requestOrganizationProfile,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();

  const [hydrated, setHydrated] = useState(false);
  const [refreshStarted, setRefreshStarted] = useState(false);
  const [requested, setRequested] = useState(false);
  const [orgProfileRequested, setOrgProfileRequested] = useState(false);
  const [orgProfileLoaded, setOrgProfileLoaded] = useState(false);

  // Save state upon auth/org change
  useEffect(() => {
    saveState({ onboarding });
  }, [onboarding]);

  // Request public org profile if we need it
  useEffect(() => {
    if(HOST_TO_ORG_MAPPING[window.location.host]) {
      setOrgProfileRequested(true);
      requestOrganizationProfile(
        HOST_TO_ORG_MAPPING[window.location.host]
      );
    }
  }, [
    requestOrganizationProfile,
  ]);

  // Load state when loading the app
  useEffect(() => {
    loadState()
      .then(hydrateStore)
      .then(() => setHydrated(true));
  }, [ hydrateStore ]);

  // On resize
  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  // Refresh access token when loading the app
  useEffect(() => {
    requestIdentity();
    setRefreshStarted(true);
  }, [ requestIdentity ]);

  // Request the profile upon login / org changes
  useEffect(() => {
    const theme = organization.config?.theme;
    updateTheme(theme);

    if(orgProfileRequested) {
      // NOTICE: Half a second to load the theme
      setTimeout(() => setOrgProfileLoaded(true), 500);
    }

    if(auth.logged_in && (!requested || auth.refreshProfile)) {
      setRequested(true);
      requestProfile(organization.slug);
    }
  }, [
    auth.logged_in,
    auth.refreshProfile,
    requested,
    organization,
    requestProfile,
    orgProfileRequested,
  ]);

  const isReady = hydrated && refreshStarted && (auth.logged_in || !auth.refreshing_token) && (
    !auth.logged_in || requested
  ) && (
    !orgProfileRequested ||
    (!organization.fetching && orgProfileLoaded)
  );

  const {
    data: profile
  } = useSelector(state => state.my_profile);

  const acceptTerms = useCallback(
    (termType) => dispatch(updateProfile(organization.slug, {[termType]: true})),
    [organization.slug, dispatch]
  );

  return (
    <Sentry.ErrorBoundary
      fallback={
      <ErrorBoundaryFallback
        titleErrorMessage={intl.formatMessage({id:'error_boundary_title_message'})}
        buttonLabel={intl.formatMessage({id:'error_boundary_reload_button'})}
        descriptionErrorMessage={intl.formatMessage({id:'error_boundary_default_message'})}
        customErrorImage="/images/error_image.png"
      />
    }>
      <>
        <GlobalChat/>
        <BrowserBanner intl={intl} />
        <ConfigProvider locale={intl.formats.antd}>
          <FeatureProvider value={new Set(organization.features || config.DEFAULT_FEATURES)}>
            {
              !isReady
              ? <Loading />
              : <React.Fragment>
                  <Routes />
                  { !auth.logged_in ? null : <Onboarding /> }
                </React.Fragment>
            }
            {isReady && auth.logged_in &&
            <>
              <AcceptTermsModal
                title={t.terms_and_conditions_title}
                body={t.terms_and_conditions_body}
                lastTermsVersion={profile && profile.last_terms_and_conditions_version}
                lastTermsAccepted={profile && profile.last_terms_accepted}
                onAccept={() => acceptTerms('last_terms_accepted')}
                closable={false}
              />
              <AcceptTermsModal
                title={t.volunteering_policy}
                body={profile?.organization?.terms}
                lastTermsVersion={profile?.organization?.org_last_terms_version}
                lastTermsAccepted={profile && profile.org_last_terms_accepted}
                onAccept={() => acceptTerms('org_last_terms_accepted')}
                privacyEmail={organization?.general_config?.contact_email}
                closable={false}
              />
            </>
            }
          </FeatureProvider>
        </ConfigProvider>
      </>
    </Sentry.ErrorBoundary>
  );
}

const mapStateToProps = ({
  auth,
  organization,
  onboarding,
}) => ({
  auth,
  organization,
  onboarding,
});

export default connect(
  mapStateToProps,
  {
    hydrateStore,
    requestIdentity,
    requestProfile,
    requestOrganizationProfile,
  }
)(
  injectIntl(Root)
);
