import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';
import {
  List,
  Modal,
  Input,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Checkbox from 'components/Checkbox';

import './style.less';

import useSetState from 'utils/useSetState';

const CohortSelector = ({
  onboardingMode = false,
  intl,
  values = [],
  visible,
  onClose,
  onSave,
  loading,
  isRequired,
}) => {
  const t = intl.messages;
  const [ searchValue, setSearchValue ] = useState('');

  const initialCohorts = useMemo(() =>
    values.filter(({ member }) => member).map(({ slug }) => slug)
  , [ values ]);

  const filteredCohorts = useMemo(() =>
    values.filter(({name}) => name.toLowerCase().includes(
      searchValue.toLowerCase()
    ))
  , [searchValue, values]);

  const {
    has: isSelected,
    toggle: toggleSelected,
    set: selectedCohorts,
  } = useSetState( initialCohorts );

  const [ saving, setSaving ] = useState(false);

  const handleSave = useCallback(() => {
    const initialSet = new Set(initialCohorts);
    if(
      initialCohorts.length === selectedCohorts.length &&
      selectedCohorts.every(slug => initialSet.has(slug))
    ) {
      // Bail out
      return onClose();
    }

    setSaving(true);
    onSave(selectedCohorts);
  }, [
    initialCohorts,
    selectedCohorts,
    onClose,
    onSave,
  ]);

  useEffect(() => {
    if(saving && !loading) {
      setSaving(false);
      onClose();
    }
  }, [
    saving,
    loading,
    onClose,
  ]);

  return (
    <Modal
      className="Onboarding__modal cohort-selector"
      onOk={handleSave}
      onCancel={onClose}
      okText={t.save}
      cancelText={onboardingMode ? t.continue_without_this : t.cancel}
      visible={visible}
      animationType="slide-up"
      title={t.cohortselector_title}
      cancelButtonProps={{ style: { display: isRequired ? 'none' : 'initial' } }}
      maskClosable={!isRequired}
      keyboard={!isRequired}
      closable={!isRequired}
    >
      <div className="Onboarding__modal-inner cohort-selector-body">
        <Input
          placeholder={t.initiative_search_placeholder}
          onChange={(e) => setSearchValue(e.target.value)}
          suffix={<FontAwesomeIcon icon="search" />}
        />
        <List>
          {filteredCohorts.map(({ name, slug }) => (
            <Checkbox
              key={slug}
              checked={isSelected(slug)}
              onClick={() => toggleSelected(slug)}
              title={name}
            />
          ))}
        </List>
      </div>
    </Modal>
  );
};

export default injectIntl(CohortSelector);
