const TARGET = 'initiative_detail';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(action.target !== TARGET) return state;
      return {
        data: action.method === 'GET' ? null : state.data,
        loading: true,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || action.response.target !== TARGET) return state;
      return {
        data: action.response.result,
        loading: false,
        error: null,
      };
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        data: null,
        loading: false,
        error: action.code,
      };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as initiative_detail,
};
