import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import SdgLogo from 'components/SdgLogo';

import {
  Popover,
} from 'antd';

const Sdgs = ({ sdgs = [] }) => {
  const [showSdgPopover, setshowSdgPopover] = useState(false);

  return (
    <Popover
      placement="bottom"
      trigger="hover"
      onVisibleChange={visible => setshowSdgPopover(visible)}
      visible={showSdgPopover && sdgs.length > 3}
      content={
        <div className="sdgs-popover-content">
          {sdgs.slice(3).map(sdg =>
            <SdgLogo key={sdg} sdg={sdg} />
          )}
        </div>
      }
    >
      {sdgs.slice(0, 3).map(sdg =>
        <SdgLogo key={sdg} sdg={sdg} />
      )}
    </Popover>
  )
};

export default injectIntl(Sdgs);
