import React from 'react';
import { injectIntl } from 'react-intl';

import ReactMarkdown from 'react-markdown'

import {
  Modal,
} from 'antd';

import useOpenInNewWindow from 'utils/useOpenInNewWindow';

const AcceptTermsModal = ({
  intl,
  title,
  body,
  lastTermsVersion,
  lastTermsAccepted,
  onAccept,
  privacyEmail,
  ...props
}) => {
  const t = intl.messages;
  const openInNewWindow = useOpenInNewWindow();

  return (
    <Modal
      className="Terms-modal"
      visible={lastTermsAccepted < lastTermsVersion}
      onOk={onAccept}
      okText={t.accept}
      cancelText={t.contact_us}
      onCancel={() => { openInNewWindow(`mailto:${privacyEmail || 'privacy@aplanet.org'}`) }}
      title={title}
      {...props}
    >
      <ReactMarkdown
        linkTarget="_blank"
      >
        { body }
      </ReactMarkdown>
    </Modal>
  );
}

export default injectIntl(AcceptTermsModal);
