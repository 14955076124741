import React, { useState, useMemo } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import NavTopBack from 'components/NavTopBack';
import Header from '../cards/Header';
import When from '../cards/When';
import Collaborators from '../cards/Collaborators';
import Submitter from '../cards/Submitter';
import Description from '../cards/Description';
import Buttons from '../cards/InformativeButtons';
import Address from '../cards/Address';
import Categories from '../cards/Categories';
import InitiativeScore from '../cards/InitiativeScore';
import TargetAudience from '../cards/TargetAudience';
import InitiativeTags from '../cards/Tags';
import Indicators from '../cards/Indicators';
import Organizer from '../cards/Organizer';
import AppHeader from 'containers/AppHeader';
import Nav from 'containers/Nav';
import RatingScreen from 'components/RatingScreen';
import AttachmentDocuments from '../cards/AttachmentDocuments';
import AttachmentImages from '../cards/AttachmentImages';
import Feedback from '../cards/Feedback';

import {
  Row,
  Col,
  Alert,
  Layout,
} from 'antd';

const Informative = ({
  history,
  notGoing,
  join,
  interested,
  intl,
  loading,
  profile,
  feedback_url,
  submitScores,
  participant_initiative_score = 0,
  participant_organization_score = 0,
  hideRating,
  evaluationConfig,
  ...initiative
}) => {
  const [ showRatingDrawer, setShowRatingDrawer ] = useState(false);

  const showRatingScreen = useMemo(() => {
    const {
      hide_evaluate_initiative,
      hide_evaluate_organizer
    } = evaluationConfig;
    return showRatingDrawer && (!hide_evaluate_initiative || !hide_evaluate_organizer || feedback_url);
  }, [
    showRatingDrawer,
    evaluationConfig,
    feedback_url
  ]);

  return (
    <Layout>
      <AppHeader/>
      <Layout>
        <Nav/>
        <Layout.Content>
          <NavTopBack onClick={history.goBack} text={intl.formatMessage({ id: `back` })}/>
          <Header
            hideRating={hideRating}
            {...initiative}
            leave={notGoing}
          />

          <div className="initiative-details">
            <Row>
              <Col className="main-section" xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
                {initiative.isPastInitiative &&
                <Alert message={intl.formatMessage({ id: `initiative_expired_warning` })} type="warning" showIcon />
                }
                <Row>
                  <Col>
                    <Description description={initiative.description} />
                  </Col>
                </Row>

                <Row justify="space-between" gutter={[20, 40]}>
                   { initiative.categories && !!initiative.categories.length &&
                     <div className="categories">
                       <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                         <Categories competences={initiative.competences} />
                       </Col>
                     </div>
                   }

                   { initiative.target_audience && !!initiative.target_audience.length &&
                     <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                       <div className="target-audience">
                         <TargetAudience targetAudience={initiative.target_audience} />
                       </div>
                     </Col>
                   }

                  { (
                      initiative.own_interested
                      || initiative.organization?.config?.show_attachments_before_participation
                    ) && !initiative.organization?.config?.hide_initiative_images &&
                    <Col span={24}>
                      <AttachmentImages {...initiative} />
                    </Col>
                  }

                  { (
                      initiative.own_interested
                      || initiative.organization?.config?.show_attachments_before_participation
                    ) && !initiative.organization?.config?.hide_initiative_documents &&
                    <Col span={24}>
                      <AttachmentDocuments {...initiative} />
                    </Col>
                  }
                </Row>
              </Col>

              <Col className="side-section" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                <div className="when">
                  <When {...initiative} />
                </div>

                <div className="tags">
                  { initiative.tags && !!initiative.tags.length &&
                    <InitiativeTags tags={initiative.tags} />
                  }
                </div>

                { initiative.submitter && <Submitter submitter={initiative.submitter} /> }

                <div className="address">
                  <Address {...initiative} />
                </div>

                { !!initiative.organization_score_count &&
                  <Organizer hideRating ={hideRating} {...initiative} />
                }

                { initiative.collaborators && !!initiative.collaborators.length &&
                  <Collaborators hideRating ={hideRating} {...initiative} />
                }

                {!hideRating && (
                  <div className="ratings">
                    <InitiativeScore
                      initiative_score={initiative.initiative_score}
                      initiative_score_count={initiative.initiative_score_count}
                    />
                  </div>
                )}

                { initiative.own_interested && !!feedback_url &&
                <div className="feedback">
                  <Feedback feedback_url={initiative.feedback_url} />
                </div>
                }
              </Col>
            </Row>

            { initiative.indicators && !!initiative.indicators.length &&
              <Indicators indicators={initiative.indicators} />
            }

            {!initiative.isPastInitiative &&
            <div className="initiative-buttons">
              <Buttons
                profile={profile}
                loading={loading}
                {...initiative}
                join={join}
                interested={interested}
                setShowRatingDrawer={setShowRatingDrawer}
              />
            </div>
            }
          </div>
        </Layout.Content>
      </Layout>
      <RatingScreen
        visible={showRatingScreen}
        onClose={() => setShowRatingDrawer(false)}
        feedback_url={feedback_url}
        submitScores={submitScores}
        hideRatingInitiative={evaluationConfig.hide_evaluate_initiative}
        hideRatingOrganizer={evaluationConfig.hide_evaluate_organizer}
        participant_initiative_score={participant_initiative_score}
        participant_organization_score={participant_organization_score}
      />
    </Layout>
  )
}

export default withRouter(
  injectIntl(Informative)
);
