import React, { useMemo, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  Row,
  Col,
  List,
  Layout,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Nav from 'containers/Nav';
import SettingsHeader from 'components/Settings/SettingsHeader';

const LanguageSettings = ({
  intl,
  updateProfile,
  organization,
  profile,
}) => {
  const t = intl.messages;

  const languages = useMemo(() => [
    'en-US',
    'es-ES',
    //'es-MX',
    //'fr',
    'pt-BR',
    'pt-PT',
    'eu-ES',
  ].map(value => ({ value, label: t[value] })), [
    t,
  ]);

  const updateLanguage = useCallback((language) => {
    updateProfile(organization.slug, { language });
  }, [
    updateProfile,
    organization.slug,
  ]);

  if (!profile) {
    return null;
  }

  return (
    <>
      <Layout>
        <Nav />
        <Layout.Content className="Settings-main">
          <SettingsHeader heading={t.language} to="/settings" />
          <Row align="middle" className="Settings-language">
            <Col span={24}>
              {languages.map(language => (
                <List.Item
                  key={language.value}
                  extra={
                    profile.language === language.value
                    ? <FontAwesomeIcon icon="check"/>
                    : null
                  }
                  onClick={() => updateLanguage(language.value)}
                >
                  {t[language.value]}
                </List.Item>
              ))}
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </>
  );
}

export default injectIntl(withRouter(LanguageSettings));
