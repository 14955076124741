import { loop, Cmd } from 'redux-loop';
import { history } from 'store';

const requestPush = async (url) => {
  history.push(url);
  return Promise.resolve();
};

const ENDPOINT_REGEX = new RegExp('^[a-z0-9_-]+/initiative/([a-z0-9_-]+)/interested$');
const initialState = null;

const reducer = (state = initialState, action) => {
  if(
    action.type === 'API_CALL_COMPLETE' &&
    action.response &&
    action.response.method === 'DELETE'
  )
  {
      const matches = ENDPOINT_REGEX.exec(action.response.endpoint);
      if(!matches || !matches[1]) return state;

      return loop(state, Cmd.run(requestPush, { args: [`/wall`] }));
  }
  return state;
};

export {
  reducer as initiative_not_interested_done,
};
