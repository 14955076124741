import React, {
  useState,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import { injectIntl } from 'react-intl';

import {
  List,
  Modal,
  Input,
} from 'antd';

import './style.less';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  slugToUrl,
  urlToSlug,
  isValidUrl,
} from 'utils/social';

import T from 'components/T';

const ICON_PER_PROFILE = {
  linkedin: ["fab", "linkedin"],
  twitter: ["fab", "twitter-square"],
  github: ["fab", "github"],
  gitlab: ["fab", "gitlab"],
  facebook: ["fab", "facebook"],
  default: "user-circle",
};

const SocialNetworkInput = ({
  onboardingMode = false,
  intl,
  network,
  value = null,
  visible,
  onClose,
  onSave,
  loading,
  isRequired,
}) => {
  const t = intl.messages;
  const [ saving, setSaving ] = useState(false);

  // WARNING: Unexpected behavior if 'network' or 'value' do change
  const [url, setUrl] = useState(value ? slugToUrl(network)(value) : '');

  const isValid = useMemo(() => isValidUrl(network), [network]);

  const handleSave = useCallback(() => {
    if(!isValid(url)) {
      return;
    }

    const slug = urlToSlug(network)(url);
    setSaving(true);

    if(slug === value) {
      // Bail out
      return onClose();
    }

    setSaving(true);
    onSave(network, slug);
  }, [
    isValid,
    url,
    network,
    value,
    onClose,
    onSave,
  ]);

  useEffect(() => {
    if(saving && !loading) {
      setSaving(false);
      onClose();
    }
  }, [
    saving,
    loading,
    onClose,
  ]);

  return (
    <Modal
      className="Onboarding__modal"
      onOk={handleSave}
      onCancel={onClose}
      okText={t.save}
      cancelText={ onboardingMode ? t.continue_without_this : t.cancel }
      okButtonProps={{ disabled: !isValid(url) }}
      visible={visible}
      cancelButtonProps={{ style: { display: isRequired ? 'none' : 'initial' } }}
      maskClosable={!isRequired}
      keyboard={!isRequired}
      closable={!isRequired}
      title={<T socialnetworkinput_title={{ network: t[`social_network_${network}`] }} />}
    >
      <div className="Onboarding__modal-inner">
        <List
          header={<T socialnetworkinput_subtitle={{ network: t[`social_network_${network}`] }} />}
        >
          <Input
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder={t.socialnetworkinput_paste_here}
            prefix={
              <FontAwesomeIcon
                className="SocialNetworkInput__networkicon"
                icon={ICON_PER_PROFILE[network] || ICON_PER_PROFILE.default}
              />
            }
          />
        </List>
      </div>
    </Modal>
  );
};

export default injectIntl(SocialNetworkInput);
