import React from 'react';
import { injectIntl } from 'react-intl';

import Checkbox from 'components/Checkbox';

const Source = ({
  intl,
  sources = [],
  selected,
  onChoose,
}) => {
  const t = intl.messages;

  const patchedSources = (
    sources.includes('paypal')
      ? ['paypal_cards', ...sources]
      : sources
  );

  const ICON_PER_SOURCE = {
    paypal: ["fab", "paypal"],
    paypal_cards: "credit-card",
    transfer: "money-check-alt",
    external: "link",
    inhand: "hand-holding-usd",
    default: "money-bill-alt",
  };

  return (
    <div className="ContributionScreen__source">
      {patchedSources.map(source => (
          <Checkbox
            icon={ICON_PER_SOURCE[source] || ICON_PER_SOURCE.default}
            key={source}
            checked={selected === source}
            title={ t[`donation_${source}`] }
            onClick={() => onChoose(source)}
          />
      ))}
    </div>
  );
};

export default injectIntl(Source);

