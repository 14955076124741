import React, { forwardRef, useMemo } from 'react';
import ReactPhoneNumberInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import metadata from './metadata.custom.json';
import 'react-phone-number-input/style.css';
import { injectIntl } from 'react-intl';

import './style.less';
import colors from 'colors';
import Input from './Input';

const PhoneInput = ({ intl, error, ...props }) => {
  const InputComponent = useMemo(() => {
    return forwardRef((props, ref) => <Input inputRef={ref} error={error} {...props} />)
  }, [ error ]);

  return (
    <div className="PhoneInput">
      <ReactPhoneNumberInput
        flags={flags}
        metadata={metadata}
        inputComponent={InputComponent}
        internationalIcon={() => <FontAwesomeIcon icon="phone" color={colors['@color-icon-faded']} />}
        labels={intl.formats.reactPhoneNumber}
        {...props}
      />
    </div>
  );
}

export default injectIntl(PhoneInput);
