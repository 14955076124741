import React, {
  useCallback,
} from 'react';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.less';

// TODO: When we add ProptTypes, check we have either 'onClick' or 'to'
const NavTopBack = ({
  to,
  history,
  text,
  onClick,
}) => {
  const _onClick = useCallback((e) => {
    if(onClick) {
      onClick();
    } else if(to) {
      history.push();
    } else {
      history.goBack();
    }
  }, [onClick, to, history])

  return (
    <a href={to} onClick={_onClick} className="NavTopBack">
      <FontAwesomeIcon icon="chevron-left"/>
      <div className="back-text">{text}</div>
    </a>
  );
};

export default withRouter(NavTopBack);
