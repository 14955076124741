import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  Input,
  Button,
  Alert,
} from 'antd';

import { PasswordPolicyInfo } from 'aplanet-ui-kit';

import {
  fetchPasswordPolicy,
  confirmEmail,
  resetAuth,
  resetAuthError,
} from 'actions/auth';

import OrganizationLogo from 'components/OrganizationLogo';
import QuoteWall from 'components/QuoteWall';

import validatePasswordPolicy from 'utils/validatePasswordPolicy';

import './style.less';

const ConfirmEmail = ({
  intl,
  match,
  auth,
  organization,
  history,
  password_policy,
  email_confirmation,
  fetchPasswordPolicy,
  confirmEmail,
  resetAuth,
  resetAuthError,
}) => {
  const push = history.push;
  const t = intl.messages;
  const {
    error,
    loading,
  } = email_confirmation;
  const { token } = match.params;
  const [password, doSetPassword] = useState('');

  const setPassword = (password) => {
    if(error) {
      resetAuthError();
    }
    doSetPassword(password);
  };

  useEffect(() => {
    fetchPasswordPolicy(
      token,
      'email_confirmation',
    )
  }, [
    fetchPasswordPolicy,
    token,
  ]);

  const {
    loading: loadingPolicy,
    data: passwordPolicy,
  } = password_policy;

  const passwordError = useMemo(() => {
    if(!password || !passwordPolicy) {
      return null;
    }
    return validatePasswordPolicy(
      intl,
      passwordPolicy.policy,
      passwordPolicy.email,
    )(password)
  }, [
    intl,
    passwordPolicy,
    password,
  ]);

  const showError = useMemo(() => {
    // 401 = Token expired
    const showErrorTip = error === 401;
    const errorAction = showErrorTip ? (() => { resetAuthError() ; push('/signin'); }) : null;

    let errorText = error ? (t[`confirm_email_error_${error}`] || t.confirm_email_error_default) : passwordError;

    if (showErrorTip) {
      errorText += ' - ' + t[`confirm_email_error_${error}_tip`];
    }

    if(error || passwordError) {
      return (
        <Alert
          message={errorText}
          type="error"
          onClick={errorAction}
          showIcon
        />
      );
    }
    return <div style={{ height: '36px' }}></div>;
  }, [
    t,
    error,
    passwordError,
    push,
    resetAuthError,
  ]);

  const onSubmit = useCallback(
    () => {
      if(!password || loading || loadingPolicy) {
        return;
      }
      confirmEmail(token, password);
    },
    [
      password,
      token,
      loading,
      loadingPolicy,
      confirmEmail,
    ]
  );

  return (
    <Row className="ConfirmEmail">
      <Col xs={24} sm={12}>
        <Row>
          <Row justify="center" align="middle">
            <OrganizationLogo className="ConfirmEmail-corporatelogo" {...organization} />
          </Row>
          <Col span={24}>
            { showError }
            <div className="ConfirmEmail-input">
              <label>{t.choose_your_password}</label>
              <Input
                type="password"
                placeholder={ t.password }
                value={password}
                onPressEnter={() => onSubmit(token, password)}
                suffix={<PasswordPolicyInfo intl={intl} policy={passwordPolicy?.policy} />}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Button
          className="brand-button-primary cta"
          type="primary"
          disabled={!password || passwordError || loading || loadingPolicy}
          onClick={onSubmit}
        >{ t.save_password }</Button>
      </Col>
      <Col xs={0} sm={12} className="cover">
        <QuoteWall organization={organization} />
      </Col>
    </Row>
  );
};

const mapStateToProps = ({
  auth,
  organization,
  email_confirmation,
  password_policy,
}) => ({
  auth,
  organization,
  email_confirmation,
  password_policy,
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      fetchPasswordPolicy,
      confirmEmail,
      resetAuth,
      resetAuthError,
    }
  )(ConfirmEmail)
);
