import React from 'react';
import { injectIntl } from 'react-intl';
import { hoursPerDay, formatDateAndTime } from 'utils/date';

const When = ({
  intl,
  start_time,
  end_time,
  hours_per_day,
  showExtra = true,
}) => {
  const [ hoursToShow, isPerDay ] = hoursPerDay(start_time, end_time, hours_per_day);
  const { date: start_date, time: start_hour } = formatDateAndTime(start_time, intl);
  const { date: end_date, time: end_hour } = formatDateAndTime(end_time, intl);

  return (
    <>
      <div className="title">{ intl.formatMessage({ id: `when` }) }</div>
      <div className="show-extra">
        { showExtra && hoursToShow && `${hoursToShow} ${isPerDay ? intl.formatMessage({ id: `hours_per_day` }) : intl.formatMessage({ id: `hours` })}` }
      </div>
      <div className="date">{ `${start_date}, ${start_hour}` }</div>
      <div className="date"> { `${end_date}, ${end_hour}` }</div>
    </>
  );
};

export default injectIntl(When);

