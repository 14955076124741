import React, { useMemo, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Anchor,
  Layout,
  Menu,
} from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { useFeatureList } from 'components/FeatureSwitch';
import A from 'components/A';

import useScreenSize from 'utils/useScreenSize';

import { toggleSidebar } from 'actions/app';

import { ReactComponent as HomeIcon } from 'assets/icons/icon-home.svg';
import { ReactComponent as ProposalsIcon } from 'assets/icons/icon-proposals.svg';
import { ReactComponent as StarsIcon } from 'assets/icons/icon-stars.svg';
import { ReactComponent as MegaphoneIcon } from 'assets/icons/icon-megaphone.svg';
import { ReactComponent as GearsIcon } from 'assets/icons/icon-gears.svg';
import { ReactComponent as ContactUsLogo } from 'assets/icons/icon-contact-us.svg';
import {
  InfoCircleOutlined,
} from "@ant-design/icons";

import './style.less';

const Nav = ({
  intl,
  location,
}) => {
  const t = intl.messages;
  const featureList = useFeatureList();
  const selected = [location.pathname.split('/')[1]];

  const organization = useSelector(state => state.organization);

  const tabs = useMemo(() => [
    {
      key: 'wall',
      icon: <HomeIcon className="anticon" />,
      route: '/wall',
      text: t.wall_nav
    },
    {
      key: 'initiative',
      icon: <MegaphoneIcon className="anticon" />,
      route: '/initiative',
      text: t.initiative_nav
    },
    (
      featureList.has('proposals')
      ? {
          key: 'proposal',
          icon: <ProposalsIcon className="anticon" />,
          route: '/proposal',
          text: t.proposals_nav
        }
      : null
    ),
    (
      featureList.has('responsible_profile')
      && {
        key: 'responsible_profile',
        icon: <FontAwesomeIcon size="lg" icon="address-card" className="anticon" />,
        route: '/responsible_profile',
        text: t.responsible_profile_nav
      }
    ),
    (
      featureList.has('rankings') && !organization.config.hide_ranking_in_app
      ? {
          key: 'rankings',
          icon: <StarsIcon className="anticon" />,
          route: '/rankings',
          text: t.rankings_nav
        }
      : null
    ),
    (
      featureList.has('about_our_program')
      ? {
          key: 'about_our_program',
          icon: <InfoCircleOutlined className="about-our-program-icon anticon" />,
          route: '/about-our-program',
          text: t.about_our_program
        }
      : null
    ),
    {
      key: 'settings',
      icon: <GearsIcon className="anticon" />,
      route: '/settings',
      text: t.settings_nav
    },
  ].filter(Boolean), [featureList, t, organization.config.hide_ranking_in_app]);

  const contactEmail = useMemo(
    () => {
      return organization?.general_config?.contact_email
        || `${ t.contact_email_address }@aplanet.org`;
    },
    [organization, t]
  );

  return (
    <Menu
      className="nav"
      mode="vertical"
      theme="light"
      selectable={false}
      selectedKeys={selected}
    >
      {
        tabs.map(({key, icon: Icon, route, text})=> (
          <Menu.Item key={key} icon={Icon}>
            <Link to={route}>
              <span className="navLabel">{text}</span>
            </Link>
          </Menu.Item>
        ))
      }
      <Menu.Item key="contact-us" icon={<ContactUsLogo className="anticon"/>}>
        <A href={`mailto:${ contactEmail }`}>
          <span className="navLabel">{t.contact_us}</span>
        </A>
      </Menu.Item>
    </Menu>
  )
}

const MainSiderNav = ({
  intl,
  location,
}) => {
  const dispatch = useDispatch();
  const screenSize = useScreenSize();

  const {
    isSidebarCollapsed,
  } = useSelector(state => state.app);

  const isCollapsed = useMemo(() => {
    if(isSidebarCollapsed !== undefined) {
      return isSidebarCollapsed;
    }
    return ['xs', 'sm', 'md'].includes(screenSize);
  }, [
    screenSize,
    isSidebarCollapsed,
  ]);

  const onCollapse = useCallback((collapsed) => {
    dispatch(toggleSidebar());
  }, [
    dispatch,
  ]);

  return (
    <Layout.Sider
      theme="light"
      collapsible
      collapsed={isCollapsed}
      trigger={
        isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
      }
      onCollapse={onCollapse}
    >
      <Anchor offsetTop={24}>
        <Nav intl={intl} location={location} />
      </Anchor>
    </Layout.Sider>
  );
}


const mapStateToProps = ({
  proposals,
}) => ({
  proposals,
});

export default injectIntl(connect(mapStateToProps)(withRouter(MainSiderNav)));

export {
  Nav,
}
