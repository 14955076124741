import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryFallback } from 'aplanet-ui-kit';

import AppHeader from 'containers/AppHeader';
import Nav from 'containers/Nav';

import {
  Layout,
  Row,
  Col,
  Empty,
} from 'antd';

import './style.less';


const AboutOurProgram = ({
  intl,
  organization,
}) => {
  const t = intl.messages;

  return (
    <Layout>
      <AppHeader/>
      <Layout>
        <Nav/>
        <Layout.Content className="AboutOurProgram">
          <Sentry.ErrorBoundary
            fallback={
            <ErrorBoundaryFallback
              titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
              buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button'})}
              descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_about_our_program_message' })}
              customErrorImage="/images/error_image.png"
            />
          }>
              <Row className="AboutOurProgram-header">
                <Col span={24}>
                  <h1>{t.about_our_program}</h1>
                </Col>
              </Row>
              <Row className="AboutOurProgram-content">
                <Col span={24}>
                  { organization.config.about_our_program
                    ? <ReactMarkdown
                        source={organization.config.about_our_program}
                      />
                    : <Empty description={t.no_data} />
                  }
                </Col>
              </Row>
          </Sentry.ErrorBoundary>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

const mapStateToProps = ({
  organization,
}) => ({
  organization,
});

export default injectIntl(
  connect(mapStateToProps, {})(AboutOurProgram)
);