import React from 'react';
import { injectIntl } from 'react-intl';

import Avatar from 'components/Avatar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'antd';

const Organization = ({
  organization_logo_small,
  organization_score,
}) => {
  return (
    <>
      <Avatar icon="globe" src={organization_logo_small} />
      <div className="rating">
        <FontAwesomeIcon icon="star"/>
        <Badge count={Number(organization_score).toFixed(1)}/>
      </div>
    </>
  );
};

export default injectIntl(Organization);
