import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import colors from 'colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Row, Col, Modal } from 'antd';

import T from 'components/T';

import './style.less';

const NewProposalHeader = ({
  intl,
  stepCount,
  history,
}) => {
  const t = intl.messages;
  const { push, goBack } = history;
  const { confirm } = Modal;

  const closeModal = useCallback(() => push(`/proposal`), [ push ]);

  const showCancelModal = () => {
    confirm({
      title: t.proposal_cancel,
      content: `${t.proposal_cancel_are_you_sure} ${t.proposal_cancel_are_you_sure_2}`,
      okText: t.proposal_cancel_confirm,
      cancelText: t.proposal_cancel_cancel,
      onOk: () => {
        closeModal();
      },
      onCancel:() => {},
    });
  };

  return (
    <>
      <Row className="ProposeInitiative-header" align="middle">
        {stepCount > 0
        ? (
          <Col span={24}>
            <FontAwesomeIcon
              size="2x"
              icon="arrow-left"
              color={colors['@color-icon-faded']}
              onClick={() => goBack()}
            />
          </Col>
        )
        : null}
        <Col span={24}>
          <h1>{ t.propose_initiative_new }</h1>
        </Col>
        <div className={`ProposeInitiative-close ${stepCount === 1 && 'down'}`}>
          <FontAwesomeIcon
            size="2x"
            icon="times"
            color={colors['@color-icon-faded']}
            onClick={() => showCancelModal()}
          />
        </div>
      </Row>
      <Row className="ProposalInitiative-steps">
        <Col span={24}>
            { stepCount===7
            ? t.proposal_header_steps2
            : (<T proposal_header_steps={{stepCount}}/>) }
        </Col>
      </Row>
    </>
  );
}

export default injectIntl(withRouter(NewProposalHeader));
