import { loop, Cmd } from 'redux-loop';
import { history } from 'store';

const requestPush = async (url) => {
  history.push(url);
  return Promise.resolve();
};

const TARGET = 'proposals';

const initialState = {
  items: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(action.target !== TARGET) return state;
      return {
        items: action.method === 'GET' ? [] : state.items,
        loading: true,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || action.response.target !== TARGET) return state;
      return loop({
        items: action.response.method === 'GET' ? action.response.result : [ action.response.result, ...state.items ],
        loading: false,
        error: null,
      }, (action.response.method === 'GET' ? Cmd.none : Cmd.run(requestPush, { args: [`/proposal/new/finish`] })));
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        loading: false,
        error: action.code,
      };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as proposals,
};
