import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Layout,
  Button,
} from 'antd';

import {
  useSelector,
} from 'react-redux';

import Nav from 'containers/Nav';
import AppHeader from 'containers/AppHeader';
import NavBottom from 'containers/NavBottom';
import NewProposalHeader from 'components/NewProposalHeader/NewProposalHeader';
import Checkbox from 'components/Checkbox';

const Competences = ({
  intl,
  values,
  handleChange,
  showError,
  next,
}) => {
  const profile = useSelector(state => state.my_profile);
  const competences = useMemo(() => {
    return profile.data?.competences.map(competence => competence.slug) || [];
  }, [
    profile,
  ]);

  return (
    <>
      <AppHeader />
      <Layout>
        <Nav />
        <Layout.Content>
          <NewProposalHeader
            stepCount={6}
          />
          <Row className="ProposalInitiative-competences">
            <Col span={24} className="ProposalInitiative-heading">
            { intl.formatMessage({ id: `proposal_competences_title` }) }
            </Col>
            <Col span={24} className="ProposalInitiative-skills">
              {competences.map(competence => (
                <Checkbox
                  key={competence}
                  checked={values.competences.includes(competence)}
                  title={intl.formatMessage({ id: `competence_${competence}` })}
                  onClick={() => handleChange('competences')(competence)}
                />
              ))}
            </Col>
            <Col span={24}>
              { showError('competences') }
            </Col>
          </Row>
          <Row className="ProposalInitiative-button-next relative">
            <Col span={24}>
              <Button
                type="primary"
                onClick={next}
                disabled={values.competences.length === 0}
              >
                { intl.formatMessage({ id: `continue` }) }
              </Button>
            </Col>
            <Col span={24} className="ProposalInitiative-button-skip">
              <span onClick={next}>
                { intl.formatMessage({ id: `continue_without_this` }) }
              </span>
            </Col>
          </Row>
          <NavBottom />
        </Layout.Content>
      </Layout>
  </>
  );
};

export default injectIntl(Competences);
