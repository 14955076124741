import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import './style.less';

import useScreenSize from 'utils/useScreenSize';
import Success from './cards/Success';
import Amount from './cards/Amount';
import External from './cards/External';
import Paypal from './cards/Paypal';
import PaypalCards from './cards/PaypalCards';
import Transfer from './cards/Transfer';
import InHand from './cards/InHand';
import DisabledDoneButton from './cards/DisabledDoneButton';
import TransferButton from './cards/TransferButton';
import InHandButton from './cards/InHandButton';
import PaypalButton from './cards/PaypalButton';
import ExternalLinkButton from './cards/ExternalLinkButton';
import Source from './cards/Source';
import Anonymous from './cards/Anonymous';
import Comment from './cards/Comment';

import {
  List,
  Affix,
  Button,
  Drawer,
} from 'antd';

const FIXED_AMOUNTS = {
  'BRL': [99, 198, 297],
  default: [5, 10, 50],
};

const CARD_PER_SOURCE = {
  external: External,
  paypal: Paypal,
  paypal_cards: PaypalCards,
  transfer: Transfer,
  inhand: InHand,
};

const BUTTON_PER_SOURCE = {
  external: ExternalLinkButton,
  paypal: PaypalButton,
  paypal_cards: null,
  transfer: TransferButton,
  inhand: InHandButton,
  default: DisabledDoneButton,
};

const ContributionScreen = ({
  history,
  intl,
  visible,
  onClose,
  title,
  organization,
  slug,
  reportHours,
  participant_uuid,
  participation_method,
  reportContribution,
  donation_params = {},
  loading,
  error,
}) => {
  const t = intl.messages;
  const {
    currency,
    sources = [],
  } = donation_params;
  const drawerPlacement = ['xs', 'sm', 'md'].includes(useScreenSize()) ? 'bottom' : 'right';

  const [ contributionAmount, setContributionAmount ] = useState(0);
  const [ amountDone, setAmountDone ] = useState(false);
  const singleSource = sources.length === 1;
  const [ contributionSource, setContributionSource ] = useState( singleSource ? sources[0] : null );
  const [ sourceDone, setSourceDone ] = useState(singleSource);
  const [ isAnonymous, setIsAnonymous ] = useState(false);
  const [ comment, setComment ] = useState('');
  const [ sourceParams, setSourceParams ] = useState({});
  const [ success, setSuccess ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);

  const close = useCallback(() => {
    setContributionSource(null);
    setAmountDone(false);
    setSourceParams({});
    setSourceDone(false);
    setSubmitting(false);
    setSuccess(false);
    onClose();
  }, [ onClose ]);

  const submitDonation = useCallback((params = {}) => {
    setSubmitting(true);
    return reportContribution(
      contributionAmount,
      contributionSource,
      params,
      isAnonymous,
      comment
    );
  }, [ contributionAmount, contributionSource, reportContribution, isAnonymous, comment ]);

  useEffect(() => {
    if(submitting && !loading && !error) {
      setSuccess(true);
    }
  }, [ submitting, loading, error ]);

  return (
    <Drawer
      visible={visible}
      onClose={close}
      title={t.donate_title}
      placement={drawerPlacement}
      className="ContributionScreen"
    >
      {
      success
      ? <Success
          source={contributionSource}
          params={donation_params}
          title={title}
          amount={contributionAmount}
          currency={currency}
        />
      : !amountDone
      ?
        <>
          <Amount
            contributionAmount={contributionAmount}
            setContributionAmount={setContributionAmount}
            fixedAmounts={FIXED_AMOUNTS[currency] || FIXED_AMOUNTS.default}
            currency={t[currency]}
          />
          <List>
            <Comment
              value={comment}
              onChange={setComment}
            />
            <Anonymous
              value={isAnonymous}
              onChange={setIsAnonymous}
            />
          </List>
        </>
      : !sourceDone
      ? <Source
          sources={sources}
          selected={contributionSource}
          onChoose={setContributionSource}
        />
      : <>
        {(() => {
          const Card = CARD_PER_SOURCE[contributionSource];
          if(!Card) {
            return null;
          }
          return (
            <Card
              source={contributionSource}
              params={donation_params}
              title={title}
              amount={contributionAmount}
              currency={currency}
              sourceParams={sourceParams}
              setSourceParams={setSourceParams}
              organization={organization}
            />
          );
        })()}
        </>
      }
      <Affix className="affix-button">
        {
          success
          ?
            <Button onClick={close}>
              { t.done }
            </Button>
          : !amountDone
          ?
            <Button
              onClick={() => setAmountDone(true)}
              disabled={contributionAmount === 0}
            >
              { t.donation_next_amount }
            </Button>
          :
          !sourceDone
          ?
            <Button
              onClick={() => setSourceDone(true)}
              disabled={!contributionSource}
            >
              { t.donation_next_method }
            </Button>
          :
            (() => {
              const ConfirmButton = BUTTON_PER_SOURCE[contributionSource];
              return (
                !ConfirmButton ? null :
                <ConfirmButton
                  submit={submitDonation}
                  goBack={close}
                  disabled={!contributionAmount || !contributionSource}
                  amount={contributionAmount}
                  currency={currency}
                  source={contributionSource}
                  params={donation_params}
                  sourceParams={sourceParams}
                  setSourceParams={setSourceParams}
                />
              );
            })()
        }
      </Affix>
    </Drawer>
    )
}

export default injectIntl(
  withRouter(
    ContributionScreen
  )
);
