import React, { useState, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import Rate from 'rc-rate';
import useScreenSize from 'utils/useScreenSize';

import './style.less';
import 'rc-rate/assets/index.css';

import {
  Button,
  Row,
  Col,
  Drawer,
  Affix,
} from 'antd';

import useOpenInNewWindow from 'utils/useOpenInNewWindow';

const RatingScreen = ({
  intl,
  loading,
  title,
  slug,
  feedback_url,
  submitScores,
  participant_initiative_score = 0,
  participant_organization_score = 0,
  visible,
  onClose,
  hideRatingInitiative,
  hideRatingOrganizer
}) => {
  const [initiativeScore, setInitiativeScore] = useState(participant_initiative_score);
  const [orgScore, setOrgScore] = useState(participant_organization_score);
  const drawerPlacement = ['xs', 'sm', 'md'].includes(useScreenSize()) ? 'bottom' : 'right';
  const openInNewWindow = useOpenInNewWindow();

  const handleSubmit = useCallback(() => {
    submitScores(initiativeScore, orgScore);
    onClose();
  }, [initiativeScore, onClose, orgScore, submitScores]);

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      title={ intl.formatMessage({ id: `rate` }) }
      placement={drawerPlacement}
      className="RatingScreen"
    >
      {hideRatingInitiative ? null : (
        <Row className="RatingScreen-row">
          { intl.formatMessage({ id: `rate_initiative` }) }
          <Col span={24}>
            <Rate
              allowClear={false}
              value={initiativeScore}
              onChange={setInitiativeScore}
              disabled={loading}
            />
          </Col>
        </Row>
      )}
      {hideRatingOrganizer ? null : (
        <Row className="RatingScreen-row">
          { intl.formatMessage({ id: `rate_organizer` }) }
          <Col span={24}>
            <Rate
              allowClear={false}
              value={orgScore}
              onChange={setOrgScore}
              disabled={loading}
            />
          </Col>
        </Row>
      )}
      <Affix className="affix-button">
        { !feedback_url
        ? null
        : <Row className="RatingScreen-row">
          <span>
            { intl.formatMessage({ id: `feedback_title` }) }
          </span>
          <Col span={24}>
            <Button
              inline
              type="primary"
              className="InitiativeDetail-feedback-cta"
              onClick={() => {
                openInNewWindow(feedback_url);
              }}
            >
              { intl.formatMessage({ id: `feedback_cta` }) }
            </Button>
          </Col>
        </Row> }
        <Button
          type="primary"
          loading={loading}
          disabled={loading || (!initiativeScore && !hideRatingInitiative) || (!orgScore && !hideRatingOrganizer)}
          onClick={() => handleSubmit()}
        >
          { intl.formatMessage({ id: `submit` }) }
        </Button>
      </Affix>
    </Drawer>
  );
}

export default injectIntl(RatingScreen);
