import React from 'react';
import { injectIntl } from 'react-intl';

import { A } from 'aplanet-ui-kit';

const Feedback = ({
  intl,
  feedback_url,
}) => (
    <>
      <div className="title">{ intl.formatMessage({ id: `initiative_feedback_title` }) }</div>
      <A href={feedback_url} newWindow>{ intl.formatMessage({ id: `initiative_feedback_detail` }) }</A>
    </>
);

export default injectIntl(Feedback);
