const TARGET = 'initiative_documents';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(action.target !== TARGET) return state;
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || action.response.target !== TARGET) return state;
      const result = action.response.result;
      switch(action.response.method) {
        case 'GET':
          return {
            data: result,
            loading: false,
            error: null,
          };
        case 'DELETE':
          return {
            data: state.data.filter(item => item.id !== result.id),
            loading: false,
            error: null,
          };
        default:
          return {
            ...state,
            loading: false,
            error: null,
          };
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        data: null,
        loading: false,
        error: action.code,
      };
    case 'ADD_INITIATIVE_DOCUMENT':
      if(action.target !== TARGET) return state;
      return {
        ...state,
        data: [...state.data, action.attachment],
      };
    default:
      return state;
  }
};

export {
  reducer as initiative_documents,
};

