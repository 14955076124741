import React from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import './style.less';

const SsoError = ({
  intl,
  match,
}) => {
  const t = intl.messages;
  const { code } = match.params;

  return (
    <div>
      { t[`sso_error_${code}`] || t.sso_error_default }
    </div>
  )
}

export default injectIntl(
  withRouter(
    SsoError
  )
);
