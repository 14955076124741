import {
  addDays,
  parseISO,
  isSameDay,
  differenceInHours,
  differenceInDays, /* eslint-disable-line no-unused-vars */
  formatDistanceToNow,
  getDate,
  format,
  isAfter,
  isBefore,
  areIntervalsOverlapping,
  isYesterday,
  isToday,
} from 'date-fns';

import { legacyParse, convertTokens } from "@date-fns/upgrade/v2";

// These we use unchanged
export {
  isSameDay,
  differenceInDays
} from 'date-fns';

const MONTH_SHORT = 'MMM';
const DATE_ONLY = 'D MMMM YYYY';
const TIME_ONLY = 'HH:mm';
const SHORT_FORMAT = 'D MMM HH:mm';
const DB_FORMAT = 'YYYY-MM-DD[T]HH:mm:SS.sss[Z]';
const DB_DATE_FORMAT = 'YYYY-MM-DD';
const SLASH_DATE_FORMAT = 'DD/MM/YYYY';

const getLocale = (intl) => ((intl && intl.formats) || {}).dateFns;

export const fromDb = (str) => typeof str === 'string' ? parseISO(str) : str;

export const toDb = (d) => format(legacyParse(d), convertTokens(DB_FORMAT));

export const toDbDate = (d) => format(legacyParse(d), convertTokens(DB_DATE_FORMAT));

export const toDateArray = (start_time, end_time, intl) => {
  const start = fromDb(start_time);
  const end = fromDb(end_time);

  if(isSameDay(legacyParse(start), legacyParse(end))) {
    return [{
      day: getDate(legacyParse(start)),
      month: format(
        legacyParse(start),
        convertTokens(MONTH_SHORT),
        { locale: getLocale(intl) }
      ),
    }];
  } else {
    return [{
      day: getDate(legacyParse(start)),
      month: format(
        legacyParse(start),
        convertTokens(MONTH_SHORT),
        { locale: getLocale(intl) }
      ),
    },{
      day: getDate(legacyParse(end)),
      month: format(legacyParse(end), convertTokens(MONTH_SHORT), { locale: getLocale(intl) }),
    }];
  }
};

export const hoursPerDay = (start_time, end_time, hours_per_day = 8) => {
  const start = fromDb(start_time);
  const end = fromDb(end_time);

  if(isSameDay(legacyParse(start), legacyParse(end))) {
    return [differenceInHours(legacyParse(end), legacyParse(start)), false];
  } else if(hours_per_day > 0) {
    return [hours_per_day, true];
  } else {
    return [];
  }
};

export const timeAgo = (start_time, intl) => {
  const start = fromDb(start_time);
  return formatDistanceToNow(legacyParse(start), { locale: getLocale(intl) });
}

export const formatDateOrTime = (time, intl) => {
  const d = fromDb(time);

  if (isToday(legacyParse(time))) {
    return format(legacyParse(d), convertTokens(TIME_ONLY), { locale: getLocale(intl) });
  }
  if (isYesterday(legacyParse(time))) {
    return intl.formatMessage({ id: `yesterday` });
  }
  return format(
    legacyParse(d),
    convertTokens(SLASH_DATE_FORMAT),
    { locale: getLocale(intl) }
  );
};

export const formatDateAndTime = (time, intl) => {
  const d = fromDb(time);

  return {
    date: format(legacyParse(d), convertTokens(DATE_ONLY), { locale: getLocale(intl) }),
    time: format(legacyParse(d), convertTokens(TIME_ONLY), { locale: getLocale(intl) })
  };
};

export const formatDateLong = (time, intl) => {
  const d = fromDb(time);
  return format(legacyParse(d), convertTokens(DATE_ONLY), { locale: getLocale(intl) });
};

export const formatShort = (time, intl) => {
  const d = fromDb(time);

  return format(legacyParse(d), convertTokens(SHORT_FORMAT), { locale: getLocale(intl) });
};

export const isAfterNow = (time) => {
  const d = fromDb(time);

  return isAfter(legacyParse(d), legacyParse(new Date()));
};

export const isBeforeNow = (time) => {
  const d = fromDb(time);

  return isBefore(legacyParse(d), legacyParse(new Date()));
};

export const getTomorrow = () => {
  return addDays(legacyParse(new Date()), 1);
};

export const formatDb = (time) => {
  const d= fromDb(time);
  return toDb(d);
};

export const formatDbDate = (time) => {
  const d= fromDb(time);
  return toDbDate(d);
};

export const rangeOverlap = ([start_a, end_a], [start_b, end_b]) =>
  areIntervalsOverlapping(
    { start: fromDb(start_a), end: fromDb(end_a) },
    { start: fromDb(start_b), end: fromDb(end_b) }
  );
