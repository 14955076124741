import React, {
  useState,
  useCallback,
} from 'react';
import {
  Row,
  Col,
  Card,
  Avatar,
  Checkbox,
} from 'antd';

import './style.less';

const ImageCheckbox = ({
  selected,
  title,
  onChange,
  image,
}) => {
  const [ checked, setChecked ] = useState(selected);

  const _onChange = useCallback(() => {
    setChecked(!checked);
    if (onChange) {
      onChange();
    }
  },
  [checked, onChange]
  );

  return (
    <Card className={`ImageCheckbox ${checked && 'checked'}`} onClick={_onChange}>
      <Row>
        <Col span={24}>
          <Avatar size={100} src={image} />
        </Col>
        <Col span={24} className="title">
          {title}
        </Col>
      </Row>
      <Checkbox checked={checked} />
    </Card>
  );
}

export default ImageCheckbox;
