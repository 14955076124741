import { isValidPhoneNumber } from 'react-phone-number-input'
//import phoneMetadata from 'components/PhoneInput/metadata.custom.json';

//const allowedCountryCodes = Object.keys(phoneMetadata.country_calling_codes || {}).map(code => `+${code}`);

const fullValidation = (t) => ({
  phone,
}) => {
  let errors = {};

  if(!phone) {
    errors.phone = t.form_error_required;
  //} else if(!allowedCountryCodes.find(cc => phone.startsWith(cc))) {
  //  errors.phone = t.form_error_choose_phone_country;
  } else if(!isValidPhoneNumber(phone)) {
    errors.phone = t.form_error_invalid_phone;
  }

  return errors;
};

export default fullValidation;
