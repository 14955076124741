import React from 'react';
import { injectIntl } from 'react-intl';

const Program = ({
  intl,
  program,
}) => {
  return (
    <>
      <div className="title">{ intl.formatMessage({ id: `program_title` }) }</div>
      <div className="info">
        { program }
      </div>
    </>
  );
};

export default injectIntl(Program);

