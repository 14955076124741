import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';

import {
  InputNumber,
  Modal,
  Col,
  Row,
} from 'antd';

import './style.less';

const OnboardingMachineNumber = ({
  onboardingMode = false,
  intl,
  value = '',
  visible,
  onClose,
  onSave,
  loading,
  isRequired,
}) => {
  const t = intl.messages;

  const [machineNumber, setMachineNumber] = useState(value);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback(() => {
    if (value === machineNumber) {
      // Bail out
      return onClose();
    }

    setSaving(true);
    onSave(machineNumber);
  }, [
    value,
    machineNumber,
    onClose,
    onSave,
  ]);

  useEffect(() => {
    if(saving && !loading) {
      setSaving(false);
      onClose();
    }
  }, [
    saving,
    loading,
    onClose,
  ]);

  return (
    <Modal
      className="Onboarding__modal Onboarding__modal_machine_number"
      onCancel={onClose}
      onOk={handleSave}
      okText={t.save}
      cancelText={onboardingMode ? t.continue_without_this : t.cancel}
      visible={visible}
      title={t.onboarding_machine_number_title}
      cancelButtonProps={{ style: { display: isRequired ? 'none' : 'initial' } }}
      maskClosable={!isRequired}
      keyboard={!isRequired}
      closable={!isRequired}
    >
      <div className="Onboarding__modal-inner">
        <Row>
          <Col span={24}>
            <InputNumber
              value={machineNumber}
              placeholder={t.onboarding_machine_number_placeholder}
              type='number'
              onChange={value => setMachineNumber(value)}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default injectIntl(OnboardingMachineNumber);
