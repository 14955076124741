import React from "react";
import { Row, Col } from "antd";
import Error from 'components/Error';

import './style.less';

const NotFound = () => {
  return (
    <Row align="middle" className="NotFound">
      <Col span={24}>
        <Error error={404} />
      </Col>
    </Row>
  );
}

export default NotFound;
