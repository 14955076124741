import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';

import {
  Input,
  Modal,
  Col,
  Row,
} from 'antd';

import './style.less';

const OnboardingAlias = ({
  onboardingMode = false,
  intl,
  value = '',
  visible,
  onClose,
  onSave,
  loading,
  isRequired,
}) => {
  const t = intl.messages;
  const { TextArea } = Input;

  const [alias, setAlias] = useState(value);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback(() => {
    if (value === alias) {
      // Bail out
      return onClose();
    }

    setSaving(true);
    onSave(alias);
  }, [
    value,
    alias,
    onClose,
    onSave,
  ]);

  useEffect(() => {
    if(saving && !loading) {
      setSaving(false);
      onClose();
    }
  }, [
    saving,
    loading,
    onClose,
  ]);

  return (
    <Modal
      className="Onboarding__modal"
      onCancel={onClose}
      onOk={handleSave}
      okText={t.save}
      cancelText={onboardingMode ? t.continue_without_this : t.cancel}
      visible={visible}
      title={t.onboarding_alias_title}
      cancelButtonProps={{ style: { display: isRequired ? 'none' : 'initial' } }}
      maskClosable={!isRequired}
      keyboard={!isRequired}
      closable={!isRequired}
    >
      <div className="Onboarding__modal-inner">
        <Row>
          <Col span={24}>
            <TextArea
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
              placeholder={t.onboarding_alias_placeholder}
              rows={4}
              autoHeight
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default injectIntl(OnboardingAlias);
