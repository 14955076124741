import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { isAfterNow } from 'utils/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Contact from './Contact';

import {
  Button,
} from 'antd';

const Buttons = ({
  intl,
  status,
  contact_person,
  contact_phone,
  contact_email,
  contribute,
  start_time,
  end_time,
}) => {
  const t = intl.messages;
  const [ contactsShown, setContactsShown ] = useState(false);

  const canDonate = !isAfterNow(start_time) && isAfterNow(end_time) && status === 'open';
  const statusText = isAfterNow(start_time)
    ? t.donation_upcoming
    : (
      isAfterNow(end_time)
      ? t.donation_closed // Otherwise we canDonate
      : t.donation_finished
    );

  return (
    <>
      <Button
        className="secondary-button"
        onClick={() => setContactsShown(true)}
      >
        { t.contact_organizer }
      </Button>
      { canDonate
      ? <Button
          className="brand-button-primary"
          icon={<FontAwesomeIcon icon="coins" />}
          onClick={contribute}
        >
          { t.donate }
        </Button>
      : <Button
          className="brand-button-primary"
          icon={<FontAwesomeIcon icon="coins" />}
          disabled
        >
          { statusText }
        </Button>
      }
      { contactsShown &&
        <Contact
          t={t}
          contact_person={contact_person}
          contact_phone={contact_phone}
          contact_email={contact_email}
          onClose={() => setContactsShown(false)}
        />
      }
    </>
  );
};

export default injectIntl(Buttons);
