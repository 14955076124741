import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  Alert,
  Button,
  Checkbox,
  Input,
} from 'antd';

import { PasswordPolicyInfo } from 'aplanet-ui-kit';

import validatePasswordPolicy from 'utils/validatePasswordPolicy';

import './style.css';

import A from 'components/A';
import NavTopBack from 'components/NavTopBack';
import QuoteWall from 'components/QuoteWall';
import OrganizationLogo from 'components/OrganizationLogo';

import {
  fetchPasswordPolicy,
  submitRecoveredPassword,
  resetAuthError,
  logout,
} from 'actions/auth';

const EnterPassword = ({
  intl,
  match,
  organization,
  history,
  password_policy,
  password_recovery,
  fetchPasswordPolicy,
  submitRecoveredPassword,
  resetAuthError,
  logout,
}) => {
  const push = history.push;
  const t = intl.messages;
  const {
    error,
    loading,
  } = password_recovery;
  const {
    loading: loadingPolicy,
    data: passwordPolicy,
  } = password_policy;

  const { token } = match.params;

  const [password, doSetPassword] = useState('');
  const [agreeTC, setAgreeTC] = useState(false);
  const [agreeComms, setAgreeComms] = useState(false);

  const setPassword = (password) => {
    if(error) {
      resetAuthError();
    }
    doSetPassword(password);
  };

  useEffect(() => {
    logout(false);
  }, [logout]);

  useEffect(() => {
    fetchPasswordPolicy(
      token
    )
  }, [
    fetchPasswordPolicy,
    token,
  ]);

  const passwordError = useMemo(() => {
    if(!password || !passwordPolicy) {
      return null;
    }
    return validatePasswordPolicy(
      intl,
      passwordPolicy.policy,
      passwordPolicy.email,
    )(password)
  }, [
    intl,
    passwordPolicy,
    password,
  ]);

  const showError = useMemo(() => {
    // 401 = Token expired
    const showErrorTip = error === 401;
    const errorAction = showErrorTip ? (() => { resetAuthError() ; push('/signin'); }) : null;

    let errorText = error ? (t[`recover_password_error_${error}`] || t.recover_password_error_default) : passwordError;

    if (showErrorTip) {
      errorText += ' - ' + t[`recover_password_error_${error}_tip`];
    }

    if(error || passwordError) {
      return (
        <Alert
          message={errorText}
          type="error"
          onClick={errorAction}
          showIcon
        />
      );
    }
    return <div style={{ height: '36px' }}></div>;
  }, [
    t,
    error,
    passwordError,
    push,
    resetAuthError,
  ]);

  return (
    <Row className="SignUp SetInitialPassword">
      <Col xs={24} sm={12}>
        <NavTopBack to="/signin" />
        <OrganizationLogo className="SignUp-corporatelogo" {...organization} />
        { showError }
        <Row className="SignUp-input">
          <Col span={24}>
            <label>{ t.enter_new_password }</label>
          </Col>
          <Col span={24}>
            <Input
              type="password"
              placeholder={ t.password }
              value={password}
              onChange={e => setPassword(e.target.value)}
              suffix={<PasswordPolicyInfo intl={intl} policy={passwordPolicy?.policy} />}
            />
          </Col>
        </Row>
        <Row className="SignUp-checkboxes">
          <Col span={24}>
            <Checkbox
              checked={agreeTC}
              onChange={() => setAgreeTC(!agreeTC)}
            >{ t.terms_and_conditions_accept } <A href={t.terms_and_conditions_url}>{ t.terms_and_conditions }</A></Checkbox>
          </Col>
          <Col>
            <Checkbox
              checked={agreeComms}
              onChange={() => setAgreeComms(!agreeComms)}
            >{ t.receive_commercial_comms }</Checkbox>
          </Col>
        </Row>
        <div className="SignUp-calltoaction">
          <Button
            className="brand-button-primary cta"
            type="primary"
            disabled={!password || passwordError || loading || loadingPolicy || !agreeTC}
            onClick={() => submitRecoveredPassword(token, password, { agreeTC, agreeComms })}
          >{ t.save_password }</Button>
        </div>
      </Col>
      <Col xs={0} sm={12} className="cover">
        <QuoteWall organization={organization} />
      </Col>
    </Row>
  );
};

const SetInitialPassword = (props) => {
  const { token } = props.match.params;

  return (
    <React.Fragment>
      <Row>
        { token ? <EnterPassword {...props} /> : null}
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = ({
  auth,
  organization,
  password_recovery,
  password_policy,
}) => ({
  auth,
  organization,
  password_recovery,
  password_policy,
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      fetchPasswordPolicy,
      submitRecoveredPassword,
      resetAuthError,
      logout,
    }
  )(SetInitialPassword)
);
