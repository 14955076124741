import React, {
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import AppHeader from 'containers/AppHeader';
import Nav from 'containers/Nav';
import NavTopBack from 'components/NavTopBack';
import Header from '../cards/Header';
import ContributionScreen from 'components/ContributionScreen';
import When from '../cards/When';
import Collaborators from '../cards/Collaborators';
import Submitter from '../cards/Submitter';
import Description from '../cards/Description';
import Buttons from '../cards/DonationButtons';
import Categories from '../cards/Categories';
import InitiativeScore from '../cards/InitiativeScore';
import DonationBlock from '../cards/DonationBlock';
import TargetAudience from '../cards/TargetAudience';
import InitiativeTags from '../cards/Tags';
import Indicators from '../cards/Indicators';
import AttachmentDocuments from '../cards/AttachmentDocuments';
import AttachmentImages from '../cards/AttachmentImages';
import Organizer from '../cards/Organizer';

import {
  Layout,
  Row,
  Col,
  Alert,
  Divider,
} from 'antd';

const Donation = ({
  organization,
  notGoing,
  join,
  intl,
  loading,
  profile,
  history,
  hideRating,
  ...initiative
}) => {
  const [ showContributionScreen, setShowContributionScreen ] = useState(false);

  return (
    <Layout>
        <AppHeader/>
        <Layout>
          <Nav/>
          <Layout.Content>
            <NavTopBack onClick={history.goBack} text={intl.formatMessage({ id: `back` })}/>
              <Header
                hideRating={hideRating}
                {...initiative}
                leave={notGoing}
              />
              <div className="donation-details">
                <Row>
                  <Col className="main-section" xs={24} sm={24} md={24} lg={18} xl={18} xxl={18} >
                    {initiative.isPastInitiative &&
                    <Alert message={intl.formatMessage({ id: `initiative_expired_warning` })} type="warning" showIcon />
                    }
                    <Row>
                      <Col span={24}>
                      <div className="donation-section">
                        <DonationBlock organization={organization} {...initiative} />
                        <Divider />
                      </div>

                        <Description description={initiative.description} />
                        <Divider />
                      </Col>
                    </Row>

                    <Row justify="space-between" gutter={[20, 40]}>
                      { !!initiative.categories.length &&
                        <div className="categories">
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                              <Categories categories={initiative.categories} />
                            </Col>
                        </div>
                      }
                      { initiative.target_audience && !!initiative.target_audience.length &&
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                          <div className="target-audience">
                            <TargetAudience targetAudience={initiative.target_audience} />
                          </div>
                        </Col>
                      }
                    </Row>

                    { (
                        (parseInt(initiative.own_contribution_total || "0", 10) > 0)
                        || organization?.config?.show_attachments_before_participation
                      ) && !organization?.config?.hide_initiative_images &&
                      <Col span={24}>
                        <AttachmentImages {...initiative} organization={organization} />
                      </Col>
                    }

                    { (
                        (parseInt(initiative.own_contribution_total || "0", 10) > 0)
                        || organization?.config?.show_attachments_before_participation
                      ) && !organization?.config?.hide_initiative_documents &&
                      <Col span={24}>
                        <AttachmentDocuments {...initiative} organization={organization} />
                      </Col>
                    }
                  </Col>

                  <Col className="side-section" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                    <div className="when">
                      <When {...initiative} />
                    </div>

                    <div className="tags">
                    { initiative.tags && !!initiative.tags.length &&
                        <InitiativeTags tags={initiative.tags} />
                      }
                    </div>

                    { initiative.submitter &&
                    <div className="submitter">
                      <Submitter submitter={initiative.submitter} />
                    </div>
                    }

                    { !!initiative.organization_score_count &&
                      <Organizer hideRating ={hideRating} {...initiative} />
                    }

                    { initiative.collaborators && !!initiative.collaborators.length &&
                      <Collaborators hideRating ={hideRating} {...initiative} />
                    }

                    {!hideRating && (
                      <div className="ratings">
                        <InitiativeScore
                          initiative_score={initiative.initiative_score}
                          initiative_score_count={initiative.initiative_score_count}
                        />
                      </div>
                    )}    
                  </Col>
                </Row>

                { !!initiative.indicators.length &&
                  <Indicators indicators={initiative.indicators} />
                }

                {!initiative.isPastInitiative &&
                <div className="donation-buttons">
                  <Buttons
                    profile={profile}
                    {...initiative}
                    join={join}
                    contribute={() => setShowContributionScreen(true)}
                  />
                </div>
                }
            </div>
          </Layout.Content>
        </Layout>

        <ContributionScreen
          visible={showContributionScreen}
          onClose={() => setShowContributionScreen(false)}
          loading={loading}
          organization={organization}
          {...initiative}
        />
    </Layout>)
}

export default withRouter(
  injectIntl(Donation)
);
