import intl from 'intl';
import { addLocaleData } from 'react-intl';
import { updateIntl } from 'react-intl-redux';

// Locale Data
import locale_en from 'react-intl/locale-data/en';
import locale_es from 'react-intl/locale-data/es';
import locale_eu from 'react-intl/locale-data/eu';
import locale_pt from 'react-intl/locale-data/pt';

// Date-fns locale
import dateFns_en from 'date-fns/locale/en-US';
import dateFns_es from 'date-fns/locale/es';
import dateFns_eu from 'date-fns/locale/eu';
import dateFns_pt from 'date-fns/locale/pt';

// Antd
import antd_es from 'antd/lib/locale/es_ES';
import antd_en from 'antd/lib/locale/en_US';
import antd_ptbr from 'antd/lib/locale/pt_BR';
import antd_ptpt from 'antd/lib/locale/pt_PT';
import antd_eu from '../locale/antd/eu';

// react-phone-number-input locale
import reactPhoneNumber_en from 'react-phone-number-input/locale/en';
//import reactPhoneNumber_es from 'react-phone-number-input/locale/es';
//import reactPhoneNumber_eu from 'react-phone-number-input/locale/eu';
//import reactPhoneNumber_pt from 'react-phone-number-input/locale/pt';
import reactPhoneNumber_br from 'react-phone-number-input/locale/br';

// This is a polyfill
import 'intl/locale-data/jsonp/en.js';
import 'intl/locale-data/jsonp/es.js';
import 'intl/locale-data/jsonp/pt.js';

// Messages
import messages_es_ES from './es-ES.json';
import messages_eu_ES from './eu-ES.json';
import messages_es_MX from './es-MX.json';
import messages_en_US from './en-US.json';
import messages_pt_BR from './pt-BR.json';
import messages_pt_PT from './pt-PT.json';

import messages_quote_es_ES from './quote/es-ES.json';
import messages_quote_eu_ES from './quote/eu-ES.json';
import messages_quote_en_US from './quote/en-US.json';
import messages_quote_pt_BR from './quote/pt-BR.json';
import messages_quote_pt_PT from './quote/pt-PT.json';

import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/pt-br';
import 'moment/locale/eu';

//Custom moment for eu locale
import moment_eu from '../locale/moment/eu'; 
moment.updateLocale('eu', moment_eu);

const locales = [
  ...locale_en,
  ...locale_es,
  ...locale_eu,
  ...locale_pt,
];

const en_US = {
  locale: 'en-US',
  messages: {
    ...messages_en_US,
    quote: messages_quote_en_US,
  },
  formats: {
    dateFns: dateFns_en,
    reactPhoneNumber: reactPhoneNumber_en,
    antd: antd_en
  },
};

const es_ES = {
  locale: 'es-ES',
  messages: {
    ...messages_es_ES,
    quote: messages_quote_es_ES,
  },
  formats: {
    dateFns: dateFns_es,
    reactPhoneNumber: reactPhoneNumber_en,
    antd: antd_es
  },
};

const eu_ES = {
  locale: 'eu-ES',
  messages: {
    ...messages_eu_ES,
    quote: messages_quote_eu_ES,
  },
  formats: {
    dateFns: dateFns_eu,
    reactPhoneNumber: reactPhoneNumber_en,
    antd: antd_eu
  },
};

// eslint-disable-next-line no-unused-vars
const es_MX = {
  locale: 'es-MX',
  messages: {
    ...messages_es_MX,
  },
  formats: {
    dateFns: dateFns_es,
    reactPhoneNumber: reactPhoneNumber_en,
    antd: antd_es
  },
};

// eslint-disable-next-line no-unused-vars
const pt_BR = {
  locale: 'pt-BR',
  messages: {
    ...messages_pt_BR,
    quote: messages_quote_pt_BR,
  },
  formats: {
    dateFns: dateFns_pt,
    reactPhoneNumber: reactPhoneNumber_br,
    antd: antd_ptbr
  },
};

const pt_PT = {
  locale: 'pt-PT',
  messages: {
    ...messages_pt_PT,
    quote: messages_quote_pt_PT,
  },
  formats: {
    dateFns: dateFns_pt,
    reactPhoneNumber: reactPhoneNumber_br,
    antd: antd_ptpt
  },
};

const config = {
  'en': {
    default: en_US,
    'en-US': en_US,
  },
  'es': {
    default: es_ES,
    'es-ES': es_ES,
    //'es-MX': es_MX,
  },
  'eu': {
    default: eu_ES,
    'eu-ES': eu_ES,
  },
  'pt': {
    default: pt_PT,
    'pt-BR': pt_BR,
    'pt-PT': pt_PT,
  },
  default: {
    default: en_US,
  },
};

const defaultLocale = 'en-US';

const getConfig = (locale = defaultLocale) => {
  const langConfig = config[locale.slice(0, 2)] || config.default;
  return langConfig[locale] || langConfig.default;
};

const setupIntl = () => {
  if (typeof window.Intl === 'undefined') window.Intl = intl;
  addLocaleData(locales);
  const config = getConfig(navigator.language || navigator.userLanguage);
  moment.locale(config.locale);
  return config;
};

const changeLocale = (locale, appIntl={}) => {
  const config = getConfig(locale);
  moment.locale(config.locale);
  const customTranslation = appIntl[config.locale] || {};

  return updateIntl({
    ...config,
    messages: {
      ...config.messages,
      ...customTranslation,
    }
  });
};

export {
  setupIntl,
  changeLocale,
};

