import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { OpenStreetMapProvider, GoogleProvider } from 'leaflet-geosearch';

import {
  Layout,
  Row,
  Input,
  Col,
  Button,
  List,
  Radio,
} from 'antd';

import Nav from 'containers/Nav';
import AppHeader from 'containers/AppHeader';
import NavBottom from 'containers/NavBottom';
import NewProposalHeader from 'components/NewProposalHeader/NewProposalHeader';

import config from 'config';
import MapWithPins from 'components/MapWithPins';

const provider = config.GOOGLE_MAPS_KEY
  ? new GoogleProvider({
    params: {
      key: config.GOOGLE_MAPS_KEY,
    },
  })
  : new OpenStreetMapProvider({});

const SEARCH_DEBOUNCE = 1000; // 1 second

const DEFAULT_LAT = 40.4083539;
const DEFAULT_LON = -3.692422;
const DEFAULT_ZOOM = 18;

const Where = ({
  intl,
  organization = {},
  values,
  handleChangeEvent,
  handleChange,
  showError,
  next,
}) => {
  const t = intl.messages;

  const [position, setPosition] = useState({
    latitude: organization.default_latitude || DEFAULT_LAT,
    longitude: organization.default_longitude || DEFAULT_LON,
    zoom: DEFAULT_ZOOM,
    is_default: true,
    text: '',
  });

  const [results, setResults] = useState([]);

  useEffect(() => {
    // Monkey patching FTW
    // This only works on Google Maps
    const params = provider.options.params;
    provider.options.params = { ...params, region: (organization.region || '') };
  }, [organization]);

  const onChangeLocation = useCallback(({ latitude, longitude, text }) => {
    handleChange('latitude')(parseFloat(latitude));
    handleChange('longitude')(parseFloat(longitude));
    handleChange('address')(text);
  }, [ handleChange ]);

  const doChooseAddress = useMemo(() => async (result) => {
    try {
      const { x, y, label } = result;
      const newPosition = { ...position, latitude: y, longitude: x, text: label, is_default: false };
      setPosition(newPosition);
      onChangeLocation(newPosition);
    } catch(err) {
    }
    setResults([]);
  }, [ position, setPosition, onChangeLocation ]);

  const doSearch = useMemo(() => async (query) => {
    const results = await provider.search({ query });
    setResults(results);
  }, []);

  useEffect(() => {
    if(!values.address) {
      return;
    }
    const timer = setTimeout(doSearch, SEARCH_DEBOUNCE, values.address);
    return () => clearTimeout(timer);
  }, [ values.address, doSearch ]);

  const pins = position.text ? [position] : [];

  const disableContinue = useMemo(() => {
    if(values.is_in_person && !values.address) {
      return true;
    }
  }, [
    values,
  ]);

  return (
    <>
      <AppHeader />
      <Layout>
        <Nav />
        <Layout.Content>
          <NewProposalHeader
            stepCount={4}
          />
          <Row className="ProposalInitiative-where">
            <Col span={24} className="ProposalInitiative-heading">
              { t.initiative_in_person_or_remote_label }
            </Col>

            <Col span={12}>
              <Col span={24} className="ProposalInitiative-subheading">
                { t.initiative_in_person }
              </Col>
              <Radio.Group
                name="is_in_person"
                size="large"
                value={values.is_in_person ? 'yes': 'no'}
                onChange={(e) => handleChange('is_in_person')(
                  e.target.value === 'yes' ? true : false
                )}
              >
                <Radio.Button value="yes">
                  { t.yes }
                </Radio.Button>
                <Radio.Button value="no">
                  { t.no }
                </Radio.Button>
              </Radio.Group>
            </Col>

            <Col span={12}>
              <Col span={24} className="ProposalInitiative-subheading">
                { t.initiative_remote }
              </Col>
              <Radio.Group
                name="is_remote"
                size="large"
                value={values.is_remote ? 'yes': 'no'}
                onChange={(e) => handleChange('is_remote')(
                  e.target.value === 'yes' ? true : false
                )}
              >
                <Radio.Button value="yes">
                  { t.yes }
                </Radio.Button>
                <Radio.Button value="no">
                  { t.no }
                </Radio.Button>
              </Radio.Group>
            </Col>

            {values.is_remote &&
            <>
              <Col span={24} className="ProposalInitiative-heading">
                { t.initiative_remote_url }
              </Col>
              <Col span={24}>
                <Input
                  name="remote_url"
                  placeholder={t.initiative_remote_url_placeholder}
                  value={values.remote_url}
                  onChange={handleChangeEvent}
                />
              </Col>
            </>
            }

            {values.is_in_person &&
            <>
              <Col span={24} className="ProposalInitiative-heading">
                { t.proposal_where }
              </Col>
              <Col span={24} className="ProposalInitiative-subheading">
                { t.proposal_where_location }
              </Col>

              <Col span={24} className="ProposalInitiative-input">
                <Input
                  value={values.address}
                  name='address'
                  onChange={handleChangeEvent}
                  placeholder={ t.proposal_where_placeholder }
                />
              </Col>
              <Col span={24}>
                { results.length === 0 ? null :
                  <List>
                    { results.map((r, index) => (
                      <List.Item
                        key={index}
                        arrow="horizontal"
                        onClick={() => doChooseAddress(r)}
                      >
                        { r.label }
                      </List.Item>
                    )) }
                  </List>
                }
              </Col>
              <Col span={24} className="ProposalInitiative-map">
                <MapWithPins
                  latitude={position.latitude}
                  longitude={position.longitude}
                  zoom={position.zoom}
                  pins={pins}
                />
              </Col>
            </>
            }
            <Col span={24}>
              { showError('address') }
            </Col>
          </Row>
          <Row className="ProposalInitiative-button-next relative">
            <Col span={24}>
              <Button
                disabled={disableContinue}
                type="primary"
                onClick={next}
              >
                { t.continue }
              </Button>
            </Col>
            <Col span={24} className="ProposalInitiative-button-skip">
              <span onClick={next}>
                { t.continue_without_this }
              </span>
            </Col>
          </Row>
          <NavBottom />
        </Layout.Content>
      </Layout>
    </>
  );
};

export default injectIntl(Where);

