import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { PayPalButton } from 'react-paypal-button-v2';

import T from 'components/T';

const PaypalCards = ({
  intl,
  source = 'paypal_cards',
  params = {},
  single = false,
  amount,
  submit,
}) => {
  const t = intl.messages;

  const {
    paypal_clientid: clientId,
    currency,
  } = params;

  const handleSuccess = useCallback((details, data) => {
    submit({ details, data });
  }, [ submit ]);

  return (
    <>
      <T donation_paypal_cards_desc={{ amount, currency }} />
      <div>{ t.donation_paypal_cards_no_account_needed}</div>
      <PayPalButton
        amount={amount}
        onSuccess={handleSuccess}
        components="marks"
        style={{
          layout: 'vertical',
          fundingicons: false,
          color: 'white',
          shape: 'rect',
          label: 'paypal',
          tagline: false,
        }}
        options={{
          clientId,
          currency,
          disableFunding: 'bancontact,eps,giropay,ideal,sofort',
          disableCard: 'amex,discover,jcb,elo,hiper',
          commit: true,
        }}
      />
    </>
  );
};

export default injectIntl(PaypalCards);
