import React, {
} from 'react';
import { injectIntl } from 'react-intl';

//import A from 'components/A';
import AuthorizedLink from 'containers/AuthorizedLink';

import { ReactComponent as DocxIcon } from './icons/file-docx.svg';
import { ReactComponent as ImageIcon } from './icons/file-image.svg';
import { ReactComponent as PdfIcon } from './icons/file-pdf.svg';
import { ReactComponent as PptxIcon } from './icons/file-pptx.svg';
import { ReactComponent as PsIcon } from './icons/file-ps.svg';
import { ReactComponent as TxtIcon } from './icons/file-txt.svg';
import { ReactComponent as XlsxIcon } from './icons/file-xlsx.svg';
import { ReactComponent as OtherIcon } from './icons/file-other.svg';
import mime from 'mime-types';

import formatNumber from 'utils/formatNumber';

import './style.less';

const DEFAULT_ICON_PER_TYPE = {
  docx: DocxIcon,
  jpeg: ImageIcon,
  gif: ImageIcon,
  pdf: PdfIcon,
  png: ImageIcon,
  pptx:PptxIcon,
  ps: PsIcon,
  svg: ImageIcon,
  txt: TxtIcon,
  xlsx: XlsxIcon,
  default: OtherIcon,
}

const FORMAT_FILESIZE = {
  locale: 'bytes', // LOL
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

const FileItem = ({
  intl,
  filename,
  mimetype,
  size,
  download,
  children,
  showDownload = true,
}) => {
  const t = intl.messages;

  const type = mime.extension(mimetype);
  //console.log('DEBUG: This mime type', mimetype, 'equals this extension type', type);
  const Icon = DEFAULT_ICON_PER_TYPE[type] || DEFAULT_ICON_PER_TYPE.default;

  return(
    <section className="FileItem__item">
      <div className="FileItem__icon">
        <Icon />
      </div>
      <div className="FileItem__detail">
        <div className="FileItem__name">{ filename }</div>
        <div className="FileItem__info">
          <span>{ formatNumber(size, FORMAT_FILESIZE) }</span>
          {
            !showDownload
            ? null
            : (
              <React.Fragment>
                { ' - ' }
                <AuthorizedLink
                  href={download}
                  download={filename}
                  mimeType={mimetype}
                >
                  { t.fileitem_download }
                </AuthorizedLink>
              </React.Fragment>
            )
          }
          { children }
        </div>
      </div>
    </section>
  )
};

export default injectIntl(FileItem);
