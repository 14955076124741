import React from 'react';
import { injectIntl } from 'react-intl';

import Rating from 'components/Rating';

import {
  List,
  Avatar,
} from 'antd';
import {
  GlobalOutlined,
} from '@ant-design/icons';


const Organizer = ({
  intl,
  organization_logo_small,
  organization_name,
  organization_score,
  organization_score_count,
  hideRating = false
}) => {
  return (
    <List className="InitiativeDetail-organizer" renderHeader={() => intl.formatMessage({ id: `organized_by` })}>
      <div className="title">{ intl.formatMessage({ id: `organized_by` }) }</div>
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar icon={<GlobalOutlined />} src={organization_logo_small} />}
          title={ organization_name }
          description={ hideRating || !organization_score_count ? null :
            <>
              <Rating value={organization_score} />
              <span>({ organization_score_count })</span>
            </>
          }
        />
      </List.Item>
    </List>
  );
};

export default injectIntl(Organizer);

