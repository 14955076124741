import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Input } from 'antd';

const ROWS=3;

const Comment = ({
  value,
  onChange,
  intl,
}) => {
  const t = intl.messages;

  const handleChange = useCallback((val) => {
    onChange(val)
  }, [ onChange ]);

  return (
    <Input.TextArea
      className="ContributionScreen__message"
      title={ t.donation_comment }
      placeholder={ t.donation_comment_placeholder }
      value={value}
      onChange={e => handleChange(e.target.value)}
      rows={ROWS}
    />
  );
};

export default injectIntl(Comment);

