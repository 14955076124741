import { loop, Cmd } from 'redux-loop';

const TARGET = 'responsible_profile';
const PDF_TARGET = 'responsible_profile_pdf';

const isTarget = (target) => [TARGET, PDF_TARGET].includes(target);
const isGeneralTarget = (target) => target === TARGET;

const waitForPdf = (endpoint) => ({
  type: 'ACTIVE_WAIT_REQUEST',
  endpoint,
});

const initialState = {
  data: null,
  loading: false,
  error: false,
  pdf: {
    processing: false,
    ready: false,
    error: false,
    endpoint: '',
    ticket: '',
    url: '',
  },
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        ...state,
        loading: true,
        error: false,
        pdf: initialState.pdf,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;
      const result = action.response.result;
      if(isGeneralTarget(action.response.target)) {
        return {
          data: result,
          loading: false,
          error: false,
          pdf: initialState.pdf,
        };
      }

      // here action.response.target === PDF_TARGET
      const { ticket } = result || {};
      if(!ticket) return {
        ...state,
        pdf: initialState.pdf,
      }

      const endpoint = `${action.response.endpoint}/status/${ticket}`;
      return loop({
        ...state,
        pdf: {
          ...(action.response.result || initialState.pdf),
          processing: true,
          ready: false,
          error: false,
          endpoint,
        },
      }, Cmd.action(waitForPdf(endpoint)));
    case 'ACTIVE_WAIT_COMPLETE':
      if(!state.pdf.endpoint || state.pdf.endpoint !== action.endpoint) return state;
      return {
        ...state,
        pdf: {
          ...state.pdf,
          done: true,
          processing: false,
          error: false,
        }
      };
    case 'ACTIVE_WAIT_FAILED':
      if(!state.pdf.endpoint || state.pdf.endpoint !== action.endpoint) return state;
      return {
        ...state,
        pdf: {
          ...state.pdf,
          done: false,
          processing: false,
          error: true,
        }
      };
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        data: null,
        loading: false,
        error: true,
      };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as responsible_profile,
};
