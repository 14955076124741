import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  requestInitiativeImages,
  deleteInitiativeImage,
} from 'actions/api';

import {
  addInitiativeImage,
} from 'actions/initiative';

import {
  Row,
  Col,
  Button,
  Avatar,
  Modal,
  Empty,
  Alert,
} from 'antd';

import {
  DeleteOutlined,
} from '@ant-design/icons';

import UploadButton from 'containers/UploadButton';
import config from 'config';

const SHORT_LIST_SIZE = 10;

const AttachmentImages = ({
  intl,
  ...initiative
}) => {
  const t = intl.messages;

  const [expandList, setExpandList] = useState();
  const [currentImage, setCurrentImage] = useState();
  const [showMyAttachments, setShowMyAttachments] = useState();
  const [isUploaded, setIsUploaded] = useState();
  const [isUploading, setIsUploading] = useState();

  const dispatch = useDispatch();
  const { data } = useSelector(state => state.initiative_images);
  const { data: profile } = useSelector(state => state.my_profile);

  useEffect(() => {
    dispatch(requestInitiativeImages(
      initiative?.organization?.slug || initiative.organization_slug,
      initiative.slug,
    ));
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    dispatch,
    initiative.slug,
    initiative.organization,
    initiative.organization_slug,
  ]);

  const uploadUrl = useMemo(() => {
    const { slug, organization } = initiative;
    const { API_URL } = config;
    return `${API_URL}/${organization.slug}/initiative/${slug}/attachment/image`;
  }, [
    initiative,
  ]);

  const officialAttachments = useMemo(() => {
    const attachments = data?.filter(attachment => attachment.status === 'published') || [];
    if(attachments.length > SHORT_LIST_SIZE && !expandList) {
      return attachments.slice(0, SHORT_LIST_SIZE);
    }
    return attachments;
  }, [
    expandList,
    data,
  ]);

  const myAttachments = useMemo(() => (
    data?.filter(attachment => attachment.member_id === profile?.id)
  ), [
    profile,
    data,
  ]);

  const onUploadSuccess = useCallback((attachment) => {
    dispatch(
      addInitiativeImage(JSON.parse(attachment))
    );
    setIsUploaded(true);
  }, [
    dispatch,
  ]);

  const onDelete = useCallback(attachment => {
    Modal.confirm({
      title: `${t.attachment_images_delete} ${attachment.filename}`,
      content: t.attachment_images_confirm_delete,
      okText: t.attachment_images_delete,
      cancelText: t.cancel,
      onOk() {
        dispatch(deleteInitiativeImage(
          initiative.organization_slug,
          initiative.slug,
          attachment.id,
        ));
        setCurrentImage(null);
      },
    })
  }, [
    dispatch,
    initiative,
    t,
  ]);

  const onUploadStatusChange = useCallback(file => {
    switch(file?.status) {
      case 'error':
      case 'done':
        setIsUploading(false);
        break;
      case 'uploading':
        setIsUploading(true);
        break;
      default:
    }
  }, [ ]);

  return (
    <div className="AttachmentImages">
      <div className="title">{ t.attachment_images }</div>

        <Row
          type="flex"
          gutter={[30, 30]}
          justify={officialAttachments.length ? 'left' : 'center'}
        >
        {officialAttachments?.map(attachment => (
          <Col key={attachment.id}>
            <Avatar
              shape="square"
              size={80}
              src={attachment.download}
              onClick={() => setCurrentImage(attachment)}
            />
          </Col>
        ))}

        <Col>
          {!officialAttachments?.length && (
            <Empty description={t.no_data} />
          )}
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col>
          <Button
            onClick={() => setShowMyAttachments(true)}
            type="primary"
          >
            { t.attachment_images_send_photos }
          </Button>
        </Col>
        <Col>
          {officialAttachments?.length >= SHORT_LIST_SIZE && (
            expandList
            ? <Button onClick={() => setExpandList(false)}>{t.attachment_images_show_less}</Button>
            : <Button onClick={() => setExpandList(true) }>{t.attachment_images_show_more}</Button>
          )}
        </Col>
      </Row>

      <Modal
        className="InitiativeDetail-upload-attachments"
        title={t.attachment_images_upload_photos}
        visible={showMyAttachments}
        footer={null}
        onCancel={() => setShowMyAttachments(false)}
      >
        <Row type="flex" justify="center" gutter={[30, 30]}>
          {myAttachments?.map(attachment => (
            <Col key={attachment.id}>
              <Avatar
                shape="square"
                size={80}
                src={attachment.download}
                onClick={() => setCurrentImage(attachment)}
              />
            </Col>
          ))}

          <Col>
            {!myAttachments?.length && (
              <Empty description={t.no_data} />
            )}
          </Col>
        </Row>

        <Row type="flex" justify="center" gutter={[0, 20]}>
          <Col span={24}>
            {isUploaded &&
            <Alert
              message={t.attachment_images_upload_success}
              type="success"
              closable
            />
            }
          </Col>
          <Col>
            <UploadButton
              actionUrl={uploadUrl}
              onSuccess={onUploadSuccess}
              onChange={onUploadStatusChange}
              accept="image/*"
              component={
                <Button
                  type="primary"
                  loading={isUploading}
                >
                  {t.attachment_images_upload}
                </Button>
              }
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        className="InitiativeDetail-image-viewer"
        title={currentImage?.filename}
        visible={!!currentImage}
        footer={null}
        onCancel={() => setCurrentImage(null)}
      >
        <Row type="flex" gutter={[20, 20]} justify="center">
          <Col span={24}>
            <img src={currentImage?.download} alt={currentImage?.filename} />
          </Col>
          { currentImage?.member_id === profile?.id &&
            <Col>
              <Button
                onClick={() => onDelete(currentImage)}
                icon={<DeleteOutlined />}
              >
                { t.attachment_images_delete }
              </Button>
            </Col>
          }
        </Row>
      </Modal>
    </div>
  );
};

export default injectIntl(AttachmentImages);
