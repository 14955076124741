import React from 'react';
import { injectIntl } from 'react-intl';

import MapWithPins from 'components/MapWithPins';
import A from 'components/A';

const DEFAULT_ZOOM = 13;

const Address = ({
  intl,
  address,
  address_detail,
  latitude,
  longitude,
  is_remote,
  remote_url,
  participant_status,
}) => {
  const googleMapsLink = `https://maps.google.com/?q=${latitude},${longitude}`
  const showRemoteLink = participant_status === 'accepted'; // TODO: Other conditions?

  if(!address && !is_remote) {
    return null;
  }

  return (
    <>
      <div className="title">{ intl.formatMessage({ id: `where` }) }</div>
      { address
        ? <>
            <A href={googleMapsLink} className="subtitle">{address}</A>
            { !!address_detail &&
              <div>{address_detail}</div>
            }
            <MapWithPins
              latitude={latitude}
              longitude={longitude}
              zoom={DEFAULT_ZOOM}
              pins={[{
                latitude,
                longitude,
                text: address,
              }]}
            />
          </>
        : null
      }
      { is_remote
        ? <>
            <div>{intl.formatMessage({ id: `initiative_remote` })}</div>
            { remote_url && showRemoteLink
              ? <A href={remote_url} className="subtitle">
                  {intl.formatMessage({ id: `initiative_remote_url` })}
                </A>
              : null
            }
          </>
        : null
      }
    </>
  );
};

export default injectIntl(Address);

