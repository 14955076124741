import React, {
  useMemo,
} from 'react';

import { injectIntl } from 'react-intl';

import './style.less';

const DEFAULT_COVER_URL = '/images/cover.jpg';

const QuoteWall = ({
  intl,
  organization = {},
}) => {
  const t = intl.messages;

  const cover_url = useMemo(() => {
    return organization?.config?.cover_url || DEFAULT_COVER_URL
  }, [
    organization,
  ]);

  const cover_quote = useMemo(() => {
    return organization?.config?.cover_quote || {
      visible: true,
      quote: t.quote[`quote_universal_1`],
      author: t.quote[`quote_universal_1_author`],
      meta: t.quote.quote,
    }
  }, [
    t,
    organization,
  ]);

  return (
    <div
      className="QuoteWall"
      style={{
        background: `linear-gradient( to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url('${cover_url}')`,
        backgroundSize: "cover"
      }}
    >
      {
        !cover_quote.visible
        ? null
        : (
          <div className="container">
            <div className="intro">
              { t.quote.quote }
            </div>
            <q className="quote">
              { t.quote[`quote_universal_1`] }
            </q>
            <div className="author">
              { t.quote[`quote_universal_1_author`] }
            </div>
          </div>
        )
      }
    </div>
  );
}

export default injectIntl(QuoteWall);
