import React from 'react';
import { injectIntl } from 'react-intl';

const InitiativeTypeString = injectIntl(({
  intl,
  type,
  subtype,
}) => {
  const t = intl.messages;
  return (
    <span>
      {
        t[`initiative_type_${type}_${subtype}`] || t[`initiative_type_${type}`]
      }
    </span>
  );
});

export default InitiativeTypeString;

