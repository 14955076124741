import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import Checkbox from 'components/Checkbox';
import useSetState from 'utils/useSetState';

import {
  Modal,
  Row,
  Col,
  TimePicker,
} from 'antd';

import './style.less';


const preferredLocationOptions = [
  'country',
  'municipality',
  'continent',
  'place_live_and_work',
  'autonomous',
];

const daysOptions = [
  'everyday',
  'working_days',
  'working_days_after_work',
  'weekends_and_holidays',
];

const { RangePicker } = TimePicker;

const OnboardingAvailablity = ({
  onboardingMode = false,
  intl,
  details = {},
  visible,
  onClose,
  onSave,
  loading,
  isRequired,
}) => {
  const t = intl.messages;

  const [values, setValues] = useState(details);
  const [saving, setSaving] = useState(false);

  const {
    has: isPreferredLocationSelected,
    toggle: togglePreferredLocationSelected,
    set: selectedPreferredLocations,
  } = useSetState( values.availability_preferred_location || [] );

  const {
    has: isAvailabilityDaysSelected,
    toggle: toggleAvailabilityDaysSelected,
    set: selectedAvailabilityDays,
  } = useSetState( values.availability_days || [] );

  const hasAvailabilityHoursChanged = useMemo(
    () => {
      const initialAvailabilityHours = details.availability_days;
      const updatedAvailabilityHours = values.availability_days;

      if (!initialAvailabilityHours && !updatedAvailabilityHours) {
        return false;
      }

      if (
        initialAvailabilityHours &&
        initialAvailabilityHours.length === 2 &&
        updatedAvailabilityHours &&
        updatedAvailabilityHours.length === 2 &&
        initialAvailabilityHours[0] === updatedAvailabilityHours[0] &&
        initialAvailabilityHours[1] === updatedAvailabilityHours[1]
      ) {
        return false;
      } else {
        return true;
      }
    },
    [details, values]
  );

  const handleSave = useCallback(() => {
    const preferredLocations = values.availability_preferred_location || [];
    const availabilityDays = values.availability_days || [];
    if (
      preferredLocations.length === selectedPreferredLocations.length &&
      selectedPreferredLocations.every(slug => preferredLocations.includes(slug)) &&
      availabilityDays.length === selectedAvailabilityDays.length &&
      selectedAvailabilityDays.every(slug => availabilityDays.includes(slug)) &&
      !hasAvailabilityHoursChanged
    ) {
      // Bail out
      return onClose();
    }

    setSaving(true);
    onSave({
      ...values,
      availability_preferred_location: selectedPreferredLocations,
      availability_days: selectedAvailabilityDays,
    });
  }, [
    values,
    onClose,
    onSave,
    selectedPreferredLocations,
    selectedAvailabilityDays,
    hasAvailabilityHoursChanged,
  ]);

  const handleOnChange = useCallback(
    (key, value) => setValues({...values, [key] : value}),
    [values]
  );

  useEffect(() => {
    if(saving && !loading) {
      setSaving(false);
      onClose();
    }
  }, [
    saving,
    loading,
    onClose,
  ]);

  return (
    <Modal
      className="Onboarding__modal"
      onOk={handleSave}
      onCancel={onClose}
      okText={t.save}
      cancelText={onboardingMode ? t.continue_without_this : t.cancel}
      visible={visible}
      animationType="slide-up"
      title={t.onboarding_availability_title}
      cancelButtonProps={{ style: { display: isRequired ? 'none' : 'initial' } }}
      maskClosable={!isRequired}
      keyboard={!isRequired}
      closable={!isRequired}
    >
      <div className="Onboarding__modal-inner">
        <Row className="Onboarding__modal-details-row">
          <span>
            {t.onboarding_availability_preferred_location_title}
          </span>
          <Col span={24}>
            {preferredLocationOptions.map(slug => (
              <Row align="middle" key={slug}>
                <Col span={24}>
                  <Checkbox
                    checked={isPreferredLocationSelected(slug)}
                    onClick={() => togglePreferredLocationSelected(slug)}
                    title={t[`onboarding_availability_preferred_location_option_${slug}`]}
                  />
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row className="Onboarding__modal-details-row">
          <span>
            {t.onboarding_availability_days_title}
          </span>
          <Col span={24}>
            {daysOptions.map(slug => (
              <Row align="middle" key={slug}>
                <Col span={24}>
                  <Checkbox
                    checked={isAvailabilityDaysSelected(slug)}
                    onClick={() => toggleAvailabilityDaysSelected(slug)}
                    title={t[`onboarding_availability_days_option_${slug}`]}
                  />
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row className="Onboarding__modal-details-row">
          <span>
            {t.onboarding_availability_hours_title}
          </span>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <RangePicker
                  format="HH:mm"
                  value={
                    values.availability_hours && values.availability_hours.find(item => !!item)
                    ? values.availability_hours.map(time => moment(time, 'HH:mm'))
                    : ["", ""]
                  }
                  onChange={(_, value) => handleOnChange('availability_hours', value)} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default injectIntl(OnboardingAvailablity);
