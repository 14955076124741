const updateInitiativeBannerVisibility = (state) => ({
  type: 'INITIATIVE_BANNER_VISIBILITY',
  state,
});

const toggleSidebar = (state) => ({
  type: 'TOGGLE_SIDEBAR',
  state,
});

export {
  updateInitiativeBannerVisibility,
  toggleSidebar,
}
