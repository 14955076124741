import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryFallback } from 'aplanet-ui-kit';
import { injectIntl } from 'react-intl';
import {
  Layout
} from 'antd';
import {
  requestProfile,
  updateProfile,
  requestResponsibleProfile,
  requestGenerateResponsibleProfilePDF,
} from 'actions/api';
import Nav from 'containers/Nav';

import {
  formatDateLong,
} from 'utils/date';

import AppHeader from 'containers/AppHeader';
import NavBottom from 'containers/NavBottom';
import ResponsibleProfileComponent from 'components/ResponsibleProfile';

const ResponsibleProfile = ({
  match,
  organization,
  my_profile,
  chat,
  requestProfile,
  updateProfile,
  responsible_profile,
  requestResponsibleProfile,
  requestGenerateResponsibleProfilePDF,
  intl
}) => {
  const {
    data: profile,
    pdf: pdfStatus,
  } = responsible_profile;

  // TODO: Pull to refresh
  useEffect(() => {
    requestResponsibleProfile(organization.slug);
  }, [organization.slug, requestResponsibleProfile, my_profile.data]);

  useEffect(() => {
    requestProfile(organization.slug);
  }, [
    requestProfile,
    organization.slug,
  ]);

  if (!profile || !my_profile.data) {
    return null;
  }

  return (
    <>
      <AppHeader/>
      <Layout>
        <Nav/>
        <Layout>
          <Layout.Content>
            <Sentry.ErrorBoundary
                  fallback={
                  <ErrorBoundaryFallback
                    titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
                    buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button'})}
                    descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_social_cv_message' })}
                    customErrorImage="/images/error_image.png"
                  />
                }>
              <ResponsibleProfileComponent
                profile={profile}
                pdfStatus={pdfStatus}
                formatDateLong={formatDateLong}
                requestGenerateResponsibleProfilePDF={requestGenerateResponsibleProfilePDF}
                organization={organization}
                updateProfile={updateProfile}
                chatUserId={chat.activeUser?.id}
              />
            </Sentry.ErrorBoundary>
            <NavBottom />
          </Layout.Content>
        </Layout>
      </Layout>
    </>
    )
}

const mapStateToProps = ({ organization, responsible_profile, my_profile, chat }) => ({
  organization,
  responsible_profile,
  my_profile,
  chat
});

export default connect(
  mapStateToProps,
  {
    requestResponsibleProfile,
    requestGenerateResponsibleProfilePDF,
    requestProfile,
    updateProfile,
  }
)(injectIntl(ResponsibleProfile));
