import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
  Input,
  Button,
  Alert,
} from 'antd';

import validatePasswordPolicy from 'utils/validatePasswordPolicy';

import './style.less';

import OrganizationLogo from 'components/OrganizationLogo';
import QuoteWall from 'components/QuoteWall';

import {
  fetchPasswordPolicy,
  submitRecoveredPassword,
  resetAuth,
  resetAuthError,
  logout,
} from 'actions/auth';

const RecoverPassword = ({
  intl,
  match,
  organization,
  history,
  password_policy,
  password_recovery,
  fetchPasswordPolicy,
  submitRecoveredPassword,
  resetAuthError,
  location,
  logout,
}) => {
  const push = history.push;
  const t = intl.messages;
  const {
    error,
    loading,
  } = password_recovery;
  const { token } = match.params;

  const [password, doSetPassword] = useState('');

  useEffect(() => {
    logout(false);
  }, [logout]);

  const setPassword = (password) => {
    if(error) {
      resetAuthError();
    }
    doSetPassword(password);
  };

  useEffect(() => {
    fetchPasswordPolicy(
      token
    )
  }, [
    fetchPasswordPolicy,
    token,
  ]);

  const {
    loading: loadingPolicy,
    data: passwordPolicy,
  } = password_policy;

  const passwordError = useMemo(() => {
    if(!password || !passwordPolicy) {
      return null;
    }
    return validatePasswordPolicy(
      intl,
      passwordPolicy.policy,
      passwordPolicy.email,
    )(password)
  }, [
    intl,
    passwordPolicy,
    password,
  ]);

  const showError = useMemo(() => {
    // 401 = Token expired
    const showErrorTip = error === 401;
    const errorAction = showErrorTip ? (() => { resetAuthError() ; push('/signin', location.state); }) : null;

    let errorText = error ? (t[`recover_password_error_${error}`] || t.recover_password_error_default) : passwordError;

    if (showErrorTip) {
      errorText += ' - ' + t[`recover_password_error_${error}_tip`];
    }

    if(error || passwordError) {
      return (
        <Alert
          message={errorText}
          type="error"
          onClick={errorAction}
          showIcon
        />
      );
    }
    return <div style={{ height: '36px' }}></div>;
  }, [
    t,
    error,
    passwordError,
    push,
    resetAuthError,
    location.state,
  ]);

  const onSubmit = useCallback(
    () => {
      if(!password || loading || loadingPolicy) {
        return;
      }
      submitRecoveredPassword(token, password);
    },
    [
      password,
      token,
      loading,
      loadingPolicy,
      submitRecoveredPassword,
    ]
  );

  return (
    <Row className="RecoverPassword">
      <Col xs={24} sm={12}>
        <Row>
          <OrganizationLogo className="RecoverPassword-corporatelogo" {...organization} />
          <Col span={24}>
            { showError }
            <div className="ConfirmEmail-input">
              <label>{t.enter_new_password}</label>
              <Input
                type="password"
                placeholder={ t.password }
                value={password}
                onPressEnter={() => onSubmit(token, password)}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Button
          className="brand-button-primary cta"
          type="primary"
          disabled={!password || passwordError || loading || loadingPolicy}
          onClick={() => onSubmit(token, password)}
        >{ t.save_password }</Button>
      </Col>
      <Col xs={0} sm={12} className="cover">
        <QuoteWall organization={organization} />
      </Col>
    </Row>
  );
};

const mapStateToProps = ({
  auth,
  organization,
  password_recovery,
  password_policy,
}) => ({
  auth,
  organization,
  password_recovery,
  password_policy,
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      fetchPasswordPolicy,
      submitRecoveredPassword,
      resetAuth,
      resetAuthError,
      logout,
    }
  )(withRouter(RecoverPassword))
);
