import React, {
  useState,
  useCallback,
} from 'react';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from 'colors';
import {
  Input,
  Button,
} from 'antd';

const ReportHours = ({ intl, max, reportHours }) => {
  const [hours, setHours] = useState('');

  const report = useCallback(
    hours => {
      reportHours(parseInt(hours));
      setHours('');
    }, [reportHours]
  );

  return (
    <div className="ParticipationScreen-report">
      <div className="header">
        <span> { intl.formatMessage({ id: `hours_need_to_be_validated` }) }</span>
      </div>
      <Input
        type="number"
        min={1}
        max={max > 0 ? max : undefined}
        placeholder={ intl.formatMessage({ id: `hours` }) }
        value={hours}
        onChange={(e) => setHours(e.target.value)}
        prefix={<FontAwesomeIcon icon="clock" color={colors['@color-icon-faded']} />}
        suffix={
          <Button
            size="small"
            disabled={!hours || parseInt(hours) <= 0}
            onClick={() => report(parseInt(hours))}
          >
            { intl.formatMessage({ id: `report_hours` }) }
          </Button>
        }
      >
      </Input>
    </div>
  );
};

export default injectIntl(ReportHours);

