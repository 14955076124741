import React, {useMemo} from "react";
import { Collapse } from 'antd';
import PropTypes from 'prop-types';

import ChatHeader from "./ChatHeader";
import ChatConversation from "./ChatConversation";

const CHAT_CONVERSATION_KEY = 'chatConversation'

const { Panel } = Collapse;

const ChatConversationWindow = ({
	handleChange,
	handleSend,
	useChat,
	expandChatWindow,
	setExpandChatWindow,
}) => {
	const {
		activeConversation,
		setActiveConversation,
		getUser,
	} = useChat;
	
	const [currentUserAvatar, currentUserName] = useMemo(() => {

		if (activeConversation) {
			const participant = activeConversation.participants?.length > 0 ? activeConversation.participants[0] : undefined;
			if (participant) {
				const user = getUser(participant.id);
				if (user) {
					return [user.avatar, user.username]
				}
			}
		}

		return [undefined, undefined];

	}, [activeConversation, getUser]);	

	return (
		<Collapse
			className={`
				GlobalChat__chatWindow
				${activeConversation.unreadCounter > 0 && !expandChatWindow.includes(CHAT_CONVERSATION_KEY) ? 'has-unread' : ''}
			`}
			activeKey={expandChatWindow}
			onChange={(activeKey)=> setExpandChatWindow(activeKey)}
			expandIconPosition="right"
		>
			<Panel
				key={CHAT_CONVERSATION_KEY}
				header={
					<ChatHeader
						avatar={currentUserAvatar}
						username={currentUserName}
						totalUnreadCount={activeConversation.unreadCounter}
						isExpanded={expandChatWindow.includes(CHAT_CONVERSATION_KEY)}
						onClose={() => setActiveConversation("")}
						hasClose
					/>
				}
			>
				{
					<ChatConversation
						handleChange={handleChange}
						handleSend={handleSend}
						useChat={useChat}
					/>
				}
			</Panel>
		</Collapse>
	)
}

ChatConversationWindow.propTypes = {
	handleChange: PropTypes.func.isRequired,
	handleSend: PropTypes.func.isRequired,
	useChat: PropTypes.object.isRequired,
	expandChatWindow: PropTypes.arrayOf(PropTypes.string).isRequired,
	setExpandChatWindow: PropTypes.func.isRequired,
};

export default ChatConversationWindow