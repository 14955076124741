const addInitiativeImage = (attachment) => ({
  type: 'ADD_INITIATIVE_IMAGE',
  target: 'initiative_images',
  attachment,
});

const addInitiativeDocument = (attachment) => ({
  type: 'ADD_INITIATIVE_DOCUMENT',
  target: 'initiative_documents',
  attachment,
});

export {
  addInitiativeImage,
  addInitiativeDocument,
}
