import React from 'react';
import { injectIntl } from 'react-intl';
import {
  Row,
  Col,
} from 'antd';
import QRCode from 'qrcode.react';

import config from 'config';

const Qr = ({ intl, uuid }) => {
  return (
    <Row justify="center" align="middle">
      <Col className="ParticipationScreen-qr-header">
        <span>{ intl.formatMessage({ id: `find_qr_scanner` }) }</span>
      </Col>
      <QRCode
        value={`${config.QR_URL}/p/${uuid}`}
        renderAs="svg"
      />
    </Row>
  );
};

export default injectIntl(Qr);

