import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import {
  Modal,
  Row,
  Col,
  DatePicker,
} from 'antd';

import './style.less';

const OnboardingDateOfBirth = ({
  onboardingMode = false,
  intl,
  value = '',
  visible,
  onClose,
  onSave,
  loading,
  isRequired,
  onboardingStep
}) => {

  const [dateOfBirth, setDateOfBirth] = useState(value);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback(() => {
    if (value === dateOfBirth) {
      // Bail out
      return onClose();
    }

    setSaving(true);
    onSave(dateOfBirth);
  }, [
    value,
    dateOfBirth,
    onClose,
    onSave,
  ]);

  useEffect(() => {
    if(saving && !loading) {
      setSaving(false);
      onClose();
    }
  }, [
    saving,
    loading,
    onClose,
  ]);

  return (
    <Modal
      className="Onboarding__modal"
      onOk={handleSave}
      onCancel={onClose}
      okText={intl.formatMessage({ id: `save` })}
      cancelText={onboardingMode ? intl.formatMessage({ id: `continue_without_this` }) : intl.formatMessage({ id: `cancel` })}
      visible={visible}
      title={intl.formatMessage({ id: `onboarding_${onboardingStep}_title` })}
      cancelButtonProps={{ style: { display: isRequired ? 'none' : 'initial' } }}
      maskClosable={!isRequired}
      keyboard={!isRequired}
      closable={!isRequired}
    >
      <div className="Onboarding__modal-inner">
        <Row className="Onboarding__modal-details-row">
          <span className="Onboarding__modal-dateOfBirthDescription">
            {intl.formatMessage({ id: `onboarding_${onboardingStep}_description` })}
          </span>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <DatePicker
                  size="large"
                  value={dateOfBirth ? moment(dateOfBirth, 'YYYY-MM-DD') : dateOfBirth}
                  onChange={(_, dateStrings) => setDateOfBirth(dateStrings)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default injectIntl(OnboardingDateOfBirth);
