import React from 'react';
import { injectIntl } from 'react-intl';

import {
  List,
  Row,
  Col,
} from 'antd';

const InHand = ({
  intl,
  source = 'inhand',
  params = {},
  single = false,
  amount,
  currency,
}) => {
  const t = intl.messages;

  const {
    inhand_instructions,
  } = params;
  return (
    <div className="ContributionScreen__cash">
      <Row>
        <Col span={24}>
          <h4>{ t.you_are_going_to_donate }:</h4>
        </Col>
        <Col span={24}>
          <h1>{ amount } { currency }</h1>
        </Col>
        <Col span={24}>
          <h3>{ t.by_cash }</h3>
        </Col>
      </Row>
      <List.Item>
        <List.Item.Meta
          title={ t.instructions }
          description={<span className="ContributionScreen__instructions">
            { inhand_instructions }
          </span>}
        />
      </List.Item>
    </div>
  );
};

export default injectIntl(InHand);

