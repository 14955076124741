const EMAIL_REGEXP = new RegExp('^[a-z0-9._%+-]{1,64}@(?:[a-z0-9-]{1,63}.){1,125}[a-z]{2,63}$');

const fullValidation = (t) => ({
  name,
  email,
}) => {
  let errors = {};
  if(!name) {
    errors.name = t.form_error_required;
  }

  if(!email) {
    errors.email = t.form_error_required;
  } else if(!EMAIL_REGEXP.test(email)) {
    // TODO: In the future we might allow you to sign up without an email
    errors.email = t.form_error_invalid_email;
  }

  return errors;
};

export default fullValidation;
