import React from 'react';

import { Tag } from 'antd';

import { injectIntl } from 'react-intl';
import { stringToColor } from 'utils/stringToColor';

const InitiativeTags = ({ intl, tags = [] }) => {
  return (
    <>
      <div className="title">{ intl.formatMessage({ id: `tags` }) }</div>
      { tags.map(
        tag => (
          <Tag key={tag} color={stringToColor(tag)} className="tag">{ tag }</Tag>
        ))
      }
    </>
  );
};

export default injectIntl(InitiativeTags);

