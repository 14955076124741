import React, {
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

import NavTopBack from 'components/NavTopBack';
import Header from '../cards/Header';
import Program from '../cards/Program';
import Points from '../cards/Points';
import When from '../cards/When';
import Collaborators from '../cards/Collaborators';
import Submitter from '../cards/Submitter';
import Description from '../cards/Description';
import Buttons from '../cards/VolunteeringButtons';
import Address from '../cards/Address';
import Categories from '../cards/Categories';
import Competences from '../cards/Competences';
import Restrictions from '../cards/Restrictions';
import InitiativeScore from '../cards/InitiativeScore';
import TargetAudience from '../cards/TargetAudience';
import InitiativeTags from '../cards/Tags';
import Indicators from '../cards/Indicators';
import ManagerApproval from '../cards/ManagerApproval';
import AttachmentDocuments from '../cards/AttachmentDocuments';
import AttachmentImages from '../cards/AttachmentImages';
import Feedback from '../cards/Feedback';
import Organizer from '../cards/Organizer';
import AppHeader from 'containers/AppHeader';
import Nav from 'containers/Nav';

import {
  Row,
  Col,
  Alert,
  Layout,
  Divider,
} from 'antd';

import {
  isSameDay,
} from 'utils/date';

const Volunteering = ({
  goBackTo,
  notGoing,
  join,
  participate,
  intl,
  loading,
  profile,
  hideRating,
  ...initiative
}) => {
  const requireManagerApproval = useMemo(() => {
    if (initiative.manager_approval_status === 'not_needed') {
      return false;
    }
    return initiative.organization?.config?.require_manager_approval;
  }, [
    initiative,
  ]);

  const hasPoints = useMemo(() => {
    const orgFeatures = (initiative.organization || {}).features;
    if(!orgFeatures) {
      return false;
    }

    return orgFeatures.includes('points') &&
      typeof (initiative || {}).points !== 'undefined' &&
      (initiative || {}).points >= 0
  }, [
    initiative,
  ]);

  const hasProgram = useMemo(() => {
    const orgFeatures = (initiative.organization || {}).features;
    if(!orgFeatures) {
      return false;
    }

    return orgFeatures.includes('program') && (initiative || {}).program
  }, [
    initiative,
  ]);

  const showWorkingHours = useMemo(() => {
    return ((initiative.organization || {}).config ||{}).consider_working_hours && initiative.is_working_hours;
  }, [initiative]);

  const showRestrictions = useMemo(() => {
    const { minors_allowed,  registration_end_time } = initiative;
    const sameDate = isSameDay(registration_end_time, initiative.end_time);
    return !minors_allowed || showWorkingHours || !sameDate;
  }, [initiative, showWorkingHours]);

  return (
    <Layout>
      <AppHeader/>
      <Layout>
        <Nav/>
        <Layout.Content>
          <NavTopBack to={goBackTo} text={intl.formatMessage({ id: `back` })}/>
            <Header
              hideRating={hideRating}
              {...initiative}
              leave={notGoing}
            />
            <div className="initiative-details">
              <Row>
                <Col className="main-section" xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
                  {initiative.isPastInitiative &&
                  <Alert message={intl.formatMessage({ id: `initiative_expired_warning` })} type="warning" showIcon />
                  }
                  <Row>
                    <Col>
                      <Description description={initiative.description} />
                      <Divider />
                    </Col>
                  </Row>

                  <Row justify="space-between" gutter={[20, 40]}>
                    <Col className="skills" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <div>
                        { initiative.competences && !!initiative.competences.length &&
                          <Competences competences={initiative.competences} />
                        }
                      </div>
                    </Col>
                    { showRestrictions && (
                      <Col className="skills" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <div className="restrictions">
                          <Restrictions
                            showRegistrationEndTime={!isSameDay(initiative.registration_end_time, initiative.end_time)}
                            showWorkingHours={showWorkingHours}
                            minorsAllowed={initiative.minors_allowed}
                            registrationEndTime={initiative.registration_end_time}
                          />
                        </div>
                      </Col>
                    )}
                     { !!initiative.categories.length &&
                       <div className="categories">
                         <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                           <Categories categories={initiative.categories} />
                         </Col>
                       </div>
                     }
                     { initiative.target_audience && !!initiative.target_audience.length &&
                       <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                         <div className="target-audience">
                           <TargetAudience targetAudience={initiative.target_audience} />
                         </div>
                       </Col>
                     }

                     { !!initiative.indicators.length &&
                     <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                       <div className="indicators">
                         <Indicators indicators={initiative.indicators} />
                       </div>
                     </Col>
                     }

                     { requireManagerApproval &&
                     <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                       <ManagerApproval
                         profile={profile}
                         {...initiative}
                       />
                     </Col>
                     }

                    { (
                        initiative.participant_status === 'accepted'
                        || initiative.organization?.config?.show_attachments_before_participation
                      ) && !initiative.organization?.config?.hide_initiative_images &&
                     <Col span={24}>
                       <AttachmentImages {...initiative} />
                     </Col>
                    }

                    { (
                        initiative.participant_status === 'accepted'
                        || initiative.organization?.config?.show_attachments_before_participation
                      ) && !initiative.organization?.config?.hide_initiative_documents &&
                     <Col span={24}>
                       <AttachmentDocuments {...initiative} />
                     </Col>
                    }
                  </Row>
                </Col>

                <Col className="side-section" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                  {
                    !hasProgram ? null :
                    <div className="program">
                      <Program program={initiative.program} />
                    </div>
                  }
                  {
                    !hasPoints ? null :
                    <div className="points">
                      <Points points={initiative.points} />
                    </div>
                  }

                  <div className="when">
                    <When {...initiative} />
                  </div>

                  <div className="tags">
                    { initiative.tags && !!initiative.tags.length &&
                      <InitiativeTags tags={initiative.tags} />
                    }
                  </div>

                  { initiative.submitter &&
                  <div className="submitter">
                    <Submitter submitter={initiative.submitter} />
                  </div>
                  }

                  <div className="address">
                    <Address {...initiative} />
                  </div>

                  { !!initiative.organization_score_count &&
                    <Organizer hideRating ={hideRating} {...initiative} />
                  }

                  { initiative.collaborators && !!initiative.collaborators.length &&
                    <Collaborators hideRating ={hideRating} {...initiative} />
                  }

                  {!hideRating && (
                    <div className="ratings">
                      <InitiativeScore
                        initiative_score={initiative.initiative_score}
                        initiative_score_count={initiative.initiative_score_count}
                      />
                    </div>
                  )}
  
                  { initiative.participant_status === 'accepted' && !!initiative.feedback_url &&
                  <div className="feedback">
                    <Feedback feedback_url={initiative.feedback_url} />
                  </div>
                  }
                </Col>
              </Row>

              {!initiative.isPastInitiative &&
              <div className="initiative-buttons">
                <Buttons
                  profile={profile}
                  {...initiative}
                  join={join}
                  participate={participate}
                />
              </div>
              }
            </div>
          </Layout.Content>
        </Layout>
    </Layout>
  )
}

export default injectIntl(Volunteering);
