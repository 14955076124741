import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';

import Checkbox from 'components/Checkbox';
import useSetState from 'utils/useSetState';

import {
  Modal,
  Row,
  Col,
} from 'antd';

import './style.less';


const reasonOptions = [
  'curiosity',
  'useful_to_others',
  'develop_other_skills',
  'opportunity_to_exercise_citizenship',
  'other_reason',
];

const OnboardingVolunteeringMotivation = ({
  onboardingMode = false,
  intl,
  details = {},
  visible,
  onClose,
  onSave,
  loading,
  isRequired,
}) => {
  const t = intl.messages;

  const [saving, setSaving] = useState(false);

  const {
    has: isSelected,
    toggle: toggleSelected,
    set: selectedItems,
  } = useSetState( details.volunteering_motivation_reason || [] );

  const handleSave = useCallback(() => {
    const initialValue = details.volunteering_motivation_reason || [];
    if (
      initialValue.length === selectedItems.length &&
      selectedItems.every(slug => initialValue.includes(slug))
    ) {
      // Bail out
      return onClose();
    }

    setSaving(true);
    onSave({
      ...details,
      volunteering_motivation_reason: selectedItems,
    });
  }, [
    details,
    onClose,
    onSave,
    selectedItems,
  ]);

  useEffect(() => {
    if(saving && !loading) {
      setSaving(false);
      onClose();
    }
  }, [
    saving,
    loading,
    onClose,
  ]);

  return (
    <Modal
      className="Onboarding__modal"
      onOk={handleSave}
      onCancel={onClose}
      okText={t.save}
      cancelText={onboardingMode ? t.continue_without_this : t.cancel}
      visible={visible}
      animationType="slide-up"
      title={t.onboarding_volunteering_motivation_title}
      cancelButtonProps={{ style: { display: isRequired ? 'none' : 'initial' } }}
      maskClosable={!isRequired}
      keyboard={!isRequired}
      closable={!isRequired}
    >
      <div className="Onboarding__modal-inner">
        <Row className="Onboarding__modal-details-row">
          <span>
            {t.onboarding_volunteering_motivation_reason_title}
          </span>
          <Col span={24}>
            {reasonOptions.map(slug => (
              <Row align="middle" key={slug}>
                <Col span={24}>
                  <Checkbox
                    checked={isSelected(slug)}
                    onClick={() => toggleSelected(slug)}
                    title={t[`onboarding_volunteering_motivation_option_${slug}`]}
                  />
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default injectIntl(OnboardingVolunteeringMotivation);
