import React from 'react';
import { injectIntl } from 'react-intl';

import './style.less';

import { Row, Col, Empty } from 'antd';
import Avatar from 'components/Avatar';
import { ReactComponent as CrownIcon } from 'assets/icons/icon-crown.svg';

const RankingCard = ({
  region,
  participants = [],
}) => {
  if (participants.length === 0) {
    return <Empty />;
  }

  const [leader, ...otherParticipants] = participants.sort(
    (a, b) => parseInt(a.rank) - parseInt(b.rank)
  );

  return (
    <>
      <Row className="RankingCard-Top">
        <Col span={24}>
          <div className="RankingCard-avatar">
            <Avatar
              region={region}
              src={leader.avatar}
              name={leader.name}
              icon={'user-circle'}
            />
            <span>#1</span>
          </div>
          <h2 className="RankingCard-name-top">
            <CrownIcon />
            { leader.name }
          </h2>
          <h2 className="RankingCard-hours-top">{ leader.hours }h</h2>
        </Col>
      </Row>
      {otherParticipants.map(({rank, avatar, name, hours}, index) => (
        <Row align="middle" justify="start" className='RankingCard-row' >
          <Col xs={3} sm={2}>
            <span className="RankingCard-list-rank">
              #{rank}
            </span>
          </Col>
          <Col xs={17} sm={16}>
            <span className="RankingCard-avatar">
              <Avatar
                region={region}
                src={avatar}
                name={name}
                icon={'user-circle'}
              />
            </span>
            <span className="RankingCard-name">
              { name }
            </span>
          </Col>
          <Col xs={4} sm={4} className="RankingCard-list-hour">
            <span>
              {hours}h
            </span>
          </Col>
        </Row>
      ))}
    </>
  );
}

export default injectIntl(RankingCard);
