import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';

import {
  Input,
  Modal,
  Row,
  Col,
  Radio,
} from 'antd';

import './style.less';


const OnboardingSpecificTraining = ({
  onboardingMode = false,
  intl,
  details = {},
  visible,
  onClose,
  onSave,
  loading,
  isRequired,
}) => {
  const t = intl.messages;
  const { TextArea } = Input;

  const [values, setValues] = useState(details);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback(() => {
    if (
      values.specific_training_cap_ccp === details.specific_training_cap_ccp &&
      values.specific_training_first_aid === details.specific_training_first_aid &&
      values.specific_training_received_volunteering_training === details.specific_training_received_volunteering_training &&
      values.specific_training_received_volunteering_training_where === details.specific_training_received_volunteering_training_where
    ) {
      // Bail out
      return onClose();
    }

    setSaving(true);
    onSave(values);
  }, [
    values,
    details,
    onClose,
    onSave,
  ]);

  const handleOnChange = useCallback(
    (key, value) => setValues({...values, [key] : value}),
    [values]
  );

  useEffect(() => {
    if(saving && !loading) {
      setSaving(false);
      onClose();
    }
  }, [
    saving,
    loading,
    onClose,
  ]);

  return (
    <Modal
      className="Onboarding__modal"
      onOk={handleSave}
      onCancel={onClose}
      okText={t.save}
      cancelText={onboardingMode ? t.continue_without_this : t.cancel}
      visible={visible}
      animationType="slide-up"
      title={t.onboarding_specific_training_title}
      cancelButtonProps={{ style: { display: isRequired ? 'none' : 'initial' } }}
      maskClosable={!isRequired}
      keyboard={!isRequired}
      closable={!isRequired}
    >
      <div className="Onboarding__modal-inner">
        <Row className="Onboarding__modal-details-row">
          <span>
            {t.onboarding_specific_training_cap_ccp_title}
          </span>
          <Col span={24}>
            <Radio.Group
              name="specific_training_cap_ccp"
              size="large"
              value={!!values.specific_training_cap_ccp}
              onChange={(e) => handleOnChange('specific_training_cap_ccp', e.target.value)}
              optionType="button"
            >
              <Radio.Button value={true}>
                { t.yes }
              </Radio.Button>
              <Radio.Button value={false}>
                { t.no }
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        <Row className="Onboarding__modal-details-row">
          <span>
            {t.onboarding_specific_training_first_aid_title}
          </span>
          <Col span={24}>
            <Radio.Group
              name="specific_training_first_aid"
              size="large"
              value={!!values.specific_training_first_aid}
              onChange={(e) => handleOnChange('specific_training_first_aid', e.target.value)}
              optionType="button"
            >
              <Radio.Button value={true}>
                { t.yes }
              </Radio.Button>
              <Radio.Button value={false}>
                { t.no }
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        <Row className="Onboarding__modal-details-row">
          <span>
            {t.onboarding_specific_training_received_volunteering_training_title}
          </span>
          <Col span={24}>
            <Radio.Group
              name="specific_training_received_volunteering_training"
              size="large"
              value={!!values.specific_training_received_volunteering_training}
              onChange={(e) => handleOnChange('specific_training_received_volunteering_training', e.target.value)}
              optionType="button"
            >
              <Radio.Button value={true}>
                { t.yes }
              </Radio.Button>
              <Radio.Button value={false}>
                { t.no }
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        { values.specific_training_received_volunteering_training
          ? <Row className="Onboarding__modal-details-row">
              <span>
                {t.onboarding_specific_training_received_volunteering_training_where_title}
              </span>
              <Col span={24}>
                <TextArea
                  value={values.specific_training_received_volunteering_training_where}
                  onChange={(e) => handleOnChange('specific_training_received_volunteering_training_where', e.target.value)}
                  placeholder={t.onboarding_specific_training_received_volunteering_training_where_placeholder}
                  rows={4}
                  autoHeight
                />
              </Col>
            </Row>
          : null
        }
      </div>
    </Modal>
  );
};

export default injectIntl(OnboardingSpecificTraining);
