import React, {
  useRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { useFeatureList } from 'components/FeatureSwitch';
import { NavLink } from 'react-router-dom';
import EXIF from 'exif-js';

import colors from 'colors';

import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  Empty,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Avatar from 'components/Avatar';
import SdgLogo from 'components/SdgLogo';
import AvatarEditor from 'components/AvatarEditor';

import {createAndUploadAvatar} from 'utils/chat'

import './style.less';

const ICON_PER_PROFILE = {
  linkedin: ["fab", "linkedin"],
  twitter: ["fab", "twitter-square"],
  github: ["fab", "github"],
  gitlab: ["fab", "gitlab"],
  facebook: ["fab", "facebook"],
  default: "user-circle",
};

const ResponsibleProfileComponent = ({
  intl,
  profile,
  pdfStatus,
  formatDateLong,
  requestGenerateResponsibleProfilePDF,
  organization,
  updateProfile,
  chatUserId,
}) => {
  const t = intl.messages;
  const [avatarImage, setAvatarImage] = useState(null);
  const [rotation, setRotation] = useState(0);
  const pdfButtonIcon = pdfStatus.processing ? 'spinner' : 'file-download';
  const pdfButtonIconLoadingClassName = pdfStatus.processing ? 'am-icon-loading' : '';
  const featureList = useFeatureList();

  const fileInput = useRef(null);

  const onFileChange = useCallback((e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    if(!file) {
      console.log('No file!');
      return;
    }

    EXIF.getData(file, function(){ // <= we need function here :(
      const orientation = EXIF.getTag(this, 'Orientation');
      switch (orientation) {
        case 8:
          setRotation(270);
          break;
        case 6:
          setRotation(90);
          break;
        case 3:
          setRotation(180);
          break;
        default:
          setRotation(0);
      }
    });

    reader.onload = function(img) {
      fileInput.current.value = '';
      setAvatarImage(img.target.result);
    }

    reader.readAsDataURL(file);
  }, []);

  const saveAvatar = useCallback((dataURL) => {
    updateProfile(organization.slug, { avatar: dataURL });
    setAvatarImage(null);
    setRotation(0);
    createAndUploadAvatar(dataURL, chatUserId)
  }, [updateProfile, organization.slug, chatUserId]);

  const hasPoints = useMemo(() => {
    const orgFeatures = organization.features;
    return orgFeatures && orgFeatures.includes('points')
  }, [
    organization,
  ]);

  return (
    <>
      <div className="ResponsibleProfile-header">
        <Row align="middle" justify="space-between">
          <Col span={featureList.has('responsible_profile') ? 12 : 24}>
            <NavLink
              exact to="/responsible_profile"
              activeClassName="active">
              <h1>{t.profile}</h1>
            </NavLink>
          </Col>
          {
            !featureList.has('rankings') || organization.config.hide_ranking_in_app
            ? null
            : (
              <Col span={12} className="ResponsibleProfile-settings-icon">
                <NavLink
                  exact to="/rankings"
                  activeClassName="active">
                  <h1>{t.rankings_nav}</h1>
                </NavLink>
              </Col>
            )
          }
        </Row>
      </div>
      <Row className="ResponsibleProfile-details">
        <Col span={24}>
          <div className="ResponsibleProfile-avatarwrapper">
            <input
              className="Profile-fileupload"
              ref={fileInput}
              type="file"
              accept="image/*"
              onChange={onFileChange}
            />
            <Avatar
              region={organization?.region}
              name={profile.name}
              src={profile.avatar}
            />
            <FontAwesomeIcon
              className="Profile-plussign"
              icon="plus-circle"
              size="3x"
            />
          </div>
        </Col>
        <Col span={24} className="ResponsibleProfile-headertext">
          <div className="ResponsibleProfile-name">{profile.name}</div>
          {Object.keys(profile.social).map(socialNetwork => (
            <div key={socialNetwork}>
              <FontAwesomeIcon
                className="ResponsibleProfile-icon"
                icon={ICON_PER_PROFILE[socialNetwork] || ICON_PER_PROFILE.default}
              />
              {profile.social[socialNetwork]}
            </div>
          ))}
          <div className="ResponsibleProfile-regulartext">
            {t.member_since}{` `}
            {profile.created_at && formatDateLong(profile.created_at, intl)}
          </div>
        </Col>
      </Row>
      {!profile.participation_summary
      ? null 
      : (
        <Row gutter={8} className="ResponsibleProfile-stats">
          <Col xs={24} md={hasPoints ? 8 : 12}>
            <Card className="ResponsibleProfile-statcard">
              <span className="card-header-content">
                {t.initiatives_participated}
              </span>
              <div className="ResponsibleProfile-largetext">
                {profile.initiatives_joined}
              </div>
            </Card>
          </Col>
          <Col xs={24} md={hasPoints ? 8 : 12}>
            <Card className="ResponsibleProfile-statcard">
              <span className="card-header-content">
                {t.hours_validated}
              </span>
              <div className="ResponsibleProfile-largetext">
                {profile.hour_count}
              </div>
            </Card>
          </Col>
          {
            !hasPoints ? null:
            <Col xs={24} md={8}>
              <Card className="ResponsibleProfile-statcard">
                <span className="card-header-content">
                  {t.responsible_profile_points}
                </span>
                <div className="ResponsibleProfile-largetext">
                  {profile.point_count || '0'}
                </div>
              </Card>
            </Col>
          }
        </Row>
      )}
      <Row className="ResponsibleProfile-stats">
        <Col span={24}>
          <Card className="ResponsibleProfile-statcard">
            <span className="card-header-content">
              {t.impacted_sdgs}
            </span>
            {profile.sdg_report && profile.sdg_report.length > 0
            ? <>
              {profile.sdg_report.map(({ id, value }) =>
                <Row
                  key={id}
                  className="ResponsibleProfile-card-row"
                  align="middle"
                  gutter={8}
                  justify="space-between"
                >
                  <Col
                    span={4}
                    className="ResponsibleProfile-card-thumb"
                  >
                    <SdgLogo sdg={id} />
                  </Col>
                  <Col
                    span={16}
                    className="ResponsibleProfile-card-name"
                  >
                    {t[`sdg_${id}`]}
                  </Col>
                  <Col
                    span={4}
                    className="ResponsibleProfile-card-hours"
                  >
                    {value} h
                  </Col>
                </Row>
              )}
            </>
            : <div className="ResponsibleProfile-emptytext">
                <Empty description={t.impacted_sdgs_empty_join} />
              </div>
            }
          </Card>
        </Col>
      </Row>
      <Row className="ResponsibleProfile-stats">
        <Col span={24}>
          <Card className="ResponsibleProfile-statcard">
            <span className="card-header-content">
              {t.competences_applied}
            </span>
            {profile.competence_report && profile.competence_report.length > 0
            ? <>
              {profile.competence_report.map(({ id, value }) =>
                <Row
                  key={id}
                  className="ResponsibleProfile-card-row"
                  align="middle"
                  gutter={8}
                >
                  <Col span={20} className="ResponsibleProfile-card-name">
                    {t[`competence_${id}`]}
                  </Col>
                  <Col span={4} className="ResponsibleProfile-card-hours">
                    {value} h
                  </Col>
                </Row>
              )}
            </>
            : <div className="ResponsibleProfile-emptytext">
                <Empty description={t.competences_empty_join} />
              </div>
            }
          </Card>
        </Col>
      </Row>
      <Row className="ResponsibleProfile-stats">
        <Col span={24}>
          <Card className="ResponsibleProfile-statcard">
            <span className="card-header-content">
              {t.initiatives_joined}
            </span>
            {profile.initiative_report && profile.initiative_report.length > 0
            ? <>
              {profile.initiative_report.map(({ id, title, start_time, end_time, valid_hours, points }) =>
                <Row
                  key={id}
                  className="ResponsibleProfile-card-row"
                  align="middle"
                  gutter={8}
                >
                  <Col span={20} className="ResponsibleProfile-card-name">
                    <div>
                      {title}
                    </div>
                    <div className="ResponsibleProfile-date">
                      {`${formatDateLong(start_time, intl)} - ${formatDateLong(end_time, intl)}`}
                    </div>
                  </Col>
                  <Col span={4} className="ResponsibleProfile-card-hours">
                    {
                      hasPoints
                      ? `${valid_hours} h / ${points || '-'} p`
                      : `${valid_hours} h`
                    }
                  </Col>
                </Row>
              )}
            </>
            : <div className="ResponsibleProfile-emptytext">
                <Empty description={t.initiatives_joined_empty_join} />
              </div>
            }
          </Card>
        </Col>
      </Row>
      <Row className="ResponsibleProfile-stats">
        <Col span={24}>
          {pdfStatus.done && pdfStatus.url
          ? <Button
              className="ResponsibleProfile-pdfbutton"
              download
              href={pdfStatus.url}
              target="_blank"
            >
              <FontAwesomeIcon
                icon="file-download"
                size="lg"
                className="am-button-icon"
              />
              <span>{t.responsible_profile_download}</span>
            </Button>
          : <Button
              className="ResponsibleProfile-pdfbutton"
              onClick={() => requestGenerateResponsibleProfilePDF(organization.slug)}
            >
              <FontAwesomeIcon
                icon={pdfButtonIcon}
                size="lg"
                color={colors['@brand-primary']}
                className={`am-button-icon ${pdfButtonIconLoadingClassName}`}
              />
              <span>{pdfStatus.processing ? t.responsible_profile_loading : t.responsible_profile_generate}</span>
            </Button>
          }
        </Col>
      </Row>
      {!avatarImage
      ? null
      : <Modal
        visible
        closable={false}
        maskClosable={false}
        className="AvatarEditor"
      >
        <AvatarEditor
          t={t}
          src={avatarImage}
          rotation={rotation}
          onCrop={saveAvatar}
          onCancel={() => { setAvatarImage(null); setRotation(0); }}
        />
      </Modal>}
    </>
  );
}

export default injectIntl(ResponsibleProfileComponent);
