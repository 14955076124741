import QuickBlox from 'quickblox/quickblox.min'
import {nanoid} from "nanoid";

import {
    updateProfile,
  } from 'actions/api';


function srcToFile(src, fileName, mimeType){
	return (fetch(src)
    .then(function(res){return res.arrayBuffer();})
    .then(function(buf){return new File([buf], fileName, {type:mimeType});})
	)
}

const QUICKBLOXPLUS = '__quickbloxplus__'

export const convertEmailToQuickBloxType = (email) => {
  return email.replace('+', QUICKBLOXPLUS)
}

export const convertEmailQuickBloxTypeToEmail = (email) => {
  return email.replace(QUICKBLOXPLUS, '+')
}

export const createAndUploadAvatar = async (path, userId) => {
  var inputFile = await srcToFile(path, 'avatar.png', 'image/png')

  var fileParams = {
    name: inputFile.name,
    file: inputFile,
    type: inputFile.type,
    size: inputFile.size,
    public: false,
  }

  QuickBlox.content.createAndUpload(fileParams, function(error, result) {
    if (error) {
      console.error("Error uploading avatar to quickblox", error)
    } else {
      var updatedUserProfile = {
        blob_id: result.id
      };
      QuickBlox.users.update(userId, updatedUserProfile, function(error, user) {});
    }
  })
}

export const updateUsername = async (username, userId) => {
  var updatedUserProfile = {
    full_name: username
  };
  QuickBlox.users.update(userId, updatedUserProfile, function(error, user) {});
}

export const createQuickBloxUser = (async ({email, name, avatar}, organization_slug, dispatch) => {
  await new Promise((resolve, reject) => QuickBlox.createSession((error, result) => error ? reject(error) : resolve(result)))
  const quickblox_password = nanoid()
  await new Promise((resolve, reject) => 
    {
      QuickBlox.users.create(
      {
        email: convertEmailToQuickBloxType(email), 
        password: quickblox_password, 
        full_name: name,	
        tag_list: [organization_slug]
      }, 
      async (error, result) => {
        if(error) reject(error)
        else {
          QuickBlox.createSession({email: convertEmailToQuickBloxType(email), password: quickblox_password}, function(error, res) {
              if (error){ 
                console.error(error)
              } else if(avatar) {
                createAndUploadAvatar(avatar, result.id)
              }
            })
            dispatch(updateProfile(organization_slug, {quickblox_password}))
          resolve(result)
        }
      }
    )}
  )
})

export const getQuickBloxUserAvatar = async (blob_id) => {
	var fileId = blob_id;
	return new Promise((resolve, reject) => {
		QuickBlox.content.getInfo(fileId, function(error, result) {
			if (error) {
				reject("Get file information by ID error: " + JSON.stringify(error));
			} else {
				var fileUID = result.blob.uid;
				var fileUrl = QuickBlox.content.privateUrl(fileUID);
				resolve(fileUrl)
			}
		})
	})
}

