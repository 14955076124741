import React, { useState, useRef } from 'react';
import ReactAvatarEditor from 'react-avatar-editor'

import './style.less';

import { Slider, Button, Row, Col } from 'antd';

const AvatarEditor = ({ t, src, rotation, onCrop, onCancel }) => {
  const editorRef = useRef(null);

  // Zoom vs Scale as follows:
  // zoom=0 => scale=0.5
  // zoom=50 (default) => scale=1
  // zoom=100 => scale=2.5
  const [zoom, setZoom] = useState(50);
  const scale = 0.5 + ((zoom*zoom) / 5000);

  const doCrop = () => {
    //onCrop(editorRef.current.getImage().toDataURL());
    onCrop(editorRef.current.getImageScaledToCanvas().toDataURL());
  };

  return (
    <div>
      <ReactAvatarEditor
        ref={editorRef}
        image={src}
        rotate={rotation}
        width={360}
        height={360}
        borderRadius={360}
        scale={scale}
      />
      <Row>
        <Col span={24}>
          <p>{ t.zoom }</p>
          <Slider
            className="AvatarEditor-slider"
            value={zoom}
            min={0}
            max={100}
            step={1}
            onChange={setZoom}
          />
        </Col>
      </Row>
      <Row gutter={2}>
        <Col span={12}>
          <Button
            type="primary"
            onClick={doCrop}
          >
            { t.ok_crop }
          </Button>
        </Col>
        <Col span={12}>
          <Button
            onClick={onCancel}
          >
            { t.cancel_crop }
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default AvatarEditor;
