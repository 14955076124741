import {useState, useEffect, useReducer, useMemo, useCallback} from "react";
import {
	useSelector,
  useDispatch
} from 'react-redux'
import QuickBlox from 'quickblox/quickblox.min'
import {find, difference} from "lodash"
import {chat as chatReducer} from 'reducers/chat'
import useDebounceTyping from './useDebounceTyping'
import {
	chatAddActiveConversation,
	chatAddMessage,
  chatAddActiveUser,
  chatAddUsers,
  chatAddConversation,
  chatChangeLogInStatus,
  chatSetCurrentMessage,
  chatSetLastMessage,
  chatSetUnreadCountConversation,
  chatSetUnreadCountActiveConversation,
  chatAddTypingUser,
  chatRemoveTypingUser,
} from 'actions/chat';

import {
  createQuickBloxUser,
  getQuickBloxUserAvatar,
  convertEmailToQuickBloxType,
  convertEmailQuickBloxTypeToEmail,
} from 'utils/chat'

import config from 'config'
const {QUICKBLOX_APP_ID, QUICKBLOX_AUTH_KEY, QUICKBLOX_AUTH_SECRET, QUICKBLOX_ACCOUNT_KEY} = config

const MESSAGE_DIRECTION = {
  outgoing: 'outgoing',
  incoming: 'incoming',
}
const MESSAGE_STATUS_SENT = 'sent'
const USER_ROLE_SYSTEM = 'system'
const USERS_LIST_LIMIT = 500
const MESSAGES_LIMIT = 1000
const DIALOGS_LIMIT = 1000

QuickBlox.init(QUICKBLOX_APP_ID, QUICKBLOX_AUTH_KEY, QUICKBLOX_AUTH_SECRET, QUICKBLOX_ACCOUNT_KEY);

const initQuickBloxService = (email, quickblox_password, setActiveUserQBId, dispatch) => {
	new Promise((resolve, reject) => QuickBlox.createSession((error, result) => error ? reject(error) : resolve(result)))
  .then(async _result => {
    const loginResult = await new Promise((resolve, reject) => 	QuickBlox.login({email: convertEmailToQuickBloxType(email), password: quickblox_password}, (error, result) => error ? reject(error) : resolve(result)))
    await new Promise((resolve, reject) => QuickBlox.chat.connect({password: quickblox_password, userId: loginResult.id}, (error, result) => error ? reject(error) : resolve(result)))
    dispatch(chatChangeLogInStatus(true))
    setActiveUserQBId(loginResult.id)
  })
  .catch(error => {
    console.error(error)
    if(error.code === 422) initQuickBloxService(email, quickblox_password, setActiveUserQBId, dispatch)
  })
}

function useChat() {

  const [state, dispatch] = useReducer(chatReducer, {
    loggedIn: false,
    activeUser: {},
    users: [],
    activeConversation: null,
    conversations: {},
    messages: {},
  });

  const [activeUserQBId, setActiveUserQBId] = useState();
  const organization_slug = useSelector(state => state.organization.slug);
  const {data : profile} = useSelector(state => state.my_profile)
  const {activeUser} = useSelector(state => state.chat)
  const {
    users,
    messages,
    conversations,
    activeConversation,
    loggedIn,
    currentMessage,
  } = state
  const currentMessages = useMemo(() => state.messages[state.activeConversation?.id] ? state.messages[state.activeConversation.id] : null, [state.activeConversation, state.messages])
  const reduxDispatch = useDispatch()
  
  useEffect(() => {
    if(profile.quickblox_password) initQuickBloxService(profile.email, profile.quickblox_password, setActiveUserQBId, dispatch)
  }, [profile.email, profile.quickblox_password])

  useEffect(() => {
    if(profile.quickblox_password === null && organization_slug && profile.role !== USER_ROLE_SYSTEM) createQuickBloxUser(profile, organization_slug, reduxDispatch)
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [profile.quickblox_password, profile.role, organization_slug]
  )

  useEffect(() => {
    if(activeUserQBId) reduxDispatch(
      chatAddActiveUser(
        {
          id: activeUserQBId,
          firstName: "",
          lastName: "",
          username: profile.name,
          email:"",
          avatar: profile.avatar,
          bio:""
        }
      )
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUserQBId, profile.avatar, profile.name, profile.slug])
  
  useEffect(() => {
    if(activeConversation?.id && !currentMessages) {
      var params = {
        chat_dialog_id: activeConversation.id,
        sort_desc: 'date_sent',
        limit: MESSAGES_LIMIT
      };
      
      QuickBlox.chat.message.list(params, (error, messages) => {
        if(error) console.error(error)
        const newMessages = messages?.items.map(ms => ({
            id: ms._id,
            content: ms.message,
            contentType: 2,
            senderId: ms.sender_id,
            direction: ms.sender_id === activeUserQBId ? MESSAGE_DIRECTION.outgoing : MESSAGE_DIRECTION.incoming, 
            status: 1,
            updated_at: ms.updated_at,
        }))
        newMessages.reverse()
        dispatch(chatAddMessage(activeConversation.id, newMessages))
      })
    }
  }, [activeConversation?.id, activeUserQBId, currentMessages])

  const getUser = (id) => find(users, ['id', id])
  const getConversation = useCallback((id) => conversations[id], [conversations])
  const setActiveConversation = (id) => dispatch(chatAddActiveConversation(conversations[id]))
  const setCurrentMessage = (message) => dispatch(chatSetCurrentMessage(message))
  const removeTypingUser = (dialogId, userId) => dispatch(chatRemoveTypingUser(dialogId, userId))
  const debouncedTyping = useDebounceTyping(900, removeTypingUser, getConversation);
  
  const getOrganizationUsers = async () => {
    var searchParams = {tags: [organization_slug], per_page: USERS_LIST_LIMIT};
    await QuickBlox.users.get(searchParams, async function(error, response) {
      if(error) console.error(error)
      const allUsers = await Promise.all(
        (response?.items || []).map( async ({user: u}) => {
          const userEmail = convertEmailQuickBloxTypeToEmail(u.email)
          if (userEmail !== profile.email) {
            const avatar = u.blob_id ? await getQuickBloxUserAvatar(u.blob_id) : null
            return {
              id: u.id,
              username: u.full_name,
              email: userEmail,
              avatar: avatar,
            }
          }
        })
      )
      dispatch(chatAddUsers(allUsers.filter(Boolean)))
    });
  }

  const getOrganizationDialogs = async () => {
	  const openedDialogs = await new Promise ((res, rej) => QuickBlox.chat.dialog.list({limit: DIALOGS_LIMIT}, (error, dialogs) => error ? rej(error) : res(dialogs.items)))
    users.forEach( async (user) => {
      if (user.email !== profile.email) {
        let existingDialog = find(openedDialogs, dialog => !difference(dialog.occupants_ids, [activeUserQBId, user.id]).length)
        var params = {
          type: 3,
          occupants_ids: [user.id],
        };
        if(!existingDialog) existingDialog = await new Promise ((res, rej) => QuickBlox.chat.dialog.create(params, (error, dialog) => res(dialog)))
        dispatch(chatAddConversation({
          id: existingDialog._id,
          participants: [
            {
                id: user.id,
            }
          ],
          unreadCounter: existingDialog.unread_messages_count,
          typingUsers: [],
          last_message: existingDialog.last_message,
          last_message_user_id: existingDialog.last_message_user_id,
          last_message_timestamp: existingDialog.last_message ? existingDialog.updated_at : null
        }))
      }
    });
  }

  const sendTyping = (conversationId) => {
    const opponentId = conversations[conversationId].participants[0].id
    try {
      QuickBlox.chat.sendIsTypingStatus(opponentId);
    } catch (e) {
    if (e.name === "ChatNotConnectedError") {
      console.error("not connected to chat")
    }}
  }

  const sendMessage = (message) => {
    
    const opponentId = conversations[activeConversation.id].participants[0].id
    const QBMessage = {
        type: "chat",
        body: message.content,
        extension: {
          save_to_history: 1,
          dialog_id: activeConversation.id
        },
        markable: 1
      };
        
    QuickBlox.chat.send(opponentId, QBMessage);
    dispatch(chatSetUnreadCountConversation(activeConversation.id, 0))
    dispatch(chatSetUnreadCountActiveConversation(0))
    dispatch(chatAddMessage(activeConversation.id, [message]))
    dispatch(chatSetCurrentMessage(""))
    dispatch(chatSetLastMessage(activeConversation.id, message.content, activeUser.id))
  }

  const getLastMessage = useCallback((dialogId) => {
    const conversation = getConversation(dialogId)
    if(conversation?.last_message){
      const lastMessage = {
        content: conversation.last_message,
        direction: conversation.last_message_user_id === activeUserQBId ? MESSAGE_DIRECTION.outgoing : MESSAGE_DIRECTION.incoming,
      }
      return lastMessage
    }
    else return ""
  }, [activeUserQBId, getConversation])

  const initOrganizationChat = async () => {
    await getOrganizationUsers()
    await getOrganizationDialogs()
  }

  const markMessageAsRead = (messageId, userId, dialogId) => {
    try {
      QuickBlox.chat.sendReadStatus({
        messageId,
        userId,
        dialogId,
      })
    } catch (e) {
      if (e.name === "ChatNotConnectedError") {
        console.error("not connected to chat")
      }
    }
  }

  const setMessagesAsRead = (conversationId, allMessages, unreadCounter) => {
    const lastMessageGroup = allMessages[allMessages.length - 1]
    const unreadMessages = lastMessageGroup.messages.slice(-unreadCounter)
    unreadMessages.forEach(unreadMessage => {
      markMessageAsRead(unreadMessage.id, unreadMessage.senderId, conversationId)
    })
  }

  QuickBlox.chat.onMessageListener = (userId, ms) => {
    if (ms.recipient_id) return
    const message = {
      id: ms.id,
      content: ms.body,
      senderId: userId,
      direction: MESSAGE_DIRECTION.incoming,
      status: MESSAGE_STATUS_SENT,
    };
    if(activeConversation?.id === ms.dialog_id) {
      markMessageAsRead(ms.id, userId, ms.dialog_id)
      dispatch(chatSetUnreadCountConversation(ms.dialog_id, 0))
      dispatch(chatSetUnreadCountActiveConversation(0))
      dispatch(chatAddMessage(ms.dialog_id, [message]))
    } else {
      const conversation = getConversation(ms.dialog_id);
      dispatch(chatSetUnreadCountConversation(ms.dialog_id, conversation.unreadCounter + 1))
      const dialogMessages = messages[conversation.id]
      if(dialogMessages) dispatch(chatAddMessage(ms.dialog_id, [message]))
    }
    dispatch(chatSetLastMessage(ms.dialog_id, ms.body, userId))
  }

  QuickBlox.chat.onMessageTypingListener = (isTyping, userId, dialogId) => {
    if (isTyping) {
        const conversationId = find(Object.values(conversations), conv => conv.participants[0].id === userId).id
        const conversation = getConversation(conversationId);
        if (conversation) {
          debouncedTyping(conversationId, userId);
          dispatch(chatAddTypingUser(conversationId, userId))
        }
    }
  }

  return {
    users, 
    conversations: Object.values(conversations), 
    activeConversation, 
    activeUser, 
    loggedIn,
    currentMessages, 
    currentMessage,
    initOrganizationChat, 
    getUser, 
    setActiveConversation,
    getOrganizationUsers,
    getOrganizationDialogs,
    setCurrentMessage,
    sendTyping,
    sendMessage,
    getLastMessage,
    setMessagesAsRead,
    messages,
  }
}

export default useChat