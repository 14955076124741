import React, {
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

import { formatShort } from 'utils/date';

const ManagerApproval = ({
  intl,
  manager: initiativeManager,
  organization = {},
  profile = {},
  manager_approval_status,
  participant_created_at,
  is_working_hours,
}) => {

  const showDate = ['rejected', 'approved'].includes(manager_approval_status);
  const manager = initiativeManager || profile?.manager || {};
  const statusLabel = useMemo(() => {
    if(['rejected', 'approved', 'pending'].includes(manager_approval_status)) {
      return intl.formatMessage({ id: `manager_approval_status_${manager_approval_status}` });
    }
    if(
      organization.config.require_manager_approval && (
        !organization.config.consider_working_hours || is_working_hours
      )
    ) {
      return intl.formatMessage({ id: `manager_approval_status_needed` });
    }
    return intl.formatMessage({ id: `manager_approval_status_not_needed` });
  }, [
    intl,
    manager_approval_status,
    organization,
    is_working_hours,
  ]);

  return (
    <div className="manager-approval">
      <span className="title">{ intl.formatMessage({ id: `manager_approval` }) }</span>
      <div className="item">
        <div className="item-name">
          { intl.formatMessage({ id: `manager_approval_status` }) }
        </div>
        <div className="item-value">
          { statusLabel }
        </div>
      </div>

      <div className="item">
        <div className="item-name">
          { intl.formatMessage({ id: `manager_name` }) }
        </div>
        <div className="item-value">
          { manager.name || '-' }
        </div>
      </div>

      <div className="item">
        <div className="item-name">
          { intl.formatMessage({ id: `manager_email` }) }
        </div>
        <div className="item-value">
          { manager.email || '-' }
        </div>
      </div>

      { showDate &&
      <div className="item">
        <div className="item-name">
          { intl.formatMessage({ id: `date` }) }
        </div>
        <div className="item-value">
          { formatShort(participant_created_at, intl) }
        </div>
      </div>
      }
    </div>
  );
};

export default injectIntl(ManagerApproval);

