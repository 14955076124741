import React from 'react';
import { Row, Col, Checkbox } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.less';

const CheckBox = ({
  checked,
  title,
  onClick,
  icon,
}) => {
  return (
    <Row
      align="middle"
      onClick={onClick}
      className={`checkbox-row ${checked && 'selected'}`}
    >
      <Col span={20} className="header">
        {icon && <FontAwesomeIcon icon={icon} />}
        {title}
      </Col>
      <Col span={4} className="checkbox">
        <Checkbox
          checked={checked}
        />
      </Col>
    </Row>
  );
}

export default CheckBox;
