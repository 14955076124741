import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { useFeatureList } from 'components/FeatureSwitch';

import './style.less';

import { TabBar } from 'antd-mobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ReactComponent as HomeIcon } from 'assets/icons/icon-home.svg';
import { ReactComponent as ProposalsIcon } from 'assets/icons/icon-proposals.svg';
import { ReactComponent as MegaphoneIcon } from 'assets/icons/icon-megaphone.svg';
import { ReactComponent as GearsIcon } from 'assets/icons/icon-gears.svg';

const NavBottom = ({
  location,
  history,
  my_profile,
}) => {
  const path = location.pathname;
  const push = history.push;
  const featureList = useFeatureList();

  const profileIcon = useCallback(
    (className) => {
      const avatar = (my_profile.data || {}).avatar;
      return avatar
        ? <img className={className} alt="" src={my_profile.data && my_profile.data.avatar} />
        : <FontAwesomeIcon className={className} size="lg" icon="user-circle" />
    }, [my_profile]
  )

  const tabs = useMemo(() => [
    {
      key: 'wall',
      icon: HomeIcon,
      route: '/wall',
    }, {
      key: 'initiative',
      icon: MegaphoneIcon,
      route: '/initiative',
    },
    (
      featureList.has('proposals') && {
        key: 'propose_initiative',
        icon: ProposalsIcon,
        route: '/proposal',
      }
    ),
    {
      key: 'settings',
      icon: GearsIcon,
      route: '/settings',
    },
  ].filter(Boolean), [ featureList ]);

  return (
    <>
      <div className="NavBottom-wrapper">
        <div className="NavBottom">
          <TabBar>
            { tabs.map(({ key, icon: Icon, route }) => (
              <TabBar.Item
                badge=""
                key={key}
                icon={<Icon />}
                selectedIcon={<Icon className="active" />}
                selected={path === route}
                onPress={() => push(route)}
              />
            )) }
            <TabBar.Item
              icon={profileIcon('profile')}
              selectedIcon={profileIcon('profile-selected')}
              selected={path === '/responsible_profile' || path === '/settings'}
              onPress={() => push(
                featureList.has('responsible_profile')
                ? '/responsible_profile'
                : '/settings'
              )}
            />
          </TabBar>
        </div>
      </div>
      <div className="NavBottom-placeholder"></div>
    </>
  )
}

const mapStateToProps = ({
  my_profile,
}) => ({
  my_profile,
});

export default injectIntl(connect(mapStateToProps)(withRouter(NavBottom)));
