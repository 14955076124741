import React from 'react';
import { injectIntl } from 'react-intl';

import {
  Drawer,
  Col,
  Row,
  Input
} from 'antd';

import A from 'components/A';

import useScreenSize from 'utils/useScreenSize';

const Contact = ({
  intl,
  contact_person,
  contact_email,
  contact_phone,
  onClose
}) => {
  const mailto_link = `mailto:${contact_email}`;
  const tel_link = `tel:${contact_phone}`;

  return (
    <Drawer
      maskClosable
      className="Contact-drawer"
      placement={
        ['xs', 'sm', 'md'].includes(useScreenSize()) ? 'bottom' : 'right'
      }
      visible={true}
      onClose={onClose}
      title={intl.formatMessage({ id: `contact_organizer` })}
    >
      {contact_phone && contact_phone.trim() &&
      <Row>
        { intl.formatMessage({ id: `phone` }) }
        <Col span={24}>
          <A href={contact_phone ? tel_link : undefined} >
            <Input
              value={contact_phone.trim() || intl.formatMessage({ id: `not_available` })}
              addonAfter={intl.formatMessage({ id: `call` })}
              disabled
            />
          </A>
        </Col>
      </Row>
      }
      <Row>
        { intl.formatMessage({ id: `email` }) }
        <Col span={24}>
          <A href={mailto_link} >
            <Input
              value={contact_email}
              addonAfter={intl.formatMessage({ id: `send_email` })}
              disabled
            />
          </A>
        </Col>
      </Row>
    </Drawer>
  );
};

export default injectIntl(Contact);
