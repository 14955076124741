import React, {
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';

import './style.less';

const TIMEOUT = 1000; // 1 second

const SsoSuccess = ({
  intl,
}) => {
  const t = intl.messages;

  useEffect(() => {
    if(window.opener) {
      window.opener.location.reload();
      setTimeout(window.close, TIMEOUT);
    } else {
      window.location = '/';
    }
  }, []);

  return (
    <div>
      { t.sso_success }
    </div>
  )
}

export default injectIntl(SsoSuccess);
