import React from 'react';
import { injectIntl } from 'react-intl';

const Indicators = ({
  intl,
  indicators = [],
}) => {
  return (
    <>
      <span className="title">{ intl.formatMessage({ id: `indicators_to_collect` }) }</span>
      { indicators.map(
        ({
          name,
          unit,
        }) => (
          <div className="item">
            <div className="item-name">
              { name }
            </div>
            <div className="item-value">
              { intl.formatMessage({ id: `unit_${unit}` }) }
            </div>
          </div>
        ))
      }
    </>
  );
};

export default injectIntl(Indicators);

