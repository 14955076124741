import React, {
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Button,
} from 'antd';

import './style.less';

const DEFAULT_COVER_URL = '/images/cover.jpg';

const PreSignIn = ({
  intl,
  location,
  organization,
}) => {
  const t = intl.messages;

  const cover_url = useMemo(() => {
    return organization?.config?.cover_url || DEFAULT_COVER_URL
  }, [
    organization,
  ]);

  return (
    <>
      <Row
        type="flex"
        align="bottom"
        justify="center"
        className="PreSignIn"
        style={{
          background: `linear-gradient( to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url('${cover_url}')`,
          backgroundSize: "cover"
        }}
      >
        <Col className="heading">
          {t.manage_with_aplanet}
        </Col>
        <Col xs={24} md={24}>
          <Link to="/signup">
            <Button className="brand-button-primary">{t.create_an_account}</Button>
          </Link>
          <Link to="/signin">
            <Button className="brand-button-secondary" >{t.signin}</Button>
          </Link>
        </Col>
      </Row>
    </>
    )
}

const mapStateToProps = ({
  organization,
}) => ({
  organization,
});

export default injectIntl(
  connect(
    mapStateToProps,
    {}
  )(PreSignIn)
);
