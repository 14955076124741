import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import Linkify from 'react-linkify';
import {
  useSelector,
  useDispatch,
} from 'react-redux';

import ReactMarkdown from 'react-markdown';

import * as Sentry from '@sentry/react';
import { ErrorBoundaryFallback } from 'aplanet-ui-kit';

import AppHeader from 'containers/AppHeader';
import Nav from 'containers/Nav';

import {
  requestMyInitiatives,
  registerInInitiative,
  notGoingToInitiative,
  interestedInInitiative,
  notInterestedInInitiative,
} from 'actions/api';
import { updateInitiativeBannerVisibility } from 'actions/app';

import useNow from 'utils/useNow';

import NavBottom from 'containers/NavBottom';
import PhoneModal from 'containers/PhoneModal';
import InitiativeCard from 'components/InitiativeCard';
import InitiativeFilter from 'components/InitiativeFilter';

import { isAfterNow } from 'utils/date';

import './style.less';

import {
  Button,
  Spin,
  Row,
  Col,
  Layout,
  Tabs,
  Empty,
  Alert,
  Modal,
} from 'antd';

const { TabPane } = Tabs;
const CANCELED_STATUSES = new Set([ 'suspended', 'canceled' ]);

const LoadingInitiatives = () => (
  <Row justify="center" align="center">
    <Col>
      <Spin />
    </Col>
  </Row>
);

const NoInitiativeFound = ({ t, text, onClick }) => (
  <div className="no-initiative">
    <Empty
      className="empty"
      image={'images/empty-card.svg'}
      description={text}
    />
    <h2>{ t.explore_how }</h2>
    <Button
      className="button"
      onClick={onClick}
    >
      { t.check_wall }
    </Button>
  </div>
);

const MyInitiatives = ({
  intl,
  history,
  location,
}) => {
  const t = intl.messages;
  const now = useNow();
  
  const dispatch = useDispatch();
  const push = history.push;

  const {
    items: allInitiatives = [],
    loading,
  } = useSelector(state => state.my_initiatives);

  const {
    data: profile
  } = useSelector(state => state.my_profile);

  const organization = useSelector(state => state.organization);

  const {
    isInitiativeBannerVisible,
  } = useSelector(state => state.app);

  useEffect(() => {
    dispatch(requestMyInitiatives(organization.slug));
  }, [organization.slug, dispatch]); // TODO: Pull to refresh


  const onInitiativeBannerClose = useCallback(() => {
    dispatch(updateInitiativeBannerVisibility(false));
  }, [
    dispatch,
  ]);

  const [ phoneModalShown, setPhoneModalShown ] = useState(false);

  const lastInitiative = useRef(null);
  const lastInitiativeHash = location.hash && location.hash.slice(1);
  useEffect(() => {
    // NOTICE: This is not great beacuse we leave this effect open
    // and it could affect us if we update either of these:
    // `allInitiatives`: for example if we get live updates via websockets
    // `location.hash`: for example if we track any state (such as modals open) in the URL
    if(lastInitiative.current) {
      lastInitiative.current.scrollIntoView(false);
    }
  }, [ lastInitiative, allInitiatives ]);

  useEffect(() => {
    if(lastInitiativeHash) {
      if(profile && !profile.phone && organization.config?.ask_member_phone) {
        setPhoneModalShown(true);
      }
      const {
        initiative_participation_success_message,
      } = organization?.config || {};
      Modal.success({
        title: t.initiative_join_success_title,
        content: (
          <div>
            {
              initiative_participation_success_message
              ? (
                <ReactMarkdown
                  source={initiative_participation_success_message}
                  className="initiatives__success_message"
                  linkTarget="_blank"
                />
              ) : (
                <span>
                  { t.initiative_join_success_desc }
                </span>
              )
            }
          </div>
        ),
      });
    }
  }, [
    t,
    lastInitiativeHash,
    profile,
    organization,
  ]);

  const pastInitiatives = useMemo(
    () => allInitiatives.filter(({ end_time, status }) => {
      return !isAfterNow(end_time) || CANCELED_STATUSES.has(status);
    }),
    [ // eslint-disable-line react-hooks/exhaustive-deps
      allInitiatives,
      now, // Not needed, but we need to recalculate
    ]);

  const activeInitiatives = useMemo(
    () => allInitiatives.filter(({ id }) => !pastInitiatives.map(i => i.id).includes(id)),
    [
      pastInitiatives,
      allInitiatives,
    ]
  );

  const initiativeBanner = useMemo(() => organization.config.initiative_banner || {},
  [organization],
  );

  const hasPoints = useMemo(() => {
    const orgFeatures = organization?.features;
    if(!orgFeatures) {
      return false;
    }
    return orgFeatures.includes('points');
  }, [
    organization
  ]);

  const hasProgram = useMemo(() => {
    const orgFeatures = organization?.features;
    if(!orgFeatures) {
      return false;
    }
    return orgFeatures.includes('program');
  }, [
    organization
  ]);

  const renderInitiatives = useCallback((initiatives, searchText, tabKey) => (
    <Row type="flex" justify="start" gutter={[8, 20]}>
    { initiatives.map(initiative => (
      <Col key={initiative.slug} xs={24} sm={12} xl={8} xxl={6}>
        <div
          id={initiative.slug}
          key={initiative.slug}
          ref={tabKey === 'active' && lastInitiativeHash && lastInitiativeHash === initiative.slug ? lastInitiative : null}
          className="initiativeCard-wrapper"
        >
          <InitiativeCard
            profile={profile}
            initiative={initiative}
            loading={loading}
            considerParticipantCount={false}
            viewDetail={() => push(`/initiative/${initiative.slug}`)}
            join={() => dispatch(registerInInitiative(organization.slug, initiative.slug))}
            leave={() => dispatch(notGoingToInitiative(organization.slug, initiative.slug))}
            participate={() => push(`/initiative/${initiative.slug}/participate`)}
            contribute={() => push(`/initiative/${initiative.slug}/contribute`)}
            searchText={searchText}
            interested={() => dispatch(interestedInInitiative(organization.slug, initiative.slug))}
            notInterested={() => dispatch(notInterestedInInitiative(organization.slug, initiative.slug))}
            hasPoints={hasPoints && initiative.points > 0}
            hasProgram={hasProgram && initiative.program}
          />
        </div>
      </Col>))}
    </Row>
  ), [
    organization,
    loading,
    profile,
    dispatch,
    push,
    lastInitiativeHash,
    hasPoints,
    hasProgram,
  ]);

  return (
    <Layout>
      <AppHeader/>
      <Layout>
        <Nav/>
        <Sentry.ErrorBoundary
          fallback={
          <ErrorBoundaryFallback
            titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
            buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
            descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_initiatives_message' })}
            customErrorImage="/images/error_image.png"
          />
        }>
          <Layout.Content className="initiatives">
            <Tabs
              defaultActiveKey="active"
              renderTabBar={(props) =>
                <div className="initiative-tab">
                  <h1>{t.my_initiatives}</h1>
                  <div className="tabs">
                  {props.panels.map((tab, index) => (
                    <span
                      key={`${tab.key}_${index}`}
                      className={(props.activeKey === tab.key ? 'active' : 'inactive')}
                      onClick={() => props.onTabClick(tab.key)}
                    >
                      {tab.props.tab}
                    </span>))}
                  </div>
              </div>
              }
            >
              <TabPane tab={t.active_initiatives} key="active">
                { loading
                ? <LoadingInitiatives />
                : (
                  <>
                    { initiativeBanner.visible && isInitiativeBannerVisible &&
                      <Alert
                        showIcon
                        closable
                        className="initiativeBanner"
                        type="warning"
                        onClose={onInitiativeBannerClose}
                        message={
                          <Linkify properties={{target: '_blank'}}>
                            {initiativeBanner.content}
                          </Linkify>
                        }
                      />
                    }
                    {
                      (!activeInitiatives.length
                        ? <NoInitiativeFound t={t} text={t.no_active_initiatives} onClick={() => push('/wall')} />
                        : <InitiativeFilter
                            data={activeInitiatives}
                            profile={profile}
                            className="initiatives-filters"
                            organizationHasPoints={hasPoints}
                            organizationHasProgram={hasProgram}
                            renderChildren={
                              (initiatives, searchText) => (
                                !initiatives.length
                                ? <NoInitiativeFound t={t} text={t.no_active_initiatives} onClick={() => push('/wall')} />
                                : (renderInitiatives(initiatives, searchText, 'active'))
                              )
                            }
                          />
                        )
                      }
                    </>
                  )
                }
              </TabPane>
              <TabPane tab={t.history} key="history">
                { loading
                ? <LoadingInitiatives />
                : (!pastInitiatives.length
                    ? <NoInitiativeFound t={t} text={t.no_past_initiatives} onClick={() => push('/wall')} />
                    : <InitiativeFilter
                        data={pastInitiatives}
                        organizationHasPoints={hasPoints}
                        organizationHasProgram={hasProgram}
                        renderChildren={
                          (initiatives, searchText) => (
                            !initiatives.length
                            ? <NoInitiativeFound t={t} text={t.no_active_initiatives} onClick={() => push('/wall')} />
                            : (renderInitiatives(initiatives, searchText, 'history'))
                          )
                        }
                  />)}
                </TabPane>
              </Tabs>
            { phoneModalShown &&
              <PhoneModal
                onSuccess={() => setPhoneModalShown(false)}
                onCancel={() => setPhoneModalShown(false)}
              />
            }
          </Layout.Content>
        </Sentry.ErrorBoundary>
      </Layout>
      <NavBottom/>
      </Layout>
    );
}

export default injectIntl(withRouter(MyInitiatives));
