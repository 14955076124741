import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';

import {
  Input,
  Modal,
  Col,
  Row,
} from 'antd';

import './style.less';

const { TextArea } = Input;

const OnboardingTextArea = ({
  onboardingMode = false,
  intl,
  value,
  visible,
  onClose,
  onSave,
  loading,
  isRequired,
  onboardingStep
}) => {

  const [textValue, setTextValue] = useState(value);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback(() => {
    if (value === textValue) {
      // Bail out
      return onClose();
    }

    setSaving(true);
    onSave(textValue);
  }, [
    value,
    textValue,
    onClose,
    onSave,
  ]);

  useEffect(() => {
    if(saving && !loading) {
      setSaving(false);
      onClose();
    }
  }, [
    saving,
    loading,
    onClose,
  ]);

  return (
    <Modal
      className="Onboarding__modal"
      onOk={handleSave}
      onCancel={onClose}
      okText={intl.formatMessage({ id: `save` })}
      cancelText={onboardingMode ? intl.formatMessage({ id: `continue_without_this` }) : intl.formatMessage({ id: `cancel` })}
      visible={visible}
      animationType="slide-up"
      title={intl.formatMessage({ id: `onboarding_${onboardingStep}_title` })}
      cancelButtonProps={{ style: { display: isRequired ? 'none' : 'initial' } }}
      maskClosable={!isRequired}
      keyboard={!isRequired}
      closable={!isRequired}
    >
      <div className="Onboarding__modal-inner">
        <Row>
          <Col span={24}>
            <TextArea
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
              placeholder={intl.formatMessage({ id: `onboarding_${onboardingStep}_placeholder` })}
              rows={4}
              autoHeight
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default injectIntl(OnboardingTextArea);
