import React from 'react';
import { injectIntl } from 'react-intl';

import { formatShort } from 'utils/date';

const Restrictions = ({
  intl,
  minorsAllowed,
  registrationEndTime,
  showRegistrationEndTime,
  showWorkingHours
}) => {
  const t = intl.messages;

  // NOTICE: This card (Restrictions) is already only shown on initiative.type === 'volunteering' (or 'training')

  return (
    <>
    { !minorsAllowed && (
      <>
        <span className="title">{ t.restrictions }</span>
        <div className="item">
          <div className="item-name">
            { t.minors_allowed }
          </div>
          <div className="item-value">
            { t.no }
          </div>
        </div>
      </>
    )}

      { showRegistrationEndTime &&
        <div className="item">
          <div className="item-name">
              { t.registration_ends }
          </div>
          <div className="item-value">
              {formatShort(registrationEndTime, intl)}
          </div>
        </div>
      }

      { showWorkingHours && (
        <div className="item">
          <div className="item-name">
            { t.during_working_hours_question }
          </div>
          <div className="item-value">
            { t.yes }
          </div>
        </div>
      )}
  </>
  );
};

export default injectIntl(Restrictions);
