import React from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'antd';

const DisabledDoneButton = ({
  intl,
  // disabled, // THIS IS ALWAYS DISABLED!!!!
}) => {
  const t = intl.messages;
  return (
    <Button
      className="Button-secondary"
      disabled={true}
    >
      { t.done }
    </Button>
  );
};

export default injectIntl(DisabledDoneButton);

