import React, { useEffect, useMemo } from 'react';
import {
  Row,
  Col,
  Divider,
} from 'antd';

import groupBy  from 'utils/groupBy';
import ImageCheckbox  from 'components/Checkbox/ImageCheckbox';
import QuoteWall from 'components/QuoteWall';

const PublicOrganizationSignUp = ({
  t,
  organization,
  handleFieldChange,
  showError,
  values,
  reset,
  public_organizations = [],
  history,
  locationState,
}) => {

  useEffect(() => {
    if(!values.name || !values.email || !values.agreeTC) reset();
  }, [values, reset]);

  const groupedPublicOrganizations = useMemo(
    () => groupBy(public_organizations, 'region'),
    [ public_organizations ]
  );

  return (
    <Row className="SignUp-organization">
      <Col xs={24} sm={12}>
        <h1>{t.choose_organizations_to_start}</h1>
        { showError }
        {Object.entries(groupedPublicOrganizations).map(([region, organizations]) => (
          <Row type="flex" align="middle" justify="left" className="SignUp-publicorglist" key={region}>
            <Divider>{t[`region_${region}`]}</Divider>
            {organizations.map(organization => (
              <ImageCheckbox
                key={organization.slug}
                image={organization.logo_small}
                title={organization.name}
                onChange={() => handleFieldChange.public_organization_slug(organization.slug)}
              />
            ))}
          </Row>
        ))}
      </Col>
      <Col xs={0} sm={12} className="cover">
        <QuoteWall organization={organization} />
      </Col>
    </Row>
  );
}

export default PublicOrganizationSignUp;
