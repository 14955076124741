import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

import {
  Input,
  Modal,
  Row,
  Col,
} from 'antd';

import './style.less';

const { TextArea } = Input;

const OnboardingExperienceEducationSkills = ({
  onboardingMode = false,
  intl,
  details = {},
  visible,
  onClose,
  onSave,
  loading,
  isRequired,
  onboardingStep
}) => {

  const [values, setValues] = useState(details);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback(() => {
      if ((
        onboardingStep === 'experience' &&
        (values.professional_experience === details.professional_experience &&
        values.volunteering_experience === details.volunteering_experience &&
        values.social_work_motivations === details.social_work_motivations &&
        values.talents_and_skills === details.talents_and_skills)
        ) || (
        onboardingStep === 'experience_education_and_skills' &&
        (values.professional_experience === details.professional_experience &&
        values.education === details.education &&
        values.technical_skills === details.technical_skills &&
        values.talents_and_skills === details.talents_and_skills)
        )
      ) {
        // Bail out
        return onClose();
      }

    setSaving(true);
    onSave(values);
  }, [
    values,
    details,
    onClose,
    onSave,
    onboardingStep
  ]);

  useEffect(() => {
    if(saving && !loading) {
      setSaving(false);
      onClose();
    }
  }, [
    saving,
    loading,
    onClose,
  ]);

  const onboardingDetails = useMemo (() => {
    return onboardingStep === 'experience' ? [
      {
        title: intl.formatMessage({ id: `onboarding_professional_experience_title` }),
        value: values.professional_experience,
        key: 'professional_experience',
      },
      {
        title: intl.formatMessage({ id: `onboarding_volunteering_experience_title` }),
        value: values.volunteering_experience,
        key: 'volunteering_experience',
      },
      {
        title: intl.formatMessage({ id: `onboarding_social_work_motivations_title` }),
        value: values.social_work_motivations,
        key: 'social_work_motivations',
      },
      {
        title: intl.formatMessage({ id: `onboarding_talents_and_skills_title` }),
        value: values.talents_and_skills,
        key: 'talents_and_skills',
      },
    ] : [
      {
        title: intl.formatMessage({ id: `onboarding_education_title` }),
        value: values.education,
        key: 'education',
      },
      {
        title: intl.formatMessage({ id: `onboarding_professional_experience_title` }),
        value: values.professional_experience,
        key: 'professional_experience',
      },
      {
        title: intl.formatMessage({ id: `onboarding_technical_skills_title` }),
        value: values.technical_skills,
        key: 'technical_skills',
      },
      {
        title: intl.formatMessage({ id: `onboarding_leadership_skills_title` }),
        value: values.leadership_skills,
        key: 'leadership_skills',
      },
    ]
  }, [intl, values, onboardingStep]);

  return (
    <Modal
      className="Onboarding__modal"
      onOk={handleSave}
      onCancel={onClose}
      okText={intl.formatMessage({ id: `save` })}
      cancelText={onboardingMode ? intl.formatMessage({ id: `continue_without_this` }) : intl.formatMessage({ id: `cancel` })}
      visible={visible}
      animationType="slide-up"
      title={intl.formatMessage({ id: `onboarding_details_title` })}
      cancelButtonProps={{ style: { display: isRequired ? 'none' : 'initial' } }}
      maskClosable={!isRequired}
      keyboard={!isRequired}
      closable={!isRequired}
    >
      <div className="Onboarding__modal-inner">
        {onboardingDetails.map((item) => (
          <Row key={item.key} className="Onboarding__modal-details-row">
            <span>
              {item.title}
            </span>
            <Col span={24}>
              <TextArea
                value={item.value}
                onChange={(e) => setValues({...values, [item.key] : e.target.value})}
                placeholder={item.title}
                rows={4}
                autoHeight
              />
            </Col>
          </Row>
        ))}
      </div>
    </Modal>
  );
};

export default injectIntl(OnboardingExperienceEducationSkills);
