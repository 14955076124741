import React, {
  useState,
  useMemo,
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';
import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  notification,
  Modal,
  Alert,
  Col,
  Row,
} from 'antd';

import useForm from 'utils/useForm';
import PhoneInput from 'components/PhoneInput';
import validation from 'components/PhoneInput/validation';

import './style.less';

const OnboardingPhone = ({
  onboardingMode = false,
  intl,
  value = '',
  visible,
  onClose,
  onSave,
  loading,
  isRequired,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  const validateForm = useMemo(() => validation(t), [ t ]);

  const {
    error: profileError,
  } = useSelector(state => state.my_profile);

  useEffect(() => {
    if (
      profileError?.code === 409 &&
      Object.keys(profileError?.body || {}).includes('phone')
    ) {
      notification.error({
        message: t.profile_update_error_title,
        description: t.profile_update_error_desc,
      });
      dispatch({type: 'RESET_PROFILE_ERROR'});
    }
  }, [
    t,
    dispatch,
    profileError,
  ]);

  const handleSave = () => {
    if (value === values.phone) {
      return onClose();
    }

    setSaving(true);
    onSave(values.phone);
  };

  useEffect(() => {
    if(saving && !loading) {
      setSaving(false);
      onClose();
    }
  }, [
    saving,
    loading,
    onClose,
  ]);

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
  } = useForm({
    callback: handleSave,
    validate: validateForm,
    defaultValues: {
      phone: value,
    },
  });

  return (
    <Modal
      className="Onboarding__modal"
      onCancel={onClose}
      onOk={handleSubmit}
      okText={t.save}
      cancelText={onboardingMode ? t.continue_without_this : t.cancel}
      visible={visible}
      title={t.onboarding_phone}
      confirmLoading={loading}
      cancelButtonProps={{ style: { display: isRequired ? 'none' : 'initial' } }}
      maskClosable={!isRequired}
      keyboard={!isRequired}
      closable={!isRequired}
    >
      <div className="Onboarding__modal-inner">
        <Row>
          <Col span={24}>
            { errors.phone &&
              <Alert message={errors.phone} type="error" showIcon />
            }
            <PhoneInput
              size="large"
              placeholder={t.we_need_your_phone}
              value={values.phone}
              onChange={handleChange('phone')}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default injectIntl(OnboardingPhone);
