import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Modal } from 'antd';

const TransferButton = ({
  submit,
  intl,
  source,
  disabled = false,
  params = {},
  sourceParams = {},
}) => {
  const t = intl.messages;

  const handleClick = useCallback(() => {
    Modal.confirm({
      title: t.donation_transfer_modal_title,
      content: t.donation_transfer_modal_body,
      okText: t.yes,
      cancelText: t.no,
      onOk: () => {
        submit(sourceParams); // TODO: Anything else?
      },
    });
  }, [ t, submit, sourceParams ]);


  return (
    <Button
      className="Button-secondary"
      disabled={disabled}
      onClick={handleClick}
    >
      { t.done }
    </Button>
  );
};

export default injectIntl(TransferButton);

