const DELETED_STATUS = 'deleted';

const isDeletedUser = (userData) => {
  return userData.status === DELETED_STATUS;
};

const getUserName = (userData, intl) => {
  if (isDeletedUser(userData)) {
    return intl.formatMessage({ id: 'deleted_user_label' })
  }
  return userData.name;
};

export {
  isDeletedUser,
  getUserName
}
