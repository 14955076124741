import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

import {
  Row,
  Col,
  List,
  Layout,
  Button,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Nav from 'containers/Nav';
import NavBottom from 'containers/NavBottom';
import A from 'components/A';

const Settings = ({
  history,
  intl,
}) => {
  const t = intl.messages;
  const { push } = history;

  const menuItems = [
    {title: t.account, route: 'account'},
    {title: t.notifications, route: 'notification'},
    {title: t.preferences, route: 'preferences'},
    {title: t.language, route: 'language'}
  ];

  return (
    <>
      <Layout>
        <Nav />
        <Layout.Content className="Settings-main">
          <div className="Settings-header">
            <NavLink
              exact to="/settings"
              activeClassName="active">
              <h1>{t.settings_nav}</h1>
            </NavLink>
          </div>
          <Row>
            <Col span={24} className="Settings-menu">
              <List>
                {menuItems.map((menu) => (
                  <NavLink key={menu} exact to={`/settings/${menu.route}`}>
                    <List.Item extra={<FontAwesomeIcon icon="chevron-right"/>}>
                      {menu.title}
                    </List.Item>
                  </NavLink>
                ))}
              </List>
            </Col>
          </Row>
          <Row justify="center" className="Settings-contact">
            <FontAwesomeIcon
              icon="question-circle"
              size="lg"
            />
              <span>
                { t.have_questions }
              </span>
              <div>
                { t.send_us_email }
                <A href={`mailto:${t.contact_email_address}@aplanet.org`}>
                  { t.contact_email_address }@aplanet.org
                </A>
              </div>
          </Row>
          <Row justify="center" gutter={[20, 20]} className="Settings-button">
            <Col span={24} sm={16} md={10}>
              <Button onClick={() => push('/logout')}>
                { t.logout }
              </Button>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
      <NavBottom />
    </>
  );
}

export default injectIntl(withRouter(Settings));
